<template>
  <div class="modal-card" style="width: auto">
    <div class="container" style="width: 42rem">
      <section class="modal-card-body">
        <h1 class="title">Adatok importálása tervezési tételből</h1>

        <form v-if="loaded">
          <vuelidated-form
            :validations="v$.selectedPlan"
            class="field is-expanded"
          >
            <template v-slot="{ errors, invalid }">
              <b-field
                :message="v$.selectedPlan.$error ? errors[0] : null"
                :type="{ 'is-danger': invalid }"
                label="Terv"
                label-position="on-border"
              >
                <b-autocomplete
                  :data="plans"
                  clearable
                  field="projectName"
                  icon="search"
                  placeholder="pl.: Support 2023"
                  rounded
                  @select="selectedPlan = $event"
                >
                  <template #empty>Nincs találat</template>
                </b-autocomplete>
              </b-field>
            </template>
          </vuelidated-form>

          <b-field class="mt-5 is-pulled-right">
            <b-button @click="close"> Mégsem</b-button>
            <b-button class="ml-3" type="is-info" @click="save">
              Tovább
            </b-button>
          </b-field>
        </form>

        <LoadingSkeleton v-else />
      </section>
    </div>
  </div>
</template>

<script>
import { required } from "@vuelidate/validators";
import LoadingSkeleton from "../module/loading/LoadingSkeleton.vue";
import { mapGetters } from "vuex";
import VuelidatedForm from "@/components/module/input/VuelidatedForm.vue";
import { getCurrentInstance } from "vue";
import useCustomVuelidate from "@/plugins/vuelidate";

export default {
  name: "ForecastPlanSelectModal",
  setup: () => {
    const instance = getCurrentInstance();
    const { v$, touch$ } = useCustomVuelidate();
    return {
      v$,
      touch$,
      instance,
    };
  },
  async mounted() {
    await this.$store.dispatch("forecast_plan/fetchPlans", {
      params: { all: true },
    });
    this.loaded = true;
  },
  data() {
    return {
      loaded: false,
      selectedPlan: undefined,
    };
  },
  components: { VuelidatedForm, LoadingSkeleton },
  validations: {
    selectedPlan: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      plans: "forecast_plan/plans",
    }),
  },
  methods: {
    close() {
      this.instance.emit("close");
    },
    async save() {
      this.touch$();
      if (!this.v$.$invalid) {
        this.$emit("save", this.selectedPlan);
        this.close();
      }
    },
  },
};
</script>
