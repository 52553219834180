<template>
  <div class="modal-card" style="width: 54rem">
    <div class="container">
      <section class="modal-card-body">
        <div class="status-change">
          <h3 class="heading">
            {{ $tf("projects.statusChanger.title|Státuszváltás") }}
          </h3>
          <b-steps
            ref="statusSteps"
            v-model="actualStatus"
            :type="[3, 4].includes(actualStatus) ? 'is-success' : 'is-primary'"
            animated
            label-position="bottom"
            mobile-mode="minimalist"
            rounded
          >
            <b-step-item
              :clickable="false"
              :label="$tf('projects.statusChanger.REQUEST|Kérelem')"
              icon="question"
              step="1"
            />
            <b-step-item
              :clickable="false"
              :label="$tf('projects.statusChanger.APPROVED|Elfogadva')"
              icon="thumbs-up"
              step="2"
            />
            <b-step-item
              :clickable="false"
              :label="$tf('projects.statusChanger.ONGOING|Folyamatban')"
              icon="long-arrow-alt-right"
              step="3"
            />
            <b-step-item
              :clickable="false"
              :label="$tf('projects.statusChanger.DONE|Kész')"
              icon="check"
              step="4"
            />
            <b-step-item
              :clickable="false"
              :label="$tf('projects.statusChanger.CLOSED|Lezárva')"
              icon="lock"
              step="5"
            />

            <template #navigation="{ previous, next }">
              <b-button
                :disabled="previous.disabled"
                icon-left="chevron-left"
                @click="moveStatus('prev', actualStatus - 1)"
              >
                {{ $tf("projects.statusChanger.prev|Vissza") }}
              </b-button>
              <b-button
                :disabled="next.disabled"
                class="ml-2"
                icon-right="chevron-right"
                @click="moveStatus('next', actualStatus + 1)"
              >
                {{ $tf("projects.statusChanger.next|Előre") }}
              </b-button>

              <template v-if="actualStatus === 0">
                <b-button
                  class="ml-2"
                  icon-left="undo"
                  @click="changeStatusPrompt('CANCELLED')"
                >
                  {{ $tf("projects.statusChanger.CANCELLED|Visszavonás") }}
                </b-button>
                <b-button
                  class="ml-2"
                  icon-left="ban"
                  @click="changeStatusPrompt('DENIED')"
                >
                  {{ $tf("projects.statusChanger.DENIED|Elutasítás") }}
                </b-button>
              </template>
              <template v-if="actualStatus !== 0 && actualStatus !== 4">
                <b-button
                  class="ml-2"
                  icon-left="stop"
                  @click="changeStatusPrompt('CLOSED')"
                >
                  {{ $tf("projects.statusChanger.CLOSED|Lezárás") }}
                </b-button>
              </template>
              <template v-else-if="actualStatus === 4">
                <b-button
                  class="ml-2"
                  icon-left="box-archive"
                  @click="showArchiveConfirmDialog"
                >
                  {{ $tf("projects.statusChanger.ARCHIVE|Archiválás") }}
                </b-button>
              </template>
            </template>
          </b-steps>

          <div class="close-btn cursor-pointer" @click="close">
            <b-icon icon="times" icon-pack="fas" type="is-grey-icon" />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/utils/util";
import { getCurrentInstance } from "vue";

export default {
  name: "StatusChanger",
  setup: () => {
    const instance = getCurrentInstance();
    return {
      instance: instance,
    };
  },
  mixins: [],
  mounted() {
    this.setActualStatus();
  },
  data() {
    return {
      actualStatus: null,
    };
  },
  props: {
    projectData: {
      type: Object,
      required: true,
    },
    projectType: {
      type: String,
      required: true,
    },
  },
  methods: {
    setActualStatus() {
      this.actualStatus = this.getStatusValue(this.projectData.status);
    },
    changeStatusPrompt(status, isReopen) {
      this.$buefy.dialog.prompt({
        message: this.$tf(
          "projects.statusChanger.changePrompt.message|Új státusz: <b> {newStatus}</b> <br/>Kérlek add meg a változás dátumát!",
          {
            newStatus: this.resolveStatus(status),
          }
        ),
        title: isReopen
          ? this.$tf(
              "projects.statusChanger.changePrompt.reopenTitle|Projekt újranyitása"
            )
          : this.$tf(
              "projects.statusChanger.changePrompt.title|Státuszváltozás"
            ),
        cancelText: this.$tf(
          "projects.statusChanger.changePrompt.cancel|Mégsem"
        ),
        confirmText: this.$tf(
          "projects.statusChanger.changePrompt.save|Mentés"
        ),
        type: "is-success",
        inputAttrs: {
          type: "date",
          value: formatDate(new Date()),
        },
        trapFocus: true,
        onConfirm: async (value) => {
          await this.saveStatusChange(status, value);
        },
      });
    },
    showArchiveConfirmDialog() {
      this.$buefy.dialog.confirm({
        message: this.$tf(
          "projects.statusChanger.archiveConfirm|Biztosan archiválni szeretnéd a projektet?"
        ),
        title: this.$tf(
          "projects.statusChanger.archiveConfirmTitle|Projekt archiválás"
        ),
        cancelText: this.$tf("projects.statusChanger.cancel|Mégsem"),
        confirmText: this.$tf("projects.statusChanger.confirm|Archiválás"),
        type: "is-success",
        trapFocus: true,
        onConfirm: async () => {
          await this.archive();
        },
      });
    },
    async saveStatusChange(status, changeDate) {
      await this.$store.dispatch("enterprise_projects/statusChange", {
        identifier: this.projectData.identifier,
        status: status,
        date: formatDate(changeDate),
        projectType: this.projectType,
      });
      await this.clearCache();
    },
    async archive() {
      await this.$store.dispatch("enterprise_projects/archive", {
        identifier: this.projectData.identifier,
        projectType: this.projectType,
      });
      await this.clearCache();
    },
    async clearCache() {
      this.instance.emit("save");
      this.close();
    },
    getStatusText(value) {
      switch (value) {
        case 0:
          return "REQUEST";
        case 1:
          return "APPROVED";
        case 2:
          return "ONGOING";
        case 3:
          return "DONE";
        case 4:
          return "CLOSED";
        default:
          return -1;
      }
    },
    getStatusValue(text) {
      switch (text) {
        case "REQUEST":
          return 0;
        case "APPROVED":
          return 1;
        case "ONGOING":
          return 2;
        case "DONE":
          return 3;
        case "CLOSED":
          return 4;
        default:
          return -1;
      }
    },
    moveStatus(direction, newStatusIndex) {
      let status = this.getStatusText(newStatusIndex);

      this.$buefy.dialog.prompt({
        message: this.$tf(
          "projects.statusChanger.movePrompt.message|Új státusz: <b> {newStatus}</b> <br/>Kérlek add meg a változás dátumát!",
          {
            newStatus: this.resolveStatus(status),
          }
        ),
        title: this.$tf(
          "projects.statusChanger.movePrompt.title|Státuszváltozás"
        ),
        cancelText: this.$tf("projects.statusChanger.movePrompt.cancel|Mégsem"),
        confirmText: this.$tf("projects.statusChanger.movePrompt.save|Mentés"),
        type: "is-success",
        inputAttrs: {
          type: "date",
          value: formatDate(new Date()),
        },
        trapFocus: true,
        onConfirm: async (value) => {
          if (direction === "prev") {
            this.$refs.statusSteps.prev();
          } else if (direction === "next") {
            this.$refs.statusSteps.next();
          }
          await this.saveStatusChange(status, value);
        },
      });
    },
    resolveStatus(enu) {
      switch (enu) {
        case "REQUEST":
          return this.$tf("projects.statusChanger.REQUEST|Kérelem");
        case "CANCELLED":
          return this.$tf("projects.statusChanger.CANCELLED|Visszavonva");
        case "DENIED":
          return this.$tf("projects.statusChanger.DENIED|Elutasítva");
        case "APPROVED":
          return this.$tf("projects.statusChanger.APPROVED|Elfogadva");
        case "ONGOING":
          return this.$tf("projects.statusChanger.ONGOING|Folyamatban");
        case "DONE":
          return this.$tf("projects.statusChanger.DONE|Kész");
        case "CLOSED":
          return this.$tf("projects.statusChanger.CLOSED|Lezárva");
      }
    },
    close() {
      this.instance.emit("close");
    },
  },
};
</script>

<style scoped></style>
