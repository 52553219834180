<template>
  <section class="section">
    <div class="container is-flex is-justify-content-space-between">
      <div>
        <h1 class="title">
          {{ $tf("projectBaseData.page.title|Projekt törzsadatok") }}
        </h1>
        <h2 class="subtitle">
          {{ $tf("projectBaseData.page.subtitle|Törzsadatok beállítása") }}
        </h2>
      </div>
    </div>

    <div class="container">
      <b-tabs v-model="activeTab" class="toggle-only" expanded type="is-toggle">
        <b-tab-item
          v-for="type in PROJECT_BASE_DATA_TYPE.ENUM"
          :key="type"
          :label="$tf(PROJECT_BASE_DATA_TYPE.LABEL.TAB_ITEM[type])"
          :value="type"
        />
      </b-tabs>

      <div v-for="type in PROJECT_BASE_DATA_TYPE.ENUM" :key="type">
        <ProjectBaseDataTab
          v-if="activeTab === type"
          :ref="type"
          :type="type"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { PROJECT_BASE_DATA_TYPE } from "@/utils/const";
import ProjectBaseDataTab from "@/components/enterprise/base-data/ProjectBaseDataTab.vue";

export default {
  name: "ProjectBaseData",
  data() {
    return {
      PROJECT_BASE_DATA_TYPE,
      activeTab: PROJECT_BASE_DATA_TYPE.ENUM.SERVICE_TYPE,
      carouselPage: 0,
      carouselDetailsOpen: false,
    };
  },
  components: {
    ProjectBaseDataTab,
  },
};
</script>
