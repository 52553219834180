<template>
  <div class="modal-card" style="width: auto; overflow: visible">
    <div class="container" style="width: 50rem; overflow: visible">
      <section class="modal-card-body" style="overflow: visible">
        <h1 v-if="isCreate" class="title">Új hozzáférés hozzáadása</h1>
        <h1 v-else class="title">Hozzáférések módosítása</h1>

        <form @submit.prevent="submit">
          <b-field class="mt-5" label="Dolgozó" label-position="on-border">
            <b-autocomplete
              v-model="name"
              :data="employeeFilter(name, employees)"
              :disabled="!isCreate"
              clearable
              field="name"
              icon="search"
              placeholder="pl.: Elektrom Ágnes"
              rounded
              @select="(option) => selectUser(option)"
            >
              <template v-slot:empty>Nincs találat</template>
            </b-autocomplete>
          </b-field>

          <b-field label="Hozzáférések">
            <b-taglist class="filter-tags">
              <b-tag
                v-for="(tag, index) in editData.permissionsEdit"
                :key="index"
                :closable="false"
                :tabstop="false"
                :type="getTypeForPermission(tag.text)"
              >
                {{ tag.text }}
              </b-tag>
            </b-taglist>
          </b-field>

          <div class="mb-4">
            <div class="pt-2">
              <permit-entry
                v-for="(entry, index) in changes"
                ref="entries"
                v-bind:key="index"
                :entry="entry"
                :index="index"
                :permissions="unusedPermissions(index)"
                :permits="editData.permits"
                class="mb-4"
                style="overflow: visible"
                v-on:minus="removeEntry"
              >
              </permit-entry>
            </div>

            <b-field v-if="changes.length !== permissions.length">
              <b-button
                size="is-small"
                type="is-light is-info"
                @click="addEntry"
                >Hozzáférésváltoztaztás-kérelem hozzáadása
              </b-button>
            </b-field>
          </div>

          <b-field class="mt-5 is-pulled-right">
            <b-button @click="close"> Mégse</b-button>
            <b-button class="ml-3" native-type="submit" type="is-info">
              Mentés
            </b-button>
          </b-field>
        </form>
      </section>
    </div>
  </div>
</template>

<script>
import {
  correctWithTimezoneOffset,
  deepCopy,
  employeeFilter,
  normalize,
} from "@/utils/util";
import { USER_PERMISSION_TYPE_ENUM } from "@/utils/const";
import { mapGetters } from "vuex";
import PermitEntry from "@/components/census/permission/PermitEntry";
import { getCurrentInstance } from "vue";

export default {
  name: "UserPermitForm",
  setup: () => {
    const instance = getCurrentInstance();
    return {
      instance: instance,
    };
  },
  components: { PermitEntry },
  props: {
    data: {
      type: Object,
      required: false,
      default: null,
    },
    permissions: {
      type: Array,
      required: true,
    },
    employees: {
      type: Array,
      require: true,
    },
    fetch: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      name: "",
      selectedUser: null,
      filteredPermissions: [],
      editData: {
        user: "",
        permits: [],
      },
      loading: false,
      isCreate: true,
      changes: [],
      employeeFilter,
    };
  },
  mounted() {
    if (this.data) {
      this.preloadData(this.data);
    } else {
      this.isCreate = true;
    }
  },
  computed: {
    ...mapGetters({
      permits: "user_permission/userPermits",
    }),
  },
  methods: {
    preloadData(values) {
      this.isCreate = false;
      this.name = values.userName;
      this.selectedUser = values.userId;
      this.editData = JSON.parse(JSON.stringify(values));

      this.changes = deepCopy(this.editData.permits).filter(
        (permit) => permit.status !== "HAVE"
      );
      this.changes.forEach((permit) => {
        permit.disabled = true;
        permit.permission = permit.permission.id;
      });

      this.filteredPermissions = this.permissions.map((i) => {
        return {
          value: i.id,
          text: i.name,
        };
      });
      if (this.editData.permits) {
        this.editData.permissionsEdit = this.editData.permits
          .filter(
            (permit) => permit.status !== USER_PERMISSION_TYPE_ENUM.NOT_HAVE
          )
          .map((permit) =>
            this.filteredPermissions
              .filter((pp) => pp.value === permit.permission.id)
              .shift()
          );
      }
    },
    filterTags(value, selectable, fieldKey) {
      let filtered = [];
      if (!value) {
        filtered = selectable;
      } else {
        filtered = selectable.filter((e) =>
          normalize(e.text).includes(normalize(value))
        );
      }
      this[fieldKey] = filtered;
    },
    getTypeForPermission(permission) {
      let permit = this.editData.permits
        .filter((permit) => {
          if (permit.permission) return permit.permission.name === permission;
          return false;
        })
        .shift();
      if (permit) {
        switch (permit.status) {
          case "HAVE":
            return "is-success";
          case "SHOULD_NOT_HAVE":
            return "is-danger";
          default:
            return "is-warning";
        }
      }
      return "is-warning";
    },
    getPermissionTagClosable(permission) {
      let permit = this.editData.permits
        .filter((permit) => {
          if (permit.permission) return permit.permission.name === permission;
          return false;
        })
        .shift();
      return (
        !permit || permit.status === "HAVE" || permit.status === "NOT_HAVE"
      );
    },
    close() {
      this.fetch(true);
      this.instance.emit("close");
    },
    async submit() {
      let changeEntryComponents = this.$refs.entries;
      let entryValues = [];

      if (changeEntryComponents) {
        for (let index in changeEntryComponents) {
          let changeEntryComponent = changeEntryComponents[index];
          if (await changeEntryComponent.isInvalid()) {
            return;
          }
          entryValues.push(changeEntryComponent.getValues());
        }
      }

      const payload = this.employees
        .filter((employee) => employee.id === this.selectedUser)
        .shift();
      entryValues.forEach((c) => {
        c.permission = this.permissions
          .filter((i) => i.id === c.permission)
          .shift();
        c.deadline = correctWithTimezoneOffset(new Date(c.deadline));
      });
      payload.permissions = deepCopy(entryValues);

      this.$store.dispatch("employee/update", payload).then(() => {
        this.close();
      });
    },
    selectUser(option) {
      this.loading = true;
      this.selectedUser = option.id;
      this.$store
        .dispatch("user_permission/fetchUserPermits", option.id)
        .then(() => {
          const data = {
            user: option.id,
            permits: this.permits,
          };
          this.preloadData(data);
        });
      this.loading = false;
    },

    async addEntry() {
      let changeEntryComponents = this.$refs.entries;

      if (changeEntryComponents) {
        for (let index in changeEntryComponents) {
          let changeEntryComponent = changeEntryComponents[index];
          if (await changeEntryComponent.isInvalid()) {
            return;
          }
        }
      }

      let deadline = new Date();
      deadline.setDate(deadline.getDate() + 30);
      this.changes.push({
        permission: null,
        status: "SHOULD_HAVE",
        deadline: deadline,
        disabled: false,
      });
    },
    removeEntry(index) {
      this.changes.splice(index, 1);
    },
    unusedPermissions(comp) {
      let changeEntryComponents = this.$refs.entries;
      let used = [];

      if (changeEntryComponents) {
        for (let index in changeEntryComponents) {
          if (parseInt(index) === comp) continue;
          let changeEntryComponent = changeEntryComponents[index];
          used.push(changeEntryComponent.getValues()?.permission);
        }
      }
      return this.permissions.filter(
        (permission) =>
          !used.some((usedPermission) => usedPermission === permission.id)
      );
    },
  },
};
</script>
