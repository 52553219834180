module.exports = {
  "bulmaCssVariablesDefs": {
    "always-black": {
      "calls": []
    },
    "always-white": {
      "calls": []
    },
    "black": {
      "calls": [
        {
          "fn": "rgba",
          "fnArg": "10",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "1000",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "2",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "2",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "20",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "250",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "5",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "50",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "500",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "80",
          "composeArg": null
        },
        {
          "fn": "lighten",
          "fnArg": "800",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "86",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "90",
          "composeArg": null
        }
      ]
    },
    "black-bis": {
      "calls": []
    },
    "black-ter": {
      "calls": []
    },
    "blue": {
      "calls": [
        {
          "fn": "darken",
          "fnArg": "1000",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "2",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "25",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "250",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "5",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "500",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "80",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "90",
          "composeArg": null
        },
        {
          "fn": "color-invert",
          "fnArg": null,
          "composeArg": null
        },
        {
          "fn": "color-invert",
          "fnArg": null,
          "composeArg": {
            "fn": "darken",
            "fnArg": "500",
            "composeArg": null
          }
        },
        {
          "fn": "dark-color",
          "fnArg": null,
          "composeArg": null
        },
        {
          "fn": "light-color",
          "fnArg": null,
          "composeArg": null
        },
        {
          "fn": "light-color",
          "fnArg": null,
          "composeArg": {
            "fn": "darken",
            "fnArg": "250",
            "composeArg": null
          }
        },
        {
          "fn": "light-color",
          "fnArg": null,
          "composeArg": {
            "fn": "darken",
            "fnArg": "500",
            "composeArg": null
          }
        }
      ]
    },
    "configuration-warning": {
      "calls": []
    },
    "custom-danger": {
      "calls": []
    },
    "custom-dark-blue": {
      "calls": []
    },
    "custom-fatal": {
      "calls": []
    },
    "custom-grayer-blue": {
      "calls": []
    },
    "custom-green-light": {
      "calls": []
    },
    "custom-light-blue": {
      "calls": []
    },
    "custom-lighter-blue": {
      "calls": []
    },
    "custom-success": {
      "calls": []
    },
    "custom-text-danger": {
      "calls": []
    },
    "custom-text-fatal": {
      "calls": []
    },
    "custom-text-success": {
      "calls": []
    },
    "custom-text-warning": {
      "calls": []
    },
    "custom-warning": {
      "calls": []
    },
    "forecast-primary": {
      "calls": []
    },
    "forecast-secondary": {
      "calls": []
    },
    "forecast-tertiary": {
      "calls": []
    },
    "green": {
      "calls": [
        {
          "fn": "rgba",
          "fnArg": "10",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "1000",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "2",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "250",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "5",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "500",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "80",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "90",
          "composeArg": null
        },
        {
          "fn": "color-invert",
          "fnArg": null,
          "composeArg": null
        },
        {
          "fn": "color-invert",
          "fnArg": null,
          "composeArg": {
            "fn": "darken",
            "fnArg": "500",
            "composeArg": null
          }
        },
        {
          "fn": "dark-color",
          "fnArg": null,
          "composeArg": null
        },
        {
          "fn": "light-color",
          "fnArg": null,
          "composeArg": null
        },
        {
          "fn": "light-color",
          "fnArg": null,
          "composeArg": {
            "fn": "darken",
            "fnArg": "250",
            "composeArg": null
          }
        },
        {
          "fn": "light-color",
          "fnArg": null,
          "composeArg": {
            "fn": "darken",
            "fnArg": "500",
            "composeArg": null
          }
        }
      ]
    },
    "grey": {
      "calls": [
        {
          "fn": "rgba",
          "fnArg": "10",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "60",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "70",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "80",
          "composeArg": null
        }
      ]
    },
    "grey-dark": {
      "calls": []
    },
    "grey-darker": {
      "calls": [
        {
          "fn": "darken",
          "fnArg": "1000",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "2",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "250",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "5",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "500",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "80",
          "composeArg": null
        },
        {
          "fn": "lighten",
          "fnArg": "800",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "90",
          "composeArg": null
        },
        {
          "fn": "color-invert",
          "fnArg": null,
          "composeArg": null
        },
        {
          "fn": "color-invert",
          "fnArg": null,
          "composeArg": {
            "fn": "darken",
            "fnArg": "500",
            "composeArg": null
          }
        }
      ]
    },
    "grey-light": {
      "calls": [
        {
          "fn": "rgba",
          "fnArg": "90",
          "composeArg": null
        }
      ]
    },
    "grey-lighter": {
      "calls": [
        {
          "fn": "rgba",
          "fnArg": "50",
          "composeArg": null
        }
      ]
    },
    "grey-lightest": {
      "calls": []
    },
    "info": {
      "calls": [
        {
          "fn": "darken",
          "fnArg": "1000",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "2",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "250",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "5",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "500",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "80",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "90",
          "composeArg": null
        },
        {
          "fn": "color-invert",
          "fnArg": null,
          "composeArg": null
        },
        {
          "fn": "color-invert",
          "fnArg": null,
          "composeArg": {
            "fn": "darken",
            "fnArg": "500",
            "composeArg": null
          }
        },
        {
          "fn": "dark-color",
          "fnArg": null,
          "composeArg": null
        },
        {
          "fn": "light-color",
          "fnArg": null,
          "composeArg": null
        },
        {
          "fn": "light-color",
          "fnArg": null,
          "composeArg": {
            "fn": "darken",
            "fnArg": "250",
            "composeArg": null
          }
        },
        {
          "fn": "light-color",
          "fnArg": null,
          "composeArg": {
            "fn": "darken",
            "fnArg": "500",
            "composeArg": null
          }
        }
      ]
    },
    "primary": {
      "calls": [
        {
          "fn": "darken",
          "fnArg": "1000",
          "composeArg": null
        },
        {
          "fn": "lighten",
          "fnArg": "150",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "2",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "25",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "250",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "5",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "50",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "500",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "80",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "90",
          "composeArg": null
        },
        {
          "fn": "color-invert",
          "fnArg": null,
          "composeArg": null
        },
        {
          "fn": "color-invert",
          "fnArg": null,
          "composeArg": {
            "fn": "darken",
            "fnArg": "500",
            "composeArg": null
          }
        }
      ]
    },
    "primary-dark": {
      "calls": []
    },
    "primary-darker": {
      "calls": []
    },
    "primary-darkest": {
      "calls": []
    },
    "primary-light": {
      "calls": [
        {
          "fn": "darken",
          "fnArg": "250",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "500",
          "composeArg": null
        }
      ]
    },
    "primary-lighter": {
      "calls": []
    },
    "primary-lightest": {
      "calls": []
    },
    "purple": {
      "calls": [
        {
          "fn": "rgba",
          "fnArg": "10",
          "composeArg": null
        }
      ]
    },
    "red": {
      "calls": [
        {
          "fn": "rgba",
          "fnArg": "10",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "1000",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "1500",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "2",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "250",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "5",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "500",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "80",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "90",
          "composeArg": null
        },
        {
          "fn": "color-invert",
          "fnArg": null,
          "composeArg": null
        },
        {
          "fn": "color-invert",
          "fnArg": null,
          "composeArg": {
            "fn": "darken",
            "fnArg": "500",
            "composeArg": null
          }
        },
        {
          "fn": "dark-color",
          "fnArg": null,
          "composeArg": null
        },
        {
          "fn": "light-color",
          "fnArg": null,
          "composeArg": null
        },
        {
          "fn": "light-color",
          "fnArg": null,
          "composeArg": {
            "fn": "darken",
            "fnArg": "250",
            "composeArg": null
          }
        },
        {
          "fn": "light-color",
          "fnArg": null,
          "composeArg": {
            "fn": "darken",
            "fnArg": "500",
            "composeArg": null
          }
        }
      ]
    },
    "scheme-main": {
      "calls": []
    },
    "sidebar-bg-color": {
      "calls": []
    },
    "sidebar-text-color": {
      "calls": []
    },
    "sidebar-toggle-bg-color": {
      "calls": []
    },
    "text": {
      "calls": [
        {
          "fn": "color-invert",
          "fnArg": null,
          "composeArg": null
        }
      ]
    },
    "text-strong": {
      "calls": []
    },
    "turquoise": {
      "calls": []
    },
    "white": {
      "calls": [
        {
          "fn": "darken",
          "fnArg": "1000",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "2",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "250",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "5",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "500",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "80",
          "composeArg": null
        },
        {
          "fn": "lighten",
          "fnArg": "800",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "90",
          "composeArg": null
        }
      ]
    },
    "white-bis": {
      "calls": []
    },
    "white-ter": {
      "calls": [
        {
          "fn": "darken",
          "fnArg": "1000",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "2",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "250",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "5",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "500",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "80",
          "composeArg": null
        },
        {
          "fn": "lighten",
          "fnArg": "800",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "90",
          "composeArg": null
        },
        {
          "fn": "color-invert",
          "fnArg": null,
          "composeArg": null
        },
        {
          "fn": "color-invert",
          "fnArg": null,
          "composeArg": {
            "fn": "darken",
            "fnArg": "500",
            "composeArg": null
          }
        }
      ]
    },
    "yellow": {
      "calls": [
        {
          "fn": "rgba",
          "fnArg": "10",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "1000",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "2",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "250",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "5",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "500",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "80",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "90",
          "composeArg": null
        },
        {
          "fn": "color-invert",
          "fnArg": null,
          "composeArg": null
        },
        {
          "fn": "color-invert",
          "fnArg": null,
          "composeArg": {
            "fn": "darken",
            "fnArg": "500",
            "composeArg": null
          }
        },
        {
          "fn": "dark-color",
          "fnArg": null,
          "composeArg": null
        },
        {
          "fn": "light-color",
          "fnArg": null,
          "composeArg": null
        },
        {
          "fn": "light-color",
          "fnArg": null,
          "composeArg": {
            "fn": "darken",
            "fnArg": "250",
            "composeArg": null
          }
        },
        {
          "fn": "light-color",
          "fnArg": null,
          "composeArg": {
            "fn": "darken",
            "fnArg": "500",
            "composeArg": null
          }
        }
      ]
    }
  }
};
