<template>
  <div class="modal-card" style="width: auto">
    <div class="container" style="width: 24rem">
      <section class="modal-card-body">
        <h1 class="title">
          {{ $tf("users.import.modal.title|Felhasználók importálása") }}
        </h1>

        <form>
          <div v-if="invalidRows" class="mb-2">
            <div v-if="invalidRows.length === 0" class="columns mb-2">
              <div class="column">
                <h4 class="subtitle">
                  {{
                    $tf(
                      "users.import.modal.success|Adatok sikeresen beolvasva."
                    )
                  }}
                </h4>
              </div>
              <div class="column is-narrow">
                <b-icon
                  icon="circle-check"
                  pack="fad"
                  size="is-large"
                  type="is-success"
                ></b-icon>
              </div>
            </div>
            <div v-else>
              <div class="columns">
                <div class="column">
                  <h4 class="subtitle">
                    {{
                      $tf(
                        "users.import.modal.invalidRows|Az alábbi sorokat nem sikerült beolvasni:"
                      )
                    }}
                  </h4>
                </div>
                <div class="column is-narrow">
                  <b-icon
                    icon="triangle-exclamation"
                    pack="fad"
                    size="is-large"
                    type="is-warning"
                  ></b-icon>
                </div>
              </div>
              <p v-for="(invalidRow, i) in invalidRows" :key="i">
                <b>{{ `"${invalidRow.sheet}" ` }}</b>
                {{
                  `${$tf("users.import.modal.invalidRows.sheet|munkalap")}, ${
                    invalidRow.row + 1
                  }. ${$tf("users.import.modal.invalidRows.row|sor")}, `
                }}
                <b>{{ `"${invalidRow.column}" ` }}</b>
                {{ `${$tf("users.import.modal.invalidRows.column|mező")}` }}
              </p>
            </div>
          </div>
          <b-field v-else>
            <b-upload
              v-model="file"
              :disabled="isImportInProgress"
              drag-drop
              expanded
            >
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="upload" size="is-large"></b-icon>
                  </p>
                  <p v-if="file">{{ file.name }}</p>
                  <p v-else>
                    {{
                      $tf(
                        "users.import.modal.uploadText|Húzd ide a fájlt vagy kattints a feltöltéshez."
                      )
                    }}
                  </p>
                </div>
              </section>
            </b-upload>
          </b-field>
          <b-field v-if="invalidRows" class="is-pulled-right">
            <b-button class="ml-3" type="is-info" @click="close">
              {{ $tf("users.standard.modal.close|Bezárás") }}
            </b-button>
          </b-field>
          <b-field v-else class="is-pulled-right">
            <b-button @click="close">
              {{ $tf("users.standard.modal.cancel|Mégse") }}
            </b-button>
            <b-button
              :disabled="file === null"
              :loading="isImportInProgress"
              class="ml-3"
              type="is-info"
              @click="save"
            >
              {{ $tf("users.standard.modal.save|Mentés") }}
            </b-button>
          </b-field>
        </form>
      </section>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance } from "vue";
import { mapGetters } from "vuex";

export default {
  name: "UserImportModal",
  setup: () => {
    const instance = getCurrentInstance();
    return {
      instance: instance,
    };
  },
  data() {
    return {
      file: null,
      invalidRows: null,
      isImportInProgress: false,
    };
  },
  computed: {
    ...mapGetters({
      env: "env/getField",
    }),
  },
  methods: {
    close() {
      this.instance.emit("close");
    },
    async save() {
      this.isImportInProgress = true;
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("lang", this.env("DEFAULT_LANGUAGE"));
      this.invalidRows = await this.$store.dispatch(
        "census_user/importUsers",
        formData
      );
      this.isImportInProgress = false;
      this.$emit("save");
    },
  },
};
</script>
