<template>
  <section class="section">
    <div class="container">
      <template v-if="loading">
        <LoadingSkeleton></LoadingSkeleton>
      </template>
      <template v-else>
        <div>
          <b-field grouped>
            <b-field>
              <b-checkbox v-model="onlyMine"
                >{{ $tf("taskPage.filter.onlyMine|Csak hozzám rendelt") }}
              </b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox v-model="hideDone"
                >{{ $tf("taskPage.filter.hideDone|Kész jegyek elrejtése") }}
              </b-checkbox>
            </b-field>
          </b-field>
        </div>
        <template v-if="issues.length > 0">
          <div
            v-for="project in filteredIssues"
            :key="project.projectId"
            class="mt-4 mb-4"
          >
            <h1 class="subtitle">
              {{ project.projectName }} ({{ project.projectIdentifier }})
            </h1>
            <div class="columns">
              <div class="column">
                <h3 class="subtitle">
                  {{ $tf("taskPage.taskTab.toDo|Teendő") }}
                </h3>
                <template
                  v-if="project.issues.find((i) => i.status === 'TODO')"
                >
                  <logging-task-card
                    v-for="issue in project.issues.filter(
                      (i) => i.status === 'TODO'
                    )"
                    :key="issue.id"
                    :employees="employees"
                    :issue="issue"
                  ></logging-task-card>
                </template>
                <template v-else>
                  <div class="card p-4">
                    <div class="content">
                      <p
                        class="has-font-weight-700 has-text-grey has-text-centered"
                      >
                        {{
                          $tf(
                            "taskPage.taskTab.toDO.empty|Nincsenek teendő jegyek"
                          )
                        }}
                      </p>
                    </div>
                  </div>
                </template>
              </div>
              <div class="column">
                <h3 class="subtitle">
                  {{ $tf("taskPage.taskTab.doing|Folyamatban") }}
                </h3>
                <template
                  v-if="project.issues.find((i) => i.status === 'IN_PROGRESS')"
                >
                  <logging-task-card
                    v-for="issue in project.issues.filter(
                      (i) => i.status === 'IN_PROGRESS'
                    )"
                    :key="issue.id"
                    :employees="employees"
                    :issue="issue"
                  ></logging-task-card>
                </template>
                <template v-else>
                  <div class="card p-4">
                    <div class="content">
                      <p
                        class="has-font-weight-700 has-text-grey has-text-centered"
                      >
                        {{
                          $tf(
                            "taskPage.taskTab.doing.empty|Nincsenek folyamatban lévő jegyek"
                          )
                        }}
                      </p>
                    </div>
                  </div>
                </template>
              </div>
              <div v-if="!hideDone" class="column">
                <h3 class="subtitle">
                  {{ $tf("taskPage.taskTab.done|Elvégezve") }}
                </h3>
                <template
                  v-if="project.issues.find((i) => i.status === 'DONE')"
                >
                  <logging-task-card
                    v-for="issue in project.issues.filter(
                      (i) => i.status === 'DONE'
                    )"
                    :key="issue.id"
                    :employees="employees"
                    :issue="issue"
                  ></logging-task-card>
                </template>
                <template v-else>
                  <div class="card p-4">
                    <div class="content">
                      <p
                        class="has-font-weight-700 has-text-grey has-text-centered"
                      >
                        {{
                          $tf(
                            "taskPage.taskTab.done.empty|Nincsenek elvégzett jegyek"
                          )
                        }}
                      </p>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <template v-if="filteredIssues.length === 0">
            <div class="container mt-4 mb-4">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon icon="exclamation-triangle" size="is-large"></b-icon>
                </p>
                <p>
                  {{
                    $tf(
                      "taskPage.noFilteredIssues|Nincs a keresésnek megfelelő jegy!"
                    )
                  }}
                </p>
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="container mt-4 mb-4">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon icon="exclamation-triangle" size="is-large"></b-icon>
              </p>
              <p>
                {{
                  $tf(
                    "taskPage.noOngoingProjects|Nincs folyamatban lévő projekt!"
                  )
                }}
              </p>
            </div>
          </div>
        </template>
      </template>
    </div>
  </section>
</template>

<script>
import LoadingSkeleton from "@/components/module/loading/LoadingSkeleton.vue";
import { mapGetters } from "vuex";
import LoggingTaskCard from "@/components/enterprise/logging/LoggingTaskCard.vue";

export default {
  name: "SelfTrackingTaskList",
  components: { LoggingTaskCard, LoadingSkeleton },
  data() {
    return {
      loading: true,
      onlyMine: true,
      hideDone: true,
    };
  },
  computed: {
    ...mapGetters({
      issues: "enterprise_core/activeIssues",
      employees: "employee/loggingEmployees",
      userId: "session/userId",
    }),
    filteredIssues() {
      return this.issues
        .map((project) => {
          return {
            ...project,
            issues: project.issues.filter((issue) => {
              const onlyMinePredicate = this.onlyMine
                ? issue.assignee === this.userId
                : true;
              const hideDonePredicate = this.hideDone
                ? issue.status !== "DONE"
                : true;
              return onlyMinePredicate && hideDonePredicate;
            }),
          };
        })
        .filter((project) => project.issues.length > 0);
    },
  },
  mounted() {
    this.$store.dispatch("enterprise_core/fetchActiveIssues");
    // this.$store.dispatch("employee/fetchWorkers");
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped></style>
