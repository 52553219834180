<template>
  <section class="section">
    <div class="container">
      <h1 class="title">
        {{ $tf("adminCreateAbsence.title|Távollét felvétele") }}
      </h1>
      <h2 class="subtitle">
        {{
          $tf(
            "adminCreateAbsence.subtitle|Az így felvett szabadságok automatikusan elfogadottak lesznek, vagy kivettek ha a múltba történik a rögzítés"
          )
        }}
      </h2>
    </div>
    <div class="is-flex is-justify-content-center">
      <div style="width: 600px">
        <div class="container">
          <form @submit.prevent="submit">
            <b-field
              :label="$tf('adminCreateAbsence.user|Dolgozó')"
              label-position="on-border"
            >
              <b-autocomplete
                v-model="name"
                :data="filteredEmployees"
                :placeholder="
                  $tf('adminCreateAbsence.user.placeholder|pl. Elektrom Ágnes')
                "
                clearable
                field="name"
                icon="search"
                rounded
                @select="(option) => (selectedUser = option)"
              >
                <template #empty
                  >{{ $tf("adminCreateAbsence.user.empty|Nincs találat") }}
                </template>
              </b-autocomplete>
            </b-field>
            <b-field :expanded="true">
              <vuelidated-form :validations="v$.formData.type">
                <template v-slot="{ errors, invalid }">
                  <b-field
                    :expanded="true"
                    :message="v$.formData.type.$error ? errors[0] : null"
                    :type="{ 'is-danger': invalid }"
                    label-for="type"
                  >
                    <template #label>
                      {{ $tf("adminCreateAbsence.type|Típus") }}
                      <span class="has-text-danger">*</span>
                    </template>
                    <b-select
                      v-model="absenceSelected"
                      :data="absenceTypes"
                      :expanded="true"
                      :placeholder="
                        $tf(
                          'absenceRequest.form.type.placeholder|Távollét típusa'
                        )
                      "
                      field="display"
                      @update:modelValue="(option) => (formData.type = option)"
                    >
                      <option
                        v-for="type in absenceTypes"
                        :key="type.id"
                        :value="type.id"
                      >
                        {{ type.name }}
                      </option>
                    </b-select>
                  </b-field>
                </template>
              </vuelidated-form>
            </b-field>

            <b-field expanded>
              <vuelidated-input
                v-model="formData.description"
                :expanded="true"
                :label="$tf('adminCreateAbsence.description|Leírás')"
                :placeholder="
                  $tf('adminCreateAbsence.description.placeholder|Leírás')
                "
                :ref-value="'description'"
                :validation-rule="v$.formData.description"
              />
            </b-field>

            <b-field v-if="carryOverVisible">
              <b-checkbox
                v-model="formData.carryOver"
                :disabled="!canCarryOver"
                class="mr-0"
              >
                <strong>{{
                  $tf("adminCreateAbsence.carryOver|Következő évre átvitt")
                }}</strong>
              </b-checkbox>

              <b-tooltip
                :label="
                  $tf(
                    'adminCreateAbsence.carryOver.tooltip|Akkor lehet szabadságot átvinni a következő évre, ha a kérelem az év első munkanapján kezdődik, egybefüggő és nem haladja meg az öt munkanapot!'
                  )
                "
                class="ml-1"
                style="font-size: 0.9rem"
                type="is-light"
              >
                <b-icon icon="info-circle" />
              </b-tooltip>
            </b-field>

            <b-field expanded>
              <vuelidated-form :validations="v$.formData.dates">
                <template v-slot="{ errors, invalid }">
                  <b-field
                    :message="v$.formData.dates.$error ? errors[0] : null"
                    :type="{ 'is-danger': invalid }"
                    label-for="dates"
                  >
                    <template #label>
                      {{ $tf("adminCreateAbsence.dates|Távollét napjai") }}
                      <span class="has-text-danger">*</span>
                    </template>
                    <f-datepicker
                      id="dates"
                      ref="dates"
                      v-model="formData.dates"
                      :events="carryOverDays"
                      :placeholder="
                        $tf(
                          'adminCreateAbsence.dates.placeholder|Napok kiválasztása'
                        )
                      "
                      :unselectable-dates="disabledDates"
                      icon="calendar-alt"
                      indicators="bars"
                      multiple
                      name="dates"
                      @changeYear="onChangeYear"
                      @update:modelValue="onDateSelect"
                    />
                  </b-field>
                </template>
              </vuelidated-form>
            </b-field>

            <b-field>
              <b-button native-type="submit" type="is-info">
                {{ $tf("adminCreateAbsence.submit|Küldés") }}
              </b-button>
            </b-field>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { employeeFilter, formatDate } from "@/utils/util";
import { maxLength, required } from "@vuelidate/validators";
import VuelidatedInput from "@/components/module/input/VuelidatedInput";
import VuelidatedForm from "@/components/module/input/VuelidatedForm";
import FDatepicker from "@/components/module/input/FDatepicker.vue";
import useCustomVuelidate from "@/plugins/vuelidate";

export default {
  name: "AdminRequest",
  components: {
    FDatepicker,
    VuelidatedForm,
    VuelidatedInput,
  },
  setup: () => useCustomVuelidate(),

  async mounted() {
    // await this.$store.dispatch("employee/fetch");
    await this.$store.dispatch("absence_request/getAdminTypes");
    this.absenceTypes.forEach((type) => {
      this.absenceTypeNames.push(type.name);
    });
    this.onChangeYear(new Date().getFullYear());
    await this.$store.dispatch("specialday/fetchCarryOverDates", {
      year: new Date().getFullYear() + 1,
    });
    this.setDisabledDates();
  },

  data() {
    return {
      name: "",
      selectedUser: "",
      formData: {
        type: null,
        description: null,
        dates: [],
        carryOver: false,
      },
      pagination: {
        totalCount: 0,
        page: 0,
        size: 10,
      },
      sort: {
        field: "created",
        order: "desc",
      },
      isCollapseOpen: false,
      absenceTypeNames: [],
      absenceTypeSub: "",
      absenceType: null,
      absenceSelected: null,
      selectedDates: [],
      disabledDates: [],
      defaultOpenedDetails: [1],
      years: new Set([new Date().getFullYear()]),
      formatDate,
    };
  },

  validations: {
    formData: {
      type: {
        required,
      },
      description: {
        maxLength: maxLength(255),
      },
      dates: {
        required,
      },
    },
  },

  computed: {
    filteredTypeArray() {
      return this.absenceTypeNames.filter((option) => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(this.absenceTypeSub.toLowerCase()) >= 0
        );
      });
    },
    filteredEmployees() {
      return employeeFilter(this.name, this.employees);
    },
    selectedType() {
      if (this.absenceTypes && this.absenceSelected) {
        return this.absenceTypes.find(
          (type) => type.id === this.absenceSelected
        );
      }
      return null;
    },

    carryOverDays() {
      const today = new Date();
      let days = [];
      if (this.selectedType) {
        if (this.selectedType.quotaUsage === "CANT_CARRY_OVER") {
          return days;
        } else if (this.selectedType.quotaUsage === "JANUARY") {
          let date =
            today.getMonth() === 0
              ? today
              : new Date(today.getFullYear() + 1, 0, 1);
          while (date.getMonth() === 0) {
            days.push(new Date(date));
            date.setDate(date.getDate() + 1);
          }
        } else if (this.selectedType.quotaUsage === "LIMITLESS") {
          let date = new Date(today.getFullYear() + 1, 0, 1);
          while (date.getFullYear() === today.getFullYear() + 1) {
            days.push(new Date(date));
            date.setDate(date.getDate() + 1);
          }
        }
      }
      if (this.carryOverDates) {
        this.carryOverDates.forEach((carryOverDay) => {
          days.push(new Date(carryOverDay));
        });
      }
      return days;
    },
    carryOverVisible() {
      let carryOverDayStrings = this.carryOverDays.map((day) =>
        this.formatDate(day)
      );
      let dayStrings = this.formData.dates.map((day) => this.formatDate(day));
      return carryOverDayStrings.some((day) => dayStrings.includes(day));
    },
    canCarryOver() {
      if (!this.selectedType || this.selectedType === "CONTINUAL") {
        let carryOverDayStrings = this.carryOverDays.map((day) =>
          this.formatDate(day)
        );
        let dayStrings = this.formData.dates.map((day) => this.formatDate(day));
        dayStrings = dayStrings.sort();

        if (carryOverDayStrings.length < dayStrings.length) {
          return false;
        }

        for (let i = 0; i < dayStrings.length; i++) {
          let day = dayStrings[i];
          let carryOver = carryOverDayStrings[i];
          if (day !== carryOver) {
            return false;
          }
        }
      }

      return (
        this.formData.dates.length > 0 &&
        this.selectedType !== "CANT_CARRY_OVER"
      );
    },
    ...mapGetters({
      employees: "employee/employees",
      specialDaysGetter: "specialday/specialDaysAll",
      carryOverDatesGetter: "specialday/carryOverDates",
      absenceTypes: "absence_request/types",
    }),
    specialDays() {
      return this.specialDaysGetter(...this.years);
    },
    carryOverDates() {
      return this.carryOverDatesGetter(new Date().getFullYear() + 1);
    },
  },
  methods: {
    onChangeYear(year) {
      this.years.add(year);
      this.$store.dispatch("specialday/fetchSpecialDaysAll", {
        year,
      });
      this.setDisabledDates();
    },
    submit() {
      this.touch$();
      if (!this.v$.$invalid) {
        const selectedDatesToSend = [...this.formData.dates];
        for (let i in selectedDatesToSend) {
          if (selectedDatesToSend.hasOwnProperty(i)) {
            selectedDatesToSend[i] = formatDate(selectedDatesToSend[i]);
          }
        }
        let request = {
          userId: this.selectedUser.id,
          type: this.formData.type,
          description: this.formData.description,
          absenceRequestDates: selectedDatesToSend,
          carryOver: this.formData.carryOver,
        };
        this.$store
          .dispatch("absence_request/createRequest", request)
          .then(async () => {
            this.resetForm();
          });
      }
    },
    resetForm() {
      this.absenceSelected = null;
      this.absenceTypeSub = "";
      this.formData.type = null;
      this.formData.description = null;
      this.formData.dates = [];
      this.formData.carryOver = false;
      this.setDisabledDates();

      this.v$.$reset();
    },
    setDisabledDates() {
      for (let specDay of this.specialDays) {
        if (specDay.type !== "EXTRA_WORKDAY") {
          this.disabledDates.push(new Date(specDay.dayDate));
        }
      }
    },
    toggleDetails: function (row) {
      this.$refs.table.toggleDetails(row);
    },

    onPageChange: function (page) {
      this.pagination.page = page - 1;
      this.getAbsenceRequests();
    },
    onSort: function (field, order) {
      this.sort.field = field;
      this.sort.order = order;
      this.getAbsenceRequests();
    },
    onDateSelect() {
      this.formData.carryOver = this.formData.carryOver && this.canCarryOver;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.collapseBlock {
  border: 1px $grey solid;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
</style>
