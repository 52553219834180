<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <h1 v-if="!data.name" class="title">
        {{ $tf("roles.editorModal.newTitle|Új szerepkör felvétele") }}
      </h1>
      <h1 v-else class="title">
        {{
          $tf("roles.editorModal.editTitle|{role} szerepkör módosítása", {
            role: data.name,
          })
        }}
      </h1>
    </header>
    <section class="modal-card-body p-3">
      <h1 class="title">
        {{ $tf("roles.editorModal.identifiers.title|Azonosítók") }}
      </h1>
      <vuelidated-input
        v-model="editObject.identifier"
        :label="
          $tf('roles.editorModal.identifier.placeholder|Szerepkör azonosítója')
        "
        :placeholder="$tf('roles.editorModal.identifier|Azonosító')"
        :validation-rule="v$.editObject.identifier"
        label-position="on-border"
      ></vuelidated-input>
      <vuelidated-input
        v-model="editObject.name"
        :label="$tf('roles.editorModal.name.placeholder|Szerepkör neve')"
        :placeholder="$tf('roles.editorModal.name|Név')"
        :validation-rule="v$.editObject.name"
        label-position="on-border"
      ></vuelidated-input>
      <hr />
      <h1 class="title">
        {{ $tf("roles.editorModal.privileges.title|Jogosultságok") }}
      </h1>
      <div class="permission-group-container">
        <div
          v-for="(permissionGroup, index) in Object.keys(privileges)"
          :key="index"
          class="permission-group is-flex is-flex-direction-column has-gap-2"
        >
          <div>
            <b-checkbox
              :indeterminate="isGroupIndeterminate(permissionGroup)"
              :modelValue="isGroupComplete(permissionGroup)"
              class="onboarding-checkbox-group-leader"
              @update:modelValue="
                (value) => handleGroupSelection(permissionGroup, value)
              "
            >
              {{ $tf(privileges[permissionGroup].LABEL) }}
            </b-checkbox>
          </div>
          <div
            v-for="(permission, index) in privileges[permissionGroup]
              .PRIVILEGES"
            :key="index"
          >
            <b-checkbox
              v-model="editObject.privileges"
              :native-value="permission.value"
              class="onboarding-checkbox-thin"
            >
              {{ $tf(permission.label) }}
            </b-checkbox>
          </div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-field class="ml-auto">
        <b-button @click="$emit('close')">
          {{ $tf("roles.editorModal.cancel|Mégsem") }}
        </b-button>
        <b-button
          :disabled="v$.editObject.$invalid"
          type="is-info"
          @click="save()"
        >
          {{ $tf("roles.editorModal.save|Mentés") }}
        </b-button>
      </b-field>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { PRIVILEGE_GROUPS } from "@/utils/const";
import { required } from "@vuelidate/validators";
import VuelidatedInput from "@/components/module/input/VuelidatedInput.vue";
import { deepCopy } from "@/utils/util";
import useCustomVuelidate from "@/plugins/vuelidate";

export default {
  name: "RoleEditorModal",
  components: { VuelidatedInput },
  props: {
    data: {
      type: Object,
      default: () => ({
        id: undefined,
        name: undefined,
        identifier: undefined,
        privileges: [],
      }),
    },
  },
  mounted() {
    this.editObject = deepCopy(this.data);
  },
  data() {
    return {
      privileges: PRIVILEGE_GROUPS,
      editObject: {
        id: undefined,
        identifier: undefined,
        name: undefined,
        privileges: [],
      },
    };
  },
  setup: () => useCustomVuelidate(),
  validations: {
    editObject: {
      identifier: {
        required,
      },
      name: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      rolesGetter: "role/list",
    }),
    roles() {
      return this.rolesGetter || [];
    },
  },
  methods: {
    async save() {
      if (this.editObject.id === undefined) {
        await this.$store.dispatch("role/create", this.editObject).then(
          () => {
            this.$emit("fetch");
            this.$emit("close");
          },
          () => {}
        );
      } else {
        await this.$store.dispatch("role/update", this.editObject).then(
          () => {
            this.$emit("fetch");
            this.$emit("close");
          },
          () => {}
        );
      }
    },
    isGroupIndeterminate(permissionGroup) {
      let allOfGroup = this.privileges[permissionGroup].PRIVILEGES.length;

      let selected = this.privileges[permissionGroup].PRIVILEGES.filter((prv) =>
        this.editObject.privileges.includes(prv.value)
      ).length;

      return selected > 0 && selected < allOfGroup;
    },
    isGroupComplete(permissionGroup) {
      let allOfGroup = this.privileges[permissionGroup].PRIVILEGES.length;

      let selected = this.privileges[permissionGroup].PRIVILEGES.filter((prv) =>
        this.editObject.privileges.includes(prv.value)
      ).length;

      return selected === allOfGroup;
    },
    handleGroupSelection(permissionGroup, value) {
      this.editObject.privileges
        .filter((prv) =>
          this.privileges[permissionGroup].PRIVILEGES.find(
            (allPrv) => allPrv.value === prv
          )
        )
        .forEach((prv) =>
          this.editObject.privileges.splice(
            this.editObject.privileges.indexOf(prv),
            1
          )
        );
      if (value) {
        this.privileges[permissionGroup].PRIVILEGES.forEach((prv) =>
          this.editObject.privileges.push(prv.value)
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.permission-group-container {
  display: grid;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.permission-group > div:not(:first-child) {
  margin-left: 8px;
}

.permission-edit-input-container {
  margin-top: 8px;
  max-width: 512px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
