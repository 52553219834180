<template>
  <div class="modal-card" style="width: auto">
    <div class="container" style="width: 30rem">
      <section class="modal-card-body">
        <h1 v-if="isCreate" class="title">
          {{ $tf("absenceTypeForm.newTitle|új szabadságtípus") }}
        </h1>
        <h1 v-else class="title">
          {{ $tf("absenceTypeForm.editTitle|Szabadságtípus módosítása") }}
        </h1>

        <form @submit.prevent="submit">
          <b-field :label="$tf('absenceTypeForm.color|Szín')" class="mt-5">
            <b-colorpicker v-model="editData.color" close-on-click />
          </b-field>

          <b-field class="mt-5">
            <vuelidated-input
              v-model="editData.name"
              :label="$tf('absenceTypeForm.name|Név')"
              :placeholder="$tf('absenceTypeForm.name.placeholder|Név')"
              :validation-rule="v$.editData.name"
              label-position="on-border"
            />
          </b-field>

          <b-field class="mt-5">
            <vuelidated-input
              v-model="editData.identifier"
              :label="$tf('absenceTypeForm.identifier|Azonosító')"
              :placeholder="
                $tf('absenceTypeForm.identifier.placeholder|Azonosító')
              "
              :validation-rule="v$.editData.identifier"
              label-position="on-border"
            />
          </b-field>

          <b-field class="mt-5">
            <vuelidated-input
              v-model="editData.sign"
              :label="$tf('absenceTypeForm.sign|Jel')"
              :placeholder="$tf('absenceTypeForm.sign.placeholder|Jel')"
              :validation-rule="v$.editData.sign"
              label-position="on-border"
            />
          </b-field>

          <b-field class="mt-5">
            <b-switch v-model="editData.usingQuota">
              <p v-if="editData.usingQuota">
                {{
                  $tf(
                    "absenceTypeForm.usingQuota.switch.yes|Használja a kvótát"
                  )
                }}
              </p>
              <p v-else>
                {{
                  $tf(
                    "absenceTypeForm.usingQuota.switch.no|Nem használja a kvótát"
                  )
                }}
              </p>
            </b-switch>
          </b-field>

          <b-field
            v-if="editData.usingQuota"
            :label="$tf('absenceTypeForm.quotaUsageType|Kvótahasználat típusa')"
            class="mt-5"
            label-position="on-border"
          >
            <b-select v-model="editData.quotaUsage" expanded>
              <option value="CONTINUAL">
                {{
                  $tf(
                    "absenceTypeForm.quotaUsageType.continual|Folytatólagosan átvihető"
                  )
                }}
              </option>
              <option value="LIMITLESS">
                {{
                  $tf(
                    "absenceTypeForm.quotaUsageType.limitless|Bárhogyan átvihető"
                  )
                }}
              </option>
              <option value="JANUARY">
                {{
                  $tf(
                    "absenceTypeForm.quotaUsageType.january|Januárra átvihető"
                  )
                }}
              </option>
              <option value="CANT_CARRY_OVER">
                {{
                  $tf(
                    "absenceTypeForm.quotaUsageType.cantCarryOver|Nem átvihető"
                  )
                }}
              </option>
            </b-select>
          </b-field>

          <b-field class="mt-5">
            <b-switch v-model="editData.selectable">
              <p v-if="editData.selectable">
                {{ $tf("absenceTypeForm.selectable.switch.yes|Kiválasztható") }}
              </p>
              <p v-else>
                {{
                  $tf("absenceTypeForm.selectable.switch.no|Nem kiválasztható")
                }}
              </p>
            </b-switch>
          </b-field>

          <b-field class="mt-5">
            <b-switch v-model="editData.mustHaveComment">
              <p v-if="editData.mustHaveComment">
                {{
                  $tf(
                    "absenceTypeForm.mustHaveComment.switch.yes|Megjegyzés kötelező"
                  )
                }}
              </p>
              <p v-else>
                {{
                  $tf(
                    "absenceTypeForm.mustHaveComment.switch.no|Megjegyzés opcionális"
                  )
                }}
              </p>
            </b-switch>
          </b-field>

          <b-field class="mt-5 is-pulled-right">
            <b-button @click="close">
              {{ $tf("absenceTypeForm.cancel|Mégse") }}
            </b-button>
            <b-button class="ml-3" native-type="submit" type="is-info">
              {{ $tf("absenceTypeForm.submit|Mentés") }}
            </b-button>
          </b-field>
        </form>
      </section>
    </div>
  </div>
</template>

<script>
import { maxLength, required } from "@vuelidate/validators";
import VuelidatedInput from "@/components/module/input/VuelidatedInput";
import { getCurrentInstance } from "vue";
import useCustomVuelidate from "@/plugins/vuelidate";

export default {
  name: "UserIndicatorForm",
  components: { VuelidatedInput },
  setup: () => {
    const instance = getCurrentInstance();
    const { v$, touch$ } = useCustomVuelidate();
    return {
      v$,
      touch$,
      instance,
    };
  },
  props: {
    absenceType: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      name: "",
      editData: {
        identifier: "",
        name: "",
        usingQuota: false,
        selectable: false,
        color: "#000000",
        sign: "",
        commentOptional: true,
        quotaUsage: "CONTINUAL",
      },
      isCreate: true,
    };
  },
  mounted() {
    if (this.absenceType) {
      this.isCreate = false;
      this.name = this.absenceType.name;
      this.editData = JSON.parse(JSON.stringify(this.absenceType));
    } else {
      this.isCreate = true;
    }
  },
  validations: {
    editData: {
      name: {
        required,
        maxLength: maxLength(255),
      },
      identifier: {
        required,
        maxLength: maxLength(255),
      },
      sign: {
        required,
        maxLength: maxLength(1),
      },
    },
  },
  methods: {
    close() {
      this.instance.emit("close");
    },
    afterSave() {
      this.close();
      this.$store.dispatch("absence_request/getAdminTypes", true);
    },
    submit() {
      this.touch$();
      if (this.v$.$invalid) {
        return;
      }

      if (this.isCreate) {
        this.$store
          .dispatch("absence_request/createType", this.editData)
          .then(() => {
            this.afterSave();
          });
      } else {
        this.$store
          .dispatch("absence_request/updateType", this.editData)
          .then(() => {
            this.afterSave();
          });
      }
    },
  },
};
</script>
