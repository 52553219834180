<template>
  <section class="section">
    <div class="container">
      <div class="is-flex">
        <div>
          <h1 class="title">
            {{ $tf("adminSettings.title|Admin beállítások") }}
          </h1>
          <h2 class="subtitle">
            {{
              $tf(
                "adminSettings.subtitle|Admin beállítások megtekintése és módosítása"
              )
            }}
          </h2>
        </div>
      </div>
    </div>

    <div class="container">
      <div
        class="is-flex is-align-items-center is-justify-content-space-between mb-3"
      >
        <div>
          <b-tabs v-model="activeTab" class="toggle-only" type="is-toggle">
            <b-tab-item
              :label="$tf('adminSetting.tabs.enterprise.label|Enterprise')"
              value="ENTERPRISE"
            />
            <b-tab-item
              :label="$tf('adminSetting.tabs.issueTracking.label|Taskkezelés')"
              value="ISSUE_TRACKING"
            />
          </b-tabs>
        </div>
        <div>
          <b-button
            v-if="!editMode && enterpriseData && issueTrackingData"
            class="is-primary"
            @click="editMode = true"
            >{{ $tf("adminSettings.edit|Szerkesztés") }}
          </b-button>
        </div>
      </div>

      <div v-show="activeTab === 'ENTERPRISE'">
        <template v-if="editMode">
          <div
            class="is-flex is-flex-direction-column has-gap-4 mt-5"
            style="max-width: 500px"
          >
            <div>
              <vuelidated-input
                v-model="enterpriseDataEdit.lowerRiskKPILimit"
                :label="
                  $tf(
                    'adminSettings.projectRiskKPIlowerLimit|Projekt risk KPI alsó határ'
                  )
                "
                :placeholder="
                  $tf(
                    'adminSettings.projectRiskKPIlowerLimit|Projekt risk KPI alsó határ'
                  )
                "
                :validation-rule="v$.enterpriseDataEdit.lowerRiskKPILimit"
                label-position="on-border"
              />
            </div>
            <div>
              <vuelidated-input
                v-model="enterpriseDataEdit.upperRiskKPILimit"
                :label="
                  $tf(
                    'adminSettings.projectRiskKPIupperLimit|Projekt risk KPI felső határ'
                  )
                "
                :placeholder="
                  $tf(
                    'adminSettings.projectRiskKPIupperLimit|Projekt risk KPI felső határ'
                  )
                "
                :validation-rule="v$.enterpriseDataEdit.upperRiskKPILimit"
                label-position="on-border"
              />
            </div>
            <div>
              <vuelidated-input
                :label="$tf('adminSettings.currency|Deviza')"
                :placeholder="$tf('adminSettings.currency|Deviza')"
                :validation-rule="v$.enterpriseDataEdit.currency"
                label-position="on-border"
                type="dropdown"
              >
                <b-dropdown
                  v-model="enterpriseDataEdit.currency"
                  :max-height="200"
                  append-to-body
                  aria-role="list"
                  scrollable
                >
                  <template #trigger>
                    <div class="currency-dropdown-input">
                      <img
                        :alt="selectedCurrency.code"
                        :src="selectedCurrency.icon"
                      />
                      <div class="is-flex-grow-1">
                        {{ selectedCurrency.code }}
                        &nbsp;-&nbsp;{{ selectedCurrency.name }}
                      </div>
                      <b-icon icon="chevron-down" size="is-small"></b-icon>
                    </div>
                  </template>

                  <b-dropdown-item
                    v-for="curr in currencies"
                    :key="curr.id"
                    :value="curr.id"
                    style="z-index: 999"
                  >
                    <div class="media">
                      <img
                        :alt="curr.code"
                        :src="curr.icon"
                        class="media-left is-align-self-center"
                      />
                      <div
                        class="media-content has-text-dark-blue has-font-weight-500"
                      >
                        {{ curr.code }}
                        - {{ curr.name }}
                      </div>
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </vuelidated-input>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="is-flex is-flex-direction-column has-gap-2">
            <div>
              <span class="data-details-label"
                >{{
                  $tf(
                    "adminSettings.projectRiskKPIlowerLimit|Projekt risk KPI alsó határ"
                  )
                }}:
              </span>
              <span class="data-details-value">{{
                enterpriseData?.lowerRiskKPILimit || "-"
              }}</span>
            </div>

            <div>
              <span class="data-details-label"
                >{{
                  $tf(
                    "adminSettings.projectRiskKPIupperLimit|Projekt risk KPI felső határ"
                  )
                }}:
              </span>
              <span class="data-details-value">{{
                enterpriseData?.upperRiskKPILimit || "-"
              }}</span>
            </div>

            <div>
              <span class="data-details-label"
                >{{ $tf("adminSettings.currency|Deviza") }}:
              </span>
              <span class="data-details-value">{{
                selectedCurrency?.name || "-"
              }}</span>
            </div>
          </div>
        </template>
      </div>
      <div v-show="activeTab === 'ISSUE_TRACKING'">
        <div class="is-flex is-justify-content-space-between mb-3">
          <div v-if="editMode || issueTrackingIdentifiers.length > 1">
            <b-tabs
              v-model="activeIssueTrackingIdentifier"
              class="toggle-only"
              type="is-toggle"
            >
              <b-tab-item
                v-for="configIdentifier in issueTrackingIdentifiers"
                :key="`adminSettings.issueTracking.${configIdentifier.identifier}`"
                :label="configIdentifier.label"
                :value="configIdentifier.identifier"
              />
            </b-tabs>
          </div>
          <div v-if="editMode">
            <b-button @click="addNewIssueTrackingConfig"
              >{{
                $tf(
                  "adminSettings.issueTracking.addNewButton|Új konfiguráció hozzáadása"
                )
              }}
            </b-button>
          </div>
        </div>
        <template v-if="editMode">
          <div class="is-flex is-flex-direction-column has-gap-4 mt-5">
            <div>
              <vuelidated-input
                :label="
                  $tf(
                    'adminSettings.issueTrackingSystem|Külső taskkezelő rendszer'
                  )
                "
                :placeholder="
                  $tf(
                    'adminSettings.issueTrackingSystem|Külső taskkezelő rendszer'
                  )
                "
                :validation-rule="
                  v$.issueTrackingDataEdit[activeIssueTrackingIdentifier].system
                "
                label-position="on-border"
                type="dropdown"
              >
                <b-dropdown
                  v-model="
                    issueTrackingDataEdit[activeIssueTrackingIdentifier].system
                  "
                  :max-height="200"
                  append-to-body
                  aria-role="list"
                  scrollable
                >
                  <template #trigger>
                    <div class="currency-dropdown-input">
                      <div class="is-flex-grow-1">
                        {{
                          $tf(
                            ISSUE_TRACKING_SYSTEM[
                              issueTrackingDataEdit[
                                activeIssueTrackingIdentifier
                              ].system
                            ]?.label ?? ""
                          )
                        }}
                      </div>
                      <b-icon icon="chevron-down" size="is-small"></b-icon>
                    </div>
                  </template>

                  <b-dropdown-item
                    v-for="its in Object.keys(ISSUE_TRACKING_SYSTEM)"
                    :key="ISSUE_TRACKING_SYSTEM[its].enum"
                    :value="ISSUE_TRACKING_SYSTEM[its].enum"
                    style="z-index: 999"
                  >
                    {{ $tf(ISSUE_TRACKING_SYSTEM[its].label) }}
                  </b-dropdown-item>
                </b-dropdown>
              </vuelidated-input>
            </div>

            <template
              v-if="
                issueTrackingDataEdit[activeIssueTrackingIdentifier].system !==
                ISSUE_TRACKING_SYSTEM.SELF.enum
              "
            >
              <template
                v-if="
                  issueTrackingDataEdit[activeIssueTrackingIdentifier]
                    .system === ISSUE_TRACKING_SYSTEM.YOUTRACK.enum
                "
              >
                <div>
                  <span class="data-details-label">
                    <vuelidated-input
                      v-model="
                        issueTrackingDataEdit[activeIssueTrackingIdentifier]
                          .estimationFieldName
                      "
                      :label="
                        $tf(
                          'adminSettings.estimationFieldName.label|Feladat becsült időtartam mező'
                        )
                      "
                      :placeholder="
                        $tf(
                          'adminSettings.estimationFieldName.placeholder|Estimation'
                        )
                      "
                      :validation-rule="
                        v$.issueTrackingDataEdit[activeIssueTrackingIdentifier]
                          .estimationFieldName
                      "
                      label-position="on-border"
                    />
                  </span>
                </div>
                <div>
                  <span class="data-details-label">
                    <vuelidated-input
                      v-model="
                        issueTrackingDataEdit[activeIssueTrackingIdentifier]
                          .stateFieldName
                      "
                      :label="
                        $tf('adminSettings.stateFieldName.label|Státusz mező')
                      "
                      :placeholder="
                        $tf('adminSettings.stateFieldName.placeholder|State')
                      "
                      :validation-rule="
                        v$.issueTrackingDataEdit[activeIssueTrackingIdentifier]
                          .stateFieldName
                      "
                      label-position="on-border"
                    />
                  </span>
                </div>
                <div>
                  <span class="data-details-label"
                    ><vuelidated-input
                      v-model="
                        issueTrackingDataEdit[activeIssueTrackingIdentifier]
                          .todoIssueStateNames
                      "
                      :label="
                        $tf(
                          'adminSettings.todoIssueStateNames.label|Todo task státusz értékek'
                        )
                      "
                      :validation-rule="
                        v$.issueTrackingDataEdit[activeIssueTrackingIdentifier]
                          .todoIssueStateNames
                      "
                      allow-new
                      label-position="on-border"
                      type="taginput"
                    />
                  </span>
                </div>

                <div>
                  <span class="data-details-label">
                    <vuelidated-input
                      v-model="
                        issueTrackingDataEdit[activeIssueTrackingIdentifier]
                          .typeFieldName
                      "
                      :label="
                        $tf(
                          'adminSettings.typeFieldName.label|Feladattípus mező'
                        )
                      "
                      :placeholder="
                        $tf('adminSettings.typeFieldName.placeholder|Type')
                      "
                      :validation-rule="
                        v$.issueTrackingDataEdit[activeIssueTrackingIdentifier]
                          .typeFieldName
                      "
                      label-position="on-border"
                    />
                  </span>
                </div>
              </template>
              <div>
                <span class="data-details-label"
                  ><vuelidated-input
                    v-model="
                      issueTrackingDataEdit[activeIssueTrackingIdentifier]
                        .issueTypeMap.BUG
                    "
                    :label="
                      $tf(
                        'adminSettings.issueTypeMap.BUG.label|BUG feladattípus értékek'
                      )
                    "
                    :validation-rule="
                      v$.issueTrackingDataEdit[activeIssueTrackingIdentifier]
                        .issueTypeMap.BUG
                    "
                    allow-new
                    label-position="on-border"
                    type="taginput"
                  />
                </span>
              </div>
              <div>
                <span class="data-details-label"
                  ><vuelidated-input
                    v-model="
                      issueTrackingDataEdit[activeIssueTrackingIdentifier]
                        .issueTypeMap.DEV
                    "
                    :label="
                      $tf(
                        'adminSettings.issueTypeMap.DEV.label|DEV feladattípus értékek'
                      )
                    "
                    :validation-rule="
                      v$.issueTrackingDataEdit[activeIssueTrackingIdentifier]
                        .issueTypeMap.DEV
                    "
                    allow-new
                    label-position="on-border"
                    type="taginput"
                  />
                </span>
              </div>
              <div>
                <span class="data-details-label"
                  ><vuelidated-input
                    v-model="
                      issueTrackingDataEdit[activeIssueTrackingIdentifier]
                        .issueTypeMap.GENERAL
                    "
                    :label="
                      $tf(
                        'adminSettings.issueTypeMap.GENERAL.label|GENERAL feladattípus értékek'
                      )
                    "
                    :validation-rule="
                      v$.issueTrackingDataEdit[activeIssueTrackingIdentifier]
                        .issueTypeMap.GENERAL
                    "
                    allow-new
                    label-position="on-border"
                    type="taginput"
                  />
                </span>
                <h2
                  class="subtitle"
                  v-html="
                    $tf(
                      'adminSettings.issueTypeMap.OTHER|A fel nem sorolt értékek az <i>OTHER</i> kategóriába kerülnek'
                    )
                  "
                ></h2>
              </div>
              <div>
                <span class="data-details-label">
                  <b-field
                    :label="
                      $tf(
                        'adminSettings.reportingFieldNames.label|Riporthoz lekért extra mezők'
                      )
                    "
                    label-position="on-border"
                  >
                    <b-taginput
                      v-model="
                        issueTrackingDataEdit[activeIssueTrackingIdentifier]
                          .reportingFieldNames
                      "
                      allow-new
                    />
                  </b-field>
                </span>
              </div>

              <div>
                <span class="data-details-label">
                  <vuelidated-input
                    v-model="
                      issueTrackingDataEdit[activeIssueTrackingIdentifier].url
                    "
                    :label="
                      $tf('adminSettings.url.label|Taskkezelő rendszer elérése')
                    "
                    :placeholder="
                      $tf(
                        'adminSettings.url.placeholder|https://example.youtrack.cloud'
                      )
                    "
                    :validation-rule="
                      v$.issueTrackingDataEdit[activeIssueTrackingIdentifier]
                        .url
                    "
                    label-position="on-border"
                  />
                </span>
              </div>
              <div class="is-flex is-align-items-center has-gap-1">
                <div class="is-flex-1">
                  <span class="data-details-label">
                    <vuelidated-input
                      v-model="
                        issueTrackingDataEdit[activeIssueTrackingIdentifier]
                          .email
                      "
                      :disabled="!editEmail[activeIssueTrackingIdentifier]"
                      :label="
                        $tf(
                          'adminSettings.email.label|Taskkezelő rendszer eléréséhez használt email'
                        )
                      "
                      :placeholder="
                        getIssueTrackingDataForIdentifier(
                          activeIssueTrackingIdentifier
                        )?.email
                          ? $tf('adminSettings.email.placeholder|Beállítva')
                          : ''
                      "
                      :validation-rule="
                        v$.issueTrackingDataEdit[activeIssueTrackingIdentifier]
                          .email
                      "
                      label-position="on-border"
                    />
                  </span>
                </div>
                <div
                  v-if="
                    getIssueTrackingDataForIdentifier(
                      activeIssueTrackingIdentifier
                    )?.email
                  "
                >
                  <clickable-icon
                    :icon="
                      editEmail[activeIssueTrackingIdentifier]
                        ? 'xmark'
                        : 'pencil'
                    "
                    size="is-large"
                    type="is-info"
                    @click="
                      editEmail[activeIssueTrackingIdentifier] =
                        !editEmail[activeIssueTrackingIdentifier]
                    "
                  />
                </div>
              </div>
              <div class="is-flex is-align-items-center has-gap-1">
                <div class="is-flex-1">
                  <span class="data-details-label">
                    <vuelidated-input
                      v-model="
                        issueTrackingDataEdit[activeIssueTrackingIdentifier]
                          .apiKey
                      "
                      :disabled="!editApiKey[activeIssueTrackingIdentifier]"
                      :label="
                        $tf(
                          'adminSettings.apiKey.label|Taskkezelő rendszer eléréséjhez használ API kulcs'
                        )
                      "
                      :placeholder="
                        getIssueTrackingDataForIdentifier(
                          activeIssueTrackingIdentifier
                        )?.apiKey
                          ? $tf('adminSettings.apiKey.placeholder|Beállítva')
                          : ''
                      "
                      :validation-rule="
                        v$.issueTrackingDataEdit[activeIssueTrackingIdentifier]
                          .apiKey
                      "
                      label-position="on-border"
                    />
                  </span>
                </div>
                <div
                  v-if="
                    getIssueTrackingDataForIdentifier(
                      activeIssueTrackingIdentifier
                    )?.apiKey
                  "
                >
                  <clickable-icon
                    :icon="
                      editApiKey[activeIssueTrackingIdentifier]
                        ? 'xmark'
                        : 'pencil'
                    "
                    size="is-large"
                    type="is-info"
                    @click="
                      editApiKey[activeIssueTrackingIdentifier] =
                        !editApiKey[activeIssueTrackingIdentifier]
                    "
                  />
                </div>
              </div>
            </template>
          </div>
        </template>
        <template v-else>
          <div class="is-flex is-flex-direction-column has-gap-2">
            <div>
              <span class="data-details-label"
                >{{
                  $tf(
                    "adminSettings.issueTrackingSystem|Külső taskkezelő rendszer"
                  )
                }}:
              </span>
              <span
                v-if="
                  issueTrackingData &&
                  issueTrackingData[activeIssueTrackingIdentifier]
                "
                class="data-details-value"
                >{{
                  $tf(
                    ISSUE_TRACKING_SYSTEM[
                      getIssueTrackingDataForIdentifier(
                        activeIssueTrackingIdentifier
                      ).system
                    ]?.label ?? ""
                  )
                }}</span
              >
            </div>
            <template
              v-if="
                getIssueTrackingDataForIdentifier(activeIssueTrackingIdentifier)
                  ?.system !== ISSUE_TRACKING_SYSTEM.SELF.enum
              "
            >
              <template
                v-if="
                  getIssueTrackingDataForIdentifier(
                    activeIssueTrackingIdentifier
                  )?.system === ISSUE_TRACKING_SYSTEM.YOUTRACK.enum
                "
              >
                <div>
                  <span class="data-details-label"
                    >{{
                      $tf(
                        "adminSettings.estimationFieldName.label|Feladat becsült időtartam mező"
                      )
                    }}:
                  </span>
                  <span class="data-details-value">{{
                    getIssueTrackingDataForIdentifier(
                      activeIssueTrackingIdentifier
                    )?.estimationFieldName || "-"
                  }}</span>
                </div>
                <div>
                  <span class="data-details-label"
                    >{{
                      $tf("adminSettings.stateFieldName.label|Státusz mező")
                    }}:
                  </span>
                  <span class="data-details-value">{{
                    getIssueTrackingDataForIdentifier(
                      activeIssueTrackingIdentifier
                    )?.stateFieldName || "-"
                  }}</span>
                </div>

                <div>
                  <span class="data-details-label"
                    >{{
                      $tf(
                        "adminSettings.todoIssueStateNames|Todo task státusz értékek"
                      )
                    }}:
                  </span>
                  <span class="data-details-value">{{
                    getIssueTrackingDataForIdentifier(
                      activeIssueTrackingIdentifier
                    )?.todoIssueStateNames?.join(", ")
                  }}</span>
                </div>

                <div>
                  <span class="data-details-label"
                    >{{
                      $tf(
                        "adminSettings.typeFieldName.label|Feladattípus mező"
                      )
                    }}:
                  </span>
                  <span class="data-details-value">{{
                    getIssueTrackingDataForIdentifier(
                      activeIssueTrackingIdentifier
                    )?.typeFieldName || "-"
                  }}</span>
                </div>
              </template>
              <div>
                <span class="data-details-label"
                  >{{
                    $tf(
                      "adminSettings.issueTypeMap.BUG|BUG feladattípus értékek"
                    )
                  }}:
                </span>
                <span class="data-details-value">{{
                  getIssueTrackingDataForIdentifier(
                    activeIssueTrackingIdentifier
                  )?.issueTypeMap?.BUG?.join(", ")
                }}</span>
              </div>
              <div>
                <span class="data-details-label"
                  >{{
                    $tf(
                      "adminSettings.issueTypeMap.DEV|DEV feladattípus értékek"
                    )
                  }}:
                </span>
                <span class="data-details-value">{{
                  getIssueTrackingDataForIdentifier(
                    activeIssueTrackingIdentifier
                  )?.issueTypeMap?.DEV?.join(", ")
                }}</span>
              </div>
              <div>
                <span class="data-details-label"
                  >{{
                    $tf(
                      "adminSettings.issueTypeMap.GENERAL|GENERAL feladattípus értékek"
                    )
                  }}:
                </span>
                <span class="data-details-value">{{
                  getIssueTrackingDataForIdentifier(
                    activeIssueTrackingIdentifier
                  )?.issueTypeMap?.GENERAL?.join(", ")
                }}</span>
              </div>
              <div>
                <span class="data-details-label"
                  >{{ $tf("adminSettings.reportingFieldNames|Riport mezők") }}:
                </span>
                <span class="data-details-value">{{
                  getIssueTrackingDataForIdentifier(
                    activeIssueTrackingIdentifier
                  )?.reportingFieldNames?.join(", ")
                }}</span>
              </div>
              <div>
                <span class="data-details-label"
                  >{{
                    $tf("adminSettings.url.label|Taskkezelő rendszer elérése")
                  }}:
                </span>
                <span class="data-details-value">{{
                  getIssueTrackingDataForIdentifier(
                    activeIssueTrackingIdentifier
                  )?.url || "-"
                }}</span>
              </div>
              <div>
                <span class="data-details-label"
                  >{{
                    $tf(
                      "adminSettings.email.label|Taskkezelő rendszer eléréséhez használt email"
                    )
                  }}:
                </span>
                <span class="data-details-value">{{
                  !!getIssueTrackingDataForIdentifier(
                    activeIssueTrackingIdentifier
                  )?.email
                    ? $tf("adminSettings.email.set|Beállítva")
                    : $tf("adminSettings.email.missing|Nincs beállítva")
                }}</span>
              </div>
              <div>
                <span class="data-details-label"
                  >{{
                    $tf(
                      "adminSettings.apiKey.label|Taskkezelő rendszer eléréséhez használt API kulcs"
                    )
                  }}:
                </span>
                <span class="data-details-value">{{
                  !!getIssueTrackingDataForIdentifier(
                    activeIssueTrackingIdentifier
                  )?.apiKey
                    ? $tf("adminSettings.apiKey.set|Beállítva")
                    : $tf("adminSettings.apiKey.missing|Nincs beállítva")
                }}</span>
              </div>
            </template>
          </div>
        </template>
      </div>

      <b-loading v-model="loading" :can-cancel="false" :is-full-page="false" />
      <div v-if="editMode" class="overflow-x-auto">
        <div
          class="is-flex is-justify-content-space-between is-align-items-end pb-2 mt-4"
        >
          <div>
            <b-button
              v-if="
                activeTab === 'ISSUE_TRACKING' &&
                activeIssueTrackingIdentifier !==
                  ISSUE_TRACKING_CONST.PRIMARY_ISSUE_TRACKING_CONFIG_KEY
              "
              class="is-danger"
              outlined
              @click="deleteCurrentTrackingConfig"
              >{{ $tf("adminSettings.issueTracking.delete|Törlés") }}
            </b-button>
          </div>
          <div class="ml-auto">
            <div>
              <div class="is-flex has-gap-2">
                <b-button
                  v-if="enterpriseData && issueTrackingData"
                  class="is-light"
                  @click="
                    editMode = false;
                    fetch();
                  "
                  >{{ $tf("adminSettings.cancel|Mégse") }}
                </b-button>
                <b-button class="is-primary ml-auto" @click="save"
                  >{{ $tf("adminSettings.save|Mentés") }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { decimal, required, requiredIf } from "@vuelidate/validators";
import VuelidatedInput from "../../components/module/input/VuelidatedInput";
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash";
import { deepCopy, openDeleteConfirm } from "@/utils/util";
import { ISSUE_TRACKING_CONST, ISSUE_TRACKING_SYSTEM } from "@/utils/const";
import useCustomVuelidate from "@/plugins/vuelidate";
import ClickableIcon from "@/components/module/icon/ClickableIcon.vue";
import { nextTick } from "vue";

export default {
  name: "AdminConfigEdit",
  components: { ClickableIcon, VuelidatedInput },
  setup: () => useCustomVuelidate(),
  data() {
    return {
      loading: false,
      editMode: false,
      enterpriseData: null,
      issueTrackingData: null,
      enterpriseDataEdit: {
        lowerRiskKPILimit: 0.8,
        upperRiskKPILimit: 0.99,
        currency: undefined,
      },
      issueTrackingDataEdit: {
        [ISSUE_TRACKING_CONST.PRIMARY_ISSUE_TRACKING_CONFIG_KEY]:
          this.createBaseIssueTrackingData(
            ISSUE_TRACKING_CONST.PRIMARY_ISSUE_TRACKING_CONFIG_KEY
          ),
      },
      editEmail: {
        [ISSUE_TRACKING_CONST.PRIMARY_ISSUE_TRACKING_CONFIG_KEY]: false,
      },
      editApiKey: {
        [ISSUE_TRACKING_CONST.PRIMARY_ISSUE_TRACKING_CONFIG_KEY]: false,
      },
      ISSUE_TRACKING_SYSTEM,
      activeTab: "ENTERPRISE",
      activeIssueTrackingIdentifier:
        ISSUE_TRACKING_CONST.PRIMARY_ISSUE_TRACKING_CONFIG_KEY,
      ISSUE_TRACKING_CONST,
    };
  },
  validations() {
    return {
      enterpriseDataEdit: {
        lowerRiskKPILimit: { required, decimal },
        upperRiskKPILimit: { required, decimal },
        currency: { required },
      },
      issueTrackingDataEdit: this.issueTrackingDataValidations,
    };
  },
  computed: {
    ...mapGetters({
      clients: "enterprise_clients/clients",
      currencies: "adminConfig/getCurrencies",
    }),
    selectedCurrency() {
      return (
        this.currencies.find(
          (x) => x.id === this.enterpriseDataEdit?.currency
        ) || {
          name: "-",
        }
      );
    },
    issueTrackingIdentifiers() {
      const primaryItem = {
        identifier: ISSUE_TRACKING_CONST.PRIMARY_ISSUE_TRACKING_CONFIG_KEY,
        label: this.$tf(
          "const.issueTracking.primaryIssueTrackingConfigKey|Alapértelmezett"
        ),
      };

      const searchObject = this.issueTrackingDataEdit || {};

      const allItems = [
        primaryItem,
        ...Object.keys(searchObject).map((key) => ({
          identifier: searchObject[key].identifier,
          label: searchObject[key].identifier,
        })),
      ];

      const seen = new Set();
      return allItems.filter((item) => {
        if (seen.has(item.identifier)) return false;
        seen.add(item.identifier);
        return true;
      });
    },
    issueTrackingDataValidations() {
      const validations = {};
      for (const { identifier } of this.issueTrackingIdentifiers) {
        validations[identifier] = {
          identifier: { required },
          system: { required },
          url: { required },
          estimationFieldName: {
            required: requiredIf(
              (_, sibling) =>
                sibling.system === ISSUE_TRACKING_SYSTEM.YOUTRACK.enum
            ),
          },
          stateFieldName: {
            required: requiredIf(
              (_, sibling) =>
                sibling.system === ISSUE_TRACKING_SYSTEM.YOUTRACK.enum
            ),
          },
          todoIssueStateNames: {
            required: requiredIf(
              (_, sibling) =>
                sibling.system === ISSUE_TRACKING_SYSTEM.YOUTRACK.enum
            ),
          },
          typeFieldName: {
            required: requiredIf(
              (_, sibling) =>
                sibling.system === ISSUE_TRACKING_SYSTEM.YOUTRACK.enum
            ),
          },
          issueTypeMap: {
            BUG: {
              required: requiredIf(
                (_, sibling) =>
                  sibling.system === ISSUE_TRACKING_SYSTEM.YOUTRACK.enum
              ),
            },
            DEV: {
              required: requiredIf(
                (_, sibling) =>
                  sibling.system === ISSUE_TRACKING_SYSTEM.YOUTRACK.enum
              ),
            },
            GENERAL: {
              required: requiredIf(
                (_, sibling) =>
                  sibling.system === ISSUE_TRACKING_SYSTEM.YOUTRACK.enum
              ),
            },
          },
          email: {
            required: requiredIf(() => !!this.editEmail[identifier]),
          },
          apiKey: {
            required: requiredIf(() => !!this.editApiKey[identifier]),
          },
        };
      }
      return validations;
    },
  },
  async mounted() {
    await this.fetch();
  },
  methods: {
    addNewIssueTrackingConfig() {
      this.$buefy.dialog.prompt({
        title: this.$tf(
          "adminSettings.issueTracking.modal.title|Új konfiguráció felvétele"
        ),
        message: this.$tf(
          "adminSettings.issueTracking.modal.message|Add meg az új konfiguráció azonosítóját"
        ),
        cancelText: this.$tf(
          "adminSettings.issueTracking.modal.cancelText|Mégsem"
        ),
        confirmText: this.$tf(
          "adminSettings.issueTracking.modal.confirmText|Létrehozás"
        ),
        inputAttrs: {
          placeholder: this.$tf(
            "adminSettings.issueTracking.modal.inputPlaceholder|Azonosító"
          ),
          maxlength: 255,
          autocomplete: "off",
          required: true,
        },
        onConfirm: (value) => {
          if (["GITLAB", "FORECASTIFY"].includes(value.toUpperCase())) {
            this.$buefy.toast.open({
              message: this.$tf(
                "adminSettings.issueTracking.modal.error.reservedKeyword|Nem használható a(z) {value} érték",
                {
                  value,
                }
              ),
              type: "is-danger",
            });
          } else if (!!this.issueTrackingDataEdit[value]) {
            this.$buefy.toast.open({
              message: this.$tf(
                "adminSettings.issueTracking.modal.error.alreadyExists|Az azonosító már létezik"
              ),
              type: "is-danger",
            });
          } else {
            this.issueTrackingDataEdit[value] = {
              ...this.createBaseIssueTrackingData(value),
            };
            this.editEmail[value] = true;
            this.editApiKey[value] = true;
            nextTick(() => {
              this.activeIssueTrackingIdentifier = value;
            });
          }
        },
      });
    },
    deleteCurrentTrackingConfig() {
      openDeleteConfirm(this.$buefy, () => {
        const currentIndex = this.issueTrackingIdentifiers.findIndex(
          (config) => config.identifier === this.activeIssueTrackingIdentifier
        );

        const newActiveIdentifier =
          this.issueTrackingIdentifiers[currentIndex + 1]?.identifier ||
          this.issueTrackingIdentifiers[currentIndex - 1]?.identifier ||
          ISSUE_TRACKING_CONST.PRIMARY_ISSUE_TRACKING_CONFIG_KEY;

        delete this.issueTrackingDataEdit[this.activeIssueTrackingIdentifier];
        delete this.editEmail[this.activeIssueTrackingIdentifier];
        delete this.editApiKey[this.activeIssueTrackingIdentifier];

        this.activeIssueTrackingIdentifier = newActiveIdentifier;
      });
    },
    createBaseIssueTrackingData(identifier) {
      return {
        identifier,
        system: ISSUE_TRACKING_SYSTEM.JIRA.enum,
        url: undefined,
        email: undefined,
        apiKey: undefined,
        estimationFieldName: "Estimation",
        stateFieldName: "State",
        todoIssueStateNames: [],
        typeFieldName: "Type",
        issueTypeMap: {
          BUG: [],
          DEV: [],
          GENERAL: [],
        },
        reportingFieldNames: [],
      };
    },
    getIssueTrackingDataForIdentifier(identifier) {
      if (!this.issueTrackingData || !identifier) return null;
      return this.issueTrackingData[identifier];
    },
    async fetch(force) {
      this.loading = true;
      await this.$store.dispatch("adminConfig/fetchEnterpriseData", force);
      await this.$store.dispatch("adminConfig/fetchCurrencies", force);
      this.enterpriseData =
        this.$store.getters["adminConfig/getEnterpriseData"];
      if (this.enterpriseData) {
        this.enterpriseDataEdit = cloneDeep(this.enterpriseData);
        this.enterpriseDataEdit.currency = this.enterpriseDataEdit.currency?.id;
      }
      await this.$store.dispatch("adminConfig/fetchIssueTrackingData", force);
      this.issueTrackingData =
        this.$store.getters["adminConfig/getIssueTrackingData"];
      if (this.issueTrackingData) {
        this.issueTrackingDataEdit = cloneDeep(this.issueTrackingData);
        Object.keys(this.issueTrackingDataEdit).forEach((key) => {
          this.issueTrackingDataEdit[key].identifier = key;
          delete this.issueTrackingDataEdit[key].email;
          delete this.issueTrackingDataEdit[key].apiKey;
          if (!this.issueTrackingData[key].email) this.editEmail[key] = true;
          if (!this.issueTrackingData[key].apiKey) this.editApiKey[key] = true;
        });
      }
      this.loading = false;
    },
    async save() {
      let success = false;
      success =
        (await this.saveEnterpriseData()) &&
        (await this.saveIssueTrackingData());
      if (success) {
        await this.fetch(true);
        this.editMode = false;
      }
    },
    async saveEnterpriseData() {
      this.v$.enterpriseDataEdit.$touch();
      if (!this.v$.enterpriseDataEdit.$invalid) {
        const payload = deepCopy(this.enterpriseDataEdit);
        payload.currency = this.selectedCurrency;
        try {
          await this.$store.dispatch("adminConfig/saveEnterpriseData", payload);
          return true;
        } catch (_) {
          return false;
        }
      } else {
        this.activeTab = "ENTERPRISE";
      }
      return false;
    },
    async saveIssueTrackingData() {
      this.touch$();
      if (!this.v$.issueTrackingDataEdit.$invalid) {
        const payload = deepCopy(this.issueTrackingDataEdit);
        Object.keys(payload).forEach((key) => {
          if (!this.editEmail[key]) delete payload[key].email;
          if (!this.editApiKey[key]) delete payload[key].apiKey;
        });
        try {
          await this.$store.dispatch(
            "adminConfig/saveIssueTrackingData",
            payload
          );
          return true;
        } catch (_) {
          return false;
        }
      } else {
        this.activeTab = "ISSUE_TRACKING";
        const issueTrackingErrors = this.v$.issueTrackingDataEdit;
        for (const identifier in issueTrackingErrors) {
          if (Object.hasOwn(issueTrackingErrors, identifier)) {
            const validationObj = issueTrackingErrors[identifier];
            if (validationObj.$invalid) {
              this.activeIssueTrackingIdentifier = identifier;
              return false;
            }
          }
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.data-details-label {
  color: $custom-light-blue;
}

.data-details-value {
  font-weight: 500;
  color: $custom-dark-blue;
}

.currency-dropdown-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 250px;
  cursor: pointer;
  background: $white;
  border: 1px solid lightgray;
  border-radius: 5px;
  color: $custom-dark-blue;
  font-weight: 500;
  font-size: 14px;
}
</style>
