<template>
  <section class="error-container">
    <div class="error-content">
      <span class="icon">
        <i class="fa-duotone fa-solid fa-4x fa-plug-circle-xmark"></i>
      </span>
      <h1 class="title">Oops! Something went wrong</h1>
      <h2 class="subtitle">
        We couldn't load this page. Please try again later or contact support.
      </h2>
      <button class="retry-button" @click="reloadPage">Retry</button>
    </div>
  </section>
</template>

<script>
export default {
  name: "PortalError",
  methods: {
    reloadPage() {
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: rgb(64, 64, 64);
  padding: 20px;
  width: 100vw;

  .icon {
    color: #ff5e57;
    margin-bottom: 30px;
    margin-top: 15px;
  }

  .title {
    font-size: 1.75rem;
    color: #dfdfdf;
  }

  .subtitle {
    font-size: 1rem;
    color: #dfdfdf;
    margin-bottom: 20px;
  }

  .error-content {
    background: rgb(33, 33, 36);
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);

    .retry-button {
      background-color: #4e75d9;
      color: #dfdfdf;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      transition: color 0.1s, background-color 0.1s, border-color 0.1s;
      font-size: 1rem;

      &:hover {
        background-color: rgb(68, 109, 215);
      }
    }
  }
}
</style>
