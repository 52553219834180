<template>
  <section class="section">
    <div class="container">
      <h1 class="title">Hozzáférések</h1>
      <h2 class="subtitle">
        Hozzáférések létrehozása, szerkesztése, felhasználóhóz rendelése
      </h2>
    </div>
    <div class="container">
      <div class="is-flex is-justify-content-space-between">
        <b-tabs v-model="tab" class="toggle-only" type="is-toggle">
          <b-tab-item key="permissions" label="Hozzáférések" />
          <b-tab-item
            key="permits"
            label="Felhasználókhoz rendelt hozzáférések"
          />
        </b-tabs>
        <div class="button-container">
          <b-button
            v-if="!tab"
            class="is-pulled-right"
            type="is-info"
            @click="openEditPopup(null)"
          >
            <b-icon class="mr-2" icon="plus-circle" />
            Új jogosultság
          </b-button>
          <b-button
            v-else
            class="is-pulled-right"
            type="is-info"
            @click="openPermitEditPopup(null)"
          >
            <b-icon class="mr-2" icon="plus-circle" />
            Új jogosultság hozzárendelés
          </b-button>
        </div>
      </div>
      <template v-if="!tab">
        <b-table
          ref="permissionTable"
          :data="permissions"
          :loading="loading"
          :show-detail-icon="true"
          hoverable
          paginated
          per-page="15"
        >
          <b-table-column
            v-slot="props"
            field="name"
            label="Megnevezés"
            searchable
            sortable
          >
            {{ props.row.name }}
          </b-table-column>

          <b-table-column
            v-slot="props"
            cell-class="do-not-wrap"
            centered
            field="actions"
            label="Műveletek"
            width="32"
          >
            <clickable-icon
              class="mr-2"
              icon="pencil"
              type="is-info"
              @click="openEditPopup(props.row)"
            ></clickable-icon>
            <clickable-icon
              icon="trash"
              type="is-danger"
              @click="deleteRow(props.row)"
            ></clickable-icon>
          </b-table-column>
        </b-table>
      </template>
      <template v-else>
        <b-table
          ref="permitTable"
          :data="permits.items"
          :loading="loading"
          :per-page="pagination.size"
          :sort-multiple-data="sort"
          :total="pagination.totalCount"
          backend-filtering
          backend-pagination
          backend-sorting
          hoverable
          paginated
          sort-multiple
          @sort="onSort"
          @page-change="onPageChange"
          @sorting-priority-removed="sortingPriorityRemoved"
          @filters-change="onFilterChange"
        >
          <b-table-column
            v-slot="props"
            field="userName"
            label="Felhasználó"
            searchable
            sortable
          >
            {{ props.row.userName }}
          </b-table-column>

          <b-table-column
            v-slot="props"
            field="permits"
            label="Hozzáférés"
            searchable
            sortable
          >
            {{ formattedUserPermits(props.row.permits) }}
          </b-table-column>

          <b-table-column
            v-slot="props"
            cell-class="do-not-wrap"
            centered
            field="actions"
            label="Műveletek"
            width="32"
          >
            <clickable-icon
              class="mr-2"
              icon="pencil"
              type="is-info"
              @click="openPermitEditPopup(props.row)"
            ></clickable-icon>
            <clickable-icon
              icon="trash"
              type="is-danger"
              @click="deletePermitRow(props.row)"
            ></clickable-icon>
          </b-table-column>
        </b-table>
      </template>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import ClickableIcon from "@/components/module/icon/ClickableIcon";
import UserPermissionForm from "./UserPermissionForm";
import { getDisplayName, openDeleteConfirm } from "@/utils/util";
import { USER_PERMISSION_TYPE_LABEL } from "@/utils/const";
import UserPermitForm from "./UserPermitForm";
import debounce from "lodash.debounce";

export default {
  name: "UserPermissionList",
  components: { ClickableIcon },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch("employee/fetchAdmin");
    await this.fetchList();
  },
  computed: {
    ...mapGetters({
      permissions: "user_permission/permissions",
      permits: "user_permission/permits",
      employees: "employee/employees",
    }),
  },
  data() {
    return {
      tab: 0,
      loading: false,
      getDisplayName,
      USER_PERMISSION_TYPE_LABEL,
      openDeleteConfirm,
      pagination: {
        totalCount: 0,
        page: 0,
        size: 10,
      },
      sort: [],
      filter: {},
    };
  },
  methods: {
    openEditPopup(row) {
      this.$buefy.modal.open({
        parent: this,
        component: UserPermissionForm,
        props: { permission: row },
        hasModalCard: true,
        trapFocus: true,
      });
    },
    openPermitEditPopup(row) {
      this.$buefy.modal.open({
        parent: this,
        component: UserPermitForm,
        props: {
          data: row,
          permissions: this.permissions,
          employees: this.employees,
          fetch: this.fetchList,
        },
        hasModalCard: true,
        trapFocus: true,
      });
    },
    deleteRow(row) {
      openDeleteConfirm(
        this.$buefy,
        async () => {
          await this.$store.dispatch("user_permission/delete", row.id);
          await this.fetchList(true);
        },
        "Biztos vagy benne, hogy <strong>törölni</strong> szeretnéd?</br>Törlődni fog az <strong>összes</strong> felhasználó jogosultsága közül!"
      );
    },
    deletePermitRow(row) {
      this.openDeleteConfirm(
        this.$buefy,
        async () => {
          await this.$store.dispatch(
            "user_permission/deactivateUser",
            row.userId
          );
          await this.fetchList(true);
        },
        "Biztos, hogy <strong>deaktiválni</strong> szeretnéd <em>" +
          row.userName +
          "</em> <strong>összes</strong> jogosultságát?"
      );
    },
    async fetchList(force) {
      this.loading = true;
      await this.$store.dispatch("user_permission/fetch", force);
      await this.fetchPermits(true);
      this.loading = false;
    },
    async fetchPermits(force) {
      this.loading = true;
      const { page, size } = this.pagination;

      const sort = this.sort.map((sort) => `${sort.field},${sort.order}`);
      let params = new URLSearchParams();
      params.append("page", page);
      params.append("size", size);
      for (const currentSort of sort) {
        params.append("sort", currentSort);
      }

      for (const [key, value] of Object.entries(this.filter)) {
        if (value) params.append(key, value);
      }
      let requestParams = { params: params, force };
      await this.$store
        .dispatch("user_permission/fetchPermits", requestParams)
        .then(() => {
          this.pagination = this.permits.pagination;
        });
      this.loading = false;
    },
    onPageChange: function (page) {
      this.pagination.page = page - 1;
      this.fetchPermits();
    },
    onSort: function (field, order) {
      let current = this.sort.filter((sort) => sort.field === field).shift();
      if (current) current.order = order;
      else {
        let sort = {
          field: field,
          order: order,
        };
        this.sort.push(sort);
      }
      this.fetchPermits();
    },

    sortingPriorityRemoved(field) {
      this.sort = this.sort.filter((sort) => sort.field !== field);
      this.fetchPermits();
    },

    onFilterChange: debounce(
      function (filter) {
        const { userName, permits } = filter;
        this.filter = {
          userName,
          permits,
        };
        this.fetchPermits();
      },
      500,
      { leading: true }
    ),

    removeFilter: function (filteredField) {
      this.$refs.permitTable.filters[filteredField] = null;
      this.filter[filteredField] = null;
      this.fetchPermits();
    },

    getUserByDisplayName(displayName) {
      let employees = this.employees.filter((option) => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(displayName.toLowerCase()) >= 0
        );
      });
      for (let i in employees) {
        employees[i] = employees[i].id;
      }
      return employees;
    },

    formattedUserPermits(permits) {
      return permits
        ?.map(
          (permit) =>
            permit.permission.name +
            " (" +
            this.$tf(this.USER_PERMISSION_TYPE_LABEL[permit.status]) +
            ")"
        )
        ?.join(", ");
    },
  },
};
</script>

<style lang="scss" scoped>
.button-container {
  display: flex;

  button {
    margin-left: auto;
  }
}

.tab-content {
  padding: 0 !important;
  padding-top: 2rem !important;
}
</style>
