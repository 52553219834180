<template>
  <section class="section absence-request">
    <div class="back ml-5 mb-3 has-font-weight-500">
      <RouterLink to="../?force=true">
        <div class="is-inline-flex is-align-items-center">
          <b-icon icon="chevron-left" />
          <p>
            {{ $tf("handleAbsenceRequestForm.backToList|Szabadságkérelmek") }}
          </p>
        </div>
      </RouterLink>
    </div>
    <div v-if="absenceRequest" class="absence-request-columns">
      <div class="columns">
        <div class="column is-6">
          <article class="panel is-light">
            <div class="panel-heading">
              {{
                $tf("handleAbsenceRequestForm.requestDetails|Kérelem adatai")
              }}
            </div>
            <div class="panel-block">
              <section style="width: 100%">
                <b-field
                  :label="$tf('handleAbsenceRequestForm.date|Kérelem dátuma')"
                  horizontal
                >
                  <b-input
                    :value="formatDate(absenceRequest.created)"
                    disabled
                  />
                </b-field>
                <b-field
                  :label="$tf('handleAbsenceRequestForm.type|Típus')"
                  horizontal
                >
                  <b-input :value="absenceRequest.type.name" disabled />
                </b-field>
                <b-field
                  :label="$tf('handleAbsenceRequestForm.status|Státusz')"
                  horizontal
                >
                  <b-input :value="absenceRequest.status.display" disabled />
                </b-field>
                <b-field
                  :label="$tf('handleAbsenceRequestForm.description|Leírás')"
                  horizontal
                >
                  <b-input :value="absenceRequest.description" disabled />
                </b-field>
                <b-field
                  :label="$tf('handleAbsenceRequestForm.requestDate|Dátum')"
                  horizontal
                >
                  <b-field group-multiline grouped>
                    <b-tooltip
                      v-if="absenceRequest.carryOver"
                      :label="
                        $tf(
                          'handleAbsenceRequestForm.carryOver.tooltip|Előző évről áthozott'
                        )
                      "
                      class="mr-2 my-auto"
                      type="is-light"
                    >
                      <div class="is-flex is-align-items-center">
                        <b-icon icon="clock-rotate-left" size="is-small" />
                      </div>
                    </b-tooltip>
                    <b-field
                      v-for="(
                        actDate, index
                      ) in absenceRequest.absenceRequestDates"
                      :key="index"
                    >
                      <b-tag type="is-info">
                        {{ formatDate(actDate) }}
                      </b-tag>
                    </b-field>
                  </b-field>
                </b-field>

                <div v-if="absenceRequestId">
                  <commented-approval
                    :absence-request-id="absenceRequestId"
                    :reload="commentedApprovalKey"
                  ></commented-approval>
                </div>

                <b-input
                  v-model="approvalComment"
                  :disabled="
                    absenceRequest.status.enum === 'REVOKED' ||
                    absenceRequest.status.enum === 'ACTIVE' ||
                    absenceRequest.status.enum === 'TAKEN' ||
                    isSubmitting
                  "
                  :placeholder="
                    $tf('handleAbsenceRequestForm.approvalComment|Indoklás')
                  "
                  maxlength="200"
                  type="textarea"
                ></b-input>

                <div v-if="absenceRequestId" class="panel-block">
                  <b-field group-multiline grouped>
                    <b-field v-if="canApproveRequests">
                      <b-button
                        :disabled="
                          absenceRequest.status.enum === 'REVOKED' ||
                          absenceRequest.status.enum === 'ACTIVE' ||
                          absenceRequest.status.enum === 'TAKEN' ||
                          absenceRequest.status.enum === 'APPROVED' ||
                          absenceRequest.status.enum === 'LINE_APPROVED'
                        "
                        :loading="isSubmitting"
                        type="is-success"
                        @click="approve('APPROVE')"
                      >
                        {{ $tf("handleAbsenceRequestForm.accept|Elfogadom") }}
                      </b-button>
                    </b-field>
                    <b-field>
                      <b-button
                        :disabled="
                          absenceRequest.status.enum === 'REVOKED' ||
                          absenceRequest.status.enum === 'ACTIVE' ||
                          absenceRequest.status.enum === 'TAKEN' ||
                          absenceRequest.status.enum === 'CLARIFY'
                        "
                        :loading="isSubmitting"
                        type="is-warning"
                        @click="approve('CLARIFY')"
                      >
                        {{
                          $tf(
                            "handleAbsenceRequestForm.clarify|Egyeztetés szükséges"
                          )
                        }}
                      </b-button>
                    </b-field>
                    <b-field v-if="canRevokeRequests">
                      <b-button
                        :disabled="
                          absenceRequest.status.enum === 'REVOKED' ||
                          absenceRequest.status.enum === 'ACTIVE' ||
                          absenceRequest.status.enum === 'TAKEN' ||
                          absenceRequest.status.enum === 'DENIED'
                        "
                        :loading="isSubmitting"
                        type="is-danger"
                        @click="approve('DENY')"
                      >
                        {{ $tf("handleAbsenceRequestForm.deny|Elutasítom") }}
                      </b-button>
                    </b-field>
                  </b-field>
                  <br />
                </div>
              </section>
            </div>
          </article>
        </div>

        <div class="column is-6">
          <article class="panel is-light">
            <div class="panel-heading">
              {{
                $tf("handleAbsenceRequestForm.userDetails|Felhasználó adatai")
              }}
            </div>
            <div class="panel-block">
              <section>
                <user-info
                  :user="absenceRequest.userData"
                  display-mode="full"
                />

                <ul class="user-data-list">
                  <li v-if="quota">
                    <strong>{{
                      $tf("handleAbsenceRequestForm.quota.all|Összes:")
                    }}</strong
                    >{{
                      $tf("handleAbsenceRequestForm.quota.days|{days} nap", {
                        days: quota.quota,
                      })
                    }}
                  </li>
                  <li v-if="quota">
                    <strong>{{
                      $tf(
                        "handleAbsenceRequestForm.quota.remaining|Fennmaradó:"
                      )
                    }}</strong>
                    <span :class="{ 'has-text-red': quota.remaining <= 0 }"
                      >{{
                        $tf("handleAbsenceRequestForm.quota.days|{days} nap", {
                          days: quota.remaining,
                        })
                      }}
                    </span>
                  </li>
                  <li v-if="quota">
                    <strong>{{
                      $tf("handleAbsenceRequestForm.quota.reserved|Lefoglalt:")
                    }}</strong>
                    {{
                      $tf("handleAbsenceRequestForm.quota.days|{days} nap", {
                        days: quota.reserved,
                      })
                    }}
                  </li>
                  <li v-if="quota">
                    <strong>{{
                      $tf("handleAbsenceRequestForm.quota.taken|Felhasznált:")
                    }}</strong>
                    {{
                      $tf("handleAbsenceRequestForm.quota.days|{days} nap", {
                        days: quota.taken,
                      })
                    }}
                  </li>
                </ul>

                <div class="panel-heading datepicker-heading">
                  {{
                    $tf(
                      "handleAbsenceRequestForm.userDates|Felhasználó szabadságkérelmei"
                    )
                  }}
                </div>
                <f-datepicker
                  :editable="false"
                  :events="[
                    ...absenceRequestsWaitingDates,
                    ...absenceRequestsPastDates,
                  ]"
                  :focusable="false"
                  indicators="bars"
                  inline
                />
              </section>
            </div>
          </article>
        </div>
      </div>
    </div>

    <div v-if="absenceRequest">
      <section>
        <div class="collapse card container absence-request-card-container">
          <b-collapse
            v-model="isWaitingRequestsOpen"
            animation="slide"
            aria-id="contentIdForA11y2"
          >
            <template v-slot:trigger>
              <div
                aria-controls="contentIdForA11y2"
                class="card-header"
                role="button"
              >
                <strong class="card-header-title absence-collapse-title">
                  <a>
                    {{
                      $tf(
                        "handleAbsenceRequestForm.pendingRequestsOfUser.title|{user} döntésre váró kérelmei",
                        { user: absenceRequest.user }
                      )
                    }}
                  </a>
                  <div class="ml-auto">
                    <a class="card-header-icon">
                      <b-icon
                        :icon="
                          isWaitingRequestsOpen ? 'chevron-up' : 'chevron-down'
                        "
                      >
                      </b-icon>
                    </a>
                  </div>
                </strong>
              </div>
            </template>

            <b-table
              ref="table"
              :data="absenceRequestsWaiting.items"
              :per-page="paginationWaiting.size"
              :total="paginationWaiting.totalCount"
              class="absence-collapse-container"
              default-sort-direction="desc"
              hoverable
              mobile-cards
              striped
              @sort="onSortWaiting"
              @page-change="onPageChangeWaiting"
            >
              <b-table-column
                v-slot="props"
                :label="
                  $tf(
                    'handleAbsenceRequestForm.pendingRequestsOfUser.table.type|Típus'
                  )
                "
                field="type"
                sortable
              >
                {{ props.row.type.name }}
              </b-table-column>

              <b-table-column
                v-slot="props"
                :label="
                  $tf(
                    'handleAbsenceRequestForm.pendingRequestsOfUser.table.status|Státusz'
                  )
                "
                field="status"
                sortable
              >
                {{ props.row.status ? props.row.status.display : "-" }}
              </b-table-column>

              <b-table-column
                v-slot="props"
                :label="
                  $tf(
                    'handleAbsenceRequestForm.pendingRequestsOfUser.table.description|Leírás'
                  )
                "
                centered
                field="description"
              >
                {{ props.row.description }}
              </b-table-column>

              <b-table-column
                v-slot="props"
                :label="
                  $tf(
                    'handleAbsenceRequestForm.pendingRequestsOfUser.table.requestDate|Dátum'
                  )
                "
                centered
                field="date"
                width="450"
              >
                <div
                  class="is-flex is-flex-wrap-wrap has-gap-1 is-justify-content-center is-align-items-center"
                >
                  <b-tooltip
                    v-if="props.row.carryOver"
                    :label="
                      $tf(
                        'handleAbsenceRequestForm.pendingRequestsOfUser.table.carryOver.tooltip|Előző évről áthozott'
                      )
                    "
                    class="mr-2"
                    type="is-light"
                  >
                    <div class="is-flex is-align-items-center">
                      <b-icon icon="clock-rotate-left" size="is-small" />
                    </div>
                  </b-tooltip>
                  <b-tag
                    v-for="(actDate, index) in props.row.absenceRequestDates"
                    :key="index"
                    :type="
                      getDateType(
                        props.row.status ? props.row.status.enum : null
                      )
                    "
                  >
                    {{ formatDate(actDate) }}
                  </b-tag>
                </div>
              </b-table-column>

              <b-table-column
                v-slot="props"
                :label="
                  $tf(
                    'handleAbsenceRequestForm.pendingRequestsOfUser.table.actions|Műveletek'
                  )
                "
                centered
                field="actions"
              >
                <b-tooltip
                  :label="
                    $tf(
                      'handleAbsenceRequestForm.pendingRequestsOfUser.table.actions.open|Megnyitás'
                    )
                  "
                  type="is-light"
                >
                  <clickable-icon
                    icon="edit"
                    type="is-info"
                    @click="openAbsenceRequestForm(props.row.id)"
                  />
                </b-tooltip>
              </b-table-column>

              <template #empty>
                <div class="has-text-centered">
                  {{
                    $tf(
                      "handleAbsenceRequestForm.pendingRequestsOfUser.table.empty|Jelenleg nincs több döntésre váró kérelem."
                    )
                  }}
                </div>
              </template>
            </b-table>
          </b-collapse>
        </div>
        <div class="collapse card container absence-request-card-container">
          <b-collapse
            v-model="isPastRequestOpen"
            animation="slide"
            aria-id="contentIdForA11y2"
          >
            <template v-slot:trigger>
              <div
                aria-controls="contentIdForA11y2"
                class="card-header"
                role="button"
              >
                <strong class="card-header-title absence-collapse-title">
                  <a>
                    {{
                      $tf(
                        "handleAbsenceRequestForm.pastRequestsOfUser.title|{user} korábbi kérelmei",
                        { user: absenceRequest.user }
                      )
                    }}
                  </a>
                  <div class="ml-auto">
                    <a class="card-header-icon">
                      <b-icon
                        :icon="
                          isPastRequestOpen ? 'chevron-up' : 'chevron-down'
                        "
                      >
                      </b-icon>
                    </a>
                  </div>
                </strong>
              </div>
            </template>

            <b-table
              ref="table"
              :data="absenceRequestsPast.items"
              :per-page="paginationPast.size"
              :total="paginationPast.totalCount"
              class="absence-collapse-container"
              default-sort-direction="desc"
              hoverable
              mobile-cards
              striped
              @sort="onSortPast"
              @page-change="onPageChangePast"
            >
              <b-table-column
                v-slot="props"
                :label="
                  $tf(
                    'handleAbsenceRequestForm.pastRequestsOfUser.table.type|Típus'
                  )
                "
                field="type"
                sortable
              >
                {{ props.row.type.name }}
              </b-table-column>

              <b-table-column
                v-slot="props"
                :label="
                  $tf(
                    'handleAbsenceRequestForm.pastRequestsOfUser.table.status|Státusz'
                  )
                "
                field="status"
                sortable
              >
                {{ props.row.status ? props.row.status.display : "-" }}
              </b-table-column>

              <b-table-column
                v-slot="props"
                :label="
                  $tf(
                    'handleAbsenceRequestForm.pastRequestsOfUser.table.description|Leírás'
                  )
                "
                centered
                field="description"
              >
                {{ props.row.description }}
              </b-table-column>

              <b-table-column
                v-slot="props"
                :label="
                  $tf(
                    'handleAbsenceRequestForm.pastRequestsOfUser.table.requestDate|Dátum'
                  )
                "
                centered
                field="date"
                width="450"
              >
                <div
                  class="is-flex is-flex-wrap-wrap has-gap-1 is-justify-content-center is-align-items-center"
                >
                  <b-tooltip
                    v-if="props.row.carryOver"
                    :label="
                      $tf(
                        'handleAbsenceRequestForm.pastRequestsOfUser.table.carryOver.tolltip|Előző évről áthozott'
                      )
                    "
                    class="mr-2"
                    type="is-light"
                  >
                    <div class="is-flex is-align-items-center">
                      <b-icon icon="clock-rotate-left" size="is-small" />
                    </div>
                  </b-tooltip>
                  <b-tag
                    v-for="(actDate, index) in props.row.absenceRequestDates"
                    :key="index"
                    :type="
                      getDateType(
                        props.row.status ? props.row.status.enum : null
                      )
                    "
                  >
                    {{ formatDate(actDate) }}
                  </b-tag>
                </div>
              </b-table-column>

              <b-table-column
                v-slot="props"
                :label="
                  $tf(
                    'handleAbsenceRequestForm.pastRequestsOfUser.table.actions|Műveletek'
                  )
                "
                centered
                field="actions"
              >
                <b-tooltip
                  :label="
                    $tf(
                      'handleAbsenceRequestForm.pastRequestsOfUser.table.actions.open|Kérelem megnyitása'
                    )
                  "
                  type="is-light"
                >
                  <clickable-icon
                    icon="edit"
                    type="is-info"
                    @click="openAbsenceRequestForm(props.row.id)"
                  />
                </b-tooltip>
              </b-table-column>

              <template #empty>
                <div class="has-text-centered">
                  {{
                    $tf(
                      "handleAbsenceRequestForm.pastRequestsOfUser.table.empty|Nincs korábbi kérelme a felhasználónak."
                    )
                  }}
                </div>
              </template>
            </b-table>
          </b-collapse>
        </div>
      </section>
    </div>

    <b-loading
      v-model="isLoading"
      :can-cancel="true"
      :is-full-page="true"
    ></b-loading>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import CommentedApproval from "@/components/absence/admin/CommentedApproval";
import ClickableIcon from "@/components/module/icon/ClickableIcon";
import { createUrlSearchParams, formatDate } from "@/utils/util";
import { PRIVILEGES } from "@/utils/const";
import UserInfo from "../../../components/module/info/UserInfo.vue";
import FDatepicker from "../../../components/module/input/FDatepicker.vue";

export default {
  name: "HandleAbsenceRequestForm",
  components: { FDatepicker, UserInfo, CommentedApproval, ClickableIcon },
  async mounted() {
    this.absenceRequestId = parseInt(this.$route.params.id);
    await this.getAbsenceRequest();
    await this.getWaiting();
    await this.getPast();

    this.absenceRequestsPast.items.map((item) => {
      item.absenceRequestDates.map((date) => {
        this.absenceRequestsPastDates.push({
          date: new Date(date),
          type: item?.status?.enum === "TAKEN" ? "is-success" : "is-warning",
        });
      });
    });

    this.absenceRequestsWaiting.items.map((item) => {
      item.absenceRequestDates.map((date) => {
        this.absenceRequestsWaitingDates.push({
          date: new Date(date),
          type: "is-info",
        });
      });
    });

    // let paramsPastRequests = new URLSearchParams();
    // paramsPastRequests.append("user", this.absenceRequest.user);
    // paramsPastRequests.append("status", "APPROVED");
    // paramsPastRequests.append("status", "ACTIVE");
    // paramsPastRequests.append("status", "TAKEN");
    // paramsPastRequests.append("status", "DENIED");
    // paramsPastRequests.append("status", "REVOKED");
    //
    // let paramsWaitingRequests = new URLSearchParams();
    // paramsWaitingRequests.append("user", this.absenceRequest.user);
    // paramsWaitingRequests.append("status", "REQUEST");
    //
    // await this.$store.dispatch("absence_request/getPmAbsenceRequests", {
    //   params: paramsWaitingRequests
    // });

    await this.getAbsenceQuota();
  },
  data() {
    return {
      formatDate,
      commentedApprovalKey: 0,
      approvalComment: null,
      absenceRequestId: null,
      isWaitingRequestsOpen: false,
      isPastRequestOpen: false,
      isLoading: false,
      isSubmitting: false,
      paginationPast: {
        totalCount: 0,
        page: 0,
        size: 10,
      },
      sortPast: {
        field: "created",
        order: "desc",
      },
      paginationWaiting: {
        totalCount: 0,
        page: 0,
        size: 10,
      },
      sortWaiting: {
        field: "created",
        order: "desc",
      },
      value: "",
      context: null,
      absenceRequestsWaitingDates: [],
      absenceRequestsPastDates: [],
    };
  },
  computed: {
    canApproveRequests() {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ABSENCE.REQUEST.APPROVE_ASSIGNED,
        PRIVILEGES.ABSENCE.REQUEST.APPROVE_ANY,
        PRIVILEGES.ABSENCE.REQUEST.ADMIN
      );
    },
    canRevokeRequests: function () {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ABSENCE.REQUEST.REVOKE,
        PRIVILEGES.ABSENCE.REQUEST.REVOKE_ANY,
        PRIVILEGES.ABSENCE.REQUEST.ADMIN
      );
    },
    ...mapGetters({
      absenceRequestsWaiting: "absence_request/pmAbsenceRequestsWaiting",
      absenceRequestsPast: "absence_request/pmAbsenceRequestsPast",
      absenceRequest: "absence_request/absenceRequest",
      quota: "absence_request/userQuota",
    }),
  },
  methods: {
    async getAbsenceRequest(force) {
      await this.$store.dispatch("absence_request/getAbsenceRequest", {
        absenceId: this.absenceRequestId,
        force,
      });

      await this.getPast(force);
      await this.getWaiting(force);
    },

    async getPast(force) {
      await this.$store.dispatch("absence_request/getPmAbsenceRequestsPast", {
        user: this.absenceRequest.userId,
        pageable: {
          params: createUrlSearchParams(this.paginationPast, this.sortPast),
        },
        force,
      });
      this.paginationPast = this.absenceRequestsPast.pagination;
    },
    async getWaiting(force) {
      await this.$store.dispatch(
        "absence_request/getPmAbsenceRequestsWaiting",
        {
          user: this.absenceRequest.userId,
          pageable: {
            params: createUrlSearchParams(
              this.paginationWaiting,
              this.sortWaiting
            ),
          },
          force,
        }
      );
      this.paginationWaiting = this.absenceRequestsWaiting.pagination;
    },

    async getAbsenceQuota(force) {
      let params = {
        year: new Date().getFullYear(),
        username: this.absenceRequest.userId,
        force,
      };
      await this.$store.dispatch("absence_request/getUserQuota", params);
    },
    forceRerenderCommentedApprovalComponent() {
      this.commentedApprovalKey = this.commentedApprovalKey + 1;
    },
    async approve(decision) {
      this.isSubmitting = true;
      let payload = {
        comment: this.approvalComment,
        approval: decision,
        user: this.absenceRequest.userId,
        absenceRequestId: this.absenceRequest.id,
      };
      await this.$store
        .dispatch("absence_request/approve", payload)
        .then(async () => {
          await this.getAbsenceRequest(true);
          this.forceRerenderCommentedApprovalComponent();
          this.approvalComment = null;
          await this.getAbsenceQuota(true);
          //this.$router.push("/approval/absence");
        });
      this.isSubmitting = false;
    },
    async openAbsenceRequestForm(absenceId) {
      await this.$router.push("/approval/absence/handle-requests/" + absenceId);
      this.absenceRequestId = parseInt(this.$route.params.id);
      this.isLoading = true;
      await this.getAbsenceRequest(true);
      await this.forceRerenderCommentedApprovalComponent();
      this.isLoading = false;
    },

    onPageChangePast: function (page) {
      this.paginationPast.page = page - 1;
      this.getPast();
    },
    onSortPast: function (field, order) {
      this.sortPast.field = field;
      this.sortPast.order = order;
      this.getPast();
    },
    onPageChangeWaiting: function (page) {
      this.paginationWaiting.page = page - 1;
      this.getWaiting();
    },
    onSortWaiting: function (field, order) {
      this.sortWaiting.field = field;
      this.sortWaiting.order = order;
      this.getWaiting();
    },
    getDateType(status) {
      switch (status) {
        case "REQUEST":
          return "is-info";
        case "LINE_APPROVED":
          return "is-info";
        case "APPROVED":
          return "is-info";
        case "ACTIVE":
          return "is-success";
        case "TAKEN":
          return "is-light";
        case "DENIED":
          return "is-danger";
        case "REVOKED":
          return "is-light";
      }
    },
    onContext(context) {
      this.context = context;
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors.scss";

.absence-request {
  &-columns {
    padding-bottom: 40px;
    margin-right: 32px;
    margin-left: 32px;
    overflow: hidden;
  }

  .datepicker-heading {
    padding-left: 0 !important;
  }

  .datepicker .dropdown-content {
    box-shadow: none !important;
  }

  .dropdown-item {
    padding-left: 0 !important;
  }

  .panel {
    background: $white;
    padding: 15px 15px 25px;
    height: 100% !important;

    &-heading {
      color: $custom-dark-blue !important;
      background: $white !important;
    }
  }

  .user-data-list {
    margin-left: 8px;
  }
}

.section.absence-request {
  padding: 1rem 0 !important;
}

.absence-request-card {
  &-container {
    padding: 0 !important;
  }
}

.absence-collapse {
  &-title {
    a {
      color: $custom-dark-blue;
      padding: 10px;

      &:hover,
      &:active {
        color: $custom-dark-blue;
      }
    }
  }

  &-container {
    padding: 1.5rem;
  }

  .button .is-warning {
    color: $always-white !important;
  }
}
</style>
