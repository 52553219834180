import ForecastRule from "@/types/ForecastRule.ts";
import ForecastRuleFetch from "@/types/ForecastRuleFetch.ts";
import ForecastRuleGet from "@/types/ForecastRuleGet.ts";

export const PLACE = {
  OFFICE: "Iroda",
  HOME_OFFICE: "Home office",
  OUTSOURCE: "Külső cég",
};

export const PROJECT_TABS = {
  DEFAULT: "ALL",
  ALL: "ALL",
  SIMPLE: "SIMPLE",
  AGGREGATED: "AGGREGATED",
  SUPPORT: "SUPPORT",
  OUTSOURCE: "OUTSOURCE",
  HASH: {
    DEFAULT: "#all",
    ALL: "#all",
    SIMPLE: "#simple",
    SUPPORT: "#support",
    OUTSOURCE: "#outsource",
  },
};

export const RALLOC_TABS = {
  DEFAULT: "PROJECTS",
  PROJECTS: "PROJECTS",
  EMPLOYEES: "EMPLOYEES",
  HASH: {
    DEFAULT: "#projects",
    PROJECTS: "#projects",
    EMPLOYEES: "#employees",
  },
};

export const PROJECT_DETAILS_TABS = {
  DEFAULT: "OVERVIEW",
  OVERVIEW: "OVERVIEW",
  BASIC: "BASIC",
  RESOURCE: "RESOURCE",
  TIGS: "TIGS",
  INVOICES: "INVOICES",
  JOURNAL: "JOURNAL",
  HASH: {
    DEFAULT: "#overview",
    OVERVIEW: "#overview",
    BASIC: "#basic",
    RESOURCE: "#resource",
    TIGS: "#tigs",
    EXPENSES: "#expenses",
    INVOICES: "#invoices",
    JOURNAL: "#journal",
  },
};

export const SUPPORT_PROJECT_DETAILS_TABS = {
  DEFAULT: "OVERVIEW",
  OVERVIEW: "OVERVIEW",
  BASIC: "BASIC",
  BUGFIX: "BUGFIX",
  RESOURCE: "RESOURCE",
  LOCKUP: "LOCKUP",
  BILLABLES: "BILLABLES",
  INVOICES: "INVOICES",
  TASKS: "TASKS",
  HASH: {
    DEFAULT: "#overview",
    OVERVIEW: "#overview",
    BASIC: "#basic",
    BUGFIX: "#bugfix",
    RESOURCE: "#resource",
    LOCKUP: "#lockup",
    BILLABLES: "#billables",
    INVOICES: "#invoices",
    TASKS: "#tasks",
  },
};

export const OUTSOURCE_PROJECT_DETAILS_TABS = {
  DEFAULT: "OVERVIEW",
  OVERVIEW: "OVERVIEW",
  BASIC: "BASIC",
  CONTRACTS: "CONTRACTS",
  RESOURCE: "RESOURCE",
  TASKS: "TASKS",
  HASH: {
    DEFAULT: "#overview",
    OVERVIEW: "#overview",
    BASIC: "#basic",
    CONTRACTS: "#contracts",
    RESOURCE: "#resource",
    TASKS: "#tasks",
  },
};

export const LOGGING_TABS = {
  DEFAULT: "CALENDAR",
  CALENDAR: "CALENDAR",
  IMPORT: "IMPORT",
  COUNTER: "COUNTER",
  HASH: {
    DEFAULT: "#calendar",
    CALENDAR: "#calendar",
    IMPORT: "#import",
    COUNTER: "#counter",
  },
};

export const EMPLOYEE_LIST_TABS = {
  DEFAULT: "EMPLOYEES",
  EMPLOYEES: "EMPLOYEES",
  TEAMS: "TEAMS",
  HASH: {
    DEFAULT: "#employees",
    EMPLOYEES: "#employees",
    TEAMS: "#teams",
  },
};

export const TIG_TYPES = {
  TIG: "tigType.tig|Teljesítési mérföldkő (TIG)",
  CR: "tigType.cr|Érvényesített CR",
  MILESTONE: "tigType.milestone|Belső mérföldkő",
  UNTRACKED: "tigType.untracked|Költség nélküli mérföldkő",
};

export const OVERTIME_REQUEST_STATUS = {
  ENUM: {
    REQUEST: "REQUEST",
    APPROVED: "APPROVED",
    DENIED: "DENIED",
    REVOKED: "REVOKED",
  },
  LABEL: {
    REQUEST: "overtimeRequestStatus.request|Kérvényezve",
    APPROVED: "overtimeRequestStatus.approved|Elfogadva",
    DENIED: "overtimeRequestStatus.denied|Elutasítva",
    REVOKED: "overtimeRequestStatus.revoked|Visszavonva",
  },
  COLORS: {
    REQUEST: "overtime-badge-blue",
    APPROVED: "overtime-badge-green",
    DENIED: "overtime-badge-red",
    REVOKED: "overtime-badge-blue",
  },
};

export const TEAM_LEAD_ROLE = {
  ENUM: {
    RESOURCE: "RESOURCE",
    FINANCIAL: "FINANCIAL",
  },
  LABEL: {
    RESOURCE: "teamLeadRole.resource|Erőforrás vezető",
    FINANCIAL: "teamLeadRole.financial|Pénzügyi vezető",
  },
  ICON: {
    RESOURCE: "business-time",
    FINANCIAL: "coins",
  },
};

export const TEAM_PROJECT_STATUS_LABELS = {
  AUTOMATIC: "teamProjectStatus.automatic|Automatikusan létrehozott",
  MANUAL: "teamProjectStatus.manual|Manuálisan létrehozott",
  MISTAKE: "teamProjectStatus.mistake|Nem a csapat projektje",
};

export const TEAM_PROJECT_STATUS = {
  AUTOMATIC: "AUTOMATIC",
  MANUAL: "MANUAL",
  MISTAKE: "MISTAKE",
};

export const PROJECT_STATUS_ENUM = {
  REQUEST: "REQUEST",
  CANCELLED: "CANCELLED",
  DENIED: "DENIED",
  APPROVED: "APPROVED",
  ONGOING: "ONGOING",
  DONE: "DONE",
  CLOSED: "CLOSED",
};

export const USER_INDICATOR_TYPE_ENUM = {
  DEPARTMENT: "DEPARTMENT",
  ROLE: "ROLE",
  SENIORITY: "SENIORITY",
  COMPETENCY: "COMPETENCY",
};

export const USER_INDICATOR_TYPE_LABEL = {
  DEPARTMENT: "userIndicatorType.department|Terület",
  ROLE: "userIndicatorType.role|Szerepkör",
  SENIORITY: "userIndicatorType.seniority|Szenioritás",
  COMPETENCY: "userIndicatorType.competency|Kompetencia",
};

export const USER_PERMISSION_TYPE_ENUM = {
  HAVE: "HAVE",
  SHOULD_HAVE: "SHOULD_HAVE",
  SHOULD_NOT_HAVE: "SHOULD_NOT_HAVE",
  NOT_HAVE: "NOT_HAVE",
};

export const USER_PERMISSION_TYPE_LABEL = {
  HAVE: "userPermissionType.have|Van",
  SHOULD_HAVE: "userPermissionType.shouldHave|Kéne",
  SHOULD_NOT_HAVE: "userPermissionType.shouldNotHave|Nem kéne",
  NOT_HAVE: "userPermissionType.notHave|Nincs",
};

export const OVERTIME_REQUEST_TYPE = {
  ENUM: {
    NONE: "NONE",
    SHIFT: "SHIFT",
    CASH: "CASH",
  },
  LABEL: {
    NONE: "overtimeRequestType.none|Nincs túlóra",
    SHIFT: "overtimeRequestType.shift|Csúsztatás",
    CASH: "overtimeRequestType.cash|Kifizetés",
  },
};

export const SHORT_MONTH_NAMES = [
  "month.short.january|jan",
  "month.short.february|feb",
  "month.short.march|már",
  "month.short.april|ápr",
  "month.short.may|máj",
  "month.short.june|jun",
  "month.short.july|júl",
  "month.short.august|aug",
  "month.short.september|szep",
  "month.short.october|okt",
  "month.short.november|nov",
  "month.short.december|dec",
];

export const DAY_NAME_LABEL = {
  MONDAY: "day.monday|Hétfő",
  TUESDAY: "day.tuesday|Kedd",
  WEDNESDAY: "day.wednesday|Szerda",
  THURSDAY: "day.thursday|Csütörtök",
  FRIDAY: "day.friday|Péntek",
  SATURDAY: "day.saturday|Szombat",
  SUNDAY: "day.sunday|Vasárnap",
};

export const DAY_NAME_SHORT_LABEL = {
  MONDAY: "day.short.monday|HÉ",
  TUESDAY: "day.short.tuesday|KE",
  WEDNESDAY: "day.short.wednesday|SZE",
  THURSDAY: "day.short.thursday|CS",
  FRIDAY: "day.short.friday|PÉ",
  SATURDAY: "day.short.saturday|SZO",
  SUNDAY: "day.short.sunday|VA",
};

export const DAY_NUMBER_LABEL = {
  1: "day.monday|Hétfő",
  2: "day.tuesday|Kedd",
  3: "day.wednesday|Szerda",
  4: "day.thursday|Csütörtök",
  5: "day.friday|Péntek",
  6: "day.saturday|Szombat",
  0: "day.sunday|Vasárnap",
};

export const DAY_NUMBER_SHORT_LABEL = {
  1: "day.letter.monday|H",
  2: "day.letter.tuesday|K",
  3: "day.letter.wednesday|Sze",
  4: "day.letter.thursday|Cs",
  5: "day.letter.friday|P",
  6: "day.letter.saturday|Szo",
  0: "day.letter.sunday|V",
};

export const PROJECT_BASE_DATA_TYPE = {
  ENUM: {
    SERVICE_TYPE: "SERVICE_TYPE",
    SALES_TYPE: "SALES_TYPE",
    INCOME_TYPE: "INCOME_TYPE",
    TECHNOLOGIES: "TECHNOLOGIES",
    LABOR_TYPES: "LABOR_TYPES",
    PRICING_MODEL: "PRICING_MODEL",
    MANAGEMENT_TYPE: "MANAGEMENT_TYPE",
  },
  LABEL: {
    TAB_ITEM: {
      SERVICE_TYPE: "projectBaseDataType.tab.serviceType|Szolgáltatás típusok",
      SALES_TYPE: "projectBaseDataType.tab.salesType|Sales típusok",
      INCOME_TYPE: "projectBaseDataType.tab.incomeType|Árbevétel típusok",
      TECHNOLOGIES: "projectBaseDataType.tab.technologies|Technológiák",
      LABOR_TYPES: "projectBaseDataType.tab.laborTypes|Feladattípusok",
      PRICING_MODEL: "projectBaseDataType.tab.pricingModel|Árazások",
      MANAGEMENT_TYPE:
        "projectBaseDataType.tab.managementType|Szervezéstípusok",
    },
    NEW_BUTTON: {
      SERVICE_TYPE:
        "projectBaseDataType.button.serviceType|Új szolgáltatás típus felvétele",
      SALES_TYPE:
        "projectBaseDataType.button.salesType|Új sales típus felvétele",
      INCOME_TYPE:
        "projectBaseDataType.button.incomeType|Új árbevétel típus felvétele",
      TECHNOLOGIES:
        "projectBaseDataType.button.technologies|Új technológia felvétele",
      LABOR_TYPES:
        "projectBaseDataType.button.laborTypes|Új feladattípus felvétele",
      PRICING_MODEL:
        "projectBaseDataType.button.pricingModel|Új árazás felvétele",
      MANAGEMENT_TYPE:
        "projectBaseDataType.button.managementType|Új szervezéstípus felvétele",
    },
    NEW_TITLE: {
      SERVICE_TYPE:
        "projectBaseDataType.newTitle.serviceType|Új szolgáltatás típus felvétele",
      SALES_TYPE:
        "projectBaseDataType.newTitle.salesType|Új sales típus felvétele",
      INCOME_TYPE:
        "projectBaseDataType.newTitle.incomeType|Új árbevétel típus felvétele",
      TECHNOLOGIES:
        "projectBaseDataType.newTitle.technologies|Új technológia felvétele",
      LABOR_TYPES:
        "projectBaseDataType.newTitle.laborTypes|Új feladattípus felvétele",
      PRICING_MODEL:
        "projectBaseDataType.newTitle.pricingModel|Új árazás felvétele",
      MANAGEMENT_TYPE:
        "projectBaseDataType.newTitle.managementType|Új szervezéstípus felvétele",
    },
    EDIT_TITLE: {
      SERVICE_TYPE:
        "projectBaseDataType.editTitle.serviceType|Szolgáltatás típus módosítása",
      SALES_TYPE:
        "projectBaseDataType.editTitle.salesType|Sales típus módosítása",
      INCOME_TYPE:
        "projectBaseDataType.editTitle.incomeType|Árbevétel típus módosítása",
      TECHNOLOGIES:
        "projectBaseDataType.editTitle.technologies|technológia módosítása",
      LABOR_TYPES:
        "projectBaseDataType.editTitle.laborTypes|Feladattípus módosítása",
      PRICING_MODEL:
        "projectBaseDataType.editTitle.pricingModel|Árazás módosítása",
      MANAGEMENT_TYPE:
        "projectBaseDataType.editTitle.managementType|Szervezéstípus módosítása",
    },
  },
};

export const FORECAST_YEAR_STATUS = {
  ENUM: {
    INACTIVE: "INACTIVE",
    ACTIVE: "ACTIVE",
    CLOSED: "CLOSED",
  },
  LABEL: {
    INACTIVE: "forecastYearStatus.inactive|Inaktív",
    ACTIVE: "forecastYearStatus.active|Aktív",
    CLOSED: "forecastYearStatus.closed|Lezárt",
  },
};

export const FORECAST_YEAR_QUARTER = {
  Q1: "Q1",
  Q2: "Q2",
  Q3: "Q3",
  Q4: "Q4",
};

export const FORECAST_RULES = [
  new ForecastRule(
    "ACCOUNT",
    "forecastRules.account|Ügyfél",
    "forecastRules.accountTypeSelected|Kiválasztott ügyfelek:",
    new ForecastRuleFetch("enterprise_clients/fetchClients", undefined),
    new ForecastRuleGet("enterprise_clients/clients", "id", "name", undefined),
    "user"
  ),
  new ForecastRule(
    "SERVICE_TYPE",
    "forecastRules.serviceType|Szolgáltatás típusa",
    "forecastRules.serviceTypeSelected|Kiválasztott kategóriák:",
    new ForecastRuleFetch("project_base_data/fetchByType", {
      type: "SERVICE_TYPE",
    }),
    new ForecastRuleGet(
      "project_base_data/getByType",
      "id",
      "name",
      "SERVICE_TYPE"
    ),
    "cogs"
  ),
  new ForecastRule(
    "INCOME_TYPE",
    "forecastRules.incomeType|Árbevétel típusa",
    "forecastRules.incomeTypeSelected|Kiválasztott kategóriák:",
    new ForecastRuleFetch("project_base_data/fetchByType", {
      type: "INCOME_TYPE",
    }),
    new ForecastRuleGet(
      "project_base_data/getByType",
      "id",
      "name",
      "INCOME_TYPE"
    ),
    "money-bill-wave"
  ),
  new ForecastRule(
    "SALES_TYPE",
    "forecastRules.salesType|Sales típusa",
    "forecastRules.salesTypeSelected|Kiválasztott kategóriák:",
    new ForecastRuleFetch("project_base_data/fetchByType", {
      type: "SALES_TYPE",
    }),
    new ForecastRuleGet(
      "project_base_data/getByType",
      "id",
      "name",
      "SALES_TYPE"
    ),
    "chart-line"
  ),
];

export const PROJECT_PROGRESS_TYPE = {
  BASIC: "BASIC",
  TIGS: "TIGS",
  ESTIMATED: "ESTIMATED",
  EDUCATED: "EDUCATED",
  MANUAL: "MANUAL",
  NONE: "NONE",
};

export const PROJECT_PROGRESS_TYPE_TOKEN = {
  BASIC: "projectProgressType.basic|Munkaidő / Vállalás",
  TIGS: "projectProgressType.tigs|Mérföldkő",
  ESTIMATED: "projectProgressType.estimated|Munkaidő / Összes becslés",
  EDUCATED: "projectProgressType.educated|Done jegyekből érzékenyített",
  MANUAL: "projectProgressType.manual|Manuális",
  NONE: "projectProgressType.none|Nincs",
};

export const PRIVILEGES = {
  ABSENCE: {
    REQUEST: {
      CREATE: "arc",
      CREATE_ANY: "ard",
      EDIT: "are",
      EDIT_ANY: "arf",
      GET: "arg",
      GET_ASSIGNED: "arh",
      GET_ANY: "ari",
      LIST: "arl",
      LIST_ASSIGNED: "arm",
      LIST_ANY: "arn",
      LIST_DASHBOARD: "arx",
      APPROVE_ASSIGNED: "ara",
      APPROVE_ANY: "arb",
      REVOKE: "arr",
      REVOKE_ANY: "ars",
      ADMIN: "arz",
    },
    QUOTA: {
      CREATE: "aqc",
      EDIT: "aqe",
      GET: "aqg",
      DELETE: "aqd",
      LIST: "aql",
      REMAINING: "aqr",
      REMAINING_ANY: "aqs",
      ADMIN: "aqz",
    },
    SPECIAL_DAY: {
      CREATE: "asc",
      EDIT: "ase",
      GET: "asg",
      DELETE: "asd",
      LIST: "asl",
      ADMIN: "asz",
    },
    TYPE: {
      CREATE: "atc",
      EDIT: "ate",
      GET: "atg",
      DELETE: "atd",
      LIST: "atl",
      ADMIN: "atz",
    },
    MISC: {
      EXPECTED_GET: "ame",
      EXPECTED_GET_ANY: "amf",
      EXPORT_ABSENCES: "amx",
      APPROVAL_EDIT: "amp",
      APPROVAL_DELETE: "amd",
      ADMIN: "amz",
    },
  },
  BULWARK: {
    ROLE: {
      CREATE: "brc",
      EDIT: "bre",
      GET: "brg",
      DELETE: "brd",
      LIST: "brl",
      ADMIN: "brz",
    },
  },
  CENSUS: {
    USER: {
      CREATE: "cuc",
      EDIT: "cue",
      GET: "cug",
      DELETE: "cud",
      LIST: "cul",
      ADMIN: "cuz",
    },
    INDICATOR: {
      CREATE: "cic",
      EDIT: "cie",
      GET: "cig",
      DELETE: "cid",
      LIST: "cil",
      ADMIN: "ciz",
    },
    WORKSCHEDULE: {
      CREATE: "cwc",
      CREATE_ANY: "cwb",
      EDIT: "cwe",
      GET: "cwg",
      GET_ANY: "cwh",
      DELETE: "cwd",
      LIST: "cwl",
      APPROVE: "cwa",
      ADMIN: "cwz",
    },
    TEAM: {
      CREATE: "ctc",
      EDIT: "cte",
      GET: "ctg",
      DELETE: "ctd",
      LIST: "ctl",
      LIST_ASSIGNED: "cta",
      ADMIN: "ctz",
    },
    MISC: {
      CREATE_SITE: "cmc",
      EDIT_SITE: "cme",
      GET_SITE: "cmg",
      DELETE_SITE: "cmd",
      LIST_SITE: "cms",
      LIST_COLLEAGUES: "cmb",
      ADMIN: "cmz",
    },
  },
  ENTERPRISE: {
    PROJECT: {
      CREATE: "epc",
      EDIT_ANY: "epe",
      EDIT_ASSIGNED: "epf",
      GET_ANY: "epg",
      GET_ASSIGNED: "eph",
      DELETE: "epd",
      LIST_ANY: "epl",
      LIST_ASSIGNED: "epm",
      LIST_LIMITED: "epn",
      STAR_PROJECT: "eps",
      ADMIN: "epz",
    },
    PROJECT_EXTRAS: {
      CREATE: "ejc",
      EDIT: "eje",
      GET: "ejg",
      DELETE: "ejd",
      LIST: "ejl",
      EVALUATE_INVOICE: "ejk",
      ADMIN: "ejz",
    },
    PROJECT_CATEGORIES: {
      CREATE: "eqc",
      EDIT: "eqe",
      GET: "eqg",
      DELETE: "eqd",
      LIST: "eql",
      ADMIN: "eqz",
    },
    PROJECT_BASE_DATA: {
      CREATE: "ebc",
      EDIT: "ebe",
      GET: "ebg",
      DELETE: "ebd",
      LIST: "ebl",
      ADMIN: "ebz",
    },
    CLIENT: {
      CREATE: "ecc",
      EDIT: "ece",
      GET: "ecg",
      DELETE: "ecd",
      LIST: "ecl",
      ADMIN: "ecz",
    },
    RISK_MANAGEMENT: {
      CREATE: "erc",
      EDIT: "ere",
      GET_ANY: "erg",
      GET_ASSIGNED: "erh",
      DELETE: "erd",
      LIST_ANY: "erl",
      LIST_ASSIGNED: "erm",
      ADMIN: "erz",
    },
    TIG: {
      CREATE: "etc",
      EDIT: "ete",
      GET: "etg",
      DELETE: "etd",
      LIST: "etl",
      ADMIN: "etz",
    },
    TIER: {
      CREATE: "eic",
      EDIT: "eie",
      GET: "eig",
      DELETE: "eid",
      LIST: "eil",
      LIST_ANY: "eim",
      ADMIN: "eiz",
    },
    TIMESHEET: {
      GET: "esg",
      GET_ASSIGNED: "esh",
      GET_ANY: "esi",
      LOG: "esl",
      EXPORT: "ese",
      EXPORT_ASSIGNED: "esd",
      EXPORT_ANY: "esc",
      ADMIN: "esz",
    },
    OVERTIME: {
      CREATE_REQUEST: "eoc",
      APPROVE_REQUEST: "eoa",
      REVOKE_REQUEST: "eor",
      GET_REQUEST: "eog",
      LIST_REQUEST: "eol",
      CREATE_POLICY: "eob",
      EDIT_POLICY: "eoe",
      GET_POLICY: "eoh",
      DELETE_POLICY: "eod",
      LIST_POLICY: "eom",
      ADMIN: "eoz",
    },
    FORECAST: {
      YEAR: {
        LIST: "eyl",
        CLOSE_PLANNING: "eyc",
        OPEN_PLANNING: "eyo",
        CLOSE_QUARTER: "eyn",
        OPEN_QUARTER: "eyq",
      },
      SEGMENT: {
        LIST: "eel",
        GET: "eeg",
        CREATE: "eec",
        EDIT: "eee",
        DELETE: "eed",
      },
      PLAN: {
        LIST_OWN: "elo",
        LIST_ANY: "ela",
        GET_OWN: "ell",
        GET_ANY: "elg",
        CREATE: "elc",
        EDIT_OWN: "els",
        EDIT_ANY: "els",
        DELETE_OWN: "eld",
        DELETE_ANY: "elt",
      },
      FACT: {
        LIST_OWN: "efo",
        LIST_ANY: "efa",
        GET_OWN: "efl",
        GET_ANY: "efg",
        CREATE: "efc",
        EDIT_OWN: "efs",
        EDIT_ANY: "efs",
        DELETE_OWN: "efd",
        DELETE_ANY: "eft",
      },
      CHANCE_CATEGORY: {
        LIST: "ehl",
        GET: "ehg",
        CREATE: "ehc",
        EDIT: "ehe",
        DELETE: "ehd",
      },
      SCENARIO: {
        LIST: "eal",
        GET: "eag",
        CREATE: "eac",
        EDIT: "eae",
        DELETE: "ead",
      },
      MISC: {
        DASHBOARD: "end",
        ADMIN: "enz",
      },
    },
    MISC: {
      LIST_OVERVIEW: "emo",
      LIST_STATISTICS: "ems",
      LIST_SYNOPSYS: "emy",
      ADMIN: "emz",
    },
    REPORT: {
      GET_MONEYLESS: "edg",
      ADMIN: "edz",
    },
  },
  RALLOC: {
    RESOURCE_ALLOCATION: {
      CREATE: "rac",
      DELETE: "rad",
      EDIT: "rae",
      LIST: "ral",
      ADMIN: "raz",
    },
  },
  TENANT: {
    CONFIG: {
      ADMIN: "tcz",
    },
  },
  MISC: {
    BETA: {
      BETA: "beta",
    },
  },
};

export const DEBUG_LANGUAGE = "DEBUG";

export const PRIVILEGE_GROUPS = {
  ABSENCE_QUOTA: {
    LABEL: "privileges.absenceQuota|Szabadságkvóta",
    PRIVILEGES: [
      { label: "privileges.absenceQuota.create|Létrehozás", value: "aqc" },
      { label: "privileges.absenceQuota.edit|Szerkesztés", value: "aqe" },
      { label: "privileges.absenceQuota.get|Lekérés", value: "aqg" },
      { label: "privileges.absenceQuota.delete|Törlés", value: "aqd" },
      { label: "privileges.absenceQuota.list|Listázás", value: "aql" },
      {
        label: "privileges.absenceQuota.remainingGet|Hátralévő lekérés",
        value: "aqr",
      },
      {
        label:
          "privileges.absenceQuota.remainingGetAny|Bármelyik hátralévő lekérés",
        value: "aqs",
      },
      { label: "privileges.absenceQuota.admin|Admin", value: "aqz" },
    ],
  },
  ABSENCE_REQUEST: {
    LABEL: "privileges.AbsenceRequest|Szabadságkérelem",
    PRIVILEGES: [
      { label: "privileges.AbsenceRequest.create|Létrehozás", value: "arc" },
      {
        label: "privileges.AbsenceRequest.createForAny|Létrehozás bárkinek",
        value: "ard",
      },
      { label: "privileges.AbsenceRequest.edit|Szerkesztés", value: "are" },
      {
        label: "privileges.AbsenceRequest.editAny|Bármelyik szerkesztése",
        value: "arf",
      },
      { label: "privileges.AbsenceRequest.get|Lekérés", value: "arg" },
      {
        label: "privileges.AbsenceRequest.getMyTeam|Hozzámrendelt lekérés",
        value: "arj",
      },
      {
        label: "privileges.AbsenceRequest.getAny|Bármelyik lekérése",
        value: "ari",
      },
      { label: "privileges.AbsenceRequest.list|Listázás", value: "arl" },
      {
        label: "privileges.AbsenceRequest.listMyTeam|Hozzámrendelt listázás",
        value: "arm",
      },
      {
        label: "privileges.AbsenceRequest.listAny|Bármelyik listázás",
        value: "arn",
      },
      {
        label: "privileges.AbsenceRequest.listDashboard|Dashboard listázás",
        value: "arx",
      },
      {
        label: "privileges.AbsenceRequest.dashboard|Dashboard láthatóság",
        value: "ary",
      },
      {
        label:
          "privileges.AbsenceRequest.acceptMyTeam|Hozzámrendelt elfogadása",
        value: "ara",
      },
      {
        label: "privileges.AbsenceRequest.acceptAny|Bármelyik elfogadása",
        value: "arb",
      },
      {
        label:
          "privileges.AbsenceRequest.denyMyTeam|Hozzámrendelt visszautasítása",
        value: "arr",
      },
      {
        label: "privileges.AbsenceRequest.denyAny|Bármelyik visszautasítása",
        value: "ars",
      },
      { label: "privileges.AbsenceRequest.admin|Admin", value: "arz" },
    ],
  },
  SPECIAL_DAY: {
    LABEL: "privileges.specialDays|Különleges napok",
    PRIVILEGES: [
      { label: "privileges.specialDays.create|Létrehozás", value: "asc" },
      { label: "privileges.specialDays.edit|Szerkesztés", value: "ase" },
      { label: "privileges.specialDays.get|Lekérés", value: "asg" },
      { label: "privileges.specialDays.delete|Törlés", value: "asd" },
      { label: "privileges.specialDays.list|Listázás", value: "asl" },
      { label: "privileges.specialDays.admin|Admin", value: "asz" },
    ],
  },
  ABSENCE_TYPE: {
    LABEL: "privileges.absenceType|Szabadságtípus",
    PRIVILEGES: [
      { label: "privileges.absenceType.create|Létrehozás", value: "atc" },
      { label: "privileges.absenceType.edit|Szerkesztés", value: "ate" },
      { label: "privileges.absenceType.get|Lekérés", value: "atg" },
      { label: "privileges.absenceType.delete|Törlés", value: "atd" },
      { label: "privileges.absenceType.list|Listázás", value: "atl" },
      { label: "privileges.absenceType.admin|Admin", value: "atz" },
    ],
  },
  ABSENCE_MISC: {
    LABEL: "privileges.absenceMisc|Szabadságkezelés egyéb",
    PRIVILEGES: [
      {
        label: "privileges.absenceMisc.getMyExpected|Elvárt óraszám lekérés",
        value: "ame",
      },
      {
        label:
          "privileges.absenceMisc.getAnyExpected|Bárki elvárt óraszám lekérés",
        value: "amf",
      },
      {
        label: "privileges.absenceMisc.export|Szabadságok exportálása",
        value: "amx",
      },
      {
        label:
          "privileges.absenceMisc.modifyApproval|Szabadságkérelem bírálat szerkesztés",
        value: "amp",
      },
      {
        label:
          "privileges.absenceMisc.deleteApproval|Szabadságkérelem bírálat törlés",
        value: "amd",
      },
      { label: "privileges.absenceMisc.admin|Admin", value: "amz" },
    ],
  },
  USERS: {
    LABEL: "privileges.users|Felhasználókezelés",
    PRIVILEGES: [
      { label: "privileges.users.create|Létrehozás", value: "cuc" },
      { label: "privileges.users.edit|Szerkesztés", value: "cue" },
      { label: "privileges.users.get|Lekérés", value: "cug" },
      { label: "privileges.users.delete|Törlés", value: "cud" },
      { label: "privileges.users.list|Listázás", value: "cul" },
      { label: "privileges.users.admin|Admin", value: "cuz" },
    ],
  },
  TEAMS: {
    LABEL: "privileges.teams|Csapatkezelés",
    PRIVILEGES: [
      { label: "privileges.teams.create|Létrehozás", value: "ctc" },
      { label: "privileges.teams.edit|Szerkesztés", value: "cte" },
      { label: "privileges.teams.get|Lekérés", value: "ctg" },
      { label: "privileges.teams.delete|Törlés", value: "ctd" },
      { label: "privileges.teams.list|Listázás", value: "ctl" },
      {
        label: "privileges.teams.listAssigned|Hozzárendelt listázás",
        value: "cta",
      },
      { label: "privileges.teams.admin|Admin", value: "ctz" },
    ],
  },
  COMPETENCIES: {
    LABEL: "privileges.competencies|Kompetenciák",
    PRIVILEGES: [
      { label: "privileges.competencies.create|Létrehozás", value: "aic" },
      { label: "privileges.competencies.edit|Szerkesztés", value: "aie" },
      { label: "privileges.competencies.get|Lekérés", value: "aig" },
      { label: "privileges.competencies.delete|Törlés", value: "aid" },
      { label: "privileges.competencies.list|Listázás", value: "ail" },
      { label: "privileges.competencies.admin|Admin", value: "aiz" },
    ],
  },
  WORKSCHEDULE: {
    LABEL: "privileges.workschedule|Munkarend",
    PRIVILEGES: [
      { label: "privileges.workschedule.create|Létrehozás", value: "awc" },
      {
        label: "privileges.workschedule.createAny|Létrehozás bárkinek",
        value: "awb",
      },
      { label: "privileges.workschedule.edit|Szerkesztés", value: "awe" },
      { label: "privileges.workschedule.get|Lekérés", value: "awg" },
      {
        label: "privileges.workschedule.getAny|Bármelyik lekérése",
        value: "awh",
      },
      { label: "privileges.workschedule.delete|Törlés", value: "awd" },
      { label: "privileges.workschedule.list|Listázás", value: "awl" },
      { label: "privileges.workschedule.approve|Bírálat", value: "awa" },
      { label: "privileges.workschedule.admin|Admin", value: "awz" },
    ],
  },
  USER_MISC: {
    LABEL: "privileges.userMisc|Felhasználó egyéb",
    PRIVILEGES: [
      {
        label: "privileges.userMisc.createWorkplace|Munkahely létrehozás",
        value: "asl",
      },
      {
        label: "privileges.userMisc.editWorkplace|Munkahely szerkesztés",
        value: "asz",
      },
      {
        label: "privileges.userMisc.getWorkplace|Munkahely lekérés",
        value: "atc",
      },
      {
        label: "privileges.userMisc.deleteWorkplace|Munkahely törlés",
        value: "ate",
      },
      {
        label: "privileges.userMisc.listWorkplace|Munkahely listázás",
        value: "atg",
      },
      {
        label: "privileges.userMisc.listColleagues|Kollégák listázás",
        value: "atd",
      },
      { label: "privileges.userMisc.admin|Admin", value: "atl" },
    ],
  },
  DOCUMENT: {
    LABEL: "privileges.document|Fájlkezelés",
    PRIVILEGES: [
      { label: "privileges.document.create|Létrehozás", value: "drwdoccr" },
      { label: "privileges.document.get|Lekérés", value: "drwdocge" },
      { label: "privileges.document.update|Felülírás", value: "drwdocup" },
      { label: "privileges.document.delete|Törlés", value: "drwdocde" },
      { label: "privileges.document.list|Listázás", value: "drwdocli" },
      { label: "privileges.document.download|Letöltés", value: "drwdocdl" },
      { label: "privileges.document.preview|Előnézet", value: "drwdocpv" },
      { label: "privileges.document.admin|Admin", value: "drwdocad" },
    ],
  },
  TIMESHEET: {
    LABEL: "privileges.timesheets|Timesheetek",
    PRIVILEGES: [
      { label: "privileges.timesheets.get|Lekérés", value: "esg" },
      {
        label: "privileges.timesheets.getMyTeam|Hozzárendelt lekérés",
        value: "esh",
      },
      { label: "privileges.timesheets.getAny|Bármelyik lekérés", value: "esi" },
      { label: "privileges.timesheets.createForAny|Rögzítés", value: "esl" },
      { label: "privileges.timesheets.export|Export", value: "ese" },
      {
        label: "privileges.timesheets.exportMyTeam|Hozzárendelt export",
        value: "esd",
      },
      {
        label: "privileges.timesheets.exportAny|Bármelyik export",
        value: "esc",
      },
      { label: "privileges.timesheets.admin|Admin", value: "esz" },
    ],
  },
  TIER: {
    LABEL: "privileges.tier|Árszintek",
    PRIVILEGES: [
      { label: "privileges.tier.create|Létrehozás", value: "eic" },
      { label: "privileges.tier.edit|Szerkesztés", value: "eie" },
      { label: "privileges.tier.get|Lekérés", value: "eig" },
      { label: "privileges.tier.delete|Törlés", value: "eid" },
      { label: "privileges.tier.list|Listázás", value: "eil" },
      { label: "privileges.tier.listAny|Bármelyik listázása", value: "eim" },
      { label: "privileges.tier.admin|Admin", value: "eiz" },
    ],
  },
  PROJECT: {
    LABEL: "privileges.projects|Projektek",
    PRIVILEGES: [
      { label: "privileges.projects.create|Létrehozás", value: "epc" },
      {
        label: "privileges.projects.editAny|Bármelyik szerkesztés",
        value: "epe",
      },
      {
        label: "privileges.projects.editMine|Hozzárendelt szerkesztés",
        value: "epf",
      },
      { label: "privileges.projects.getAny|Bármelyik lekérés", value: "epg" },
      {
        label: "privileges.projects.getMine|Hozzárendelt lekérés",
        value: "eph",
      },
      { label: "privileges.projects.delete|Törlés", value: "epd" },
      { label: "privileges.projects.listAny|Bármelyik listázás", value: "epl" },
      {
        label: "privileges.projects.listMine|Hozzárendelt listázás",
        value: "epm",
      },
      { label: "privileges.projects.close|Lezárás", value: "epa" },
      { label: "privileges.projects.highlight|Kiemelés", value: "eps" },
      { label: "privileges.projects.admin|Admin", value: "epz" },
    ],
  },
  TIG: {
    LABEL: "privileges.tigs|Mérföldkövek",
    PRIVILEGES: [
      { label: "privileges.tigs.create|Létrehozás", value: "etc" },
      { label: "privileges.tigs.edit|Szerkesztés", value: "ete" },
      { label: "privileges.tigs.get|Lekérdezés", value: "etg" },
      { label: "privileges.tigs.detele|Törlés", value: "etd" },
      { label: "privileges.tigs.list|Listázás", value: "etl" },
      { label: "privileges.tigs.admin|Admin", value: "etz" },
    ],
  },
  PROJECT_EXTRA: {
    LABEL: "privileges.projectExtra|Projekt extrák",
    PRIVILEGES: [
      { label: "privileges.projectExtra.create|Létrehozás", value: "ejc" },
      { label: "privileges.projectExtra.edit|Szerkesztés", value: "eje" },
      { label: "privileges.projectExtra.get|Lekérés", value: "ejg" },
      { label: "privileges.projectExtra.delete|Törlés", value: "ejd" },
      { label: "privileges.projectExtra.list|Listázás", value: "ejl" },
      {
        label: "privileges.projectExtra.createInvoice|Számla létrehozás",
        value: "eji",
      },
      {
        label: "privileges.projectExtra.deleteInvoice|Számla törlés",
        value: "ejj",
      },
      {
        label: "privileges.projectExtra.approveInvoice|Számla bírálat",
        value: "ejk",
      },
      { label: "privileges.projectExtra.admin|Admin", value: "ejz" },
    ],
  },
  PROJECT_DIMENSIONS: {
    LABEL: "privileges.projectDimensions|Projekt dimenziók",
    PRIVILEGES: [
      { label: "privileges.projectDimensions.create|Létrehozás", value: "eqc" },
      { label: "privileges.projectDimensions.edit|Szerkesztés", value: "eqe" },
      { label: "privileges.projectDimensions.get|Lekérés", value: "eqg" },
      { label: "privileges.projectDimensions.delete|Törlés", value: "eqd" },
      { label: "privileges.projectDimensions.list|Listázás", value: "eql" },
      { label: "privileges.projectDimensions.admin|Admin", value: "eqz" },
    ],
  },
  PROJECT_BASE_DATA: {
    LABEL: "privileges.projectBaseData|Projekt törzsadatok",
    PRIVILEGES: [
      { label: "privileges.projectBaseData.create|Létrehozás", value: "ebc" },
      { label: "privileges.projectBaseData.edit|Szerkesztés", value: "ebe" },
      { label: "privileges.projectBaseData.get|Lekérés", value: "ebg" },
      { label: "privileges.projectBaseData.delete|Törlés", value: "ebd" },
      { label: "privileges.projectBaseData.list|Listázás", value: "ebl" },
      { label: "privileges.projectBaseData.admin|Admin", value: "ebz" },
    ],
  },
  RISK_MANAGEMENT: {
    LABEL: "privileges.riskManagement|Kockázatmenedzsment",
    PRIVILEGES: [
      { label: "privileges.riskManagement.create|Létrehozás", value: "erc" },
      { label: "privileges.riskManagement.edit|Szerkesztés", value: "ere" },
      {
        label: "privileges.riskManagement.getAny|Bármelyik lekérés",
        value: "erg",
      },
      {
        label: "privileges.riskManagement.getMine|Hozzárendelt lekérés",
        value: "erh",
      },
      { label: "privileges.riskManagement.delete|Törlés", value: "erd" },
      {
        label: "privileges.riskManagement.listAny|Bármelyik listázás",
        value: "erl",
      },
      {
        label: "privileges.riskManagement.listMine|Hozzárendelt listázás",
        value: "erm",
      },
      { label: "privileges.riskManagement.admin|Admin", value: "erz" },
    ],
  },
  FORECAST: {
    LABEL: "privileges.forecast|Forecast",
    PRIVILEGES: [
      { label: "privileges.forecast.year.list|Évek listázása", value: "eyl" },
      {
        label: "privileges.forecast.year.closePlanning|Tervezés lezárása",
        value: "eyc",
      },
      {
        label: "privileges.forecast.year.openPlanning|Tervezés újranyitása",
        value: "eyo",
      },
      {
        label: "privileges.forecast.year.closeQuarter|Negyedév lezárása",
        value: "eyn",
      },
      {
        label: "privileges.forecast.year.openQuarter|Negyedév újranyitása",
        value: "eyq",
      },
      {
        label: "privileges.forecast.segment.list|Szegmensek listázása",
        value: "eel",
      },
      {
        label: "privileges.forecast.segment.create|Szegmens létrehozása",
        value: "eec",
      },
      {
        label: "privileges.forecast.segment.get|Szegmens lekérése",
        value: "eeg",
      },
      {
        label: "privileges.forecast.segment.edit|Szegmens szerkesztése",
        value: "eee",
      },
      {
        label: "privileges.forecast.segment.delete|Szegmens törlése",
        value: "eed",
      },
      {
        label: "privileges.forecast.plan.listAny|Bármelyik tervadat listázása",
        value: "ela",
      },
      {
        label:
          "privileges.forecast.plan.listOwn|Hozzárendelt tervadat listázása",
        value: "elo",
      },
      {
        label: "privileges.forecast.plan.getAny|Bármelyik tervadat lekérése",
        value: "elg",
      },
      {
        label: "privileges.forecast.plan.getOwn|Hozzárendelt tervadat lekérése",
        value: "ell",
      },
      {
        label: "privileges.forecast.plan.create|Tervadat létrehozása",
        value: "elc",
      },
      {
        label:
          "privileges.forecast.plan.editAny|Bármelyik tervadat szerkesztés",
        value: "ele",
      },
      {
        label:
          "privileges.forecast.plan.editOwn|Hozzárendelt tervadat szerkesztése",
        value: "els",
      },
      {
        label:
          "privileges.forecast.plan.deleteAny|Hozzárendelt tervadat törlése",
        value: "elt",
      },
      {
        label:
          "privileges.forecast.plan.deleteOwn|Hozzárendelt tervadat törlése",
        value: "eld",
      },
      {
        label: "privileges.forecast.listAny|Bármelyik tényadat listázása",
        value: "efa",
      },
      {
        label:
          "privileges.forecast.fact.listOwn|Hozzárendelt tényadat listázása",
        value: "efo",
      },
      {
        label: "privileges.forecast.fact.getAny|Bármelyik tényadat lekérése",
        value: "efg",
      },
      {
        label: "privileges.forecast.fact.getOwn|Hozzárendelt tényadat lekérése",
        value: "efl",
      },
      {
        label: "privileges.forecast.fact.create|Tényadat létrehozása",
        value: "efc",
      },
      {
        label:
          "privileges.forecast.fact.editAny|Bármelyik tényadat szerkesztés",
        value: "efe",
      },
      {
        label:
          "privileges.forecast.fact.editOwn|Hozzárendelt tényadat szerkesztése",
        value: "efs",
      },
      {
        label:
          "privileges.forecast.fact.deleteAny|Hozzárendelt tényadat törlése",
        value: "eft",
      },
      {
        label:
          "privileges.forecast.fact.deleteOwn|Hozzárendelt tényadat törlése",
        value: "efd",
      },
      {
        label:
          "privileges.forecast.chanceCategory.list|Esélykategória listázása",
        value: "ehl",
      },
      {
        label:
          "privileges.forecast.chanceCategory.create|Esélykategória létrehozása",
        value: "ehc",
      },
      {
        label: "privileges.forecast.chanceCategory.get|Esélykategória lekérése",
        value: "ehg",
      },
      {
        label:
          "privileges.forecast.chanceCategory.edit|Esélykategória szerkesztése",
        value: "ehe",
      },
      {
        label:
          "privileges.forecast.chanceCategory.delete|Esélykategória törlése",
        value: "ehd",
      },
      {
        label: "privileges.forecast.scenario.list|Scenario listázása",
        value: "eal",
      },
      {
        label: "privileges.forecast.scenario.create|Scenario létrehozása",
        value: "eac",
      },
      {
        label: "privileges.forecast.scenario.get|Scenario lekérése",
        value: "eag",
      },
      {
        label: "privileges.forecast.scenario.edit|Scenario szerkesztése",
        value: "eae",
      },
      {
        label: "privileges.forecast.scenario.delete|Scenario törlése",
        value: "ead",
      },
      { label: "privileges.forecast.misc.dashboard|Dashboard", value: "end" },
      { label: "privileges.forecast.misc.admin|Admin", value: "enz" },
    ],
  },
  ENTERPRISE_MISC: {
    LABEL: "privileges.enterpriceMisc|Enterprise misc",
    PRIVILEGES: [
      {
        label: "privileges.enterpriceMisc.getSummary|Összefoglalás lekérés",
        value: "emo",
      },
      {
        label: "privileges.enterpriceMisc.getStatistics|Statisztikák lekérés",
        value: "ems",
      },
      {
        label: "privileges.enterpriceMisc.getSynopsys|Szinopszis lekérés",
        value: "emy",
      },
      { label: "privileges.enterpriceMisc.admin|Admin", value: "emz" },
    ],
  },
  ENTERPRISE_REPORT: {
    LABEL: "privileges.enterpriseReport|Enterprise riportok",
    PRIVILEGES: [
      {
        label: "privileges.enterpriseReport.getMoneyless|Pénzmentes lekérdezés",
        value: "edg",
      },
      { label: "privileges.enterpriseReport.admin|Admin", value: "erz" },
    ],
  },
  ADMIN: {
    LABEL: "privileges.companyConfig|Cégszintű konfiguráció",
    PRIVILEGES: [
      { label: "privileges.companyConfig.admin|Admin", value: "tcz" },
    ],
  },
  ROLES: {
    LABEL: "privileges.roles|Szerepkörök",
    PRIVILEGES: [
      { label: "privileges.roles.create|Létrehozás", value: "brc" },
      { label: "privileges.roles.edit|Szerkesztés", value: "bre" },
      { label: "privileges.roles.get|Lekérés", value: "brg" },
      { label: "privileges.roles.delete|Törlés", value: "brd" },
      { label: "privileges.roles.list|Listázás", value: "brl" },
      { label: "privileges.roles.admin|Admin", value: "brz" },
    ],
  },
};

export const ONBOARDING_PAGES = {
  START: "START",
  EXTERNAL_ISSUE_TRACKER_SELECT: "EXTERNAL_ISSUE_TRACKER_SELECT",
  EXTERNAL_ISSUE_TRACKER_SYNC_START: "EXTERNAL_ISSUE_TRACKER_SYNC_START",
  EXTERNAL_ISSUE_TRACKER_SYNC_IN_PROGRESS:
    "EXTERNAL_ISSUE_TRACKER_SYNC_IN_PROGRESS",
  EXTERNAL_ISSUE_TRACKER_SYNC_SUCCESS: "EXTERNAL_ISSUE_TRACKER_SYNC_SUCCESS",
  COMPANY_PROFILE: "COMPANY_PROFILE",
  SETUP_ROLES: "SETUP_ROLES",
  SETUP_USER_PARAMETERS: "SETUP_USER_PARAMETERS",
  SETUP_PRICE_LEVEL: "SETUP_PRICE_LEVEL",
  SETUP_USERS: "SETUP_USERS",
  SETUP_CLIENTS: "SETUP_CLIENTS",
  SETUP_PROJECTS: "SETUP_PROJECTS",
  EDIT_PROJECT: "EDIT_PROJECT",
  FINISH: "FINISH",
};

export const romanMatrix = [
  [1000, "M"],
  [900, "CM"],
  [500, "D"],
  [400, "CD"],
  [100, "C"],
  [90, "XC"],
  [50, "L"],
  [40, "XL"],
  [10, "X"],
  [9, "IX"],
  [5, "V"],
  [4, "IV"],
  [1, "I"],
];

export const UI_THEMES = {
  SYSTEM: "SYSTEM",
  DARK: "DARK",
  LIGHT: "LIGHT",
};

export const CURRENCY_TIERS = {
  LEVEL_ZERO: 0,
  LEVEL_ONE: 1,
  LEVEL_TWO: 2,
};

export const FORECAST_DASHBOARD_TYPE = {
  PLAN: "PLAN",
  FACT: "FACT",
  FORECASTED: "FORECASTED",
  DIFF: "DIFF",
};

export const FORECAST_COMPANY_DASHBOARD_TYPE = {
  ENUM: {
    PLAN_V_FACT: "PLAN_V_FACT",
    FORECASTED: "FORECASTED",
    DIFF: "DIFF",
  },
  LABEL: {
    PLAN_V_FACT: "Tervezett vs. Tényleges",
    FORECASTED: "Előrejelzett",
    DIFF: "Különbség",
  },
};

export const ISSUE_STATUS = {
  TODO: "TODO",
  IN_PROGRESS: "IN_PROGRESS",
  DONE: "DONE",
};

export const ISSUE_TRACKING_SYSTEM = {
  SELF: {
    enum: "SELF",
    label: "issueTrackingSystem.self|Forecastify",
    class: "SelftrackingIssueTargetingRequest",
  },
  JIRA: {
    enum: "JIRA",
    label: "issueTrackingSystem.jira|Jira",
    generateUrl: () =>
      "https://id.atlassian.com/manage-profile/security/api-tokens",
    image: "@/assets/images/onboarding/jira_api_token.png",
    class: "JiraIssueTargetingRequest",
    issuePageUrl: (key) => `/browse/${key}`,
  },
  YOUTRACK: {
    enum: "YOUTRACK",
    label: "issueTrackingSystem.youTrack|YouTrack",
    generateUrl: () =>
      "https://www.jetbrains.com/help/youtrack/server/manage-authentication-tokens.html",
    image: "@/assets/images/onboarding/youtrack_api_token.png",
    class: "YouTrackIssueTargetingRequest",
    issuePageUrl: (key) => `/issue/${key}`,
  },
};

export const API_KEY_TYPES = {
  ISSUE_TRACKING: {
    JIRA: "Jira",
    YOUTRACK: "YouTrack",
  },
  GENERATE: {
    FORECASTIFY: "Forecastify",
  },
  OTHER: {
    GITLAB: "GitLab",
  },
};

export const PROJECT_TYPES = {
  BASIC: "BASIC",
  OUTSOURCE: "OUTSOURCE",
  SUPPORT: "SUPPORT",
};

export const SUPPORT_ACTIVITY_STATUS = {
  ONGOING: "supportActivity.status.ongoing|Folyamatban",
  DONE: "supportActivity.status.done|Kész",
};

export const TASK_TYPE = {
  DEV: "taskType.dev|Fejlesztés",
  BUG: "taskType.bug|Hibajavítás",
  GENERAL: "taskType.general|Általános",
  OTHER: "taskType.other|Egyéb",
};

export const BILLABLE_TYPE = {
  FIX: "billableType.fix|Fix áras",
  TM: "billableType.tm|T&M elszámolás",
};

export const RALLOC_PRIORITY = {
  LOW: {
    order: 1,
    value: "LOW",
    token: "ralloc.priority.low|Bench Foglalás",
    color: "is-info",
    icon: "bullseye",
  },
  MEDIUM: {
    order: 2,
    value: "MEDIUM",
    token: "ralloc.priority.medium|Normál foglalás",
    color: "is-success",
    icon: "",
  },
  HIGH: {
    order: 3,
    value: "HIGH",
    token: "ralloc.priority.high|Kritikus Foglalás",
    color: "is-danger",
    icon: "lock",
  },
};

export const WORK_SCHEDULE_INDEXES = [
  { hours: "mondayHours", site: "mondaySite" },
  { hours: "tuesdayHours", site: "tuesdaySite" },
  { hours: "wednesdayHours", site: "wednesdaySite" },
  { hours: "thursdayHours", site: "thursdaySite" },
  { hours: "fridayHours", site: "fridaySite" },
  { hours: "saturdayHours", site: "saturdaySite" },
  { hours: "sundayHours", site: "sundaySite" },
];

export const INVOICE_STATUS = {
  DUE: {
    enum: "DUE",
    token: "invoice.status.due|Fizetendő",
    type: "is-info",
  },
  PAID: {
    enum: "PAID",
    token: "invoice.status.paid|Fizetett",
    type: "is-success",
  },
  CANCELLED: {
    enum: "CANCELLED",
    token: "invoice.status.cancelled|Visszavonva",
    type: "is-warning",
  },
};

export const UPLOAD_ACCEPT = {
  IMAGE: "image/*",
  SPREADSHEET:
    ".xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .csv",
};

export const MONEY_COLUMNS = {
  expenses: [
    {
      field: "identifier",
      label: "projectpage.expenseTable.identifier|Megnevezés",
    },
    {
      field: "summary",
      label: "projectpage.expenseTable.summary|Megjegyzés",
      notInTable: true,
    },
    {
      field: "cost",
      label: "projectpage.expenseTable.cost|Összeg",
    },
    {
      field: "date",
      label: "projectpage.expenseTable.date|Dátum",
    },
  ],
  overrides: [
    {
      field: "displayName",
      label: "projectpage.overrideTable.emplyeeName|Dolgozó",
      notOnForm: true,
    },
    // {
    //   field: "userId",
    //   label: "projectpage.overrideTable.userId|Azonosító",
    // },
    {
      field: "cost",
      label: "projectpage.overrideTable.cost|Összeg",
    },
  ],
  invoices: [
    {
      field: "number",
      label: "projectpage.invoice.invoiceNumero|Számlaszám",
    },
    {
      field: "status",
      label: "projectpage.invoice.status|Státusz",
      notOnForm: true,
    },
    {
      field: "tigIdentifiers",
      label: "projectpage.invoices.table.tig|Mérföldkő",
      notInTable: true,
    },
    {
      field: "summary",
      label: "projectpage.invoice.summary|Megjegyzés",
      notInTable: true,
    },
    {
      field: "cost",
      label: "projectpage.invoice.cost|Összeg",
    },
    {
      field: "date",
      label: "projectpage.invoice.date|Kelt",
    },
  ],
};

export const AFFILIATION_TYPE = {
  INTERNAL: {
    enum: "INTERNAL",
    label: "resource.employeeSelect.affiliation.internal|Belsős",
  },
  EXTERNAL: {
    enum: "EXTERNAL",
    label: "resource.employeeSelect.affiliation.external|Külsős",
  },
};

export const ISSUE_TRACKING_CONST = {
  PRIMARY_ISSUE_TRACKING_CONFIG_KEY: "DEFAULT",
  ISSUE_TRACKING_IDENTIFIER_FIELD: "identifier",
};
