<template>
  <div>
    <div class="is-flex is-justify-content-center header-container">
      <div class="usp-element">
        <b-icon class="mr-2" icon="check-double" icon-pack="fa"></b-icon>
        <span
          ><label>{{ $tf("signIn.usp.first|Manage Resources") }}</label></span
        >
      </div>
      <div class="usp-element">
        <b-icon class="mr-2" icon="check-double" icon-pack="fa"></b-icon>
        <span
          ><label>{{
            $tf("signIn.usp.second|Professional Services Automation")
          }}</label></span
        >
      </div>
      <div class="usp-element">
        <b-icon class="mr-2" icon="check-double" icon-pack="fa"></b-icon>
        <span
          ><label>{{
            $tf("signIn.usp.third|Assess Profitability")
          }}</label></span
        >
      </div>
    </div>

    <div
      v-if="tenantIdFromPath"
      class="is-flex is-justify-content-center sign-in-container"
    >
      <section class="section">
        <!--        <div id="google-sign-in">-->
        <!--          <div id="portal-google-signin"></div>-->
        <!--        </div>-->

        <div class="classic-sign-in">
          <img :src="logo" alt="Forecastify logo" class="erp-logo" />

          <form v-if="!pwResetSubmitted" @submit.prevent="sendPasswordReset">
            <b-field
              :label="
                $tf('forgotPassword.input.label|Felhasználónév vagy email')
              "
              :message="
                error
                  ? $tf(
                      'forgotPassword.input.error|Hiányos felhasználónév vagy email!'
                    )
                  : ''
              "
              :type="error ? 'is-danger' : ''"
              class="mb-5"
              expanded
              label-position="on-border"
            >
              <b-input
                v-model="user"
                :placeholder="
                  $tf(
                    'forgotPassword.input.placeholder|Felhasználónév vagy email'
                  )
                "
                autocomplete="username"
                icon="user"
                type="text"
              />
            </b-field>

            <div class="columns">
              <div class="column">
                <b-field>
                  <b-button
                    :disabled="isLoading"
                    :value="
                      $tf(
                        'forgotPassword.button.backToLogin|Vissza a bejelentkezéshez'
                      )
                    "
                    expanded
                    tag="input"
                    type="is-light"
                    @click="$router.push('/login')"
                  >
                  </b-button>
                </b-field>
              </div>
              <div class="column">
                <b-field>
                  <b-button
                    :disabled="isLoading"
                    :value="
                      $tf('forgotPassword.button.submit|Jelszó helyreállítása')
                    "
                    expanded
                    native-type="submit"
                    tag="input"
                    type="is-info"
                    @click="sendPasswordReset"
                  />
                </b-field>
              </div>
            </div>
          </form>
          <div v-else class="password-submitted-message">
            <div class="has-text-success-dark is-size-4 pb-4 has-text-centered">
              {{
                $tf(
                  "forgotPassword.afterSubmission.message|Amennyiben a megadott fiók létezik, elküldtük a bejelentkezéshez szükséges információkat az e-mail címedre."
                )
              }}
            </div>

            <b-button
              :disabled="isLoading"
              :value="
                $tf('forgotPassword.button.submit|Vissza a bejelentkezéshez')
              "
              expanded
              tag="input"
              type="is-light"
              @click="$router.push('/login')"
            >
            </b-button>
          </div>
        </div>
      </section>
    </div>

    <div v-else class="is-flex is-justify-content-center sign-in-container">
      <div
        class="classic-sign-in has-text-centered has-font-weight-700"
        style="width: 300px"
      >
        {{
          $tf(
            "signIn.tenant.error|Nem sikerült beazonosítani a keresett portált. Kérjük ellenőrizd hogy megfelelő címet írtál-e be!"
          )
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentTheme, tenantIdFromPath } from "@/utils/util";
import { UI_THEMES } from "@/utils/const";

export default {
  name: "ForgotPassword",
  data() {
    return {
      user: "",
      error: false,
      isLoading: false,
      pwResetSubmitted: false,
    };
  },
  computed: {
    tenantIdFromPath,
    logo() {
      if (getCurrentTheme() === UI_THEMES.LIGHT) {
        return process.env.VUE_APP_LOGIN_LOGO;
      } else {
        return process.env.VUE_APP_LOGIN_LOGO_DARK;
      }
    },
  },
  methods: {
    async sendPasswordReset() {
      this.error = this.user.trim() === "";
      if (!this.error) {
        this.isLoading = true;
        await this.$store.dispatch("session/resetPassword", {
          user: this.user,
          tenant: this.tenantIdFromPath,
        });
        this.pwResetSubmitted = true;
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.password-submitted-message {
  width: 75%;
  margin: auto;
}

.sign-in-container {
  width: 100%;
}

.header-container {
  margin-top: 1rem;
  text-shadow: 1px 1px 2px black;
  color: white;

  :deep(svg) {
    filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.4));
  }
}

.classic-sign-in {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: $white;
  border-radius: 16px;
  padding: 32px;
  min-width: 460px;
  margin-top: 3rem;
  box-shadow: rgb(38, 57, 77) 0 20px 30px -10px;

  .erp-logo {
    align-self: center;
    max-height: 8rem;
    max-width: 20rem;
    margin-bottom: 3rem;
    margin-top: 0.75rem;
  }
}

.usp-element {
  display: flex;
  align-items: center;
  filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.4));
  padding: 10px;
}
</style>
