<template>
  <div class="scrollable-modal-card">
    <div class="container">
      <header class="modal-card-head">
        <h1 class="title">
          {{ $tf("apiKeyDialog.title|API kulcs létrehozva") }}
        </h1>
      </header>
      <section class="modal-card-body">
        <b-input
          ref="input"
          :value="value"
          icon-right="copy"
          @icon-right-click="copy"
        />
      </section>
      <footer class="modal-card-footer">
        <b-field class="ml-auto">
          <b-button class="mr-3" @click="close">
            {{ $tf("apiKeyDialog.close|Bezárás") }}
          </b-button>
          <b-field>
            <b-button type="is-success" @click="copy">
              {{ $tf("apiKeyDialog.confirm|Másolás") }}
            </b-button>
          </b-field>
        </b-field>
      </footer>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance } from "vue";

export default {
  name: "ApiKeyModal",
  setup: () => {
    const instance = getCurrentInstance();
    return {
      instance: instance,
    };
  },
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  mounted() {
    const input = this.$refs.input.$el.children.item(0);
    input.setAttribute("readonly", "readonly");
  },
  methods: {
    copy() {
      const input = this.$refs.input.$el.children.item(0);
      input.select();
      input.setSelectionRange(0, 99999);

      navigator.clipboard.writeText(this.value);

      this.$emit("");
    },
    close() {
      this.$emit("close");
      this.instance.emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.animation-content {
  .scrollable-modal-card {
    h3 {
      &:not(:first-child) {
        margin-top: 1rem;
      }

      margin-bottom: 1rem;
    }
  }
}
</style>
