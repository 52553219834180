<template>
  <div class="modal-card" style="width: auto">
    <header
      class="modal-card-head is-flex-direction-column is-align-items-start pb-0"
    >
      <div class="is-flex is-align-items-center has-gap-4">
        <div
          class="has-text-dark-blue"
          style="
            width: 32px;
            height: 32px;
            border-radius: 64px;
            background-color: #ebe9fe;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <b-icon icon="pencil" size="is-small" style="opacity: 0.6" />
        </div>
        <h3
          class="my-3 has-text-black has-font-weight-500"
          style="font-size: 24px"
        >
          Adatok manuális beállítása
        </h3>
      </div>
      <p class="font-size:12px;color:darkgrey;">
        A felhasználók adatait módosíthatod a későbbiekben is. <br />
        Ha egyszerre több felhasználóra alkamaznád a beállításokat, használd az
        Egységes beállítás funkciót.
      </p>
      <div class="my-3" style="max-width: 300px">
        <b-field label="Keresés a névben" label-position="on-border">
          <b-input v-model="searchCriterion" size="is-small" />
        </b-field>
      </div>
    </header>
    <section class="modal-card-body">
      <table class="user-edit-table">
        <tr>
          <th>Alkalmazott</th>
          <th class="required">Felhasználónév</th>
          <th class="required">E-mail cím</th>
          <th class="required">Szerepkör</th>
          <th>Munkakör</th>
          <th>Szenioritás</th>
          <th>Kompetencia</th>
          <th>Terület</th>
          <th class="required">Árszint</th>
          <th></th>
        </tr>
        <tr
          v-for="user in users.filter(({ name }) =>
            name
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase()
              .includes(
                searchCriterion
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .toLowerCase()
              )
          )"
          :key="user.accountId"
        >
          <td>
            <b-input
              v-model="user.name"
              :disabled="searchCriterion.length > 0"
              size="is-small"
            />
          </td>
          <td>
            <b-input v-model="user.username" size="is-small" />
          </td>
          <td>
            <b-input v-model="user.email" size="is-small" />
          </td>
          <td>
            <b-select v-model="user.roleId" expanded size="is-small">
              <option v-for="role in roles" :key="role.id" :value="role.id">
                {{ role.name }}
              </option>
            </b-select>
          </td>
          <td>
            <b-select v-model="user.title" expanded size="is-small">
              <option
                v-for="title in competencies.titles"
                :key="title"
                :value="title"
              >
                {{ title }}
              </option>
            </b-select>
          </td>
          <td>
            <b-select v-model="user.seniority" expanded size="is-small">
              <option
                v-for="seniority in competencies.seniorities"
                :key="seniority"
                :value="seniority"
              >
                {{ seniority }}
              </option>
            </b-select>
          </td>
          <td>
            <b-select v-model="user.competency" expanded size="is-small">
              <option
                v-for="competency in competencies.competencies"
                :key="competency"
                :value="competency"
              >
                {{ competency }}
              </option>
            </b-select>
          </td>
          <td>
            <b-select v-model="user.department" expanded size="is-small">
              <option
                v-for="department in competencies.department"
                :key="department"
                :value="department"
              >
                {{ department }}
              </option>
            </b-select>
          </td>
          <td>
            <b-select v-model="user.tierId" expanded size="is-small">
              <option
                v-for="priceLevel in priceLevels"
                :key="priceLevel.id"
                :value="priceLevel.id"
              >
                {{ priceLevel.name }}
              </option>
            </b-select>
          </td>
        </tr>
      </table>
    </section>
    <footer class="modal-card-foot user-edit-modal-footer">
      <b-button
        class="onboarding-button onboarding-button-transparent onboarding-button-small"
        label="Mégse"
        @click="instance.emit('close')"
      />
      <b-button
        class="onboarding-button onboarding-button-small"
        label="Mentés"
        type="is-primary"
        @click="saveAndClose()"
      />
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash";
import { getCurrentInstance } from "vue";

export default {
  name: "OnboardingSetupUsersEditModal",
  setup: () => {
    const instance = getCurrentInstance();
    return {
      instance: instance,
    };
  },
  props: {
    users: {
      type: Array,
    },
  },
  computed: {
    ...mapGetters({
      roles: "onboarding/getUsedRoles",
      competencies: "onboarding/getCompetencies",
      priceLevels: "onboarding/getTiers",
    }),
  },
  data() {
    return {
      searchCriterion: "",
    };
  },
  methods: {
    async saveAndClose() {
      const userCopy = cloneDeep(this.users);

      userCopy.forEach((user) => {
        if ((user.role && user.roleId !== user.role.id) || !user.role) {
          user.role = this.roles.find((r) => r.id === user.roleId);
        }
        if (
          (user.priceTier && user.tierId !== user.priceTier.id) ||
          !user.priceTier
        ) {
          user.priceTier = this.priceLevels.find((pl) => pl.id === user.tierId);
        }
        delete user.roleId;
        delete user.tierId;
      });

      await this.$store.dispatch("onboarding/updateUsers", userCopy);

      this.instance.emit("close");
    },
  },
};
</script>

<style scoped>
.user-edit-modal-footer {
  border-top: 1px solid #cad3de;
  justify-content: flex-end;
}

.user-edit-table {
  width: 100%;
}

.user-edit-table > tr > th,
.user-edit-table > tr > td {
  padding: 8px;
}
</style>
