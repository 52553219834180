<template>
  <div>
    <b-modal v-model="isModalOpen" aria-modal aria-role="dialog" has-modal-card>
      <div class="card p-4">
        <outsource-contract-form
          :employees="employees"
          :prefix="projectDetails.prefix"
          :project-identifier="projectDetails.identifier"
          v-on:contract-created="handleUpdate"
        />
      </div>
    </b-modal>
    <b-modal
      v-model="isEditModalOpen"
      aria-modal
      aria-role="dialog"
      has-modal-card
    >
      <div class="card p-4">
        <outsource-contract-edit
          :contract-data="editData"
          :prefix="projectDetails.prefix"
          :project-identifier="projectDetails.identifier"
          v-on:contract-edited="handleUpdate"
        />
      </div>
    </b-modal>
    <loading-skeleton v-if="loading" />
    <div v-else>
      <div>
        <div class="columns">
          <div class="column is-two-thirds">
            <external-issue-targeting-form
              ref="external-issue-targeting-form"
              v-model="externalIssueTargeting"
              :prefix="projectDetails.prefix"
              :project-identifier="projectDetails.identifier"
              :project-type="PROJECT_TYPES.OUTSOURCE"
              @loading="isTargetingLoading = $event"
            />
          </div>
          <div class="column is-one-third">
            <b-button
              :disabled="loading"
              :expanded="true"
              type="is-info"
              @click="saveTargeting"
              >{{
                $tf(
                  "outsourceContractTab.contracts.externalIssueTargeting.save|Mentés"
                )
              }}
            </b-button>
          </div>
        </div>
        <hr />
      </div>
      <div class="is-flex is-justify-content-space-between">
        <div>
          <h4 class="title">
            {{ $tf("outsourceContractTab.contracts.title|Aktív szerződések") }}
          </h4>
        </div>
        <div>
          <b-button
            rounded
            size="is-small"
            type="is-info"
            @click="isModalOpen = true"
          >
            {{
              $tf(
                "outsourceContractTab.outsourceContract.add|Szerződés hozzáadása"
              )
            }}
          </b-button>
        </div>
      </div>
      <b-table
        ref="expenseTable"
        :aria-current-label="$tf('aria.current.label|Aktuális lap')"
        :aria-next-label="$tf('aria.next.label|Következő lap')"
        :aria-page-label="$tf('aria.page.label|Lap')"
        :aria-previous-label="$tf('aria.previous.label|Előző lap')"
        :data="projectDetails.activeContracts"
        :per-page="10"
        :show-detail-icon="false"
        detail-key="identifier"
        detailed
      >
        <template #empty>
          <div class="has-text-centered">
            {{
              $tf(
                "outsourceContractTab.outsourceContract.table.empty|Nincsen szerződés"
              )
            }}
          </div>
        </template>
        <b-table-column
          v-slot="props"
          :label="
            $tf('outsourceContractTab.outsourceContract.table.role|Munkakör')
          "
          centered
          field="contractRole"
          sortable
        >
          {{ getContractRole(props.row.contractRole) }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          :label="$tf('outsourceContractTab.outsourceContract.table.name|Név')"
          centered
          field="userData.name"
          sortable
        >
          {{ props.row.userData.name }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          :label="
            $tf(
              'outsourceContractTab.outsourceContract.table.contractStart|Szerződés kezdete'
            )
          "
          centered
          field="contractStart"
          sortable
        >
          {{ props.row.contractStart }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          :label="
            $tf(
              'outsourceContractTab.outsourceContract.table.contractExpiry|Szerződés lejár'
            )
          "
          centered
          field="contractExpiry"
          sortable
        >
          {{ props.row.contractExpiry }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          :label="
            $tf(
              'outsourceContractTab.outsourceContract.table.hours|Havi óraszám'
            )
          "
          centered
          field="monthlyHours"
          sortable
        >
          {{ props.row.monthlyHours }} óra
        </b-table-column>
        <b-table-column
          v-slot="props"
          :label="
            $tf(
              'outsourceContractTab.outsourceContract.table.hourlyFee|Szerződött óradíj'
            )
          "
          centered
          field="hourlyFee"
          sortable
        >
          {{ moneyify(props.row.hourlyFee) }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          :label="$tf('outsourceContractTab.override.table.actions|Műveletek')"
          centered
          field="actions"
          width="80"
        >
          <b-tooltip
            :label="
              $tf(
                'outsourceContractTab.override.table.actions.edit|Szerkesztés'
              )
            "
            type="is-light"
          >
            <clickable-icon
              icon="edit"
              type="is-info"
              @click="openEditModal(props.row)"
            />
          </b-tooltip>
          <b-tooltip
            :label="
              $tf('outsourceContractTab.tigList.table.actions.delete|Törlés')
            "
            type="is-light"
          >
            <clickable-icon
              icon="trash"
              type="is-danger"
              @click="deleteContract(props.row.id)"
            />
          </b-tooltip>
        </b-table-column>
      </b-table>
    </div>
    <b-loading
      v-model="isTargetingLoading"
      :can-cancel="false"
      :is-full-page="false"
    />
  </div>
</template>

<script>
import { moneyify } from "@/utils/util";
import OutsourceContractForm from "@/components/enterprise/project/form/OutsourceContractForm.vue";
import LoadingSkeleton from "@/components/module/loading/LoadingSkeleton.vue";
import ClickableIcon from "@/components/module/icon/ClickableIcon.vue";
import OutsourceContractEdit from "@/components/enterprise/project/form/OutsourceContractEdit.vue";
import { PROJECT_TYPES } from "@/utils/const";
import { mapGetters } from "vuex";
import ExternalIssueTargetingForm from "@/components/enterprise/targeting/ExternalIssueTargetingForm.vue";

export default {
  name: "OutsourceContractTabItem",
  components: {
    ExternalIssueTargetingForm,
    OutsourceContractEdit,
    ClickableIcon,
    LoadingSkeleton,
    OutsourceContractForm,
  },
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      PROJECT_TYPES,
      isModalOpen: false,
      isEditModalOpen: false,
      loading: false,
      editData: null,
      externalIssueTargeting: this.projectDetails.externalIssueTargeting || {},
      isTargetingLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      employees: "employee/loggingEmployees",
      indicators: "user_indicator/indicators",
    }),
  },
  methods: {
    moneyify,
    async saveTargeting() {
      this.loading = true;
      await this.$store.dispatch("outsource_projects/saveTargeting", {
        projectId: this.projectDetails.id,
        targeting: {
          externalIssueTargeting: this.externalIssueTargeting,
          projectId: this.projectDetails.id,
          id: null,
        },
      });
      this.$buefy.toast.open({
        duration: 5000,
        message: this.$tf(
          "outsourceTabItem.targeting.success|Célzás módosítása sikeres, szinkronizáció szükséges!"
        ),
        position: "is-bottom",
        type: "is-success",
      });
      this.loading = false;
    },
    async handleUpdate() {
      this.isModalOpen = false;
      this.isEditModalOpen = false;
      await this.refreshData();
    },
    refreshData: async function () {
      this.loading = true;
      this.$emit("refresh");
      await this.$store.dispatch(
        "enterprise_core/fetchIssues",
        this.projectDetails.id
      );
      this.loading = false;
    },
    openEditModal(contract) {
      this.editData = contract;
      this.isEditModalOpen = true;
    },
    async deleteContract(id) {
      this.$buefy.dialog.confirm({
        title: this.$tf(
          "outsourceContractTab.contract.deleteModal.title|Szerződés törlése"
        ),
        message: this.$tf(
          "outsourceContractTab.contract.deleteModal.message|Biztosan törölni szeretnéd a szerződést?"
        ),
        confirmText: this.$tf(
          "outsourceContractTab.contract.deleteModal.confirm|Törlés"
        ),
        cancelText: this.$tf(
          "outsourceContractTab.contract.deleteModal.cancel|Mégse"
        ),
        trapFocus: true,
        type: "is-danger",
        onConfirm: async () => {
          this.loading = true;
          await this.$store.dispatch("outsource_projects/deleteContract", id);
          await this.refreshData();
          this.loading = false;
        },
      });
    },
    getContractRole(roleId) {
      return this.indicators.find((indicator) => indicator.id === roleId)?.name;
    },
  },
};
</script>

<style lang="scss" scoped></style>
