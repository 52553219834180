<template>
  <section class="section">
    <div class="container">
      <p class="heading">Adatok betöltése folyamatban...</p>
      <LoadingSkeleton />
    </div>
  </section>
</template>

<script>
import LoadingSkeleton from "./LoadingSkeleton";

export default {
  name: "PageLoading",
  components: { LoadingSkeleton },
};
</script>
