<template>
  <div :class="{ hasBorders }" class="main">
    <div
      class="mb-0 info-header is-flex is-justify-content-center is-align-items-center pl-2 pr-2"
    >
      <div>{{ header }}</div>
    </div>
    <div
      class="mt-0 info-text is-flex is-justify-content-center is-align-items-center"
    >
      <div class="is-flex is-align-items-center">
        <b-tag :type="type">
          {{ maskedText }}
        </b-tag>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectFormInfoBox",
  props: {
    header: {
      type: String,
      required: true,
    },
    text: {
      type: [Number, String],
      required: false,
    },
    suffix: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: "is-info",
    },
    mask: {
      type: Function,
      required: false,
    },
    hasBorders: {
      type: Boolean,
      required: false,
    },
    showWarningText: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    maskedText() {
      if (!this.mask) {
        if (this.text == null) {
          return "";
        }
        return this.text.toString().concat(this.suffix ? this.suffix : "");
      }
      let pattern = this.mask(this.text.toString())
        .toString()
        .replaceAll(",", "");
      let i = 0;
      const v = this.text?.toString();
      return pattern
        .replaceAll("/\\d/", (_) => (v ? v[i++] : undefined))
        .concat(this.suffix ? this.suffix : "");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.hasBorders {
  border: 1px solid $grey-lighter;
}

.main {
  border-radius: 8px;
  min-width: 122px;
}

.info-header {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  height: 34px;
  box-shadow: 0 5px 30px rgba(48, 52, 62, 0.1);
  border-radius: 8px 8px 0 0;
}

.info-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: $custom-dark-blue;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 5px 30px rgba(48, 52, 62, 0.1);
  height: 48px;
}

.is-info {
  background-color: $grey-lightest;
  color: $custom-dark-blue;
}

.is-dark {
  background-color: $custom-dark-blue;
  color: $white;
}

.is-light-blue {
  background-color: $custom-light-blue;
  color: $white;
}

.is-green {
  background-color: $green;
  color: $white;
}

.is-success {
  background-color: $turquoise;
  color: $white;
}

.is-warning {
  background-color: $yellow;
  color: $custom-dark-blue;
}

.is-danger {
  background-color: $red;
  color: $white;
}
</style>
