<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="is-flex is-justify-content-space-between" style="gap: 14px">
    <div class="is-flex-5">
      <vuelidated-form
        :validations="v$.values.permission"
        class="field is-expanded"
      >
        <template v-slot="{ errors, invalid }">
          <b-field
            :message="v$.values.permission.$error ? errors[0] : null"
            :type="{ 'is-danger': invalid }"
            label="Hozzáférés"
            label-position="on-border"
          >
            <b-select
              v-model="values.permission"
              :disabled="entry.disabled"
              expanded
              placeholder="Válassz hozzáférést"
              @update:modelValue="(select) => operation(select)"
            >
              <option
                v-for="option in permissions"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </option>
            </b-select>
          </b-field>
        </template>
      </vuelidated-form>
    </div>
    <div class="is-flex-4">
      <b-field
        label="Művelet"
        label-position="on-border"
        style="min-width: 10rem"
      >
        <b-input :value="status" disabled=""></b-input>
      </b-field>
    </div>
    <div class="is-flex-5">
      <vuelidated-form
        :validations="v$.values.deadline"
        class="field is-expanded"
      >
        <template v-slot="{ errors, invalid }">
          <b-field
            :message="v$.values.deadline.$error ? errors[0] : null"
            :type="{ 'is-danger': invalid }"
            label="Határidő"
            label-position="on-border"
          >
            <f-datepicker
              v-model="values.deadline"
              expanded
              mobile-modal
              mobile-native
              placeholder="Válassz határidőt"
            ></f-datepicker>
          </b-field>
        </template>
      </vuelidated-form>
    </div>
    <div>
      <b-field>
        <b-button
          :disabled="entry.disabled"
          class="icon-button"
          icon-left="minus"
          type="is-danger"
          @click="$emit('minus', index)"
        />
      </b-field>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props*/

import { required } from "@vuelidate/validators";
import VuelidatedForm from "@/components/module/input/VuelidatedForm";
import { USER_PERMISSION_TYPE_LABEL } from "@/utils/const";
import { deepCopy } from "@/utils/util";
import FDatepicker from "@/components/module/input/FDatepicker.vue";
import useCustomVuelidate from "@/plugins/vuelidate";

export default {
  name: "PermitEntry",
  components: { FDatepicker, VuelidatedForm },
  setup: () => {
    const { v$, touch$ } = useCustomVuelidate();
    return { v$, touch$ };
  },
  props: {
    entry: {
      type: Object,
      required: true,
    },
    permissions: {
      type: Array,
      required: true,
    },
    permits: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.values.permission = this.entry.permission;
    this.values.status = this.entry.status;
    this.values.deadline = new Date(this.entry.deadline);
    this.values.disabled = this.entry.disabled;
  },
  data() {
    return {
      values: {
        permission: null,
        status: null,
        deadline: null,
        disabled: false,
      },
      USER_PERMISSION_TYPE_LABEL,
    };
  },
  validations: {
    values: {
      permission: {
        required,
      },
      deadline: {
        required,
      },
    },
  },
  computed: {
    status() {
      return this.$tf(USER_PERMISSION_TYPE_LABEL[this.values.status])
        ? this.$tf(USER_PERMISSION_TYPE_LABEL[this.values.status])
        : "Válassz hozzáférést...";
    },
  },
  methods: {
    async isInvalid() {
      await this.touch$();
      return this.v$.$invalid;
    },
    getValues() {
      return deepCopy(this.values);
    },

    operation(select) {
      this.values.permission = select;
      let status = this.permits
        ?.filter((permit) => permit.permission?.id === this.values.permission)
        ?.shift()?.status;
      switch (status) {
        case "HAVE":
        case "SHOULD_NOT_HAVE": {
          this.values.status = "SHOULD_NOT_HAVE";
          break;
        }
        case "NOT_HAVE":
        case "SHOULD_HAVE":
        default:
          this.values.status = "SHOULD_HAVE";
      }
    },
  },
};
</script>
