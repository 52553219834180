<template>
  <section class="section">
    <div class="back-button-container">
      <b-button icon-left="chevron-left" label="Szobák" @click="cancel" />
    </div>

    <div class="container">
      <h1 class="title">
        {{
          originalRoomName
            ? `${originalRoomName} módosítása`
            : "Új szoba létrehozása"
        }}
      </h1>
      <h3 class="heading">Alapadatok</h3>

      <b-field grouped>
        <vuelidated-input
          v-model="room.identifier"
          :validation-rule="v$.room.identifier"
          class="is-long"
          label="Azonosító"
          label-position="on-border"
          placeholder="Azonosító"
        ></vuelidated-input>

        <vuelidated-input
          v-model="room.name"
          :validation-rule="v$.room.name"
          class="is-long"
          label="Terem neve"
          label-position="on-border"
          placeholder="Terem neve"
        ></vuelidated-input>
      </b-field>

      <b-field grouped>
        <vuelidated-input
          v-model="room.description"
          :validation-rule="v$.room.description"
          class="is-long"
          label="Terem leírása"
          label-position="on-border"
          placeholder="Terem leírása"
        ></vuelidated-input>

        <vuelidated-input
          v-model="siteIdentifier"
          :validation-rule="v$.room.name"
          class="is-long"
          disabled
          label="Telephely neve"
          label-position="on-border"
          placeholder="Telephely neve"
        ></vuelidated-input>
      </b-field>
    </div>

    <div class="save-button-container">
      <div class="buttons-wrapper">
        <b-button @click="cancel"> Mégse</b-button>
        <b-button icon-left="save" type="is-info" @click="saveRoom">
          Mentés
        </b-button>
      </div>
    </div>
  </section>
</template>

<script>
import VuelidatedInput from "../../../components/module/input/VuelidatedInput";
import { required } from "@vuelidate/validators";
import LoadingMixin from "@/mixins/LoadingMixin";
import useCustomVuelidate from "@/plugins/vuelidate";

export default {
  name: "RoomEdit",
  components: {
    VuelidatedInput,
  },
  mixins: [LoadingMixin],
  setup: () => useCustomVuelidate(),
  validations: {
    room: {
      name: {
        required,
      },
      identifier: {
        required,
      },
      description: {
        required,
      },
    },
  },
  data() {
    return {
      originalRoomName: undefined,
      siteIdentifier: "",
      room: {
        identifier: "",
        name: "",
        description: "",
        siteId: 0,
      },
      props: {
        site: {
          type: Object,
        },
      },
    };
  },
  async mounted() {
    await this.doStartLoading();

    let id = this.$route.params.id;
    let site = this.$route.params.site;
    if (id) {
      await this.$store.dispatch("census_seating/fetchRoom", id);
      this.room = this.$store.getters["census_seating/room"];
      this.originalRoomName = this.room.name;
    }
    if (site) {
      this.room.siteId = site.id;
      this.siteIdentifier = site.name;
    } else {
      await this.cancel();
    }
    await this.doFinishLoading();
  },
  methods: {
    async cancel(force) {
      await this.$store.dispatch("census_seating/fetchSites", force);
      await this.$router.push("/settings/seating");
    },
    async saveRoom() {
      this.touch$();
      if (!this.v$.$invalid) {
        await this.$store.dispatch("census_seating/saveRoom", this.room);
        await this.cancel(true);
      }
    },
  },
};
</script>
