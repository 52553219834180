<template>
  <div class="newForecastCard has-gap-5">
    <div class="is-flex is-justify-content-space-between">
      <h3 class="title">{{ historyTitle }}</h3>
      <b-field grouped>
        <b-field>
          <b-radio v-model="timeOrMoney" native-value="money">
            {{ $tf("projectPage.history.timeOrMoney.money|Költség") }}
          </b-radio>
          <b-radio v-model="timeOrMoney" native-value="time">
            {{ $tf("projectPage.history.timeOrMoney.time|Logolt idő") }}
          </b-radio>
        </b-field>
        <b-field class="ml-5">
          <b-radio v-model="seriesOrCumulative" native-value="series">
            {{ $tf("projectPage.history.seriesOrCumulative.series|Idősor") }}
          </b-radio>
          <b-radio v-model="seriesOrCumulative" native-value="cumulative">
            {{
              $tf("projectPage.history.seriesOrCumulative.cumulative|Kumulált")
            }}
          </b-radio>
        </b-field>
      </b-field>
    </div>
    <div>
      <Apexchart
        ref="chart"
        :options="chartOptions"
        :series="chartSeries"
        height="350"
        type="area"
      />
    </div>
  </div>
</template>

<script>
import Apexchart from "vue3-apexcharts";
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import {
  getCurrentTheme,
  isWeekend,
  secondToDayFormat,
  useMoneyify,
} from "@/utils/util";
import { UI_THEMES } from "@/utils/const";

export default {
  name: "ProjectHistory",
  components: { Apexchart },
  props: {
    projectId: {
      type: Number,
      required: true,
    },
    labelCount: {
      type: Number,
      default: 10,
    },
  },
  async mounted() {
    await this.init();
  },
  data() {
    return {
      moneyify: useMoneyify(),
      chartZooms: new Map(), // per chart. in case more charts get added
      // hiddenSeries: new Map(), // same here
      seriesOrCumulative: "series",
      timeOrMoney: "money",
      nonCumulativeCostSeries: [],
      nonCumulativeTimeSeries: [],
      cumulativeCostSeries: [],
      cumulativeTimeSeries: [],
      dates: [],
      baseChartOptions: {
        chart: {
          type: "area",
          zoom: {
            enabled: true,
            allowMouseWheelZoom: false,
          },
          stacked: true,
          height: 350,
          events: {
            zoomed: this.chartZoomed,
            // legendClick: this.legendClicked,
          },
        },
        stroke: {
          width: 2,
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0.85,
            stops: [0, 100],
          },
        },
        colors: [
          "#C570D0",
          "#FFC665",
          "#746DCC",
          "#FF75AD",
          "#FF9582",
          "#A3ABE2",
          "#24C196",
          "#00D6EA",
          "#66EDC2",
          "#447E7B",
          "#EEE8A9",
          "#B3CFCD",
        ],
        title: {
          align: "center",
        },
        dataLabels: {
          enabled: false,
        },
        theme: {
          mode: getCurrentTheme() === UI_THEMES.DARK ? "dark" : "light",
        },
        noData: {
          // TODO: this or hide the whole thing
          // https://apexcharts.com/docs/options/nodata/
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      history: "history/projectHistory",
      currentTheme: "uiConfigStore/getCurrentTheme",
    }),
    historyTitle() {
      if (this.isCumulative) {
        if (this.isTime) {
          return this.$tf(
            "projectPage.history.title.isCumuIsTime|Kumulatív logolt idő diagram"
          );
        }
        return this.$tf(
          "projectPage.history.title.isCumuIsMoney|Kumulatív költség diagram"
        );
      } else {
        if (this.isTime) {
          return this.$tf(
            "projectPage.history.title.isSeriesIsTime|Idősoros logolt idő diagram"
          );
        }
        return this.$tf(
          "projectPage.history.title.isSeriesIsMoney|Idősoros költség diagram"
        );
      }
    },
    isCumulative() {
      return this.seriesOrCumulative === "cumulative";
    },
    isTime() {
      return this.timeOrMoney === "time";
    },
    // unfortunatley this is a bunch of copy-paste.
    cumulativeCostChartOptions() {
      return this.makeChartOptions({
        title: {
          text: this.$tf(
            "projectPage.history.projectCostCumulativeTitle|Projekt költsége (kumulatív)"
          ),
        },
        yaxis: {
          labels: {
            formatter: (n) => this.moneyify(n),
          },
        },
      });
    },
    cumulativeTimeChartOptions() {
      return this.makeChartOptions({
        title: {
          text: this.$tf(
            "projectPage.history.projectTimeCumulativeTitle|Projekttel töltött idő (kumulatív)"
          ),
        },
        yaxis: {
          labels: {
            formatter: secondToDayFormat,
          },
        },
      });
    },
    nonCumulativeCostChartOptions() {
      return this.makeChartOptions({
        title: {
          text: this.$tf(
            "projectPage.history.projectCostNonCumulativeTitle|Projekt költsége"
          ),
        },
        yaxis: {
          labels: {
            formatter: (n) => this.moneyify(n),
          },
        },
      });
    },
    nonCumulativeTimeChartOptions() {
      return this.makeChartOptions({
        title: {
          text: this.$tf(
            "projectPage.history.projectTimeNonCumulativeTitle|Projekttel töltött idő"
          ),
        },
        yaxis: {
          labels: {
            formatter: secondToDayFormat,
          },
        },
      });
    },
    chartSeries() {
      if (this.isTime) {
        if (this.isCumulative) {
          return this.cumulativeTimeSeries;
        }
        return this.nonCumulativeTimeSeries;
      }
      if (this.isCumulative) {
        return this.cumulativeCostSeries;
      }
      return this.nonCumulativeCostSeries;
    },
    chartOptions() {
      if (this.isTime) {
        if (this.isCumulative) {
          return this.cumulativeTimeChartOptions;
        }
        return this.nonCumulativeTimeChartOptions;
      }
      if (this.isCumulative) {
        return this.cumulativeCostChartOptions;
      }
      return this.nonCumulativeCostChartOptions;
    },
  },
  methods: {
    makeChartOptions(options) {
      return _.merge(
        // Lodash merge mutates objects
        _.cloneDeep(this.baseChartOptions),
        options
      );
    },
    // legendClicked(chartContext, seriesIndex) {
    //   console.log(this.hiddenSeries);
    //   if (!this.hiddenSeries.has(chartContext)) {
    //     this.hiddenSeries.set(chartContext, new Set());
    //   }
    //   const hiddens = this.hiddenSeries.get(chartContext);
    //   if (!hiddens.delete(seriesIndex)) {
    //     hiddens.add(seriesIndex);
    //   }
    // },
    chartZoomed(chartContext, { xaxis }) {
      // Apexcharts doesn't actually reset zoom when pressing the "Reset Zoom" button,
      // but at least it sends an event with the current zoom level so we can do it ourselves.
      // Thank you, Apexcharts! ❤❤❤
      // (note: there exists a beforeResetZoom event, but I haven't looked into it.)
      const originalMin = 1;
      const originalMax = this.dates.length + 1;
      if (_.isEqual(this.chartZooms.get(chartContext), xaxis)) {
        if (_.isEqual(xaxis, { min: originalMin, max: originalMax })) return; // Already resetting
        xaxis.min = originalMin;
        xaxis.max = originalMax;
        chartContext.zoomX(originalMin, originalMax);
      }
      this.chartZooms.set(chartContext, xaxis);
      this.setChartLabels(chartContext, xaxis);
    },
    /** Only show every n-th label on an Apexchart's x axis.
     * `n` is based on `this.labelCount`.
     * @remarks Why is this not build into Apexcharts?
     */
    setChartLabels(chartContext, { max, min }) {
      const nDates = max - min + 1;
      const everyNth = Math.ceil(nDates / this.labelCount) || 1;
      const newDates = [];
      const fromI = min - 1;
      for (let i = 0; i < nDates; ++i) {
        newDates.push(i % everyNth ? "" : this.dates[fromI + i]);
      }
      chartContext?.updateOptions({
        xaxis: {
          overwriteCategories: newDates,
        },
      });
    },
    async init() {
      await this.$store.dispatch("history/fetchProjectHistory", {
        force: true,
        params: { projectId: this.projectId },
      });
      this.seriesFromHistory();
      this.setChartLabels(this.$refs.chart, {
        min: 1,
        max: this.dates.length + 1,
      });
    },
    // Convert data from API to ApexCharts format
    // TODO: create useIsSpecialDay composable (a la useMoneyify) in util.js and check for special days instead of weekends
    // TODO: move filtering to a computed property, optionally add on-off switch to include special days
    seriesFromHistory({ includeWeekends = false } = {}) {
      this.dates = [];

      // Empty series clutter the chart legend and increase the chance that two milestones get a similar color
      const history = this.history.filter(({ records }) => records.length > 0);

      const costSeries = [];
      const timeSeries = [];
      const cumCostSeries = [];
      const cumTimeSeries = [];
      // Stores the index of the next available value for each tig
      const dataIndex = [];

      for (const { tig } of history) {
        costSeries.push({ name: tig.name, data: [] });
        timeSeries.push({ name: tig.name, data: [] });
        cumCostSeries.push({ name: tig.name, data: [] });
        cumTimeSeries.push({ name: tig.name, data: [] });
        dataIndex.push(0);
      }

      const { minDate, maxDate } = this.getHistoryMinMaxDate();
      const nDates = maxDate.diff(minDate, "day") + 1;
      for (let i = 0; i < nDates; i++) {
        const date = minDate.add(i, "day");
        const fmtDate = date.format("YYYY-MM-DD");
        const weekend = isWeekend(date);
        let hasData = false;

        const costs = [];
        const times = [];
        const cumCosts = [];
        const cumTimes = [];
        for (const [j, { records }] of history.entries()) {
          // If curve smoothing is enabled, the curve descends to and ascends from 0,
          // but gets cut off for null values. Example with null:
          // https://apexcharts.com/angular-chart-demos/line-charts/missing-null-values/
          // Curve smoothing reference: https://apexcharts.com/docs/options/stroke/#curve
          let cost = weekend ? null : 0;
          let time = weekend ? null : 0;
          let cumCost = cumCostSeries[j].data.at(-1)?.y ?? 0;
          let cumTime = cumTimeSeries[j].data.at(-1)?.y ?? 0;
          const tigI = dataIndex[j];
          if (records[tigI]?.date === fmtDate) {
            cost = records[tigI].logCost;
            time = records[tigI].logTime;
            cumCost += cost;
            cumTime += time;
            hasData = true;
            dataIndex[j]++;
          }
          costs.push(cost);
          times.push(time);
          cumCosts.push(cumCost);
          cumTimes.push(cumTime);
        }

        // When one of the series has a null value at a datapoint, but an other has a number,
        // Apexcharts draws the value to the top of the chart, instead of the correct location:
        // https://github.com/apexcharts/apexcharts.js/issues/4458
        if (weekend && hasData) {
          for (const [i, cost] of costs.entries()) costs[i] = cost ?? 0;
          for (const [i, time] of times.entries()) times[i] = time ?? 0;
        }
        if (!weekend || hasData || includeWeekends) {
          this.dates.push(fmtDate);
          for (let j = 0; j < history.length; j++) {
            costSeries[j].data.push({ x: fmtDate, y: costs[j] });
            timeSeries[j].data.push({ x: fmtDate, y: times[j] });
            cumCostSeries[j].data.push({ x: fmtDate, y: cumCosts[j] });
            cumTimeSeries[j].data.push({ x: fmtDate, y: cumTimes[j] });
          }
        }
      }

      this.nonCumulativeCostSeries = costSeries;
      this.nonCumulativeTimeSeries = timeSeries;
      this.cumulativeCostSeries = cumCostSeries;
      this.cumulativeTimeSeries = cumTimeSeries;
    },
    getHistoryMinMaxDate() {
      let minDate = "9999-99-99";
      let maxDate = "0000-00-00";
      for (const { records } of this.history) {
        if (records.length === 0) continue;
        let date = records[0].date;
        minDate = minDate > date ? date : minDate;
        date = records.at(-1).date;
        maxDate = maxDate < date ? date : maxDate;
      }
      minDate = dayjs(minDate);
      maxDate = dayjs(maxDate);
      return { minDate, maxDate };
    },
  },
};
</script>

<style lang="scss">
#project-history .apexcharts-canvas > svg {
  background-color: transparent !important;
}
</style>
