import { useVuelidate } from "@vuelidate/core";
import { nextTick } from "vue";

function scrollToField() {
  const selector = '[data-has-vuelidate-error="true"]';
  const target = document.querySelector(selector);
  if (target) {
    target.scrollIntoView({ behavior: "smooth", block: "center" });

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          const elements = document.querySelectorAll(selector);

          elements.forEach((el) => {
            el.classList.add("animate-scale");

            el.addEventListener(
              "animationend",
              () => {
                el.classList.remove("animate-scale");
              },
              { once: true }
            );
          });

          observer.disconnect();
        }
      },
      { threshold: 1.0 }
    );

    observer.observe(target);
  }
}

const useCustomVuelidate = (validations, state) => {
  const v$ = useVuelidate(validations, state);

  const touch$ = () => {
    v$.value.$touch();
    if (v$.value.$invalid) {
      nextTick(() => scrollToField());
    }
  };
  return {
    v$,
    touch$,
  };
};

export default useCustomVuelidate;
