<template>
  <div class="modal-card">
    <div class="container">
      <section class="modal-card-body">
        <h1 class="title">
          <template v-if="createMode">
            {{
              $tf("forecast.scenario.edit.createTitle|Új scenario felvétele")
            }}
          </template>
          <template v-else>
            {{ $tf("forecast.scenario.edit.editTitle|Scenario módosítása") }}
          </template>
        </h1>

        <form v-if="loaded">
          <div class="is-flex has-gap-3 is-align-items-start">
            <div>
              <vuelidated-input
                ref="nameInput"
                v-model="editScenario.name"
                :label="$tf('forecast.scenario.edit.nameLabel|Név')"
                :placeholder="$tf('forecast.scenario.edit.namePlaceholder|Név')"
                :validation-rule="v$.editScenario.name"
                class="mb-4"
                label-position="on-border"
                ref-value="input"
                @keyup.enter="save"
              ></vuelidated-input>
            </div>
            <div class="is-align-self-center">
              <b-checkbox v-model="editScenario.isDefault"
                >{{ $tf("forecast.scenario.edit.isDefault|Alapértelmezett") }}
              </b-checkbox>
            </div>
          </div>

          <vuelidated-input
            v-model="editScenario.chanceCategories"
            :checkbox-values="chanceCategories"
            :disabled="!editable"
            :label="
              $tf('forecast.scenario.edit.chanceCategories|Esélykategóriák')
            "
            :validation-rule="v$.editScenario.chanceCategories"
            type="checkbox"
          />
          <b-field class="mt-5 is-pulled-right">
            <b-button @click="close">
              {{ $tf("forecast.scenario.edit.cancel|Mégsem") }}
            </b-button>
            <b-button class="ml-3" type="is-info" @click="save">
              {{ $tf("forecast.scenario.edit.save|Mentés") }}
            </b-button>
          </b-field>
        </form>

        <LoadingSkeleton v-else />
      </section>
    </div>
  </div>
</template>

<script>
import { required } from "@vuelidate/validators";
import VuelidatedInput from "@/components/module/input/VuelidatedInput.vue";
import { deepCopy } from "@/utils/util";
import LoadingSkeleton from "../../module/loading/LoadingSkeleton.vue";
import { mapGetters } from "vuex";
import { getCurrentInstance } from "vue";
import useCustomVuelidate from "@/plugins/vuelidate";

export default {
  name: "ForecastScenarioEditModal",
  components: { LoadingSkeleton, VuelidatedInput },
  setup: () => {
    const instance = getCurrentInstance();
    const { v$, touch$ } = useCustomVuelidate();
    return {
      v$,
      touch$,
      instance,
    };
  },
  props: {
    scenario: {
      type: Object,
      required: false,
    },
  },
  async mounted() {
    this.createMode = !this.scenario;

    if (this.scenario) {
      this.editScenario = deepCopy(this.scenario);
      this.editScenario.chanceCategories =
        this.editScenario?.chanceCategories.map((chance) => chance.id) ?? [];
    }

    await this.$store.dispatch(
      "forecast_chance_category/fetchChanceCategories"
    );

    this.loaded = true;
    this.$nextTick(() => {
      this.$refs.nameInput.$refs.input.focus();
    });
  },
  data() {
    return {
      loaded: false,
      createMode: false,
      editScenario: {
        name: undefined,
        chanceCategories: [],
        isDefault: false,
      },
    };
  },
  validations: {
    editScenario: {
      name: {
        required,
      },
      chanceCategories: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      chanceCategories: "forecast_chance_category/chanceCategories",
    }),
    editable() {
      return !this.editScenario.id || this.editScenario.id > 5;
    },
  },
  methods: {
    close() {
      this.instance.emit("close");
    },
    async save() {
      this.touch$();
      if (!this.v$.$invalid) {
        let request = deepCopy(this.editScenario);

        if (this.createMode) {
          await this.$store.dispatch(
            "forecast_scenario/createScenario",
            request
          );
        } else {
          await this.$store.dispatch(
            "forecast_scenario/updateScenario",
            request
          );
        }
        this.$emit("save");
        this.close();
      }
    },
  },
};
</script>

<style>
.b-numberinput .button {
  border-radius: 8px !important;
  width: 40px;
}
</style>
