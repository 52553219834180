<template>
  <div class="modal-card" style="width: 28rem">
    <div class="container">
      <section class="modal-card-body">
        <div v-if="projectData.status !== 'REQUEST'">
          <h1 class="title small-title mt-0">
            <b-icon icon="eye" size="is-small" />
            Változtatott védett adatok
          </h1>

          <div v-if="projectData.budget !== projectEdit.budget">
            Budget: {{ moneyify(projectData.budget) }}
            <b-icon
              class="arrow-icon"
              icon="arrow-right"
              size="is-small"
            ></b-icon>
            {{ moneyify(projectEdit.budget) }}
          </div>

          <div v-if="projectData.expectedIncome !== projectEdit.expectedIncome">
            Várható bevétel: {{ moneyify(projectData.expectedIncome) }}
            <b-icon
              class="arrow-icon"
              icon="arrow-right"
              size="is-small"
            ></b-icon>
            {{ moneyify(projectEdit.expectedIncome) }}
          </div>

          <div
            v-if="
              toDate(projectData.dueDate).getFullYear() !==
                projectEdit.dueDate.getFullYear() ||
              toDate(projectData.dueDate).getMonth() !==
                projectEdit.dueDate.getMonth() ||
              toDate(projectData.dueDate).getDate() !==
                projectEdit.dueDate.getDate()
            "
          >
            Határidő: {{ projectData.dueDate }}
            <b-icon
              class="arrow-icon"
              icon="arrow-right"
              size="is-small"
            ></b-icon>
            {{ formatDate(projectEdit.dueDate) }}
          </div>

          <div v-if="projectData.commitment !== projectEdit.commitment">
            Vállalás: {{ numberify(projectData.commitment) }} nap
            <b-icon
              class="arrow-icon"
              icon="arrow-right"
              size="is-small"
            ></b-icon>
            {{ numberify(projectEdit.commitment) }} nap
          </div>

          <div v-if="!securedDataChange">Nem változtattál védett adatot</div>
        </div>

        <b-field class="mt-4" label="Összegzés" label-position="on-border">
          <b-input v-model="summary"></b-input>
        </b-field>

        <b-field class="mt-4" label="Leírás" label-position="on-border">
          <b-input v-model="description" type="textarea"></b-input>
        </b-field>

        <b-button
          :disabled="summary == null || description == null"
          type="is-info"
          @click="saveProject()"
        >
          Mentés
        </b-button>
      </section>
    </div>
  </div>
</template>

<script>
import { formatDate, moneyify, numberify } from "../../../../utils/util";
import { getCurrentInstance } from "vue";

export default {
  name: "JournalProjectModal",
  setup: () => {
    const instance = getCurrentInstance();
    return {
      instance: instance,
    };
  },
  props: {
    projectData: {
      type: Object,
      required: true,
    },
    projectEdit: {
      type: Object,
      required: true,
    },
  },
  computed: {
    securedDataChange() {
      return (
        this.projectData.budget !== this.projectEdit.budget ||
        this.projectData.expectedIncome !== this.projectEdit.expectedIncome ||
        this.projectData.dueDate !== formatDate(this.projectEdit.dueDate) ||
        this.projectData.commitment !== this.projectEdit.commitment
      );
    },
  },
  data() {
    return {
      formatDate,
      moneyify,
      numberify,
      summary: null,
      description: null,
    };
  },
  methods: {
    toDate(date) {
      const numbers = date.split("-");
      return new Date(numbers[0], numbers[1] - 1, numbers[2]);
    },
    async saveProject() {
      this.$emit("project-saved", {
        summary: this.summary,
        description: this.description,
      });
      this.instance.emit("close");
    },
  },
};
</script>
