<template>
  <section class="section" @click="hideClickContainer">
    <div class="container">
      <div>
        <h1 class="title">{{ $tf("forecastPlan.title|Éves terv") }}</h1>
      </div>
      <b-field grouped grouped-multiline>
        <ForecastYearSelector
          :key="yearSelectorKey"
          v-model="year"
          @select="selectedYearObject = $event"
        />
        <b-field :label="$tf('forecastPlan.filter.segment|Szegmens típusa')">
          <multiselect-dropdown
            v-model="filter.segments"
            :items="segmentsWithUnassigned"
            append-to-body
            has-select-all-option
            identifier-field="id"
            name-field="name"
          />
        </b-field>
        <b-field :label="$tf('forecastPlan.filter.client|Ügyfélválasztás')">
          <multiselect-dropdown
            v-model="filter.clients"
            :items="clientsWithUnassigned"
            append-to-body
            has-select-all-option
            identifier-field="id"
            name-field="name"
          />
        </b-field>
        <b-field :label="$tf('forecastPlan.filter.type|Tervsor típusa')">
          <multiselect-dropdown
            v-model="filter.type"
            :items="planTypes"
            append-to-body
            has-select-all-option
            identifier-field="id"
            name-field="name"
            use-tf
          />
        </b-field>
        <b-field
          :label="$tf('forecastPlan.filter.quarter|Negyedévek kiválasztása')"
        >
          <multiselect-dropdown
            v-model="filter.quarter"
            :items="quarters"
            append-to-body
            has-select-all-option
            identifier-field="id"
            name-field="name"
          />
        </b-field>
        <b-field class="ml-auto is-flex is-align-items-center">
          <b-button
            v-if="canEdit"
            icon-left="plus-circle"
            type="is-info"
            @click="openEdit(null)"
          >
            {{ $tf("forecastPlan.new|Tervsor felvétele") }}
          </b-button>
        </b-field>
      </b-field>
    </div>
    <div class="container">
      <div>
        <h3 class="title is-6">
          {{
            $tf(
              "forecastPlan.filterLabel|Szűrés eredményének összesített adatai"
            )
          }}
        </h3>
        <div class="is-flex is-justify-content-space-between">
          <div
            v-for="quarter in FORECAST_YEAR_QUARTER"
            :key="quarter"
            class="is-flex is-flex-1 is-justify-content-center has-gap-1"
          >
            <strong>{{ quarter }}:</strong>
            <p>
              {{
                moneyify(
                  filteredMoneyData[quarter],
                  CURRENCY_TIERS.LEVEL_TWO,
                  true,
                  false
                )
              }}
            </p>
            <p>/</p>
            <p>
              {{
                moneyify(
                  allMoneyData[quarter],
                  CURRENCY_TIERS.LEVEL_TWO,
                  true,
                  false
                )
              }}
            </p>
            <p>{{ currency }}</p>
          </div>
        </div>
      </div>
      <ForecastYearlyData
        :data="forecastData"
        :quarterly-money-data="allMoneyData"
        :year="year"
        :yearly-income="yearlyIncome"
        @select="openClickContainer"
      />
      <b-field class="mt-2 is-flex is-justify-content-end">
        <b-button
          v-if="canClosePlanning"
          icon-left="lock"
          type="is-warning"
          @click="changePlanningStatus"
        >
          {{ $tf("forecastPlan.close|Éves terv zárása") }}
        </b-button>
        <b-button
          v-if="canReopenPlanning"
          icon-left="lock-open"
          type="is-warning"
          @click="changePlanningStatus"
        >
          {{ $tf("forecastPlan.open|Éves terv újranyitása") }}
        </b-button>
      </b-field>
      <div
        v-if="canEdit || canDelete"
        ref="clickContainer"
        :class="$style.clickContainer"
      >
        <div class="is-flex is-flex-direction-column has-gap-2 p-2">
          <div
            v-if="canEdit"
            :class="$style.entry"
            class="is-flex is-align-items-center has-gap-2"
            @click="openEdit(selectedPlan)"
          >
            <b-icon icon="pencil" />
            <p>
              {{ $tf("forecastPlan.clickContainer.edit|Szerkesztés") }}
            </p>
          </div>
          <div
            v-if="canCreate"
            :class="$style.entry"
            class="is-flex is-align-items-center has-gap-2"
            @click="deleteRow(selectedPlan.id)"
          >
            <b-icon icon="trash" type="is-danger" />
            <p class="is-danger">
              {{ $tf("forecastPlan.clickContainer.delete|Törlés") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  CURRENCY_TIERS,
  FORECAST_YEAR_QUARTER,
  PRIVILEGES,
  PROJECT_BASE_DATA_TYPE,
} from "@/utils/const";
import { mapGetters } from "vuex";

import ForecastPlanEditModal from "@/components/forecast/edit/ForecastPlanEditModal.vue";

import { moneyify, openDeleteConfirm } from "@/utils/util";
import ForecastYearSelector from "@/components/forecast/ForecastYearSelector.vue";
import ForecastYearlyData from "@/components/forecast/ForecastYearlyData.vue";
import MultiselectDropdown from "@/components/MultiselectDropdown.vue";
import { isEmpty } from "lodash";
import ForecastDataMixin from "@/mixins/ForecastDataMixin";

export default {
  name: "ForecastPlan",
  mixins: [ForecastDataMixin],
  data() {
    return {
      PROJECT_BASE_DATA_TYPE,
      FORECAST_YEAR_QUARTER,
      loaded: false,
      year: new Date().getFullYear(),
      selectedYearObject: {},
      planTypes: [
        {
          id: "PLAN",
          name: "forecastPlan.filter.planName|Terv",
        },
        {
          id: "PROJECT",
          name: "forecastPlan.filter.projectName|Projekt",
        },
      ],
      filter: {
        segments: [],
        clients: [],
        type: [],
        quarter: [],
      },
      yearSelectorKey: 0,
      selectedPlan: undefined,
      CURRENCY_TIERS,
    };
  },
  components: {
    MultiselectDropdown,
    ForecastYearlyData,
    ForecastYearSelector,
  },
  watch: {
    year() {
      this.load();
    },
  },
  computed: {
    ...mapGetters({
      plans: "forecast_plan/plans",
      facts: "forecast_fact/facts",
      segments: "forecast_segment/allSegments",
      clients: "enterprise_clients/clients",
      currency: "uiConfigStore/getCurrencySymbol",
    }),
    segmentsWithUnassigned() {
      return [
        {
          id: null,
          name: this.$tf("forecast.unassignedFilter|Hozzárendelés nélkül"),
        },
        ...this.segments,
      ];
    },
    clientsWithUnassigned() {
      return [
        {
          id: null,
          name: this.$tf("forecast.unassignedFilter|Hozzárendelés nélkül"),
        },
        ...this.clients,
      ];
    },
    allMoneyData() {
      const data = [
        ...this.facts.filter((fact) => fact.year === this.year - 1),
        ...this.plans.filter((plan) => !plan.parent),
      ];
      return Object.keys(FORECAST_YEAR_QUARTER)
        .map((quarter) => {
          return {
            [quarter]: data
              .map((it) => this.getIncome(it, quarter))
              .reduce((acc, val) => acc + val, 0),
          };
        })
        .reduce((acc, val) => Object.assign(acc, val));
    },
    yearlyIncome() {
      return Object.values(this.filteredMoneyData).reduce(
        (acc, val) => acc + val,
        0
      );
    },
    filteredMoneyData() {
      return Object.keys(FORECAST_YEAR_QUARTER)
        .map((quarter) => {
          return {
            [quarter]: this.forecastData
              .map((it) => this.getIncome(it, quarter))
              .reduce((acc, val) => acc + val, 0),
          };
        })
        .reduce((acc, val) => Object.assign(acc, val));
    },
    forecastData() {
      return [
        ...this.facts.filter((fact) => fact.year === this.year - 1),
        ...this.plans.filter((plan) => !plan.parent),
      ].filter(
        (it) =>
          !(
            (!isEmpty(this.filter.segments) &&
              !this.filter.segments.some(
                (segment) =>
                  (segment === null && !it.segment?.id) ||
                  it.segment?.id === segment
              )) ||
            (!isEmpty(this.filter.clients) &&
              !this.filter.clients.some(
                (client) =>
                  (client === null && !it.account?.id) ||
                  it.account?.id === client
              )) ||
            (!isEmpty(this.filter.type) &&
              ((!this.filter.type.includes("PLAN") && !this.isProject(it)) ||
                (!this.filter.type.includes("PROJECT") &&
                  this.isProject(it)))) ||
            (!isEmpty(this.filter.quarter) &&
              !this.filter.quarter.some((quarter) =>
                this.isCurrentYear(it)
                  ? this.hasIncome(it, quarter)
                  : this.hasIncomeNextYear(it, quarter)
              ))
          )
      );
    },
    canCreate() {
      return (
        this.$store.getters["session/hasAnyAuthority"](
          PRIVILEGES.ENTERPRISE.FORECAST.PLAN.CREATE,
          PRIVILEGES.ENTERPRISE.FORECAST.MISC.ADMIN
        ) && !this.selectedYearObject?.planningClosed
      );
    },
    canEdit() {
      return (
        this.$store.getters["session/hasAnyAuthority"](
          PRIVILEGES.ENTERPRISE.FORECAST.PLAN.EDIT_OWN,
          PRIVILEGES.ENTERPRISE.FORECAST.PLAN.EDIT_ANY,
          PRIVILEGES.ENTERPRISE.FORECAST.MISC.ADMIN
        ) && !this.selectedYearObject?.planningClosed
      );
    },
    canDelete() {
      return (
        this.$store.getters["session/hasAnyAuthority"](
          PRIVILEGES.ENTERPRISE.FORECAST.PLAN.DELETE_OWN,
          PRIVILEGES.ENTERPRISE.FORECAST.PLAN.DELETE_ANY,
          PRIVILEGES.ENTERPRISE.FORECAST.MISC.ADMIN
        ) && !this.selectedYearObject?.planningClosed
      );
    },
    canReopenPlanning() {
      return (
        this.$store.getters["session/hasAnyAuthority"](
          PRIVILEGES.ENTERPRISE.FORECAST.YEAR.OPEN_PLANNING,
          PRIVILEGES.ENTERPRISE.FORECAST.MISC.ADMIN
        ) && this.selectedYearObject?.planningClosed
      );
    },
    canClosePlanning() {
      return (
        this.$store.getters["session/hasAnyAuthority"](
          PRIVILEGES.ENTERPRISE.FORECAST.YEAR.CLOSE_PLANNING,
          PRIVILEGES.ENTERPRISE.FORECAST.MISC.ADMIN
        ) && !this.selectedYearObject?.planningClosed
      );
    },
    quarters() {
      return Object.keys(FORECAST_YEAR_QUARTER).map((it) => {
        return {
          id: it,
          name: it,
        };
      });
    },
  },
  methods: {
    moneyify,
    openClickContainer(row, event) {
      event.stopPropagation();
      if (this.$refs.clickContainer) {
        this.$refs.clickContainer.style.display = "block";
        this.$refs.clickContainer.style.top = `${event.clientY}px`;
        this.$refs.clickContainer.style.left = `${event.clientX}px`;
      }
      this.selectedPlan = row;
    },
    hideClickContainer() {
      if (this.$refs.clickContainer?.style?.display)
        this.$refs.clickContainer.style.display = "none";
      this.selectedPlan = undefined;
    },
    openEdit(row) {
      this.$buefy.modal.open({
        parent: this,
        component: ForecastPlanEditModal,
        width: 1240,
        props: { edit: row, selectedYear: this.year },
        hasModalCard: true,
        trapFocus: true,
        events: {
          save: () => this.load(true),
        },
      });
    },
    async changePlanningStatus() {
      if (this.selectedYearObject.planningClosed) {
        await this.$store.dispatch("forecast_year/openPlanning", this.year);
      } else {
        await this.$store.dispatch("forecast_year/closePlanning", this.year);
      }
      await this.$store.dispatch("forecast_year/fetchYears", true);
      this.yearSelectorKey++;
      await this.load(true);
    },
    async deleteRow(id) {
      openDeleteConfirm(this.$buefy, async () => {
        await this.$store.dispatch("forecast_plan/deletePlan", id);
        await this.load(true);
      });
    },
    async load(force) {
      this.loaded = false;

      let params = new URLSearchParams();
      params.append("year", this.year);
      let requestParams = { params: params };

      await this.$store.dispatch("forecast_plan/fetchPlans", {
        force,
        params: requestParams,
      });
      await this.$store.dispatch("forecast_fact/fetchFacts", {
        force,
        params: requestParams,
      });

      this.loaded = true;
    },
    async init() {
      this.loaded = false;
      await this.$store.dispatch("forecast_segment/fetchAllSegments");
      await this.$store.dispatch("enterprise_clients/fetchClients");
    },
  },
  async mounted() {
    await this.init();
    await this.load();
    this.loaded = true;
  },
};
</script>

<style lang="scss" module scoped>
@import "~@/assets/scss/colors.scss";

.clickContainer {
  position: fixed;
  display: none;
  background-color: $white;
  border-radius: 10px;
  min-width: 20em;

  .entry {
    cursor: pointer;
  }
}
</style>
