import { newLogger } from "@/utils/util";
import StoreCore from "../../../StoreCore";
import { $tf } from "@/plugins/i18n";

let logger = newLogger("AbsenceRequest");

let setAbsences = function (state, absences, field) {
  for (let i in absences.items) {
    if (!absences.items.hasOwnProperty(i) || !absences.items[i]) continue;
    absences.items[i].absenceRequestDates.sort();
    switch (absences.items[i].status) {
      case "REQUEST":
        absences.items[i].status = {
          enum: "REQUEST",
          display: $tf("absenceRequests.status.request|Kérelem"),
        };
        break;
      case "LINE_APPROVED":
        absences.items[i].status = {
          enum: "LINE_APPROVED",
          display: $tf(
            "absenceRequests.status.lineApproved|Vezető által elfogadva"
          ),
        };
        break;
      case "APPROVED":
        absences.items[i].status = {
          enum: "APPROVED",
          display: $tf("absenceRequests.status.approved|Elfogadva"),
        };
        break;
      case "ACTIVE":
        absences.items[i].status = {
          enum: "ACTIVE",
          display: $tf("absenceRequests.status.active|Aktív"),
        };
        break;
      case "TAKEN":
        absences.items[i].status = {
          enum: "TAKEN",
          display: $tf("absenceRequests.status.taken|Felhasznált"),
        };
        break;
      case "DENIED":
        absences.items[i].status = {
          enum: "DENIED",
          display: $tf("absenceRequests.status.denied|Elutasítva"),
        };
        break;
      case "CLARIFY":
        absences.items[i].status = {
          enum: "CLARIFY",
          display: $tf("absenceRequests.status.clarify|Egyeztetés alatt"),
        };
        break;
      case "REVOKED":
        absences.items[i].status = {
          enum: "REVOKED",
          display: $tf("absenceRequests.status.revoked|Visszavonva"),
        };
        break;
    }
    switch (absences.items[i].type) {
      case "REGULAR":
        absences.items[i].type = {
          enum: "REGULAR",
          display: $tf("absenceRequests.type.regular|Normál"),
        };
        break;
      case "SHIFT":
        absences.items[i].type = {
          enum: "SHIFT",
          display: $tf("absenceRequests.type.shift|Csúsztatás"),
        };
        break;
      case "UNPAID":
        absences.items[i].type = {
          enum: "UNPAID",
          display: $tf("absenceRequests.type.unpaid|Fizetetlen"),
        };
        break;
      case "SICKLEAVE":
        absences.items[i].type = {
          enum: "SICKLEAVE",
          display: $tf("absenceRequests.type.sickleave|Betegség"),
        };
        break;
    }
  }
  state[field] = absences;
};

const urls = {
  absences: "/absence/absence-request",
  next: "/absence/absence-request/internal/next",
  absenceRequestList: (userId) => `/absence/absence-request/list/${userId}`,
};

const initState = () => {
  return {
    requestInProgress: false,
    mineAbsenceRequests: {
      items: [],
    },
    userAbsenceRequests: {
      items: [],
    },
    pmAbsenceRequests: {
      items: [],
    },
    pmAbsenceRequestsPast: {
      items: [],
    },
    pmAbsenceRequestsWaiting: {
      items: [],
    },
    adminAbsenceRequests: {
      items: [],
    },
    absenceRequest: null,
    commentedApproval: null,
    quota: null,
    userQuota: null,
    nextAbsences: [],
    types: [],
  };
};

const storeCore = new StoreCore();
const actions = {
  getAbsenceRequest: async function (
    { commit, dispatch, state },
    { absenceId, force } = {}
  ) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.absences + "/" + absenceId,
        null,
        true,
        dispatch,
        "Távollétkérelem betöltése...",
        null,
        force
      );
      commit("setAbsenceRequest", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  getNextAbsences: async function ({ commit, dispatch, state }, params) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.next,
        params,
        true,
        dispatch,
        "Legközelebbi távollétek betöltése..."
      );
      commit("setNextAbsences", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  getAdminAbsenceRequests: async function (
    { commit, dispatch, state },
    { params, force } = {}
  ) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.absences + "/admin",
        params,
        true,
        dispatch,
        "Távollétkérelmek betöltése...",
        null,
        force
      );
      commit("setAdminAbsenceRequests", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  getPmAbsenceRequests: async function (
    { commit, dispatch, state },
    { params, force } = {}
  ) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.absences,
        params,
        true,
        dispatch,
        "Kezelendő távollétkérelmek betöltése...",
        null,
        force
      );
      commit("setPmAbsenceRequests", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  getPmAbsenceRequestsPast: async function (
    { commit, dispatch, state },
    params
  ) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.absences + "/past/" + params.user + "?" + params.searchParams,
        null,
        true,
        dispatch,
        "Korábbi távollétkérelmek betöltése...",
        null,
        params.force
      );
      commit("setPmAbsenceRequestsPast", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  getPmAbsenceRequestsWaiting: async function (
    { commit, dispatch, state },
    params
  ) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.absences + "/waiting/" + params.user,
        params.pageable,
        true,
        dispatch,
        "Kezelendő távollétkérelmek betöltése...",
        null,
        params.force
      );
      commit("setPmAbsenceRequestsWaiting", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  getMine: async function (
    { commit, dispatch, state },
    { params, force } = {}
  ) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.absences + "/mine",
        params,
        true,
        dispatch,
        "Szabadságkérelmeim betöltése...",
        null,
        force
      );
      commit("setMineAbsenceRequests", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  getTypes: async function ({ commit, dispatch, state }, params) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.absences + "/types",
        params,
        true,
        dispatch,
        "Szabadságkérelem-típusok betöltése..."
      );
      commit("setTypes", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  getAdminTypes: async function ({ commit, dispatch, state }, force) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.absences + "/admin/types",
        null,
        true,
        dispatch,
        "Szabadságkérelem admin típusok betöltése...",
        null,
        force
      );
      commit("setTypes", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  updateType: async function ({ dispatch }, absenceType) {
    try {
      await this.remoteRequest(
        "put",
        urls.absences + "/updateAbsenceType/" + absenceType.id,
        absenceType,
        false,
        dispatch,
        "absenceRequest.updateType.flair|Szabadságtípus-frissítés",
        undefined,
        undefined,
        true,
        {
          progress:
            "absenceRequest.updateType.progress|Szabadságtípus frissítése folyamatban...",
          fail: "absenceRequest.updateType.fail|Szabadságtípus frissítése sikertelen!",
          success:
            "absenceRequest.updateType.success|Szabadságtípus frissítése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  createType: async function ({ dispatch }, request) {
    try {
      await this.remoteRequest(
        "post",
        urls.absences + "/createAbsenceType",
        request,
        false,
        dispatch,
        "absenceRequest.createType.flair|Szabadságtípus létrehozása",
        undefined,
        undefined,
        true,
        {
          progress:
            "absenceRequest.createType.progress|Szabadságtípus létrehozása folyamatban...",
          fail: "absenceRequest.createType.fail|Szabadságtípus létrehozása sikertelen!",
          success:
            "absenceRequest.createType.success|Szabadságtípus létrehozása sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deleteType: async function ({ dispatch }, id) {
    try {
      await this.remoteRequest(
        "delete",
        urls.absences + "/deleteAbsenceType/" + id,
        null,
        false,
        dispatch,
        "absenceRequest.deleteType.flair|Szabadságtípus törlése",
        undefined,
        undefined,
        true,
        {
          progress:
            "absenceRequest.deleteType.progress|Szabadságtípus törlése folyamatban...",
          fail: "absenceRequest.deleteType.fail|Szabadságtípus törlése sikertelen!",
          success:
            "absenceRequest.deleteType.success|Szabadságtípus törlése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  getUserAbsenceRequests: async function (
    { commit, dispatch, state },
    { user, params, force } = {}
  ) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.absenceRequestList(user),
        params,
        true,
        dispatch,
        "Adott felhasználó szabadságkérelmeinek betöltése...",
        force
      );
      commit("setUserAbsenceRequests", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  revokeRequest: async function ({ dispatch, state }, absenceId) {
    try {
      await this.remoteRequest(
        "post",
        urls.absences + "/" + absenceId + "/revoke",
        null,
        false,
        dispatch,
        "absenceRequest.revokeRequest.flair|Szabadságkérelem visszavonása",
        undefined,
        undefined,
        true,
        {
          progress:
            "absenceRequest.revokeRequest.progress|Szabadságkérelem visszavonása folyamatban...",
          fail: "absenceRequest.revokeRequest.fail|Szabadságkérelem visszavonása sikertelen!",
          success:
            "absenceRequest.revokeRequest.success|Szabadságkérelem visszavonása sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  createRequest: async function ({ dispatch, state }, request) {
    try {
      await this.remoteRequest(
        "post",
        urls.absences,
        request,
        false,
        dispatch,
        "absenceRequest.createRequest.flair|Szabadságkérelem felvétele",
        undefined,
        undefined,
        true,
        {
          progress:
            "absenceRequest.createRequest.progress|Szabadságkérelem felvétele folyamatban...",
          fail: "absenceRequest.createRequest.fail|Szabadságkérelem felvétele sikertelen!",
          success:
            "absenceRequest.createRequest.success|Szabadságkérelem felvétele sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  updateRequestByAdmin: async function ({ dispatch, state }, { id, request }) {
    try {
      await this.remoteRequest(
        "put",
        urls.absences + "/admin/" + id,
        request,
        false,
        dispatch,
        "absenceRequest.updateRequestByAdmin.flair|Szabadságkérelem módosítása",
        undefined,
        undefined,
        true,
        {
          progress:
            "absenceRequest.updateRequestByAdmin.progress|Szabadságkérelem módosítása folyamatban...",
          fail: "absenceRequest.updateRequestByAdmin.fail|Szabadságkérelem módosítása sikertelen!",
          success:
            "absenceRequest.updateRequestByAdmin.success|Szabadságkérelem módosítása sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  approve: async function ({ dispatch, state }, payload) {
    try {
      await this.remoteRequest(
        "post",
        urls.absences + "/" + payload.absenceRequestId + "/comment",
        payload,
        false,
        dispatch,
        "absenceRequest.approve.flair|Szabadságkérelem elfogadása",
        undefined,
        undefined,
        true,
        {
          progress:
            "absenceRequest.approve.progress|Szabadságkérelem elfogadása folyamatban...",
          fail: "absenceRequest.approve.fail|Szabadságkérelem elfogadása sikertelen!",
          success:
            "absenceRequest.approve.success|Szabadságkérelem elfogadása sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  adminApprove: async function ({ dispatch, state }, payload) {
    try {
      await this.remoteRequest(
        "post",
        urls.absences + "/" + payload.absenceRequestId + "/admin-comment",
        payload,
        true,
        dispatch,
        "absenceRequest.adminApprove.flair|Szabadságkérelem admin elfogadása",
        undefined,
        undefined,
        true,
        {
          progress:
            "absenceRequest.adminApprove.progress|Szabadságkérelem admin elfogadása folyamatban...",
          fail: "absenceRequest.adminApprove.fail|Szabadságkérelem admin elfogadása sikertelen!",
          success:
            "absenceRequest.adminApprove.success|Szabadságkérelem admin elfogadása sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  approveMultiple: async function ({ dispatch, state }, payload) {
    try {
      await this.remoteRequest(
        "post",
        urls.absences + "/comment-multiple",
        payload,
        false,
        dispatch,
        "absenceRequest.approveMultiple.flair|Szabadságkérelem tömeges elfogadása",
        undefined,
        undefined,
        true,
        {
          progress:
            "absenceRequest.approveMultiple.progress|Szabadságkérelem tömeges elfogadása folyamatban...",
          fail: "absenceRequest.approveMultiple.fail|Szabadságkérelem tömeges elfogadása sikertelen!",
          success:
            "absenceRequest.approveMultiple.success|Szabadságkérelem tömeges elfogadása sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  getCommentedApproval: async function (
    { dispatch, commit },
    { absenceRequestId, force } = {}
  ) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.absences + "/" + absenceRequestId + "/comment",
        null,
        true,
        dispatch,
        "Szabadságkérelem komment betöltése",
        null,
        force
      );
      commit("setCommentedApproval", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  getQuota: async function ({ dispatch, commit }, { year, force } = {}) {
    try {
      let data = await this.remoteRequest(
        "get",
        "absence/absence-quota/remaining/" + year,
        null,
        true,
        dispatch,
        "Kvóta lekérése...",
        null,
        force
      );
      commit("setQuota", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  getUserQuota: async function ({ dispatch, commit }, params) {
    try {
      let data = await this.remoteRequest(
        "get",
        "absence/absence-quota/remaining/" +
          params.year +
          "/" +
          params.username,
        null,
        true,
        dispatch,
        "Felhasználó kvóta lekérése...",
        null,
        params.force
      );
      commit("setUserQuota", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  setAbsenceRequest: (state, absence) => {
    switch (absence.status) {
      case "REQUEST":
        absence.status = {
          enum: "REQUEST",
          display: $tf("absenceRequests.status.request|Kérelem"),
        };
        break;
      case "LINE_APPROVED":
        absence.status = {
          enum: "LINE_APPROVED",
          display: $tf(
            "absenceRequests.status.lineApproved|Vezető által elfogadva"
          ),
        };
        break;
      case "APPROVED":
        absence.status = {
          enum: "APPROVED",
          display: $tf("absenceRequests.status.approved|Elfogadva"),
        };
        break;
      case "ACTIVE":
        absence.status = {
          enum: "ACTIVE",
          display: $tf("absenceRequests.status.active|Aktív"),
        };
        break;
      case "TAKEN":
        absence.status = {
          enum: "TAKEN",
          display: $tf("absenceRequests.status.taken|Felhasznált"),
        };
        break;
      case "DENIED":
        absence.status = {
          enum: "DENIED",
          display: $tf("absenceRequests.status.denied|Elutasítva"),
        };
        break;
      case "REVOKED":
        absence.status = {
          enum: "REVOKED",
          display: $tf("absenceRequests.status.revoked|Visszavonva"),
        };
        break;
      case "CLARIFY":
        absence.status = {
          enum: "CLARIFY",
          display: $tf("absenceRequests.status.clarify|Egyeztetés alatt"),
        };
        break;
    }
    switch (absence.type) {
      case "REGULAR":
        absence.type = {
          enum: "REGULAR",
          display: $tf("absenceRequests.type.regular|Normál"),
        };
        break;
      case "SHIFT":
        absence.type = {
          enum: "SHIFT",
          display: $tf("absenceRequests.type.shift|Csúsztatás"),
        };
        break;
      case "UNPAID":
        absence.type = {
          enum: "UNPAID",
          display: $tf("absenceRequests.type.unpaid|Fizetetlen"),
        };
        break;
      case "SICKLEAVE":
        absence.type = {
          enum: "SICKLEAVE",
          display: $tf("absenceRequests.type.sickleave|Betegség"),
        };
        break;
    }
    state.absenceRequest = absence;
  },
  setNextAbsences: (state, absences) => {
    setAbsences(state, absences, "nextAbsences");
  },
  setAdminAbsenceRequests: (state, absences) => {
    setAbsences(state, absences, "adminAbsenceRequests");
  },
  setPmAbsenceRequests: (state, absences) => {
    setAbsences(state, absences, "pmAbsenceRequests");
  },
  setPmAbsenceRequestsPast: (state, absences) => {
    setAbsences(state, absences, "pmAbsenceRequestsPast");
  },
  setPmAbsenceRequestsWaiting: (state, absences) => {
    setAbsences(state, absences, "pmAbsenceRequestsWaiting");
  },
  setMineAbsenceRequests: (state, absences) => {
    setAbsences(state, absences, "mineAbsenceRequests");
  },
  setUserAbsenceRequests: (state, absences) => {
    setAbsences(state, absences, "userAbsenceRequests");
  },
  setCommentedApproval: (state, commentedApproval) => {
    state.commentedApproval = commentedApproval;
  },
  setQuota: (state, quota) => {
    state.quota = quota;
  },
  setUserQuota: (state, quota) => {
    state.userQuota = quota;
  },
  setTypes: (state, types) => {
    state.types = types;
  },
};

const getters = {
  absenceRequest: (state) => {
    return state.absenceRequest;
  },
  nextAbsences: (state) => {
    return state.nextAbsences;
  },
  adminAbsenceRequests: (state) => {
    return state.adminAbsenceRequests;
  },
  pmAbsenceRequests: (state) => {
    return state.pmAbsenceRequests;
  },
  pmAbsenceRequestsPast: (state) => {
    return state.pmAbsenceRequestsPast;
  },
  pmAbsenceRequestsWaiting: (state) => {
    return state.pmAbsenceRequestsWaiting;
  },
  mineAbsenceRequests: (state) => {
    return state.mineAbsenceRequests;
  },
  userAbsenceRequests: (state) => {
    return state.userAbsenceRequests;
  },
  commentedApproval: (state) => {
    return state.commentedApproval;
  },
  quota: (state) => {
    return state.quota;
  },
  userQuota: (state) => {
    return state.userQuota;
  },
  types: (state) => {
    return state.types;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
