<template>
  <div class="onboarding-step-page-main">
    <div class="onboarding-step-page-content">
      <h2>
        {{ $tf("onboarding.setupClients.title|Céged ügyfeleinek beállítása") }}
      </h2>
      <p>
        {{
          $tf(
            "onboarding.setupClients.subtitle|Erre projektjeid és ügyfeleid megfelelő összerendelése miatt van szükség."
          )
        }}
      </p>
      <b-button
        class="onboarding-button onboarding-button-transparent onboarding-button-small my-3"
        icon-left="plus-circle"
        @click="openClientEditModal(null)"
        >{{ $tf("onboarding.setupClients.add|Ügyfél hozzáadása") }}
      </b-button>
      <p v-if="clients.length < 1">
        {{
          $tf(
            "onboarding.setupClients.empty|Jelenleg nem adtál meg egyetlen ügyfelet sem. Az ügyfeleket a későbbiekben is felveheted."
          )
        }}
      </p>
      <div v-else class="is-flex is-flex-direction-column has-gap-2">
        <fieldset
          v-for="(client, index) in clients"
          :key="client.identifier"
          :class="{ 'default-client-container': client.default }"
          class="client-card"
        >
          <legend v-if="client.default">
            {{
              $tf(
                "onboarding.setupClients.default|Saját cégedet képviselő ügyfél (pl. belső projektekhez)"
              )
            }}
          </legend>
          <div>
            <span
              class="has-text-dark-blue has-font-weight-700"
              style="font-size: 16px"
            >
              {{ client.identifier }}
            </span>
            -
            <span>
              {{ client.name }}
            </span>
            <p>
              {{ client.description }}
            </p>
          </div>
          <div class="is-flex is-align-items-center has-gap-2">
            <b-icon
              class="cursor-pointer has-text-blue"
              icon="pencil"
              size="is-small"
              @click="openClientEditModal(index)"
            />
            <b-icon
              v-if="!client.default"
              class="cursor-pointer has-text-blue"
              icon="trash"
              size="is-small"
              @click="removeClient(index)"
            />
          </div>
        </fieldset>
      </div>
    </div>
    <div class="onboarding-step-page-footer">
      <div class="is-flex ml-auto has-gap-2">
        <b-button
          class="onboarding-button onboarding-button-transparent onboarding-button-small"
          @click="stepBack"
        >
          {{ $tf("onboarding.setupClients.back|Vissza") }}
        </b-button>
        <b-button
          class="onboarding-button onboarding-button-small"
          type="is-info"
          @click="stepNext"
        >
          {{
            clients.length > 0
              ? $tf("onboarding.setupClients.continue|Tovább")
              : $tf("onboarding.setupClients.skip|Kihagyom")
          }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ONBOARDING_PAGES } from "@/utils/const";
import OnboardingSetupClientModal from "@/components/onboarding/OnboardingSetupClientModal.vue";
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash";

export default {
  name: "OnboardingSetupClients",
  computed: {
    ...mapGetters({
      clients: "onboarding/getClients",
    }),
  },

  methods: {
    async save() {
      await this.$store.dispatch("onboarding/updateClients", this.clients);
    },
    async stepBack() {
      await this.save();
      this.$emit("nextPage", ONBOARDING_PAGES.SETUP_USERS);
    },
    async stepNext() {
      await this.save();
      this.$emit("nextPage", ONBOARDING_PAGES.SETUP_PROJECTS);
    },
    async removeClient(index) {
      const splicedClients = cloneDeep(this.clients);
      splicedClients.splice(index, 1);
      await this.$store.dispatch("onboarding/updateClients", splicedClients);
    },
    openClientEditModal(index) {
      this.$buefy.modal.open({
        parent: this,
        component: OnboardingSetupClientModal,
        props: {
          index: index,
        },
        hasModalCard: true,
        fullScreen: false,
        canCancel: true,
        trapFocus: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.client-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid #cad3de;
  padding: 16px;
  max-width: 812px;
}

.default-client-container {
  border-color: $blue;

  legend {
    color: $blue;
  }
}
</style>
