<template>
  <div>
    <loading-skeleton v-if="loading" />
    <template v-else>
      <div class="project-form">
        <div class="is-flex mb-3">
          <div>
            <h3 class="title">
              {{ $tf("projectForm.namesAndIds|Nevek és azonosítók") }}
              <b-tooltip
                :auto-close="['outside', 'escape']"
                :triggers="['click']"
                position="is-right"
                type="is-info"
              >
                <template v-slot:content>
                  <div class="content tooltip">
                    <p>
                      {{
                        $tf(
                          "projectForm.namesAndIds.tooltip|Az azonosítót kézzel szükséges kitölteni!"
                        )
                      }}
                    </p>
                  </div>
                </template>
                <b-icon icon="info-circle" />
              </b-tooltip>
            </h3>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <vuelidated-input
              v-model="projectEdit.name"
              :expanded="true"
              :label="$tf('projectForm.name|Név')"
              :placeholder="$tf('projectForm.name.placeholder|Név')"
              :validation-rule="v$.projectEdit.name"
              label-position="on-border"
            />
          </div>
          <div class="column">
            <vuelidated-input
              v-model="projectEdit.identifier"
              :expanded="true"
              :label="$tf('projectForm.identifier|Azonosító')"
              :placeholder="$tf('projectForm.identifier.placeholder|Azonosító')"
              :validation-rule="v$.projectEdit.identifier"
              label-position="on-border"
            />
          </div>
          <div v-if="isUsingExternalTracking" class="column">
            <vuelidated-input
              v-model="projectEdit.prefix"
              :expanded="true"
              :label="$tf('projectForm.prefix|Prefix')"
              :placeholder="$tf('projectForm.prefix.placeholder|Prefix')"
              :validation-rule="v$.projectEdit.prefix"
              label-position="on-border"
            />
          </div>
        </div>
        <div class="columns">
          <div class="column is-half">
            <b-field
              :label="$tf('projectForm.manager|Felelős')"
              label-position="on-border"
            >
              <b-autocomplete
                v-model="managerName"
                :data="filteredLeads"
                :placeholder="
                  $tf('projectForm.manager.placeholder|pl. Elektrom Ágnes')
                "
                class="has-blue-icon"
                field="name"
                icon="search"
                rounded
                @select="(option) => (projectEdit.lead = option?.id)"
              >
                <template v-slot:empty
                  >{{ $tf("projectForm.manager.empty|Nincs találat") }}
                </template>
              </b-autocomplete>
            </b-field>
          </div>
          <div class="column is-half">
            <b-field
              :label="$tf('projectForm.client|Ügyfél')"
              label-position="on-border"
            >
              <b-autocomplete
                v-model="clientName"
                :data="filteredClients"
                :placeholder="
                  $tf('projectForm.client.placeholder|pl. Alpha Soft Kft.')
                "
                class="has-blue-icon"
                field="name"
                icon="search"
                rounded
                @select="(option) => (projectEdit.client = option?.id)"
              >
                <template v-slot:empty
                  >{{ $tf("projectForm.client.empty|Nincs találat") }}
                </template>
              </b-autocomplete>
            </b-field>
          </div>
        </div>
      </div>
      <template v-if="projectEdit.type === 'BASIC'">
        <div class="my-5">
          <h3 class="title">
            {{ $tf("projectForm.finances.title|Pénzügyek") }}
            <b-tooltip
              :auto-close="['outside', 'escape']"
              :triggers="['click']"
              position="is-right"
              type="is-info"
            >
              <template v-slot:content>
                <div class="content tooltip">
                  <p>
                    {{
                      $tf(
                        "projectForm.finances.subtitle|Megismerhető, hogy állunk anyagilag törzs szinten."
                      )
                    }}
                  </p>
                </div>
              </template>
              <b-icon icon="info-circle" />
            </b-tooltip>
          </h3>
        </div>
        <div class="columns">
          <div class="column">
            <div class="is-flex is-align-items-center">
              <b-tooltip
                v-if="
                  projectData.budget !== projectEdit.budget &&
                  projectData.status !== 'REQUEST'
                "
                :label="
                  $tf(
                    'projectForm.protectedDataMustHaveComment|Védett adat módosításához megjegyzés kell!'
                  )
                "
                multilined
                size="is-small"
                type="is-warning"
              >
                <b-icon icon="triangle-exclamation" type="is-warning"></b-icon>
              </b-tooltip>
              <money-input
                v-model="projectEdit.budget"
                :label="$tf('projectForm.budget|Büdzsé')"
                class="mb-2 ml-0 pr-0"
                custom-class="is-full-width"
                label-position="on-border"
                required
              />
            </div>

            <p v-if="!projectEdit.internal" class="money-subtext">
              {{ $tf("projectForm.calculatedProfit|Kalkulált profit:") }}
              <span>{{ projectEdit.expectedIncome - projectEdit.budget }}</span>
            </p>
          </div>
          <div class="column">
            <div class="is-flex is-align-items-center">
              <b-tooltip
                v-if="
                  projectData.expectedIncome !== projectEdit.expectedIncome &&
                  projectData.status !== 'REQUEST'
                "
                :label="
                  $tf(
                    'projectForm.protectedDataMustHaveComment|Védett adat módosításához megjegyzés kell!'
                  )
                "
                multilined
                size="is-small"
                type="is-warning"
              >
                <b-icon icon="triangle-exclamation" type="is-warning"></b-icon>
              </b-tooltip>
              <money-input
                v-model="projectEdit.expectedIncome"
                :disabled="projectEdit.internal"
                :label="$tf('projectForm.expectedIncome|Várható Bevétel:')"
                :required="!projectEdit.internal"
                class="mb-2 ml-0 pr-0"
                custom-class="is-full-width"
                label-position="on-border"
              />
            </div>
            <p v-if="!projectEdit.internal" class="money-subtext">
              {{
                $tf("projectForm.calculatedProfitRate|Kalkulált profitráta:")
              }}
              <span>{{
                percentify(
                  (projectEdit.expectedIncome - projectEdit.budget) /
                    projectEdit.expectedIncome
                )
              }}</span>
            </p>
          </div>
          <div class="column">
            <b-tooltip
              v-if="
                projectData.expectedIncome !== projectEdit.expectedIncome &&
                projectData.status !== 'REQUEST'
              "
              :label="
                $tf(
                  'projectForm.protectedDataMustHaveComment|Védett adat módosításához megjegyzés kell!'
                )
              "
              multilined
              size="is-small"
              type="is-warning"
            >
              <b-icon icon="triangle-exclamation" type="is-warning"></b-icon>
            </b-tooltip>
            <money-input
              v-model="projectEdit.commitment"
              :label="$tf('projectForm.commitment|Vállalás (előzetes becslés)')"
              class="mb-2"
              day
              expanded
              label-position="on-border"
              required
            />
          </div>
        </div>
        <div>
          <div class="is-flex is-flex-wrap-wrap info-boxes">
            <project-form-info-box
              :header="$tf('projectForm.check|Ellenőrzés')"
              :text="
                $tf(
                  'projectForm.checkByMilestonesSum|Mérföldkövek összeségében'
                )
              "
              has-borders
              type="is-light"
            />
            <project-form-info-box
              :header="
                $tf(
                  'projectForm.tigCommitmentSum|Mérföldkövek vállalásainak összege'
                )
              "
              :text="workdayify(tigSumCommitment)"
              :type="
                tigSumCommitment === projectData.commitment
                  ? 'is-success'
                  : 'is-warning'
              "
              has-borders
            />
            <project-form-info-box
              :header="
                $tf('projectForm.tigBudgetSum|Mérföldkövek büdzséinek összege')
              "
              :mask="moneyify"
              :text="tigSumBudget"
              :type="
                tigSumBudget === projectData.budget
                  ? 'is-success'
                  : 'is-warning'
              "
              has-borders
            />
            <project-form-info-box
              :header="
                $tf(
                  'projectForm.tigExpectedIncome|Mérföldkövek várható bevételeinek összege'
                )
              "
              :mask="projectData.internal ? null : moneyify"
              :text="projectData.internal ? '-' : tigSumIncome"
              :type="
                projectData.internal ||
                tigSumIncome === projectData.expectedIncome
                  ? 'is-success'
                  : 'is-warning'
              "
              has-borders
            />
            <project-form-info-box
              :header="
                $tf('projectForm.tigSumPercent|Mérföldkő teljesítési % összeg')
              "
              :text="tigSumPercent"
              :type="tigSumPercent === 100 ? 'is-success' : 'is-warning'"
              has-borders
              suffix=" %"
            />
          </div>
        </div>
      </template>
      <div class="my-5">
        <div class="mb-5">
          <h3 class="title">
            {{ $tf("projectForm.dates.title|Időpontok") }}
            <b-tooltip
              :auto-close="['outside', 'escape']"
              :triggers="['click']"
              position="is-right"
              type="is-info"
            >
              <template v-slot:content>
                <div class="content tooltip">
                  <p>
                    {{
                      $tf(
                        "projectForm.dates.subtitle|Fontos időpontok a projekt folyamatában"
                      )
                    }}
                  </p>
                </div>
              </template>
              <b-icon icon="info-circle" />
            </b-tooltip>
          </h3>
        </div>
        <template v-if="datesAreSet">
          <div class="is-flex is-flex-direction-column">
            <div
              class="is-flex is-flex-wrap-wrap is-justify-content-space-between"
            >
              <div class="is-half-width">
                <div class="columns is-align-items-center">
                  <div class="column pr-0">
                    <vuelidated-input
                      v-model="projectEdit.startDate"
                      :label="$tf('projectForm.dates.startDate|Kezdés dátuma')"
                      :placeholder="
                        $tf(
                          'projectForm.dates.startDate.placeholder|Kezdés dátuma'
                        )
                      "
                      :validation-rule="v$.projectEdit.startDate"
                      class="datepicker-field has-blue-icon"
                      expanded
                      icon="calendar-day"
                      label-position="on-border"
                      type="date"
                    />
                  </div>
                  <div class="column is-narrow">
                    <b-tooltip
                      v-if="isStartDateChanged()"
                      :label="
                        $tf(
                          'projectForm.protectedDataMustHaveComment|Védett adat módosításához megjegyzés kell!'
                        )
                      "
                      multilined
                      size="is-small"
                      type="is-warning"
                    >
                      <b-icon
                        icon="triangle-exclamation"
                        type="is-warning"
                      ></b-icon>
                    </b-tooltip>
                  </div>
                </div>
              </div>
              <div class="is-half-width">
                <div class="columns is-align-items-center">
                  <div class="column pr-0">
                    <vuelidated-input
                      v-if="projectEdit.type === 'BASIC'"
                      v-model="projectEdit.dueDate"
                      :label="$tf('projectForm.dates.dueDate|Határidő')"
                      :placeholder="
                        $tf('projectForm.dates.dueDate.placeholder|Határidő')
                      "
                      :validation-rule="v$.projectEdit.dueDate"
                      class="datepicker-field has-blue-icon"
                      expanded
                      icon="calendar-day"
                      label-position="on-border"
                      type="date"
                    />
                  </div>
                  <div class="column is-narrow">
                    <b-tooltip
                      v-if="isDueDateChanged()"
                      :label="
                        $tf(
                          'projectForm.protectedDataMustHaveComment|Védett adat módosításához megjegyzés kell!'
                        )
                      "
                      multilined
                      size="is-small"
                      type="is-warning"
                    >
                      <b-icon
                        icon="triangle-exclamation"
                        type="is-warning"
                      ></b-icon>
                    </b-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="my-5">
        <div class="mb-5">
          <h3 class="title">
            {{ $tf("projectForm.dimensions.title|Dimenziók") }}
            <b-tooltip
              :auto-close="['outside', 'escape']"
              :triggers="['click']"
              position="is-right"
              type="is-info"
            >
              <template v-slot:content>
                <div class="content tooltip">
                  <p>
                    {{
                      $tf(
                        "projectForm.dimensions.subtitle|Projekt dimenzió beállítások"
                      )
                    }}
                  </p>
                </div>
              </template>
              <b-icon icon="info-circle" />
            </b-tooltip>
          </h3>
        </div>
        <div
          class="is-flex is-flex-wrap-wrap is-justify-content-space-between mb-4"
        >
          <div class="is-half-width">
            <dimension-input
              v-for="(dimension, index) of dimensions"
              :key="index"
              v-model="selectedDimensionValues[dimension.dimensionName]"
              :dimension="dimension"
            />
          </div>
        </div>
        <div
          class="is-flex is-flex-wrap-wrap is-justify-content-space-between mb-5"
        >
          <div class="is-half-width">
            <b-field
              :label="$tf('projectForm.color.label|Színek')"
              expanded
              label-position="on-border"
            >
              <b-select
                v-model="projectEdit.color"
                :placeholder="$tf('projectForm.color.placeholder|Szín')"
                class="has-blue-icon"
                icon="palette"
              >
                <option
                  v-for="color in projectColors"
                  :key="color.id"
                  :value="color.id"
                >
                  {{ color.colorName }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
      </div>

      <div>
        <ProjectCollapse
          id="meta-data"
          :header="$tf('projectForm.misc.title|Egyéb kiegészítő adatok')"
        >
          <div class="content mt-5">
            <div
              class="is-flex is-flex-wrap-wrap is-justify-content-space-between mb-5"
            >
              <div class="is-flex-1">
                <b-field
                  :label="
                    $tf('projectForm.misc.managementType|Szervezés típusa')
                  "
                  expanded
                  label-position="on-border"
                >
                  <b-autocomplete
                    v-model="managementTypeInput"
                    :data="managementTypes"
                    :open-on-focus="true"
                    :placeholder="
                      $tf(
                        'projectForm.misc.managementType.placeholder|Szervezés típusa'
                      )
                    "
                    class="has-blue-icon"
                    clearable
                    expanded
                    field="name"
                    icon="cogs"
                    @select="
                      (option) => (projectEdit.managementType = option?.id)
                    "
                  >
                  </b-autocomplete>
                </b-field>
              </div>
              <div class="is-flex-1">
                <b-field
                  :label="$tf('projectForm.misc.pricingModel|Szervezés típusa')"
                  expanded
                  label-position="on-border"
                >
                  <b-autocomplete
                    v-model="pricingModelInput"
                    :data="pricingModels"
                    :open-on-focus="true"
                    :placeholder="
                      $tf('projectForm.misc.pricingModel.placeholder|Árazás')
                    "
                    class="has-blue-icon"
                    clearable
                    expanded
                    field="name"
                    icon="cogs"
                    @select="
                      (option) => (projectEdit.pricingModel = option?.id)
                    "
                  >
                  </b-autocomplete>
                </b-field>
              </div>
            </div>
            <div
              class="is-flex is-flex-wrap-wrap is-justify-content-space-between mb-5"
            >
              <div class="is-flex-1">
                <b-field
                  :label="$tf('projectForm.misc.technologies|Technológiák')"
                  expanded
                  label-position="on-border"
                >
                  <multiselect-dropdown
                    v-model="projectEdit.technologies"
                    :items="technologies"
                    append-to-body
                    clearable
                    expanded
                    icon="circle-xmark"
                    identifier-field="id"
                    max-height="300"
                    name-field="name"
                    scrollable
                    searchable
                  >
                    <template #empty
                      >{{
                        $tf("projectForm.misc.technologies.empty|Nincs találat")
                      }}
                    </template>
                  </multiselect-dropdown>
                </b-field>
              </div>
              <div class="is-flex-1">
                <b-field
                  :label="$tf('projectForm.misc.laborTypes|Technológiák')"
                  expanded
                  label-position="on-border"
                >
                  <multiselect-dropdown
                    v-model="projectEdit.laborTypes"
                    :items="laborTypes"
                    append-to-body
                    clearable
                    expanded
                    icon="circle-xmark"
                    identifier-field="id"
                    max-height="300"
                    name-field="name"
                    scrollable
                    searchable
                  >
                    <template #empty
                      >{{
                        $tf("projectForm.misc.laborTypes.empty|Nincs találat")
                      }}
                    </template>
                  </multiselect-dropdown>
                </b-field>
              </div>
            </div>
            <div
              class="is-flex is-flex-wrap-wrap is-justify-content-space-between mb-5"
            >
              <div class="is-flex-1">
                <b-field
                  :label="
                    $tf('projectForm.misc.service_type|Szolgáltatás típusa')
                  "
                  expanded
                  label-position="on-border"
                >
                  <b-autocomplete
                    v-model="serviceTypeInput"
                    :data="serviceTypes"
                    :open-on-focus="true"
                    :placeholder="
                      $tf(
                        'projectForm.misc.service_type.placeholder|Szolgáltatás típusa'
                      )
                    "
                    class="has-blue-icon"
                    clearable
                    expanded
                    field="name"
                    icon="cogs"
                    @select="(option) => (projectEdit.serviceType = option?.id)"
                  >
                  </b-autocomplete>
                </b-field>
              </div>
              <div class="is-flex-1">
                <b-field
                  :label="$tf('projectForm.misc.income_type|Árbevétel típusa')"
                  expanded
                  label-position="on-border"
                >
                  <b-autocomplete
                    v-model="incomeTypeInput"
                    :data="incomeTypes"
                    :open-on-focus="true"
                    :placeholder="
                      $tf(
                        'projectForm.misc.income_type.placeholder|Árbevétel típusa'
                      )
                    "
                    class="has-blue-icon"
                    clearable
                    expanded
                    field="name"
                    icon="money-bill-wave"
                    @select="(option) => (projectEdit.incomeType = option?.id)"
                  >
                  </b-autocomplete>
                </b-field>
              </div>
              <div class="is-flex-1">
                <b-field
                  :label="$tf('projectForm.misc.sales_type|Sales típusa')"
                  expanded
                  label-position="on-border"
                >
                  <b-autocomplete
                    v-model="salesTypeInput"
                    :data="salesTypes"
                    :open-on-focus="true"
                    :placeholder="
                      $tf(
                        'projectForm.misc.sales_type.placeholder|Sales típusa'
                      )
                    "
                    class="has-blue-icon"
                    clearable
                    expanded
                    field="name"
                    icon="chart-line"
                    @select="(option) => (projectEdit.salesType = option?.id)"
                  >
                  </b-autocomplete>
                </b-field>
              </div>
            </div>
            <b-field
              :label="$tf('projectForm.misc.summary|Összefoglaló, leírás')"
              expanded
              label-position="on-border"
            >
              <b-input v-model="projectEdit.summary" type="textarea"></b-input>
            </b-field>
            <b-field
              :label="
                $tf(
                  'projectForm.misc.supportPotential|CR, support és upsell lehetőségek'
                )
              "
              expanded
              label-position="on-border"
            >
              <b-input
                v-model="projectEdit.supportPotential"
                type="textarea"
              ></b-input>
            </b-field>
            <h3 class="title">
              {{ $tf("projectForm.attachments.title|Csatolmányok") }}
              <b-tooltip
                :auto-close="['outside', 'escape']"
                :triggers="['click']"
                position="is-right"
                type="is-info"
              >
                <template v-slot:content>
                  <div class="content tooltip">
                    <p>
                      {{
                        $tf(
                          "projectForm.attachments.subtitle|Szerződések, specifikációk, egyéb dokumentumok."
                        )
                      }}
                    </p>
                  </div>
                </template>
                <b-icon icon="info-circle" />
              </b-tooltip>
            </h3>
            <h2 class="subtitle">
              {{
                $tf(
                  "projectForm.attachments.subtitle|Szerződések, specifikációk, becslések, egyéb projekthez kapcsolódó dokumentumok feltöltése."
                )
              }}
            </h2>
            <div
              class="is-flex is-flex-wrap-wrap is-justify-content-space-between mb-5"
            >
              <MultifileUpload v-model="uploadedFiles" />
            </div>
            <b-table :data="projectData.documents || []">
              <b-table-column
                v-slot="props"
                :label="$tf('projectForm.attachments.table.file|Fájl')"
                field="displayName"
              >
                {{ props.row.displayName }}
              </b-table-column>
              <b-table-column
                v-slot="props"
                :label="$tf('projectForm.attachments.table.uploader|Feltöltő')"
                field="createdBy"
              >
                {{ employees.find((e) => e.id === props.row.createdBy).name }}
              </b-table-column>
              <b-table-column
                v-slot="props"
                :label="
                  $tf(
                    'projectForm.attachments.table.uploadedAt|Feltöltés időpontja'
                  )
                "
                field="created"
              >
                {{ formatDate(props.row.created) }}
              </b-table-column>
              <b-table-column
                v-slot="props"
                :label="$tf('projectForm.attachments.table.download|Letöltés')"
                cell-class="has-text-centered"
                width="40"
              >
                <a @click="downloadFile(props.row.id, props.row.displayName)">
                  <b-icon icon="download"></b-icon>
                </a>
              </b-table-column>
              <b-table-column
                v-slot="props"
                :label="$tf('projectForm.attachments.table.delete|Törlés')"
                cell-class="has-text-centered"
                width="40"
              >
                <a @click="deleteFile(props.row.id)">
                  <b-icon icon="trash" type="is-danger"></b-icon>
                </a>
              </b-table-column>
            </b-table>
          </div>
          <template #trigger="props">
            <div class="has-text-centered">
              <a class="link is-info">
                {{
                  !props.open
                    ? $tf("projectForm.attachments.metaData|Metaadatok")
                    : $tf("projectForm.attachments.close|Bezárás")
                }}
                <br />
                <b-icon
                  :icon="!props.open ? 'angle-down' : 'angle-up'"
                ></b-icon>
              </a>
            </div>
          </template>
        </ProjectCollapse>
        <div class="has-text-centered mt-5 mb-4 is-flex">
          <b-field class="ml-auto">
            <b-button
              v-if="projectEdit.type === 'BASIC'"
              class="is-info"
              @click="saveProject(true)"
            >
              {{ $tf("projectForm.saveWithComment|Mentés megjegyzéssel") }}
            </b-button>
          </b-field>
          <b-field>
            <b-button class="is-info" @click="saveProject(false)">
              {{ $tf("projectForm.forward|Mentés") }}
            </b-button>
          </b-field>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required, requiredIf } from "@vuelidate/validators";
import VuelidatedInput from "@/components/module/input/VuelidatedInput";
import {
  deepCopy,
  formatDate,
  isNullOrUndefined,
  moneyify,
  numberify,
  percentify,
  workdayify,
} from "@/utils/util";
import MoneyInput from "@/components/module/input/MoneyInput";
import MultifileUpload from "@/components/module/input/MultifileUpload";
import LoadingSkeleton from "@/components/module/loading/LoadingSkeleton";
import ProjectCollapse from "../module/ProjectCollapse";
import JournalProjectModal from "../journal/JournalProjectModal";
import ProjectFormInfoBox from "@/components/enterprise/project/module/ProjectFormInfoBox.vue";
import {
  ISSUE_TRACKING_SYSTEM,
  PROJECT_BASE_DATA_TYPE,
  USER_INDICATOR_TYPE_ENUM,
} from "@/utils/const";
import DimensionInput from "@/components/module/input/DimensionInput.vue";
import MultiselectDropdown from "@/components/MultiselectDropdown.vue";
import useCustomVuelidate from "@/plugins/vuelidate";

export default {
  name: "ProjectEdit",
  components: {
    MultiselectDropdown,
    DimensionInput,
    LoadingSkeleton,
    ProjectFormInfoBox,
    MoneyInput,
    MultifileUpload,
    VuelidatedInput,
    ProjectCollapse,
  },
  setup: () => useCustomVuelidate(),
  props: {
    projectData: {
      type: Object,
      required: true,
    },
    projectDetails: {
      type: Object,
      required: false,
    },
    projectType: {
      type: String,
      required: false,
      default: "BASIC",
    },
    employees: {
      type: Array,
      required: true,
    },
  },
  validations: {
    projectEdit: {
      identifier: {
        required,
      },
      name: {
        required,
      },
      prefix: {
        required,
      },
      lead: {
        required,
      },
      type: {
        required,
      },
      client: {
        required,
      },
      startDate: {
        required,
      },
      budget: {
        required: requiredIf(function () {
          return this.projectEdit.type === "BASIC";
        }),
      },
      expectedIncome: {
        required: requiredIf(function () {
          return (
            this.projectEdit.type === "BASIC" && !this.projectEdit.internal
          );
        }),
      },
      commitment: {
        required: requiredIf(function () {
          return this.projectEdit.type === "BASIC";
        }),
      },
      dueDate: {
        required: requiredIf(function () {
          return this.projectEdit.type === "BASIC";
        }),
      },
    },
  },
  data() {
    return {
      selectedDimensionValues: {},
      moneyify,
      numberify,
      percentify,
      workdayify,
      formatDate,
      datesAreSet: false,
      managerName: "",
      clientName: "",
      colorName: "",
      projectKey: "",
      importProject: false,
      syncKeys: false,
      syncKeyLoading: false,
      downloadKeyLoading: false,
      dimensions: [],
      filteredDimensions: {},
      filteredColors: [],
      serviceTypeInput: undefined,
      incomeTypeInput: undefined,
      salesTypeInput: undefined,
      managementTypeInput: undefined,
      pricingModelInput: undefined,
      projectEdit: {
        internal: false,
        serviceType: undefined,
        incomeType: undefined,
        salesType: undefined,
        managementType: undefined,
        technologies: undefined,
        pricingModel: undefined,
        laborTypes: undefined,
      },
      uploadedFiles: [],
      loading: false,
    };
  },
  watch: {
    "projectEdit.budget": function (val) {
      let expIncome = numberify(this.projectEdit.expectedIncome);
      let calcProfit = expIncome - numberify(val);
      this.projectEdit.calculatedProfit = moneyify.call(this, calcProfit);
      this.projectEdit.calculatedProfitRate =
        Math.round((calcProfit / expIncome) * 10000) / 100;
    },
    "projectEdit.expectedIncome": function (val) {
      let expIncome = numberify(val);
      let calcProfit = expIncome - numberify(this.projectEdit.budget);
      this.projectEdit.calculatedProfit = moneyify.call(this, calcProfit);
      this.projectEdit.calculatedProfitRate =
        Math.round((calcProfit / expIncome) * 10000) / 100;
    },
  },
  computed: {
    USER_INDICATOR_TYPE_ENUM() {
      return USER_INDICATOR_TYPE_ENUM;
    },
    filteredLeads() {
      let leads = this.leads || [];
      return leads.filter((element) => {
        if (!this.managerName || !element.name || !element.username)
          return false;
        return (
          element.name
            .toString()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .indexOf(
              this.managerName
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0 ||
          element.username
            .toString()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .indexOf(
              this.managerName
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0
        );
      });
    },
    filteredClients() {
      let clients = this.clients || [];
      return clients.filter((element) => {
        if (!this.clientName || !element.identifier) return false;
        return (
          element.identifier
            .toString()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .indexOf(
              this.clientName
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0 ||
          element.name
            .toString()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .indexOf(
              this.clientName
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0
        );
      });
    },
    tigSumBudget() {
      let sumBudget = 0;
      if (this.projectData.tigDatas) {
        for (let tig of this.projectData.tigDatas) {
          sumBudget += tig.budget ? tig.budget : 0;
        }
      }
      return sumBudget;
    },
    tigSumCommitment() {
      let sumCommitment = 0;
      if (this.projectData.tigDatas) {
        for (let tig of this.projectData.tigDatas) {
          sumCommitment += tig.commitment ? parseFloat(tig.commitment) : 0;
        }
      }
      return sumCommitment;
    },
    tigSumIncome() {
      let sumIncome = 0;
      if (this.projectData.tigDatas) {
        for (let tig of this.projectData.tigDatas) {
          sumIncome += tig.billableAmount ? tig.billableAmount : 0;
        }
      }
      return sumIncome;
    },
    tigSumPercent() {
      let sumPercent = 0;
      if (this.projectData.tigDatas) {
        for (let tig of this.projectData.tigDatas) {
          sumPercent += tig.billablePercentage ? tig.billablePercentage : 0;
        }
      }
      return sumPercent;
    },
    managementTypes() {
      return (
        this.projectBaseDataGetter(
          PROJECT_BASE_DATA_TYPE.ENUM.MANAGEMENT_TYPE
        ) || []
      );
    },
    pricingModels() {
      return (
        this.projectBaseDataGetter(PROJECT_BASE_DATA_TYPE.ENUM.PRICING_MODEL) ||
        []
      );
    },
    technologies() {
      return (
        this.projectBaseDataGetter(PROJECT_BASE_DATA_TYPE.ENUM.TECHNOLOGIES) ||
        []
      );
    },
    laborTypes() {
      return (
        this.projectBaseDataGetter(PROJECT_BASE_DATA_TYPE.ENUM.LABOR_TYPES) ||
        []
      );
    },
    serviceTypes() {
      return (
        this.projectBaseDataGetter(PROJECT_BASE_DATA_TYPE.ENUM.SERVICE_TYPE) ||
        []
      );
    },
    incomeTypes() {
      return (
        this.projectBaseDataGetter(PROJECT_BASE_DATA_TYPE.ENUM.INCOME_TYPE) ||
        []
      );
    },
    salesTypes() {
      return (
        this.projectBaseDataGetter(PROJECT_BASE_DATA_TYPE.ENUM.SALES_TYPE) || []
      );
    },
    ...mapGetters({
      leads: "employee/currentEmployees",
      clients: "enterprise_clients/clients",
      projectColors: "project_dimensions/getProjectColors",
      indicators: "user_indicator/indicators",
      isUsingExternalTracking: "uiConfigStore/isUsingExternalTracking",
      projectBaseDataGetter: "project_base_data/getByType",
    }),
  },
  methods: {
    async preloadData() {
      this.loading = true;
      this.projectEdit = deepCopy(this.projectData);
      this.projectEdit.startDate = new Date(this.projectEdit.startDate);
      if (this.projectEdit.dueDate) {
        this.projectEdit.dueDate = new Date(this.projectEdit.dueDate);
      }
      if (!this.projectEdit.type) {
        this.projectEdit.type = this.projectType;
      }

      this.projectKey = {
        key: this.projectEdit.identifier,
        used: true,
        $isDisabled: true,
      };

      const promises = [
        this.$store.dispatch("enterprise_clients/fetchClients"),
        this.$store.dispatch("commitment/getCommitments", this.projectData.id),
        this.$store.dispatch(
          "project_dimensions/fetchActiveProjectDimensionsWithValues"
        ),
        this.$store.dispatch("project_dimensions/fetchProjectColorsCompact"),
        // this.$store.dispatch("employee/fetchLeads"),
        this.$store.dispatch("project_base_data/fetchAll"),
      ];
      await Promise.all(promises);

      this.setBaseData();

      this.datesAreSet = true;
      this.filterColors("");

      this.dimensions =
        this.$store.getters[
          "project_dimensions/getProjectDimensionsWithValues"
        ];

      let managers = this.leads.filter((c) => c.id === this.projectEdit.lead);
      this.managerName = managers.length > 0 ? managers[0].name : "";
      let clients = this.clients.filter(
        (c) => c.id === this.projectEdit.client
      );
      this.clientName = clients.length > 0 ? clients[0].name : "";

      if (this.projectEdit.dimensionValues) {
        this.projectEdit.dimensionValues.forEach((dimension) => {
          this.selectedDimensionValues[dimension.dimensionName] =
            dimension.values;
        });
      }

      this.dimensions.forEach((item) => {
        this.filteredDimensions[item.dimensionName] = item.values.slice();
      });
      this.loading = false;
    },
    setBaseData() {
      this.projectEdit.serviceType = this.projectData?.serviceType?.id;
      this.projectEdit.incomeType = this.projectData?.incomeType?.id;
      this.projectEdit.salesType = this.projectData?.salesType?.id;
      this.projectEdit.managementType = this.projectData?.managementType?.id;
      this.projectEdit.pricingModel = this.projectData?.pricingModel?.id;
      this.projectEdit.technologies =
        this.projectData?.technologies?.map((it) => it.id) || [];
      this.projectEdit.laborTypes =
        this.projectData?.laborTypes?.map((it) => it.id) || [];
      this.serviceTypeInput = this.projectData?.serviceType?.name;
      this.incomeTypeInput = this.projectData?.incomeType?.name;
      this.salesTypeInput = this.projectData?.salesType?.name;
      this.managementTypeInput = this.projectData?.managementType?.name;
      this.pricingModelInput = this.projectData?.pricingModel?.name;
    },
    async saveProject(withComment) {
      this.touch$();
      if (!this.v$.$invalid) {
        if (!withComment && this.projectData.status !== "REQUEST") {
          if (
            this.projectEdit.type === "BASIC" &&
            (this.projectData.budget !== this.projectEdit.budget ||
              this.projectData.expectedIncome !==
                this.projectEdit.expectedIncome ||
              this.projectData.commitment !== this.projectEdit.commitment ||
              this.isStartDateChanged() ||
              this.isDueDateChanged())
          ) {
            this.$buefy.toast.open({
              duration: 5000,
              message: this.$tf(
                "projectForm.protectedDataMustHaveComment|Védett adat módosításához megjegyzés kell!"
              ),
              position: "is-bottom",
              type: "is-warning",
            });
            withComment = true;
          }
        }

        if (withComment) {
          this.$buefy.modal.open({
            parent: this,
            component: JournalProjectModal,
            props: {
              projectData: this.projectData,
              projectEdit: this.projectEdit,
            },
            hasModalCard: true,
            trapFocus: true,
            width: "100%",
            events: {
              "project-saved": (comment) => {
                this.actuallySaveProject(comment);
              },
            },
          });
        } else {
          await this.actuallySaveProject();
        }
      }
    },
    getColorId(projectRequest) {
      if (projectRequest.colors && projectRequest.colors.length > 0) {
        const colorInHex = projectRequest.colors[0];
        const colorId = this.projectColors.find(
          (color) => color["colorInHex"] === colorInHex
        ).id;
        delete projectRequest.colors;
        return colorId;
      }
      return null;
    },
    async actuallySaveProject(comment) {
      let projectRequest = deepCopy(this.projectEdit);

      if (projectRequest.dueDate) {
        projectRequest.dueDate = formatDate(projectRequest.dueDate);
      }
      projectRequest.startDate = formatDate(projectRequest.startDate);
      projectRequest.starred = false;
      projectRequest.prefix = projectRequest.prefix.toUpperCase();
      // projectRequest.color = this.getColorId(projectRequest);

      projectRequest.dimensionValues = Object.values(
        this.selectedDimensionValues
      )
        .filter(Array.isArray) // Filter only properties that are arrays
        .flatMap((dimensionValues) =>
          dimensionValues.map((dimValue) => dimValue.id)
        );

      delete projectRequest.calculatedProfit;
      delete projectRequest.calculatedProfitRate;
      delete projectRequest.spent;
      delete projectRequest.expenseTotal;
      delete projectRequest.income;

      if (projectRequest.internal) {
        delete projectRequest.expectedIncome;
      }

      if (comment) {
        projectRequest.commentSummary = comment.summary;
        projectRequest.commentDescription = comment.description;
      }

      await this.$store
        .dispatch("enterprise_projects/saveProject", {
          projectData: projectRequest,
        })
        .then((value) => {
          this.$emit("project-saved", value ? projectRequest.identifier : null);
        });
    },
    filterColors(text) {
      this.filteredColors = this.projectColors
        .filter((option) => {
          return (
            option.colorInHex
              .toString()
              .toLowerCase()
              .indexOf(text.toLowerCase()) >= 0
          );
        })
        .map((option) => option.colorInHex);
    },
    filterDimensionValues(text, dimensionName) {
      const dimension = this.dimensions.find(
        (dimension) => dimension.dimensionName === dimensionName
      );
      const filteredValues = dimension.values.filter((dimensionValue) => {
        return (
          dimensionValue.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
        );
      });

      this.filteredDimensions[dimensionName].splice(
        0,
        this.filteredDimensions[dimensionName].length
      );
      this.filteredDimensions[dimensionName].push(...filteredValues);
    },
    isStartDateChanged() {
      if (
        isNullOrUndefined(this.projectData.startDate) ||
        isNullOrUndefined(this.projectEdit.startDate)
      ) {
        return false;
      }
      return (
        this.projectData.startDate !== formatDate(this.projectEdit.startDate) &&
        this.projectData.status !== "REQUEST"
      );
    },
    isDueDateChanged() {
      if (
        isNullOrUndefined(this.projectData.dueDate) ||
        isNullOrUndefined(this.projectEdit.dueDate)
      ) {
        return false;
      }
      return (
        this.projectData.dueDate !== formatDate(this.projectEdit.dueDate) &&
        this.projectData.status !== "REQUEST"
      );
    },
    downloadFile(id, fileName) {
      this.$store.dispatch("enterprise_projects/downloadProjectFile", {
        fileId: id,
        fileName: fileName,
      });
    },
    deleteFile(id) {
      this.$buefy.dialog.confirm({
        message: this.$tf(
          "projectForm.attachmentDeleteModal.message|Biztosan törölni szeretnéd a csatolmányt?"
        ),
        onConfirm: () => {
          if (
            this.$store.dispatch("enterprise_projects/deleteProjectFile", id)
          ) {
            // eslint-disable-next-line vue/no-mutating-props
            this.projectData.documents = this.projectData.documents.filter(
              (document) => document.id !== id
            );
            // Vue.set(
            //   this.projectData,
            //   "documents",
            //   this.projectData.documents.filter(
            //     (document) => document.id !== id
            //   )
            // );
          }
        },
      });
    },
  },
  mounted() {
    this.preloadData();
  },
};
</script>

<style
  src="../../../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"
></style>

<style lang="scss">
@import "~@/assets/scss/colors";

.is-half-width {
  flex: 1;
  width: 50%;

  .select {
    select {
      width: 100%;
    }

    width: 100%;
  }

  @media screen and (max-width: 800px) {
    flex: 0 0 100%;
    margin-bottom: 20px;
    width: 100%;
  }
}

.is-full-width {
  .field-body {
    .field {
      .control {
        width: 100%;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.money-subtext {
  color: $turquoise;
  font-weight: 400;

  span {
    color: $custom-dark-blue;
    font-weight: 500;
  }
}

.info-boxes {
  gap: 20px;
  @media screen and (max-width: 800px) {
    justify-content: center !important;
    * {
      width: 100%;
    }
  }
}

.title {
  margin-bottom: 8px;
}

.is-flex {
  gap: 10px;
}

.is-used-key {
  color: $red;
  font-weight: bold;
  cursor: not-allowed;
}
</style>
