<template>
  <div class="rotating-onboarding-spinner">
    <svg fill="none" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m 16.977523,0.24095147 c -9.2629169,0 -16.73280045,7.51449143 -16.73280045,16.77740253 0,9.262912 7.46988355,16.777403 16.73280045,16.777403 9.262917,0 16.777413,-7.514491 16.777413,-16.777403 0,-9.2629111 -7.514496,-16.77740253 -16.777413,-16.77740253 z m 0,4.14972823 c 6.966927,0 12.627682,5.6607523 12.627682,12.6276743 0,6.966923 -5.660755,12.583053 -12.627682,12.583053 -6.966937,0 -12.5830596,-5.61613 -12.5830596,-12.583053 0,-6.966922 5.6161226,-12.6276743 12.5830596,-12.6276743 z"
        style="
          color: #000000;
          fill: #000000;
          fill-opacity: 0.02;
          stroke: #565656;
          stroke-width: 0.3;
          stroke-opacity: 0.2;
        "
      />
      <path
        d="M 31.677259,17.003529 A 14.680208,14.680199 0 0 1 20.796571,31.183505"
        style="
          fill: none;
          stroke: #4e75d9;
          stroke-width: 2.25;
          stroke-linecap: round;
        "
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "OnboardingSpinner",
};
</script>

<style scoped>
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating-onboarding-spinner {
  width: 100%;
  height: 100%;
  -webkit-animation: rotating 1s ease-in-out infinite;
  -moz-animation: rotating 1s ease-in-out infinite;
  -ms-animation: rotating 1s ease-in-out infinite;
  -o-animation: rotating 1s ease-in-out infinite;
  animation: rotating 1s ease-in-out infinite;
}
</style>
