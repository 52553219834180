<template>
  <section class="section">
    <div class="container">
      <h1 class="title">
        {{ $tf("activeLoginList.page.title|Aktív bejelentkezések") }}
      </h1>
      <b-button type="danger" @click="sendPasswords"
        >{{ $tf("activeLoginList.page.sendPasswords|Új jelszavak kiküldése") }}
      </b-button>
    </div>
    <hr />

    <div v-if="logins" class="container">
      <b-table
        v-model:checked-rows="checkedRows"
        :data="filteredEmployees"
        :loading="employeeLoading"
        backend-sorting
        checkable
        detail-key="id"
        detailed
        @sort="(field, order) => (sort = { field: field, order: order })"
      >
        <b-table-column
          v-slot="props"
          :label="$tf('activeLoginsList.table.name|Név')"
          field="name"
          searchable
          sortable
        >
          {{ props.row.name }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          :label="$tf('activeLoginsList.table.count|Bejelentkezések száma')"
          field="count"
          sortable
        >
          {{
            $tf("utils.count|{number} db", {
              number: logins(props.row.id).length,
            })
          }}
        </b-table-column>
        <template v-slot:detail="props">
          <b-table
            ref="table"
            v-model:checked-rows="checkedInnerRows"
            :data="logins(props.row.id)"
            :loading="loginsLoading"
            checkable
            paginated
            per-page="10"
          >
            <b-table-column
              v-slot="props"
              :label="$tf('activeLoginsList.table.created|Belépés')"
              field="created"
              sortable
            >
              {{ formatDate(props.row.created) }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              :label="$tf('activeLoginsList.table.location|Helyszín')"
              field="location"
              sortable
            >
              {{ props.row.location }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              :label="$tf('activeLoginsList.table.device|Operációs rendszer')"
              field="device"
              sortable
            >
              {{ props.row.device }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              :label="$tf('activeLoginsList.table.browser|Böngésző')"
              field="browser"
              sortable
            >
              {{ props.row.browser }}
            </b-table-column>

            <template v-slot:empty>
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="smile-beam" size="is-large"></b-icon>
                  </p>
                  <p>
                    {{
                      $tf(
                        "activeLoginsList.table.empty|Jelenleg nincs aktív bejelentkezés"
                      )
                    }}
                  </p>
                </div>
              </section>
            </template>
          </b-table>
        </template>

        <template v-slot:bottom-left>
          <b-button
            :disabled="checkedInnerRows.length === 0"
            class="is-danger"
            @click="revoke()"
            >{{
              $tf("activeLoginsList.table.clearSelected|Kijelöltek törlése")
            }}
          </b-button>
        </template>

        <template v-slot:empty>
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon icon="smile-beam" size="is-large"></b-icon>
              </p>
              <p>
                {{
                  $tf(
                    "activeLoginsList.table.empty|Jelenleg nincs aktív bejelentkezés"
                  )
                }}
              </p>
            </div>
          </section>
        </template>
      </b-table>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDate, getDisplayName } from "@/utils/util";

export default {
  name: "ActiveLoginsList",
  async mounted() {
    this.employeeLoading = true;
    //await this.$store.dispatch("employee/fetch");
    this.employeeLoading = false;
    await this.getActiveLogins();
  },
  data() {
    return {
      formatDate,
      checkedRows: [],
      checkedInnerRows: [],
      employeeLoading: false,
      loginsLoading: false,
      sort: {
        direction: "asc",
        field: "name",
      },
    };
  },
  watch: {
    checkedRows() {
      let rows = [];
      this.checkedRows.forEach((employee) => {
        rows = rows.concat(this.logins(employee.id));
      });
      this.checkedInnerRows = rows;
    },
    checkedInnerRows() {
      this.filteredEmployees.forEach((employee) => {
        if (
          this.checkedInnerRows.filter((row) => row.userId === employee.id)
            .length === this.logins(employee.id).length
        ) {
          let index = this.checkedRows.indexOf(employee);
          if (index === -1) {
            this.checkedRows.push(employee);
          }
        } else {
          let index = this.checkedRows.indexOf(employee);
          if (index !== -1) {
            this.checkedRows.splice(index, 1);
          }
        }
      });
    },
  },
  computed: {
    filteredEmployees() {
      let filtered = this.employees.filter((employee) => {
        return this.logins(employee.id) && this.logins(employee.id).length > 0;
      });
      let sorted = [];
      if (this.sort.field === "count") {
        sorted = filtered.sort(
          (a, b) => this.logins(a.id).length < this.logins(b.id).length
        );
        sorted = sorted.reverse();
      } else {
        sorted = filtered.sort(
          (a, b) => a[this.sort.field] < b[this.sort.field]
        );
      }
      if (this.sort.order === "asc") sorted = sorted.reverse();
      return sorted;
    },
    mappedIds() {
      return this.checkedInnerRows.map((row) => row.generation);
    },
    ...mapGetters({
      logins: "session/sessionData",
      employees: "employee/employees",
      currentSession: "session/isCurrentSession",
    }),
  },
  methods: {
    async getActiveLogins() {
      this.loginsLoading = true;
      await this.$store.dispatch("session/fetchAllSessionData");
      //      this.pagination = this.toApproveRequests.pagination;
      this.checkedRows = [];
      this.loginsLoading = false;
    },
    async revoke() {
      this.openPermissionConfirm(
        this.$buefy,
        async () => {
          await this.$store
            .dispatch("session/logout", this.mappedIds)
            .then(async () => {
              if (this.currentSession) await this.$router.push("/login");
              else {
                await this.getActiveLogins();
              }
            });
        },
        this.$tf(
          `activeLoginsList.revoke.message|Biztos vagy benne, hogy <b>törlöd</b> a kijelölt sessiönöket? (${this.mappedIds.length} db)`
        ),
        "is-danger"
      );
    },
    openPermissionConfirm(buefy, onConfirmCallback, message, type) {
      buefy.dialog.confirm({
        title: this.$tf(
          "activeLoginsList.openPermissionConfirm.title|Megerősítés"
        ),
        message: message,
        confirmText: this.$tf(
          "activeLoginsList.openPermissionConfirm.confirmText|Igen"
        ),
        cancelText: this.$tf(
          "activeLoginsList.openPermissionConfirm.cancelText|Mégsem"
        ),
        type: type,
        hasIcon: true,
        onConfirm: async () => onConfirmCallback(),
      });
    },
    getDisplayName(user) {
      return getDisplayName(this.employees, user);
    },
    sendPasswords() {
      this.$store.dispatch("adminConfig/sendPasswords");
    },
  },
};
</script>

<style scoped></style>
