<template>
  <b-tooltip
    v-if="tooltip"
    :label="tooltip"
    :position="tooltipPosition"
    :type="type"
    append-to-body
  >
    <button :disabled="disabled" class="clickable-icon-button">
      <b-icon
        :disabled="disabled"
        :icon="icon"
        :pack="pack"
        :size="size"
        :type="type"
        custom-class="clickable-icon"
      />
    </button>
  </b-tooltip>
  <button v-else :disabled="disabled" class="clickable-icon-button">
    <b-icon
      :disabled="disabled"
      :icon="icon"
      :pack="pack"
      :size="size"
      :type="type"
      custom-class="clickable-icon"
    />
  </button>
</template>

<script>
export default {
  name: "ClickableIcon",
  components: {},
  props: {
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "is-small",
    },
    type: {
      type: String,
      default: "is-primary",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
    },
    tooltipPosition: {
      type: String,
      default: "is-top",
    },
    pack: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors.scss";

.clickable-icon-button {
  background: transparent;
  border: none;
  padding: 0;

  &:not(:disabled) {
    cursor: pointer;

    :hover {
      .icon,
      .clickable-icon {
        color: $grey-dark;
      }
    }
  }

  &:disabled {
    cursor: not-allowed;

    .icon,
    .clickable-icon {
      color: $grey;
    }
  }
}
</style>
