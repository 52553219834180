<template>
  <div class="onboarding-step-page-main">
    <div class="onboarding-step-page-content">
      <h2>{{ $tf("onboarding.companyProfile.title|Cég kiválasztása") }}</h2>
      <p>
        {{
          $tf(
            "onboarding.companyProfile.subtitle|Ennek segítségével segíthetünk egy személyreszabottabb konfigurálási folyamat létrehozásában"
          )
        }}
      </p>
      <div class="company-profile-grid">
        <div
          v-for="profileTemplate in profileTemplates"
          :key="profileTemplate.id"
          :style="{
            borderColor: profileTemplate.color + '77',
            borderWidth: '2px',
            background: profileTemplate.color + '02',
          }"
          class="is-flex is-justify-content-space-between"
          @click="selectCompanyProfile(profileTemplate.id)"
        >
          <div>
            <div class="has-text-left">
              <b-icon
                :icon="profileTemplate.icon"
                :style="{ color: profileTemplate.color }"
                class="profile-icon"
              />
            </div>
            <div>
              {{ profileTemplate.name }}
            </div>
          </div>
          <div
            :class="{
              'select-circle-active': profileTemplate.id === selectedProfileId,
            }"
            class="select-circle has-font-weight-700 is-flex is-justify-content-center is-align-items-center"
          >
            <b-icon
              v-if="profileTemplate.id === selectedProfileId"
              class="has-text-white"
              icon="check"
              size="is-small"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="onboarding-step-page-footer">
      <div class="ml-auto">
        <b-button
          :disabled="selectedProfileId === null"
          class="onboarding-button onboarding-button-small"
          type="is-info"
          @click="saveCompanyProfile"
        >
          {{ $tf("onboarding.companyProfile.continue|Tovább") }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ONBOARDING_PAGES } from "@/utils/const";

export default {
  name: "OnboardingCompanyProfile",
  data() {
    return {
      selectedProfileId: null,
    };
  },
  methods: {
    selectCompanyProfile(profileId) {
      this.selectedProfileId = profileId;
    },
    async saveCompanyProfile() {
      let selectedTemplate = this.profileTemplates.find(
        (t) => t.id === this.selectedProfileId
      );

      await this.$store.dispatch(
        "onboarding/updateRoles",
        selectedTemplate.roles
      );
      await this.$store.dispatch(
        "onboarding/updateTiers",
        selectedTemplate.tiers
      );
      await this.$store.dispatch(
        "onboarding/updateCompetencies",
        selectedTemplate.competencies
      );
      this.$emit("nextPage", ONBOARDING_PAGES.SETUP_ROLES);
    },
  },
  computed: {
    profileTemplates() {
      return this.$store.getters["onboarding/getTemplates"];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.company-profile-grid {
  width: 100%;
  max-width: 500px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 16px;
}

.company-profile-grid > div {
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  padding: 12px;
  cursor: pointer;
}

.select-circle {
  width: 24px;
  height: 24px;
  border-radius: 50px;
  border: 1px solid #cccccc;
  transition: all 0.3s;
}

.select-circle-active {
  border: 1px solid #aaaaaa;
  background-color: $blue;
}

.profile-icon {
  border-radius: 500px;
  width: 48px;
  height: 48px;
  padding: 4px;
  background-color: #eeeeee;
}
</style>
