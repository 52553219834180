<template>
  <b-tab-item
    :label="$tf('employees.tabs.teams|Csapatok')"
    icon="users"
    type="is-primary"
  >
    <b-table
      ref="table"
      :aria-current-label="$tf('aria.current.label|Aktuális lap')"
      :aria-next-label="$tf('aria.next.label|Következő lap')"
      :aria-page-label="$tf('aria.page.label|Lap')"
      :aria-previous-label="$tf('aria.previous.label|Előző lap')"
      :data="teams"
      :show-detail-icon="true"
      detail-key="identifier"
      detailed
      paginated
      per-page="30"
    >
      <!--
      <b-table-column
        field="identifier"
        :label="$tf('employees.table.identifier|Azonosító')"
        v-slot="props"
        sortable
        searchable
      >
        {{ props.row.identifier }} <br />
      </b-table-column>
      -->
      <b-table-column
        v-slot="props"
        :label="$tf('employees.table.teamName|Csapat név')"
        field="name"
        searchable
        sortable
      >
        {{ props.row.name }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="$tf('employees.table.teamSize|Létszám')"
        field="teamSize"
        searchable
      >
        {{ getTeamSize(props.row) }}
      </b-table-column>

      <template #detail="props">
        <div class="container content">
          <div
            v-for="leadType in [
              $tf('team.financialLead|Financial lead'),
              $tf('team.resourceLead|Resource lead'),
            ]"
            :key="leadType"
          >
            <p class="subtitle">{{ leadType }}</p>
            <div class="is-flex is-flex-wrap-wrap has-gap-4 mb-5">
              <template v-if="!hasLead(props.row, leadType.toUpperCase())">
                <div class="is-flex-1 content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="frown" size="is-large"></b-icon>
                  </p>
                  <p>
                    {{
                      $tf(
                        "employees.teams.noLeadYet|Még nincsen {leadType} a csapathoz rendelve",
                        { leadType: leadType.toLowerCase() }
                      )
                    }}
                  </p>
                </div>
              </template>
              <template v-else>
                <template v-for="lead in props.row.leads">
                  <div
                    v-if="lead.role === leadType.toUpperCase()"
                    :key="lead.userId"
                  >
                    <UserInfo
                      :user="lead.userData"
                      displayMode="full"
                      size="64x64"
                      tooltipPosition="bottom"
                    />
                  </div>
                </template>
              </template>
            </div>
          </div>

          <p class="subtitle">
            {{ $tf("employees.teams.teamMembers|Csapattagok:") }}
          </p>
          <div class="is-flex is-flex-wrap-wrap has-gap-4">
            <template v-if="props.row.employees.length === 0">
              <div class="is-flex-1 content has-text-grey has-text-centered">
                <p>
                  <b-icon icon="frown" size="is-large"></b-icon>
                </p>
                <p>
                  {{
                    $tf(
                      "employees.teams.noMembersYet|Még nincsenek csapattagok a csapathoz rendelve"
                    )
                  }}
                </p>
              </div>
            </template>
            <template v-else>
              <div
                v-for="emp in props.row.employees"
                :key="emp.userId"
                class=""
              >
                <UserInfo
                  :user="emp.userData"
                  displayMode="full"
                  tooltipPosition="bottom"
                />
              </div>
            </template>
          </div>
        </div>
      </template>
    </b-table>
  </b-tab-item>
</template>

<script>
import UserInfo from "@/components/module/info/UserInfo.vue";

export default {
  name: "EmployeeListTeamTab",
  components: { UserInfo },
  props: {
    teams: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getTeamSize(row) {
      let userIds = [];
      row.leads.forEach((leads) => {
        let userId = leads.userId;
        if (!userIds.includes(userId)) {
          userIds.push(userId);
        }
      });
      row.employees.forEach((employee) => {
        let userId = employee.userId;
        if (!userIds.includes(userId)) {
          userIds.push(userId);
        }
      });
      return userIds.length;
    },
    hasLead(team, type) {
      return team.leads.some((lead) => lead.role === type);
    },
  },
};
</script>
