<template>
  <section class="section">
    <div class="container">
      <div class="is-flex">
        <div>
          <h1 class="title">{{ $tf("roles.title|Jogosultságok") }}</h1>
          <h2 class="subtitle">
            {{
              $tf(
                "roles.subtitle|A rendszer használatához köthető jogosultságok beállítása, módosítása"
              )
            }}
          </h2>
        </div>
        <div class="ml-auto">
          <b-button
            class="ml-auto"
            icon-left="plus-circle"
            type="is-info"
            @click="openCreateModal()"
            >{{ $tf("roles.add|Új jogosultsági szerepkör") }}
          </b-button>
        </div>
      </div>
    </div>

    <div class="container">
      <div>
        <b-table :data="roles" :loading="loading">
          <b-table-column
            v-slot="props"
            :label="$tf('roles.table.name|Megnevezés')"
            field="name"
          >
            {{ props.row.name }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            :label="$tf('roles.table.actions|Műveletek')"
            centered
            field="actions"
            width="80"
          >
            <b-tooltip
              :label="$tf('roles.table.actions.edit|Szerkesztés')"
              type="is-light"
            >
              <clickable-icon
                icon="pencil"
                type="is-info"
                @click="openEditModal(props.row)"
              />
            </b-tooltip>
            <b-tooltip
              :label="$tf('roles.table.actions.delete|Törlés')"
              type="is-light"
            >
              <clickable-icon
                icon="trash"
                type="is-danger"
                @click="openDeleteModal(props.row)"
              />
            </b-tooltip>
          </b-table-column>
        </b-table>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import ClickableIcon from "@/components/module/icon/ClickableIcon.vue";
import RoleEditorModal from "@/views/admin-config/RoleEditorModal.vue";
import { openDeleteConfirm } from "@/utils/util";

export default {
  name: "RoleEditor",
  components: { ClickableIcon },
  async mounted() {
    await this.fetch();
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      rolesGetter: "role/list",
    }),
    roles() {
      return this.rolesGetter || [];
    },
  },
  methods: {
    async fetch(force = false) {
      this.loading = true;
      await this.$store.dispatch("role/list", force);
      this.loading = false;
    },
    openCreateModal() {
      this.$buefy.modal.open({
        parent: this,
        component: RoleEditorModal,
        hasModalCard: true,
        trapFocus: true,
        fullScreen: true,
        events: {
          fetch: () => {
            this.fetch(true);
          },
        },
      });
    },
    openEditModal(data) {
      this.$buefy.modal.open({
        parent: this,
        component: RoleEditorModal,
        props: {
          data,
        },
        hasModalCard: true,
        trapFocus: true,
        fullScreen: true,
        events: {
          fetch: () => {
            this.fetch(true);
          },
        },
      });
    },
    openDeleteModal(id) {
      openDeleteConfirm(this.$buefy, () => this.delete(id));
    },
    async delete(id) {
      await this.$store.dispatch("role/delete", id);
      await this.fetch(true);
    },
  },
};
</script>
