<template>
  <div class="mt-5">
    <div>
      <h3 class="title">
        {{ $tf("projectPage.progressHistory.title|Készültség módosítások") }}
      </h3>
    </div>
    <b-table
      ref="progressHistoryTable"
      :aria-current-label="$tf('aria.current.label|Aktuális lap')"
      :aria-next-label="$tf('aria.next.label|Következő lap')"
      :aria-page-label="$tf('aria.page.label|Lap')"
      :aria-previous-label="$tf('aria.previous.label|Előző lap')"
      :data="projectDetails.progressHistory"
      :per-page="15"
      paginated
    >
      <b-table-column
        v-slot="props"
        :label="$tf('projectPage.progressHistory.table.timestamp|Időpont')"
        field="timestamp"
        sortable
      >
        {{ formatDateTime(new Date(props.row.timestamp)) }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="$tf('projectPage.progressHistory.table.progress|Készültség')"
        field="progress"
        sortable
      >
        {{ percentify(props.row.progress) }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="$tf('projectPage.progressHistory.table.userId|Szerkesztő')"
        field="userId"
        sortable
      >
        {{ props.row.userId }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="$tf('projectPage.progressHistory.table.summary|Megjegyzés')"
        field="summary"
        sortable
      >
        <span v-if="props.row.summary">{{ props.row.summary }}</span>
        <span v-else>{{
          $tf(
            "projectPage.progressHistory.table.summary.empty|Nincs megjegyzés"
          )
        }}</span>
      </b-table-column>

      <template #empty>
        <p>
          {{
            $tf(
              "projectPage.progressHistory.table.empty|Még nem volt készültség-módosítás"
            )
          }}
        </p>
      </template>
    </b-table>
  </div>
</template>

<script>
import { formatDate, formatDateTime, moneyify, percentify } from "@/utils/util";

export default {
  name: "ProgressHistory",
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formatDate,
      formatDateTime,
      moneyify,
      percentify,
    };
  },
};
</script>
