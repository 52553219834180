<template>
  <div class="container">
    <h3 class="heading">
      {{ $tf("execDashboard.startingProjects.title|Induló projektek") }}
    </h3>

    <b-table-with-loading
      :data="startingProjects"
      default-sort="dueDate"
      default-sort-direction="asc"
      hoverable
      scrollable
    >
      <b-table-column
        v-slot="props"
        :label="
          $tf('execDashboard.startingProjects.table.projectName|Projekt név')
        "
        field="name"
      >
        <router-link :to="`/projects/project/${props.row.identifier}`">
          {{ props.row.name }}
        </router-link>
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="
          $tf(
            'execDashboard.startingProjects.table.expectedIncome|Várható bevétel'
          )
        "
        field="expectedIncome"
        sortable
        >{{ moneyify(props.row.expectedIncome) }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="$tf('execDashboard.startingProjects.table.budget|Büdzsé')"
        field="budget"
        sortable
        >{{ moneyify(props.row.budget) }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="$tf('execDashboard.startingProjects.table.profit|Profit')"
        field="profit"
        sortable
        >{{ moneyify(props.row.profit) }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="
          $tf('execDashboard.startingProjects.table.profitPercentage|Profit%')
        "
        field="profitPercentage"
        sortable
        >{{ percentify(props.row.profitPercentage) }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="$tf('execDashboard.startingProjects.table.dueDate|Határidő')"
        field="dueDate"
        sortable
        >{{ formatDate(props.row.dueDate) }}
      </b-table-column>

      <template #empty>
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="frown" size="is-large"></b-icon>
            </p>
            <p>
              {{
                $tf(
                  "execDashboard.startingProjects.empty|Nincs a megadott szűrési feltételeknek megfelelő induló projekt."
                )
              }}
            </p>
          </div>
        </section>
      </template>
    </b-table-with-loading>
  </div>
</template>

<script>
import { formatDate, moneyify, percentify } from "@/utils/util";
import { defineAsyncComponent } from "vue";

export default {
  name: "ExecutiveDashboardStartingProjects",
  methods: { formatDate, percentify, moneyify },
  components: {
    BTableWithLoading: defineAsyncComponent(() =>
      import("@/components/loading/BTableWithLoading.vue")
    ),
  },
  props: {
    startingProjects: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped></style>
