import network from "../../../../utils/network";
import { newLogger } from "@/utils/util";

let logger = newLogger("Enterprise-IssueStore");
let generateRequestId = function () {
  return Math.floor(Math.random() * 100000);
};

const urls = {
  issueExport: (key, onlyTime) =>
    `/enterprise/issues/export/${key}?onlyTime=${onlyTime}`,
};

const initState = () => {
  return {};
};

const actions = {
  export: async ({ dispatch }, { key: key, onlyTime: onlyTime }) => {
    let requestId = generateRequestId();
    dispatch(
      "loading/startLoading",
      {
        id: requestId,
        type: "GET",
        url: urls.issueExport(key, onlyTime),
        flair: "Jegyek exportálása...",
      },
      { root: true }
    );

    try {
      const axios = await network.excelConnection();
      const { data } = await axios.get(urls.issueExport(key, onlyTime));

      dispatch(
        "loading/finishLoading",
        { id: requestId, result: true },
        { root: true }
      );

      return data;
    } catch (err) {
      logger.error(err);
      dispatch(
        "loading/finishLoading",
        { id: requestId, result: false },
        { root: true }
      );
    }
  },
};

const mutations = {};

const getters = {};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
