<template>
  <div class="modal-card" style="width: auto">
    <div class="container" style="width: 30rem">
      <section class="modal-card-body">
        <h1 v-if="isCreate" class="title">Új jogosultság létrehozása</h1>
        <h1 v-else class="title">Jogosultság módosítása</h1>

        <form @submit.prevent="submit">
          <b-field class="mt-5">
            <vuelidated-input
              v-model="editData.name"
              :validation-rule="v$.editData.name"
              label="Név"
              label-position="on-border"
              placeholder="Név"
            />
          </b-field>

          <b-field class="mt-5 is-pulled-right">
            <b-button @click="close"> Mégse</b-button>
            <b-button class="ml-3" native-type="submit" type="is-info">
              Mentés
            </b-button>
          </b-field>
        </form>
      </section>
    </div>
  </div>
</template>

<script>
import { maxLength, required } from "@vuelidate/validators";
import VuelidatedInput from "@/components/module/input/VuelidatedInput";
import { getCurrentInstance } from "vue";
import useCustomVuelidate from "@/plugins/vuelidate";

export default {
  name: "UserPermissionForm",
  components: { VuelidatedInput },
  setup: () => {
    const instance = getCurrentInstance();
    const { v$, touch$ } = useCustomVuelidate();
    return {
      v$,
      touch$,
      instance,
    };
  },
  props: {
    permission: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      name: "",
      editData: {
        name: "",
      },
      isCreate: true,
    };
  },
  mounted() {
    if (this.permission) {
      this.isCreate = false;
      this.name = this.permission.name;
      this.editData = JSON.parse(JSON.stringify(this.permission));
    } else {
      this.isCreate = true;
    }
  },
  validations: {
    editData: {
      name: {
        required,
        maxLength: maxLength(255),
      },
    },
  },
  methods: {
    close() {
      this.instance.emit("close");
    },
    afterSave() {
      this.close();
      this.$store.dispatch("user_permission/fetch", true);
    },
    submit() {
      this.touch$();
      if (this.v$.$invalid) {
        return;
      }

      if (this.isCreate) {
        this.$store
          .dispatch("user_permission/create", this.editData)
          .then(() => {
            this.afterSave();
          });
      } else {
        this.$store
          .dispatch("user_permission/update", this.editData)
          .then(() => {
            this.afterSave();
          });
      }
    },
  },
};
</script>
