<template>
  <section class="section">
    <div class="container is-flex">
      <div>
        <h1 class="title">{{ $tf("forecast.segment.title|Szegmensek") }}</h1>
        <h2 class="subtitle">
          {{ $tf("forecast.segment.subtitle|Szegmensek beállítása") }}
        </h2>
      </div>
      <b-button
        v-if="canCreate"
        class="ml-auto"
        icon-left="plus-circle"
        type="is-info"
        @click="openEdit(null)"
      >
        {{ $tf("forecast.segment.newButton|Új szegmens felvétele") }}
      </b-button>
    </div>

    <div class="container">
      <b-table
        ref="table"
        :data="segments ? segments.items : []"
        :loading="!loaded"
        :per-page="pagination.size"
        :total="segments ? segments.total : 0"
        backend-filtering
        backend-pagination
        backend-sorting
        default-sort="name"
        default-sort-direction="desc"
        height="auto"
        hoverable
        mobile-cards
        paginated
        sticky-header
        striped
        @sort="onSort"
        @page-change="onPageChange"
        @filters-change="onFilterChange"
      >
        <b-table-column
          v-slot="props"
          :label="$tf('forecast.segment.nameTableHeader|Név')"
          field="name"
          searchable
          sortable
        >
          <label class="has-font-weight-500">{{ props.row.name }}</label>
        </b-table-column>

        <b-table-column
          v-slot="props"
          :label="$tf('forecast.segment.filtersTableHeader|Szűrés feltételek')"
          field="filters"
          searchable
          sortable
        >
          <div
            class="is-flex is-flex-direction-column is-align-items-flex-start has-gap-2"
          >
            <div v-for="(items, key, index) in props.row.filters" :key="index">
              <template v-if="!isEmpty(items)">
                <strong>{{ $tf(labelFor(key)) }}:</strong>
                {{ ruleValues(items) }}
              </template>
            </div>
          </div>
        </b-table-column>

        <b-table-column
          v-slot="props"
          :visible="canEdit"
          cell-class="do-not-wrap"
          width="32"
        >
          <div class="is-flex has-gap-3 pr-5">
            <clickable-icon
              v-if="canEdit"
              :tooltip="$tf('forecast.segment.editTooltip|Szerkesztés')"
              class="mr-2"
              icon="pencil"
              type="is-info"
              @click="openEdit(props.row)"
            ></clickable-icon>
            <clickable-icon
              v-if="canDelete"
              :tooltip="$tf('forecast.segment.deleteTooltip|Törlés')"
              icon="trash"
              type="is-danger"
              @click="deleteRow(props.row.id)"
            ></clickable-icon>
          </div>
        </b-table-column>

        <template #empty>
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon icon="frown" size="is-large"></b-icon>
              </p>
              <p>
                {{
                  $tf(
                    "forecast.segment.emptyTable|Még nincsenek szegmensek felvéve."
                  )
                }}
              </p>
            </div>
          </section>
        </template>
        <template #bottom-left>
          <div
            class="is-flex has-gap-3 is-align-items-center has-text-dark-blue"
          >
            <span>{{
              $tf("forecast.segment.table.pageSize|Oldal mérete")
            }}</span>
            <div id="size-selector">
              <b-select
                v-model="pagination.size"
                size="is-small"
                @update:modelValue="load"
              >
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="50">50</option>
                <option :value="100">100</option>
              </b-select>
            </div>
            <span
              v-html="
                $tf(
                  'forecast.segment.table.count|Összesen: <strong>{count}</strong>',
                  {
                    count: pagination.totalCount,
                  }
                )
              "
            ></span>
          </div>
        </template>
      </b-table>
    </div>
  </section>
</template>

<script>
import {
  FORECAST_RULES,
  PRIVILEGES,
  PROJECT_BASE_DATA_TYPE,
} from "@/utils/const";
import { mapGetters } from "vuex";

import ClickableIcon from "@/components/module/icon/ClickableIcon.vue";
import ForecastSegmentEditModal from "@/components/forecast/edit/ForecastSegmentEditModal.vue";

import {
  deepCopy,
  generateArraySummaryTranslated,
  openDeleteConfirm,
} from "@/utils/util";
import debounce from "lodash.debounce";
import { isEmpty } from "lodash";

export default {
  name: "ForecastSegment",
  data() {
    return {
      PROJECT_BASE_DATA_TYPE,
      FORECAST_RULES,
      loaded: false,
      pagination: {
        totalCount: 0,
        page: 0,
        size: 10,
      },
      sort: {
        field: "name",
        order: "desc",
      },
      filter: {},
      componentKey: 0,
    };
  },
  components: {
    ClickableIcon,
  },
  computed: {
    ...mapGetters({
      segments: "forecast_segment/segments",
      employees: "employee/currentEmployees",
    }),
    canCreate() {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ENTERPRISE.FORECAST.SEGMENT.CREATE,
        PRIVILEGES.ENTERPRISE.FORECAST.MISC.ADMIN
      );
    },
    canEdit() {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ENTERPRISE.FORECAST.SEGMENT.EDIT,
        PRIVILEGES.ENTERPRISE.FORECAST.MISC.ADMIN
      );
    },
    canDelete() {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ENTERPRISE.FORECAST.SEGMENT.DELETE,
        PRIVILEGES.ENTERPRISE.FORECAST.MISC.ADMIN
      );
    },
  },
  methods: {
    isEmpty,
    labelFor(key) {
      return FORECAST_RULES.find((it) => it.enum === key)?.label;
    },
    ruleValues(items) {
      return generateArraySummaryTranslated.call(
        this,
        items.map((it) => it.name)
      );
    },
    getUserName(id) {
      const entry = this.employees.find((it) => it.id === id);
      if (entry) {
        return entry.name;
      }
      return id;
    },
    joinToString(array) {
      if (!array) return "";
      return array.map((it) => this.getUserName(it)).join(", ");
    },
    openEdit(row) {
      this.$buefy.modal.open({
        parent: this,
        component: ForecastSegmentEditModal,
        props: { segment: row },
        hasModalCard: true,
        trapFocus: true,
        events: {
          save: () => this.load(true),
        },
      });
    },
    async deleteRow(id) {
      openDeleteConfirm(this.$buefy, async () => {
        await this.$store.dispatch("forecast_segment/deleteSegment", id);
        await this.load(true);
      });
    },
    async load(force) {
      this.loaded = false;

      const { page, size } = this.pagination;
      const sort =
        this.sort.field !== null
          ? `${this.sort.field},${this.sort.order}`
          : null;
      let params = new URLSearchParams();
      params.append("page", page);
      params.append("size", size);
      params.append("sort", sort);

      for (const [key, value] of Object.entries(this.filter)) {
        params.append(key, value);
      }
      let requestParams = { params: params };
      await this.$store.dispatch("forecast_segment/fetchSegments", {
        force,
        params: requestParams,
      });

      if (this.scenarios) {
        this.pagination = this.scenarios.pagination;
        this.scenarios.items.forEach(
          (it) => (it.supervisorDisplayName = this.getUserName(it.supervisor))
        );
      }
      this.componentKey++;
      this.loaded = true;
    },

    onPageChange: function (page) {
      this.pagination.page = page - 1;
      this.load();
    },
    onSort: function (field, order) {
      this.sort.field = field;
      this.sort.order = order;
      this.load();
    },
    onFilterChange: debounce(
      function (filter) {
        this.filter = filter;
        this.load();
      },
      500,
      { leading: true }
    ),
    removeFilter: function (filteredField) {
      this.$refs.table.filters[filteredField] = null;
      this.filter[filteredField] = null;
      this.load();
    },
    async save(row, quarter) {
      const request = deepCopy(row);
      request[`q${quarter}Closed`] = !request[`q${quarter}Closed`];

      await this.$store.dispatch("forecast_segment/updateSegment", request);
      await this.load(true);
    },
  },
  async mounted() {
    // await this.$store.dispatch("employee/fetchColleagues");
    await this.load();
    this.loaded = true;
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/colors";

.link.is-danger {
  color: $red;
}

.red {
  color: $red;
}

.cost-label {
  color: $custom-grayer-blue;
}

.cost-value {
  font-weight: 500;
  color: $custom-dark-blue;
}

.cost-since {
  color: $custom-grayer-blue;
  font-size: 14px;
}

.tier-toggle {
  :deep(.has-text-link) {
    color: $custom-dark-blue !important;
  }
}

.users-without-tiers-container {
  h1.title {
    color: $red;
    font-size: 1.1rem;
  }
}

.fixed-price-margin {
  margin-bottom: 2rem;
}
</style>
