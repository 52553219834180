<template>
  <div class="modal-card logBarModal">
    <self-tracking-log-bar
      :key="logBarKey"
      :selected-issue="selectedIssue"
      @finished="onFinish"
    ></self-tracking-log-bar>
  </div>
</template>

<script>
import { getCurrentInstance } from "vue";
import SelfTrackingLogBar from "@/components/timesheet/log/SelfTrackingLogBar.vue";

export default {
  name: "SelfTrackingLogModal",
  components: { SelfTrackingLogBar },
  props: {
    selectedIssue: {
      type: Object,
      required: true,
    },
  },
  setup: () => {
    const instance = getCurrentInstance();
    return {
      instance: instance,
    };
  },
  data() {
    return {
      logBarKey: 0,
    };
  },
  computed: {},
  methods: {
    onFinish() {
      ++this.logBarKey;
      this.instance.emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.logBarModal {
  overflow-y: auto;
  width: 100%;
}
</style>
