<template>
  <div class="container">
    <h3 class="heading">
      {{ $tf("execDashboard.doneProjects.title|Kész projektek") }}
    </h3>
    <b-table-with-loading
      :data="doneProjects"
      default-sort="endDate"
      default-sort-direction="desc"
      hoverable
      scrollable
    >
      <b-table-column
        v-slot="props"
        :label="$tf('execDashboard.doneProjects.table.projectName|Projekt név')"
        field="name"
      >
        <router-link :to="`/projects/project/${props.row.identifier}`">
          {{ props.row.name }}
        </router-link>
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="$tf('execDashboard.doneProjects.table.income|Bevétel')"
        field="income"
        sortable
        >{{ moneyify(props.row.income) }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="$tf('execDashboard.doneProjects.table.budget|Büdzsé')"
        field="budget"
        sortable
        >{{ moneyify(props.row.budget) }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="$tf('execDashboard.doneProjects.table.spent|Költés')"
        field="spent"
        sortable
        >{{ moneyify(props.row.spent) }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="$tf('execDashboard.doneProjects.table.spentPercentage|Költés%')"
        field="spentPercentage"
        sortable
        >{{ percentify(props.row.spentPercentage) }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="$tf('execDashboard.doneProjects.table.overspending|Túlköltés')"
        field="overspending"
        sortable
        >{{ moneyify(props.row.overspending) }}
      </b-table-column>

      <b-table-column v-slot="props" field="profit" label="Profit" sortable
        >{{ moneyify(props.row.profit) }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="
          $tf('execDashboard.doneProjects.table.profitPercentage|Profit%')
        "
        field="profitPercentage"
        sortable
        >{{ percentify(props.row.profitPercentage) }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="$tf('execDashboard.doneProjects.table.dueDate|Határidő')"
        field="dueDate"
        sortable
        >{{ formatDate(props.row.dueDate) }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="$tf('execDashboard.doneProjects.table.endDate|Befejezés')"
        field="endDate"
        sortable
        >{{ formatDate(props.row.endDate) }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="$tf('execDashboard.doneProjects.table.delay|Csúszás')"
        field="delay"
        sortable
        >{{ props.row.delay }}
      </b-table-column>

      <template #empty>
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="frown" size="is-large"></b-icon>
            </p>
            <p>
              {{
                $tf(
                  "execDashboard.doneProjects.empty|Nincs a megadott szűrési feltételeknek megfelelő kész projekt."
                )
              }}
            </p>
          </div>
        </section>
      </template>
    </b-table-with-loading>
  </div>
</template>

<script>
import { formatDate, moneyify, percentify } from "@/utils/util";
import { defineAsyncComponent } from "vue";

export default {
  name: "ExecutiveDashboardDoneProjects",
  components: {
    BTableWithLoading: defineAsyncComponent(() =>
      import("@/components/loading/BTableWithLoading.vue")
    ),
  },
  methods: { formatDate, percentify, moneyify },
  props: {
    doneProjects: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped></style>
