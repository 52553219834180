<template>
  <div>
    <ForecastFutureTable
      :project-details="projectDetails"
      class="is-flex-1"
    ></ForecastFutureTable>
  </div>
</template>

<script>
import ForecastFutureTable from "@/components/enterprise/project/module/forecast/ForecastFutureTable.vue";
import { mapGetters } from "vuex";
import { getCurrentColorSet } from "@/utils/util";

export default {
  name: "ForecastFutureStatusOverview",
  components: {
    ForecastFutureTable,
  },
  mixins: [],
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
    projectData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currentColors() {
      return getCurrentColorSet();
    },
    ...mapGetters({
      currencySymbol: "uiConfigStore/getCurrencySymbol",
    }),
    budgetData() {
      return {
        title: this.$tf(
          "projectPage.forecastFutureStatus.budgetChart.title|Hátralévő büdzsé / költés - ezer {currency}",
          { currency: this.currencySymbol }
        ),
        tooltip: this.$tf(
          "projectPage.forecastFutureStatus.budgetChart.tooltip|Az oszlopdiagram megmutatja, hogy a projekt hátralévő részében a várható költés hogyan fog alakulni a tervvel szemben. Optimális esetben a két előrejelzési adat a terv alatt van, vagy megegyezik vele. Amennyiben a költés előrejelzés meghaladja a tervezett büdzsé értékét, a diagram jelzi az egyes oszlopokon a különbséget."
        ),
      };
    },
    mwdData() {
      return {
        title: this.$tf(
          "projectPage.forecastFutureStatus.mwdChart.title|Munkaidő - MWD"
        ),
        tooltip: this.$tf(
          "projectPage.forecastFutureStatus.mwdChart.tooltip|Az oszlopdiagram megmutatja, hogy a projekt hátralévő részében a várható erőforrás ráfordítás hogyan fog alakulni a tervvel szemben. Optimális esetben a két előrejelzési adat a terv alatt van, vagy megegyezik vele. Amennyiben a ráfordítás előrejelzés meghaladja a tervezett ráfordítás értékét, a diagram jelzi az egyes oszlopokon a különbséget."
        ),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.is-flex-basis-0 {
  flex-basis: 0;
}
</style>
