<template>
  <div>
    <b-skeleton :animated="true" width="30%"></b-skeleton>
    <b-skeleton :animated="true" width="70%"></b-skeleton>
    <b-skeleton :animated="true" width="20%"></b-skeleton>
    <b-skeleton :animated="true" width="60%"></b-skeleton>
    <b-skeleton :animated="true" width="40%"></b-skeleton>
    <b-skeleton :animated="true" width="50%"></b-skeleton>
  </div>
</template>

<script>
export default {
  name: "LoadingSkeleton",
};
</script>
