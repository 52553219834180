<template>
  <section class="section">
    <div class="container">
      <h1 class="title">
        {{ $tf("absenceRequest.title|Távollét kérelmeim") }}
      </h1>
      <h2 class="subtitle">
        {{
          $tf(
            "absenceRequest.subtitle|Távollét kérelmek felvétele, visszavonása, kezelése."
          )
        }}
      </h2>
    </div>
    <div class="container">
      <div class="columns">
        <div class="column is-2">
          <div v-if="quota" class="box">
            {{
              $tf("absenceRequest.quota.all|Összes: {all}", {
                all: quota.quota,
              })
            }}
            <br />
            {{
              $tf("absenceRequest.quota.remaining|Fennmaradó: {remaining}", {
                remaining: quota.remaining,
              })
            }}
            <br />
            {{
              $tf("absenceRequest.quota.reserved|Lefoglalt: {reserved}", {
                reserved: quota.reserved,
              })
            }}
            <br />
            {{
              $tf("absenceRequest.quota.taken|Felhasznált: {taken}", {
                taken: quota.taken,
              })
            }}
          </div>
        </div>
        <div class="column is-4 is-offset-2">
          <b-collapse
            v-model="isCollapseOpen"
            animation="fade"
            aria-id="contentIdForA11y2"
            class="panel"
          >
            <template v-slot:trigger>
              <div
                aria-controls="contentIdForA11y2"
                class="panel-heading text-center"
                role="button"
              >
                <strong>{{
                  $tf("absenceRequest.button.newRequest|Új kérelem felvétele")
                }}</strong>
              </div>
            </template>
            <div class="column collapseBlock">
              <form @submit.prevent="submit">
                <b-field :expanded="true">
                  <vuelidated-form :validations="v$.formData.type">
                    <template v-slot="{ errors, invalid }">
                      <b-field
                        :expanded="true"
                        :message="v$.formData.type.$error ? errors[0] : null"
                        :type="{ 'is-danger': invalid }"
                        label-for="type"
                      >
                        <template #label>
                          {{ $tf("absenceRequest.form.type|Típus") }}
                          <span class="has-text-danger">*</span>
                        </template>
                        <b-select
                          v-model="absenceSelected"
                          :data="absenceTypes"
                          :expanded="true"
                          :placeholder="
                            $tf(
                              'absenceRequest.form.type.placeholder|Távollét típusa'
                            )
                          "
                          field="display"
                          @update:modelValue="
                            (option) => (formData.type = option)
                          "
                        >
                          <option
                            v-for="type in absenceTypes"
                            :key="type.id"
                            :value="type.id"
                          >
                            {{ type.name }}
                          </option>
                        </b-select>
                      </b-field>
                    </template>
                  </vuelidated-form>
                </b-field>

                <b-field expanded>
                  <vuelidated-input
                    v-model="formData.description"
                    :expanded="true"
                    :label="$tf('absenceRequest.form.description.label|Leírás')"
                    :placeholder="
                      $tf('absenceRequest.form.description.placeholder|Leírás')
                    "
                    :ref-value="'description'"
                    :validation-rule="v$.formData.description"
                  />
                </b-field>

                <b-field v-if="carryOverVisible">
                  <b-checkbox
                    v-model="formData.carryOver"
                    :disabled="!canCarryOver"
                    class="mr-0"
                  >
                    <strong>{{
                      $tf("absenceRequest.form.carryOver|Következő évre átvitt")
                    }}</strong>
                  </b-checkbox>

                  <b-tooltip
                    :label="
                      $tf(
                        'absenceRequest.form.carryOver.tooltip|Jelöld be, ha azt akarod hogy a korábbi (jelenlegi) évi kvótádból vonódjon le!'
                      )
                    "
                    class="ml-1"
                    style="font-size: 0.9rem"
                    type="is-light"
                  >
                    <b-icon icon="info-circle" />
                  </b-tooltip>
                </b-field>

                <b-field expanded>
                  <vuelidated-form :validations="v$.formData.dates">
                    <template v-slot="{ errors, invalid }">
                      <b-field
                        :message="v$.formData.dates.$error ? errors[0] : null"
                        :type="{ 'is-danger': invalid }"
                        label-for="dates"
                      >
                        <template #label>
                          {{ $tf("absenceRequest.form.days|Távollét napjai") }}
                          <span class="has-text-danger">*</span>
                        </template>
                        <f-datepicker
                          id="dates"
                          ref="dates"
                          v-model="formData.dates"
                          :events="carryOverDays"
                          :max-date="maxDate"
                          :min-date="minDate"
                          :placeholder="
                            $tf(
                              'absenceRequest.days.placeholder|Napok kiválasztása'
                            )
                          "
                          :unselectable-dates="disabledDates"
                          icon="calendar-alt"
                          indicators="bars"
                          multiple
                          name="dates"
                          @changeYear="onChangeYear"
                          @update:modelValue="onDateSelect"
                        />
                      </b-field>
                    </template>
                  </vuelidated-form>
                </b-field>

                <b-field>
                  <b-button native-type="submit" type="is-info">
                    {{ $tf("absenceRequest.button.submit|Küldés") }}
                  </b-button>
                </b-field>
              </form>
            </div>
          </b-collapse>
        </div>
      </div>

      <b-table
        v-if="absenceRequests"
        ref="table"
        :data="absenceRequests.items"
        :opened-detailed="defaultOpenedDetails"
        :per-page="pagination.size"
        :show-detail-icon="false"
        :total="pagination.totalCount"
        backend-pagination
        backend-sorting
        default-sort-direction="asc"
        detail-key="id"
        detailed
        hoverable
        mobile-cards
        paginated
        striped
        @sort="onSort"
        @details-open="(row) => closeAllOtherDetails(row)"
        @page-change="onPageChange"
      >
        <b-table-column
          v-slot="props"
          :label="$tf('absenceRequest.table.date|Kérelem dátuma')"
          field="created"
          sortable
        >
          <a v-if="props.row.hasComment" @click="toggleDetails(props.row)">
            {{ formatDate(props.row.created) }}
          </a>
          <span v-else>
            {{ formatDate(props.row.created) }}
          </span>
        </b-table-column>

        <b-table-column
          v-slot="props"
          :label="$tf('absenceRequest.table.type|Típus')"
          field="type"
          sortable
        >
          <a v-if="props.row.hasComment" @click="toggleDetails(props.row)">
            <b-tag :style="style(props.row.type.color)">
              {{ props.row.type.name }}
            </b-tag>
          </a>
          <span v-else>
            <b-tag :style="style(props.row.type.color)">
              {{ props.row.type.name }}
            </b-tag>
          </span>
        </b-table-column>

        <b-table-column
          v-slot="props"
          :label="$tf('absenceRequest.table.status|Státusz')"
          field="status"
          sortable
        >
          <a v-if="props.row.hasComment" @click="toggleDetails(props.row)">
            <b-tag
              :type="
                getStatusTagType(
                  props.row.status ? props.row.status.enum : null
                )
              "
            >
              <b-icon
                :icon="
                  getStatusTagIcon(
                    props.row.status ? props.row.status.enum : null
                  )
                "
                class="mr-1"
                icon-pack="fa"
                size="is-small"
              />
              {{ props.row.status ? props.row.status.display : "-" }}
            </b-tag>
          </a>
          <span v-else>
            <b-tag
              :type="
                getStatusTagType(
                  props.row.status ? props.row.status.enum : null
                )
              "
            >
              <b-icon
                :icon="
                  getStatusTagIcon(
                    props.row.status ? props.row.status.enum : null
                  )
                "
                class="mr-1"
                icon-pack="fa"
                size="is-small"
              />
              {{ props.row.status ? props.row.status.display : "-" }}</b-tag
            >
          </span>
        </b-table-column>

        <b-table-column
          v-slot="props"
          :label="$tf('absenceRequest.table.description|Leírás')"
          centered
          field="description"
        >
          <a v-if="props.row.hasComment" @click="toggleDetails(props.row)">
            {{ props.row.description }}
          </a>
          <span v-else>
            {{ props.row.description }}
          </span>
        </b-table-column>

        <b-table-column
          v-slot="props"
          :label="$tf('absenceRequest.table.dates|Dátum')"
          centered
          field="absenceRequestDates"
          sortable
          width="450"
        >
          <div
            class="is-flex is-flex-wrap-wrap has-gap-1 is-justify-content-center is-align-items-center"
          >
            <b-tooltip
              v-if="props.row.carryOver"
              :label="
                $tf(
                  'absenceRequest.table.carryOver.tooltip|Előző évről áthozott'
                )
              "
              class="mr-2"
              type="is-light"
            >
              <div class="is-flex is-align-items-center">
                <b-icon icon="clock-rotate-left" size="is-small" />
              </div>
            </b-tooltip>
            <b-tag
              v-for="(actDate, index) in props.row.absenceRequestDates"
              :key="index"
            >
              {{ formatDate(actDate) }}
            </b-tag>
          </div>
        </b-table-column>

        <b-table-column
          v-slot="props"
          :label="$tf('absenceRequest.table.actions|Műveletek')"
          centered
          field="actions"
        >
          <b-tooltip
            v-if="canRevoke(props.row.status ? props.row.status.enum : null)"
            :label="
              $tf('absenceRequest.table.tooltip.revoke|Kérelem visszavonása')
            "
            type="is-light"
          >
            <clickable-icon
              icon="times"
              type="is-danger"
              @click="revokeDialog(props.row.id)"
            />
          </b-tooltip>
        </b-table-column>

        <template #detail="props">
          <commented-approval
            :absence-request-id="props.row.id"
          ></commented-approval>
        </template>

        <template #bottom-left>
          {{ $tf("absenceRequest.table.pageSize|Oldal mérete") }}
          <div id="size-selector" class="ml-2 mr-2">
            <b-select
              v-model="pagination.size"
              size="is-small"
              @update:modelValue="getAbsenceRequests"
            >
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
              <option :value="100">100</option>
            </b-select>
          </div>
          <p>
            {{
              $tf("absenceRequest.table.pageCount|Összesen: {count}", {
                count: pagination.totalCount,
              })
            }}
          </p>
        </template>
      </b-table>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import CommentedApproval from "@/components/absence/admin/CommentedApproval";
import { formatDate, getContrastedColor, subtractDay } from "@/utils/util";
import { maxLength, required, requiredIf } from "@vuelidate/validators";
import VuelidatedInput from "@/components/module/input/VuelidatedInput";
import VuelidatedForm from "@/components/module/input/VuelidatedForm";
import ClickableIcon from "@/components/module/icon/ClickableIcon";
import { nextTick } from "vue";
import FDatepicker from "@/components/module/input/FDatepicker.vue";
import useCustomVuelidate from "@/plugins/vuelidate";

export default {
  name: "AbsenceRequest",
  components: {
    FDatepicker,
    ClickableIcon,
    VuelidatedForm,
    VuelidatedInput,
    CommentedApproval,
  },
  setup: () => useCustomVuelidate(),

  async mounted() {
    await this.$store.dispatch("absence_request/getTypes");
    await this.getAbsenceRequests();
    this.onChangeYear(new Date().getFullYear());
    await this.$store.dispatch("specialday/fetchCarryOverDates", {
      year: new Date().getFullYear() + 1,
    });
    this.setDisabledDates();
  },

  data() {
    return {
      formData: {
        type: null,
        description: null,
        dates: [],
        carryOver: false,
      },
      pagination: {
        totalCount: 0,
        page: 0,
        size: 10,
      },
      sort: {
        field: "created",
        order: "desc",
      },
      isCollapseOpen: false,
      absenceTypeSub: "",
      absenceType: null,
      absenceSelected: null,
      selectedDates: [],
      disabledDates: [],
      defaultOpenedDetails: [1],
      formatDate,
      years: new Set([new Date().getFullYear()]),
    };
  },

  validations: {
    formData: {
      type: {
        required,
        isSelectable() {
          return this.selectedType ? this.selectedType.selectable : true;
        },
      },
      description: {
        required: requiredIf(function () {
          return this.selectedType ? this.selectedType.mustHaveComment : false;
        }),
        maxLength: maxLength(255),
      },
      dates: {
        required,
      },
    },
  },

  computed: {
    minDate() {
      return !!this.selectedType?.canRequestInPast
        ? null
        : new Date(subtractDay(new Date(), 1));
    },
    maxDate() {
      let today = new Date();
      return new Date(today.setFullYear(today.getFullYear() + 1));
    },
    selectedType() {
      if (this.absenceTypes && this.absenceSelected) {
        return this.absenceTypes.find(
          (type) => type.id === this.absenceSelected
        );
      }
      return null;
    },
    filteredTypeArray() {
      return this.absenceTypeNames.filter((option) => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(this.absenceTypeSub?.toLowerCase()) >= 0
        );
      });
    },
    carryOverDays() {
      const today = new Date();
      let days = [];
      if (this.selectedType) {
        if (this.selectedType.quotaUsage === "CANT_CARRY_OVER") {
          return days;
        } else if (this.selectedType.quotaUsage === "JANUARY") {
          let date =
            today.getMonth() === 0
              ? today
              : new Date(today.getFullYear() + 1, 0, 1);
          while (date.getMonth() === 0) {
            days.push(new Date(date));
            date.setDate(date.getDate() + 1);
          }
        } else if (this.selectedType.quotaUsage === "LIMITLESS") {
          let date = new Date(today.getFullYear() + 1, 0, 1);
          while (date.getFullYear() === today.getFullYear() + 1) {
            days.push(new Date(date));
            date.setDate(date.getDate() + 1);
          }
        }
      }
      if (this.carryOverDates) {
        this.carryOverDates.forEach((carryOverDay) => {
          days.push(new Date(carryOverDay));
        });
      }
      return days;
    },
    carryOverVisible() {
      let carryOverDayStrings = this.carryOverDays.map((day) =>
        this.formatDate(day)
      );
      let dayStrings = this.formData.dates.map((day) => this.formatDate(day));
      return carryOverDayStrings.some((day) => dayStrings.includes(day));
    },
    canCarryOver() {
      if (!this.selectedType || this.selectedType === "CONTINUAL") {
        let carryOverDayStrings = this.carryOverDays.map((day) =>
          this.formatDate(day)
        );
        let dayStrings = this.formData.dates.map((day) => this.formatDate(day));
        dayStrings = dayStrings.sort();

        if (carryOverDayStrings.length < dayStrings.length) {
          return false;
        }

        for (let i = 0; i < dayStrings.length; i++) {
          let day = dayStrings[i];
          let carryOver = carryOverDayStrings[i];
          if (day !== carryOver) {
            return false;
          }
        }
      }

      return (
        this.formData.dates.length > 0 &&
        this.selectedType !== "CANT_CARRY_OVER"
      );
    },
    ...mapGetters({
      absenceRequests: "absence_request/mineAbsenceRequests",
      quota: "absence_request/quota",
      specialDaysGetter: "specialday/specialDaysAll",
      carryOverDatesGetter: "specialday/carryOverDates",
      absenceTypes: "absence_request/types",
    }),
    specialDays() {
      return this.specialDaysGetter(...this.years);
    },
    carryOverDates() {
      return this.carryOverDatesGetter(new Date().getFullYear() + 1);
    },
  },

  methods: {
    onChangeYear(year) {
      this.years.add(year);
      this.$store.dispatch("specialday/fetchSpecialDaysAll", {
        year,
      });
      this.setDisabledDates();
    },
    async getAbsenceRequests(force) {
      const { page, size } = this.pagination;
      const sort =
        this.sort.field !== null
          ? `${this.sort.field},${this.sort.order}`
          : null;

      let params = new URLSearchParams();
      params.append("page", page);
      params.append("size", size);
      params.append("sort", sort);

      let requestParams = { params: params };

      await this.$store.dispatch("absence_request/getMine", {
        params: requestParams,
        force,
      });
      this.pagination = this.absenceRequests.pagination;
      await this.$store.dispatch("absence_request/getQuota", {
        year: new Date().getFullYear(),
        force,
      });
    },
    async submit() {
      this.touch$();
      if (!this.v$.$invalid) {
        const selectedDatesToSend = [...this.formData.dates];
        for (let i in selectedDatesToSend) {
          if (selectedDatesToSend.hasOwnProperty(i)) {
            selectedDatesToSend[i] = formatDate(selectedDatesToSend[i]);
          }
        }
        let request = {
          type: this.formData.type,
          description: this.formData.description,
          absenceRequestDates: selectedDatesToSend,
          carryOver: this.formData.carryOver,
        };
        await this.$store
          .dispatch("absence_request/createRequest", request)
          .then(async () => {
            await this.getAbsenceRequests(true);
            this.resetForm();
          });
      }
    },
    resetForm() {
      this.isCollapseOpen = false;
      this.absenceSelected = null;
      this.absenceTypeSub = "";
      this.formData.type = null;
      this.formData.description = null;
      this.formData.dates = [];
      this.formData.carryOver = false;
      this.setDisabledDates();
      this.$refs.dates.updateInternalState([]);

      //Ez egy bug a buefy-ben. Jó alul egy array-t nem frissít még az updateInternalState függvényük sem.
      // let datepickerTable = this.$refs.dates.$children
      //   .filter((c) => c.$vnode.tag.includes("BDropdown"))
      //   .shift()
      //   .$children.filter((c) => c.$vnode.tag.includes("BDropdownItem"))
      //   .shift()
      //   .$children.filter((c) => c.$vnode.tag.includes("BDatepickerTable"))
      //   .shift();
      // datepickerTable.multipleSelectedDates = [];

      this.v$.$reset();
    },
    revokeAbsenceRequest(absenceId) {
      this.$store
        .dispatch("absence_request/revokeRequest", absenceId)
        .then(() => {
          this.getAbsenceRequests();
        });
    },

    setDisabledDates() {
      for (let specDay of this.specialDays) {
        if (specDay.type !== "EXTRA_WORKDAY") {
          this.disabledDates.push(new Date(specDay.dayDate));
        }
      }

      for (let request of this.absenceRequests.items) {
        if (
          request.status.enum !== "REVOKED" &&
          request.status.enum !== "DENIED"
        ) {
          for (let date of request.absenceRequestDates) {
            this.disabledDates.push(new Date(date));
          }
        }
      }
    },

    closeAllOtherDetails(row) {
      nextTick(() => {
        this.defaultOpenedDetails = [row.id];
      });
    },
    revokeDialog(absenceId) {
      this.$buefy.dialog.confirm({
        title: "Kérelem visszavonása",
        message: "Biztosan visszavonod a távollét kérelmet?",
        cancelText: "Mégsem",
        confirmText: "Igen",
        type: "is-info",
        hasIcon: true,
        onConfirm: () => {
          this.revokeAbsenceRequest(absenceId);
        },
      });
    },
    getStatusTagIcon(status) {
      switch (status) {
        case "REQUEST":
          return "calendar";
        case "LINE_APPROVED":
          return "check-double";
        case "APPROVED":
          return "check-double";
        case "ACTIVE":
          return "umbrella-beach";
        case "TAKEN":
          return "check-double";
        case "DENIED":
          return "ban";
        case "REVOKED":
          return "times";
      }
    },
    getStatusTagType(status) {
      switch (status) {
        case "REQUEST":
          return "is-light";
        case "LINE_APPROVED":
          return "is-info";
        case "APPROVED":
          return "is-info";
        case "ACTIVE":
          return "is-success";
        case "TAKEN":
          return "is-light";
        case "DENIED":
          return "is-danger";
        case "REVOKED":
          return "is-light";
      }
    },
    canRevoke(status) {
      switch (status) {
        case "REQUEST":
          return true;
        case "LINE_APPROVED":
          return true;
        case "APPROVED":
          return true;
        case "ACTIVE":
          return false;
        case "TAKEN":
          return false;
        case "DENIED":
          return false;
        case "REVOKED":
          return false;
      }
    },
    toggleDetails: function (row) {
      this.$refs.table.toggleDetails(row);
    },

    onPageChange: function (page) {
      this.pagination.page = page - 1;
      this.getAbsenceRequests();
    },
    onSort: function (field, order) {
      this.sort.field = field;
      this.sort.order = order;
      this.getAbsenceRequests();
    },

    onDateSelect() {
      this.formData.carryOver = this.formData.carryOver && this.canCarryOver;
    },
    style(color) {
      return (
        "color: " +
        getContrastedColor(color) +
        ";" +
        "background-color:" +
        color
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.collapseBlock {
  border: 1px $grey-lighter solid;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
</style>
