<template>
  <div class="mt-5">
    <template v-if="timelogs && timelogs.length > 0">
      <timesheet-bar-graph-container
        ref="graph"
        :logs="timelogs"
        class="graph"
      ></timesheet-bar-graph-container>
    </template>
    <template v-else>
      <div class="empty-timesheet-details">
        {{
          $tf(
            "timesheetBarGraph.noLogsYet|Még nem történt logolás ebben a hónapban!"
          )
        }}
      </div>
    </template>
  </div>
</template>

<script>
import TimesheetBarGraphContainer from "@/components/timesheet/tab/graph/TimesheetBarGraphContainer";

export default {
  name: "TimesheetBarGraphTab",
  components: { TimesheetBarGraphContainer },
  props: {
    timelogs: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      now: new Date(),
      doSlide: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.empty-timesheet-details {
  border: 1px solid $grey-lighter;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  font-weight: 500;
  color: $custom-dark-blue;
}

.graph {
  padding-right: 40px;
}
</style>
