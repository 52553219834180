<template>
  <div>
    <ul>
      <li v-if="loading">
        {{ $tf("riskManagement.unknownSheets.loading|Töltés...") }}
      </li>
      <li v-else>
        <p class="risk-management-table-info">
          {{
            $tf(
              "riskManagement.unknownSheets.title|Ismeretlen logolások tételes listája az elmúlt 3 hónapból. Prefix alapján felsorolásra kerül az összes lehetséges Projekt."
            )
          }}
        </p>
        <b-field grouped>
          <b-field
            :label="$tf('riskManagement.unknownSheets.select.label|Időbeliség')"
            label-position="on-border"
            size="is-large"
          >
            <b-select v-model="exportSince">
              <option value="THREE_MONTHS">
                {{ $tf("riskManagement.unknownSheets.select.three|3 hónapos") }}
              </option>
              <option value="SIX_MONTHS">
                {{ $tf("riskManagement.unknownSheets.select.six|6 hónapos") }}
              </option>
              <option value="CURRENT_YEAR">
                {{ $tf("riskManagement.unknownSheets.select.year|Jelen éves") }}
              </option>
              <option value="ALL_TIME">
                {{
                  $tf("riskManagement.unknownSheets.select.forever|Bármikor")
                }}
              </option>
            </b-select>
          </b-field>

          <b-button
            :loading="isExportInProgress"
            class="ml-2"
            icon-left="file-excel"
            size="is-default"
            type="is-info"
            @click="exportUnknown"
          >
            {{ $tf("riskManagement.unknownSheets.export|Exportálás") }}
          </b-button>
        </b-field>
      </li>
    </ul>
    <b-table
      v-if="!loading"
      :data="records || []"
      :loading="loading"
      :per-page="50"
      :show-detail-icon="true"
      detail-key="id"
      detailed
      hoverable
      paginated
    >
      <b-table-column
        v-slot="props"
        :label="$tf('riskManagement.unknownSheets.table.user|Munkavállaló')"
        field="workerName"
        searchable
      >
        <UserInfo
          :user="{
            userId: props.row.workerUserId,
            name: props.row.workerName,
            avatar: props.row.workerAvatar,
            email: props.row.workerEmail,
            phone: props.row.workerPhone,
          }"
          displayMode="long"
          tooltipPosition="bottom"
        />
      </b-table-column>
      <b-table-column
        v-slot="props"
        :label="
          $tf(
            'riskManagement.unknownSheets.table.workLogged|Logolás napja, Bejegyzés napja'
          )
        "
        field="workStarted"
        sortable
      >
        <div>{{ formatDate(props.row.workStarted) }}</div>
        <div>{{ formatDate(props.row.workUpdated) }}</div>
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="$tf('riskManagement.unknownSheets.table.spent|Költség')"
        field="loggedSeconds"
        sortable
      >
        <div>{{ secondToShortWorkDayFormat(props.row.loggedSeconds) }}</div>
        <div>{{ moneyify(props.row.spentCost) }}</div>
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="$tf('riskManagement.unknownSheets.table.comment|Komment')"
        field="workComment"
      >
        {{ props.row.workComment }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="$tf('riskManagement.unknownSheets.table.prefix|Prefix')"
        field="prefix"
        sortable
      >
        {{ props.row.prefix }}
      </b-table-column>

      <template #detail="props">
        <span>{{
          $tf(
            "riskManagement.unknownSheets.detailsTable.title|Lehetséges gazdaprojektek"
          )
        }}</span
        ><br />
        <b-table ref="inner" :data="props.row.possibilities">
          <b-table-column
            v-slot="innerProps"
            :label="
              $tf('riskManagement.unknownSheets.innerTable.lead|Projektvezető')
            "
            field="leadName"
            searchable
          >
            <UserInfo
              :user="{
                userId: innerProps.row.leadId,
                name: innerProps.row.leadName,
                avatar: innerProps.row.leadAvatar,
                email: innerProps.row.leadEmail,
                phone: innerProps.row.leadPhone,
              }"
              displayMode="long"
              tooltipPosition="bottom"
            />
          </b-table-column>
          <b-table-column
            v-slot="innerProps"
            :label="
              $tf(
                'riskManagement.unknownSheets.innerTable.identifier|Projektazonosító'
              )
            "
            field="identifier"
            sortable
          >
            {{ innerProps.row.identifier }}
          </b-table-column>
          <b-table-column
            v-slot="innerProps"
            :label="
              $tf('riskManagement.unknownSheets.innerTable.name|Projekt név')
            "
            field="name"
          >
            {{ innerProps.row.name }}
          </b-table-column>
        </b-table>
      </template>
    </b-table>
  </div>
</template>

<script>
import { formatDate, moneyify, secondToShortWorkDayFormat } from "@/utils/util";
import { mapGetters } from "vuex";
import UserInfo from "@/components/module/info/UserInfo.vue";
import FileSaver from "file-saver";

export default {
  name: "RiskManagementUnknownSheetsTable",
  components: { UserInfo },
  computed: {
    ...mapGetters({
      records: "risk_management/getUnknownTimesheets",
    }),
  },
  methods: {
    async fetchData() {
      this.loading = true;
      await this.$store.dispatch("risk_management/clearCache");
      await this.$store.dispatch("risk_management/fetchUnknownTimesheets");
      this.loading = false;
    },
    async exportUnknown() {
      this.isExportInProgress = true;
      try {
        let data = await this.$store.dispatch("risk_management/exportUnknown", {
          since: this.exportSince,
        });

        FileSaver.saveAs(data, "ismeretlen.xlsx");
      } finally {
        this.isExportInProgress = false;
      }
    },
  },
  watch: {},
  data() {
    return {
      moneyify,
      secondToShortWorkDayFormat,
      formatDate,
      isExportInProgress: false,
      loading: true,
      exportSince: "THREE_MONTHS",
    };
  },
  async mounted() {
    await this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
.risk-management-table-info {
  color: var(--custom-grayer-blue);
}
</style>
