<template>
  <svg
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4999_23737)">
      <circle cx="16" cy="16" fill="#EBE9FE" r="16" />
      <circle cx="9.22392" cy="9.22432" fill="#6A38EF" r="1.89092" />
      <circle cx="9.22392" cy="16" fill="#6A38EF" r="1.89092" />
      <circle cx="9.22392" cy="22.7756" fill="#6A38EF" r="1.89092" />
      <circle cx="15.9993" cy="9.22423" fill="#6A38EF" r="1.89092" />
      <circle cx="15.9993" cy="16" fill="#F670C7" r="1.89092" />
      <circle cx="15.9993" cy="22.7756" fill="#6A38EF" r="1.89092" />
      <circle cx="22.7757" cy="9.22423" fill="#6A38EF" r="1.89092" />
      <circle cx="22.7757" cy="16" fill="#6A38EF" r="1.89092" />
      <circle cx="22.7757" cy="22.7756" fill="#6A38EF" r="1.89092" />
    </g>
    <defs>
      <clipPath id="clip0_4999_23737">
        <rect fill="white" height="32" width="32" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "OnboardingMassSettingIcon",
};
</script>

<style scoped></style>
