import { newLogger } from "@/utils/util";
import StoreCore from "@/store/StoreCore";

let logger = newLogger("CommitmentStore");

const urls = {
  commitment: "/ralloc/commitment",
};

const initState = () => {
  return {
    commitments: null,
  };
};

const storeCore = new StoreCore();
const actions = {
  getCommitments: async function ({ commit, dispatch }, projectId) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.commitment + `/${projectId}`,
        null,
        true,
        dispatch,
        "commitment.get.flair|Vállalás lebontás betöltése",
        undefined
      );
      commit("setCommitments", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  updateCommitment: async function ({ dispatch }, request) {
    try {
      await this.remoteRequest(
        "post",
        urls.commitment,
        request,
        false,
        dispatch,
        "commitment.modify.flair|Vállalás lebontás módosítása",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            "commitment.modify.progress|Vállalás lebontás módosítása folyamatban...",
          fail: "commitment.modify.fail|Vállalás lebontás módosítása sikertelen!",
          success:
            "commitment.modify.success|Vállalás lebontás módosítása sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  setCommitments: (state, commitment) => {
    state.commitments = commitment;
  },
};

const getters = {
  commitments: (state) => {
    return state.commitments;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
