<template>
  <div class="modal-card" style="width: 44rem">
    <div class="container">
      <section class="modal-card-body">
        <b-field label="Prefix" label-position="on-border">
          <b-input v-model="newPrefix.prefix"></b-input>
        </b-field>
        <b-field label="Megjegyzés" label-position="on-border">
          <b-input v-model="newPrefix.description"></b-input>
        </b-field>
        <b-button type="is-info" @click="savePrefix">Mentés</b-button>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "IgnoredPrefixModal",
  data() {
    return {
      newPrefix: {
        prefix: null,
        description: null,
      },
    };
  },
  methods: {
    savePrefix() {
      this.$emit("save-prefix", this.newPrefix);
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
