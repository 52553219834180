<template>
  <div class="is-flex has-gap-5">
    <div class="executive-info-card card-shadowed">
      <div-with-loading loading-height="60px" loading-width="250px"
        >{{ overview.numberOfRunningProjects }}
      </div-with-loading>
      <div-with-loading loading-height="30px" loading-width="250px"
        >{{
          $tf(
            "execDashboard.overview.projectsInProgress|folyamatban lévő projekt"
          )
        }}
      </div-with-loading>
    </div>
    <div class="executive-info-card card-shadowed">
      <div-with-loading
        :class="{
          'has-text-red': overview?.expectedOverSpending > 0,
          'has-text-green': overview?.expectedOverSpending <= 0,
        }"
        loading-height="60px"
        loading-width="250px"
      >
        {{ overview && moneyify(Math.abs(overview.expectedOverSpending)) }}
      </div-with-loading>
      <div-with-loading loading-height="30px" loading-width="250px">
        <template v-if="overview.expectedOverSpending < 0"
          >{{
            $tf(
              "execDashboard.overview.expectedBudgetSave|várható büdzsé megtakarítás"
            )
          }}
        </template>
        <template v-else
          >{{
            $tf(
              "execDashboard.overview.expectedBudgetOverspend|várható büdzsé túlköltés"
            )
          }}
        </template>
      </div-with-loading>
    </div>
    <div class="executive-info-card card-shadowed">
      <div-with-loading
        :class="{
          'has-text-red': overview?.expectedProfit < 0,
          'has-text-green': overview?.expectedProfit >= 0,
        }"
        loading-height="60px"
        loading-width="250px"
      >
        {{ overview && moneyify(Math.abs(overview.expectedProfit)) }}
      </div-with-loading>
      <div-with-loading loading-height="30px" loading-width="250px">
        <template v-if="overview.expectedProfit < 0"
          >{{ $tf("execDashboard.overview.expectedLoss|várható veszteség") }}
        </template>
        <template v-else
          >{{ $tf("execDashboard.overview.expectedProfit|várható nyereség") }}
        </template>
      </div-with-loading>
    </div>
    <div class="executive-info-card card-shadowed">
      <div-with-loading loading-height="60px" loading-width="250px"
        >{{ moneyify(overview.expectedIncome) }}
      </div-with-loading>
      <div-with-loading loading-height="30px" loading-width="250px"
        >{{ $tf("execDashboard.overview.expectedIncome|várható bevétel") }}
      </div-with-loading>
    </div>
  </div>
</template>

<script>
import { moneyify } from "@/utils/util";
import { defineAsyncComponent } from "vue";

export default {
  name: "ExecutiveDashboardOverview",
  components: {
    DivWithLoading: defineAsyncComponent(() =>
      import("@/components/loading/DivWithLoading.vue")
    ),
  },
  methods: { moneyify },
  props: {
    overview: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.executive-info-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  padding: 8px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;

  :first-child {
    font-size: 36px;
    font-weight: 700;
  }

  :nth-child(2) {
    font-size: 14px;
    color: $grey;
  }
}
</style>
