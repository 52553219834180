<template>
  <div>
    <h3 class="title">
      {{ $tf("taskForm.base.title|Új jegy létrehozása") }}
    </h3>
    <form @submit.prevent>
      <div class="columns mt-5">
        <div class="column">
          <vuelidated-input
            v-model="taskEdit.name"
            :disabled="projectType !== 'BASIC'"
            :label="$tf('taskForm.base.name|Név')"
            :placeholder="$tf('taskForm.name.placeholder|Feladat neve')"
            :validation-rule="v$.taskEdit.name"
            expanded
            label-position="on-border"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column is-half">
          <vuelidated-input
            v-model="taskEdit.key"
            :disabled="projectType !== 'BASIC'"
            :label="$tf('taskForm.base.identifier|Azonosító')"
            :placeholder="$tf('taskForm.identifier.placeholder|ABC-123')"
            :validation-rule="v$.taskEdit.key"
            expanded
            label-position="on-border"
          />
        </div>
        <div class="column is-half">
          <b-field :label="issueContainerLabel" label-position="on-border">
            <b-select
              v-model="taskEdit.tigId"
              :disabled="projectType !== 'BASIC'"
              :placeholder="$tf('taskForm.base.tig.placeholder|TIG-1')"
              expanded
              icon="swatchbook"
            >
              <option v-for="tig in tigs" :key="tig.id" :value="tig.id">
                {{ getIssueContainerName(tig) }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column is-half">
          <vuelidated-input
            v-model="taskEdit.type"
            :disabled="projectType !== 'BASIC'"
            :label="$tf('taskForm.base.type|Típus')"
            :validation-rule="v$.taskEdit.type"
            expanded
            label-position="on-border"
            type="select"
          >
            <template #select>
              <option
                v-for="status in Object.keys(TASK_TYPE)"
                :key="status"
                :value="status"
              >
                {{ $tf(TASK_TYPE[status]) }}
              </option>
              <template v-if="projectType === 'SUPPORT'">
                <option value="FIX">
                  {{ $tf("taskform.taskType.FIX|Fix díjas") }}
                </option>
                <option value="LOCKUP">
                  {{ $tf("taskform.taskType.LOCKUP|Idő lekötéses") }}
                </option>
              </template>
              <template v-else-if="projectType === 'OUTSOURCE'">
                <option value="CONTRACT">
                  {{ $tf("taskform.taskType.OUTSOURCE|Outsource szerződés") }}
                </option>
              </template>
            </template>
          </vuelidated-input>
        </div>
        <div class="column is-half">
          <vuelidated-input
            v-model="taskEdit.estimateSeconds"
            :label="$tf('taskForm.base.estimateSeconds|Becsült idő')"
            :placeholder="$tf('taskForm.estimateSeconds.placeholder|2d 4h 30m')"
            :validation-rule="v$.taskEdit.estimateSeconds"
            expanded
            label-position="on-border"
          />
        </div>
        <div class="column">
          <b-field
            :label="$tf('taskForm.base.assignee|Hozzárendelt személy')"
            label-position="on-border"
          >
            <b-autocomplete
              v-model="assigneeSearch"
              :data="filteredEmployees"
              :placeholder="
                $tf('taskForm.assignee.placeholder|pl. Elektrom Ágnes')
              "
              class="has-blue-icon"
              clearable
              field="name"
              icon="search"
              rounded
              @select="(option) => (taskEdit.assignee = option?.id ?? null)"
            >
              <template v-slot:empty
                >{{ $tf("taskForm.assignee.empty|Nincs találat") }}
              </template>
            </b-autocomplete>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field
            :label="$tf('taskForm.base.description|Leírás')"
            label-position="on-border"
          >
            <b-input v-model="taskEdit.description" type="textarea"></b-input>
          </b-field>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <b-button
            :expanded="true"
            native-type="submit"
            type="is-info"
            @click="submitTask"
            >{{ $tf("taskForm.base.save|Mentés") }}
          </b-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import VuelidatedInput from "@/components/module/input/VuelidatedInput.vue";
import { helpers, required } from "@vuelidate/validators";
import { TASK_TYPE } from "@/utils/const";
import { deepCopy, secondsToJiraTime } from "@/utils/util";
import useCustomVuelidate from "@/plugins/vuelidate";

export default {
  name: "TaskForm",
  components: { VuelidatedInput },
  setup: () => useCustomVuelidate(),
  emits: ["task-submitted"],
  props: {
    projectId: {
      type: Number,
      required: true,
    },
    projectType: {
      type: String,
      required: true,
    },
    tigs: {
      type: Array,
      required: true,
    },
    employees: {
      type: Array,
      required: true,
    },
    editedTask: {
      type: Object,
      required: false,
    },
    isBillable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      assigneeSearch: null,
      taskEdit: {
        estimateSeconds: "",
      },
    };
  },
  validations: {
    taskEdit: {
      name: {
        required,
      },
      key: {
        required,
      },
      tigId: {
        required,
      },
      type: {
        required,
      },
      estimateSeconds: {
        isJiraTime: helpers.regex(/^ *([0-9]+[DdHhMm] *)*$/),
      },
    },
  },
  computed: {
    selectedTig() {
      if (!this.tigs || !this.taskEdit?.tigId) return null;
      return this.tigs.find((t) => t.id === this.taskEdit?.tigId);
    },
    TASK_TYPE() {
      return TASK_TYPE;
    },
    filteredEmployees() {
      let employees = this.employees || [];
      return employees.filter((element) => {
        if (!this.assigneeSearch || !element.name || !element.username)
          return false;
        return (
          element.name
            .toString()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .indexOf(
              this.assigneeSearch
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0 ||
          element.username
            .toString()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .indexOf(
              this.assigneeSearch
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0
        );
      });
    },
    issueContainerLabel() {
      switch (this.projectType) {
        case "SUPPORT":
          return this.$tf("taskForm.base.tig.SUPPORT|Aktivitás");
        case "OUTSOURCE":
          return this.$tf("taskForm.base.tig.OUTSOURCE|Szerződés");
        default:
          return this.$tf("taskForm.base.tig.BASIC|Mérföldkő");
      }
    },
  },
  methods: {
    async submitTask() {
      this.touch$();
      if (this.v$.$invalid) return;

      let request = deepCopy(this.taskEdit);
      request.issueTrackingSystemIdentifier =
        this.selectedTig?.issueTrackingSystemIdentifier;
      if (this.isBillable) {
        if (this.editedTask) {
          await this.$store.dispatch(
            "support_projects/updateBillableTask",
            request
          );
        } else {
          request.projectId = this.projectId;
          request.projectType = this.projectType;
          request.tigType = "BILLABLE";
          await this.$store.dispatch(
            "support_projects/createBillableTask",
            request
          );
        }
      } else {
        if (this.editedTask) {
          await this.$store.dispatch("enterprise_core/editIssue", request);
        } else {
          request.projectId = this.projectId;
          request.projectType = this.projectType;
          await this.$store.dispatch("enterprise_core/createIssue", request);
        }
      }

      this.$emit("task-submitted");
    },
    getIssueContainerName(tig) {
      switch (this.projectType) {
        case "OUTSOURCE":
          return tig.userData.name;
        default:
          return tig.name;
      }
    },
  },
  created() {
    if (this.editedTask) {
      this.taskEdit = deepCopy(this.editedTask);
      this.taskEdit.estimateSeconds = secondsToJiraTime(
        this.editedTask.estimateSeconds
      );
    }
    this.assigneeSearch = this.employees.find(
      (emp) => emp.id === this.taskEdit.assignee
    )?.name;
    if (this.tigs?.length === 1) {
      this.taskEdit.tigId = this.tigs[0].id;
    }
  },
};
</script>

<style lang="scss" scoped></style>
