<template>
  <section class="section">
    <div class="container">
      <h1 class="title">{{ $tf("tiers.title|Szintek és árak") }}</h1>
      <h2 class="subtitle">
        {{
          $tf("tiers.subtitle|Dolgozói szintek és szintek rátáinak beállítása")
        }}
      </h2>

      <div class="mt-5">
        <div>
          <label class="cost-label">{{
            $tf("tiers.standardTier|Általános árszint:")
          }}</label>
          <label class="cost-value">
            {{ currentStandardTier.tier }}
            {{ moneyify(currentStandardTier.cost) }}</label
          >
          <b-tooltip
            :auto-close="['outside', 'escape']"
            :triggers="['click']"
            class="ml-2"
          >
            <template v-slot:content>
              <div class="content tooltip">
                <p>
                  {{
                    $tf(
                      "tiers.standardTier.tooltip|Az általános árszint használódik a vállalás és a becslések árazásakor. A projekt teljes időtartamára a projekt kezdő  dátumán érvényes ár vonatkozik. Általános árszint esetén a Szint csak referencia: Az ár  pontosan megadandó, a megjelölt szinthez egyébként csatolt ár nem számít!"
                    )
                  }}
                </p>
              </div>
            </template>
            <b-icon icon="info-circle" />
          </b-tooltip>
        </div>

        <div class="cost-since">
          {{ $tf("tiers.currentStandardTierValidFrom|Érvényesség kezdete: ") }}
          {{ $filterDateFormat(currentStandardTier.since) }}
        </div>
      </div>
      <div class="is-pulled-top-right is-inline-flex">
        <b-button
          class="mb-5 mr-2"
          icon-left="upload"
          type="is-info"
          @click="importTiers"
          >{{ $tf("userList.import|Importálás") }}
        </b-button>
        <b-button
          :loading="isExportInProgress"
          icon-left="download"
          type="is-info"
          @click="exportTiers"
          >{{ $tf("userList.export|Exportálás") }}
        </b-button>
      </div>
    </div>

    <div class="container">
      <h1 class="title is-size-5">
        {{ $tf("tiers.rates.title|Érvények szint ráták") }}
        <b-tooltip :auto-close="['outside', 'escape']" :triggers="['click']">
          <template v-slot:content>
            <div class="content tooltip">
              <p>
                {{
                  $tf(
                    "tiers.rates.tooltip|Költségkalkuláció esetén minden esetben a munka keletkezéséhez köthető naptári napon a dolgozóhoz kötött érvényes szint, és a szinthez kötött érvényes ár van figyelembe véve."
                  )
                }}
              </p>
            </div>
          </template>
          <b-icon icon="info-circle" />
        </b-tooltip>
      </h1>
      <h2 class="subtitle">
        {{
          $tf("tiers.rates.validToday|Ma ({date}) érvényes értékek", {
            date: formatDate(new Date()),
          })
        }}
        <b-tooltip :auto-close="['outside', 'escape']" :triggers="['click']">
          <template v-slot:content>
            <div class="content tooltip">
              <p>
                {{
                  $tf(
                    "tiers.rates.validity.tooltip|Minden beállítható szint illetve ár rendelkezik egy dátummal, melytől érvényes. Minden beállítás ettől a dátumtól érvényes a rákövetkező dátumig, ha van ilyen elem. Itt csak az éppen érvényes beállítások látszanak."
                  )
                }}
              </p>
            </div>
          </template>
          <b-icon icon="info-circle" />
        </b-tooltip>
      </h2>

      <hr />

      <b-carousel-list
        v-model="carouselPage"
        :arrow-hover="false"
        :autoplay="false"
        :data="aggregateTiers"
        :has-drag="false"
        :items-to-show="5"
      >
        <template #item="column">
          <div class="cost-value">
            <label>{{ column.tier.tier }} - </label>
            <label v-if="column.tier.tier === FIXED_PRICE_TIER_NAME">{{
              $tf("tiers.custom|Egyedi árszint")
            }}</label>
            <label v-else>{{ moneyify(column.cost) }}</label>
          </div>
          <div
            v-if="column.tier.tier !== FIXED_PRICE_TIER_NAME"
            class="cost-since mb-3"
          >
            {{
              $tf("tiers.validFrom|Érvényesség kezdete: {date}", {
                date: formatDate(column.since),
              })
            }}
          </div>

          <div v-else class="fixed-price-margin" />

          <transition-group name="fade">
            <template v-if="carouselDetailsOpen">
              <div
                v-for="user in column.users"
                v-bind:key="user.userId"
                class="user-tooltip"
              >
                <template v-if="column.tier.tier === FIXED_PRICE_TIER_NAME">
                  <label
                    >{{ user.displayName }} - {{ moneyify(user.cost) }}</label
                  >
                </template>

                <template v-else>
                  <label>{{ user.userDisplayName }}</label>
                </template>
              </div>
            </template>
          </transition-group>
        </template>
      </b-carousel-list>

      <div class="is-flex mb-5">
        <clickable-icon
          :icon="carouselDetailsOpen ? 'angle-up' : 'angle-down'"
          class="ml-auto mr-3 tier-toggle"
          size="is-medium"
          @click="carouselDetailsOpen = !carouselDetailsOpen"
        />
      </div>
    </div>

    <div class="container users-without-tiers-container">
      <h1 class="title">
        {{
          $tf("tiers.usersWithoutTier|Dolgozók szint nélkül ({count} db)", {
            count: usersWithoutTierFiltered.length,
          })
        }}
        <b-icon
          class="untracked-triangle"
          icon="exclamation-triangle"
          size="is-small"
        />
      </h1>

      <div class="is-flex is-align-items-center">
        <h2 class="subtitle mb-0">
          {{
            $tf(
              "tiers.usersWithoutTier.info|Kezeli a rendszer a szintnélküliséget, de ilyenkor az általános árszinttel számol."
            )
          }}
        </h2>
        <b-switch v-model="showEx" class="ml-auto is-align-self-baseline"
          >{{ $tf("tiers.usersWithoutTier.showEx|Ex-kollégák") }}
        </b-switch>
        <b-switch v-model="showExternal" class="is-align-self-baseline"
          >{{ $tf("tiers.usersWithoutTier.showExternal|Külsős kollégák") }}
        </b-switch>
        <clickable-icon
          :icon="usersWithoutTierOpen ? 'angle-up' : 'angle-down'"
          class="mr-3 tier-toggle"
          size="is-medium"
          @click="usersWithoutTierOpen = !usersWithoutTierOpen"
        />
      </div>

      <transition name="fade">
        <template v-if="usersWithoutTierOpen">
          <b-table
            :data="usersWithoutTierFiltered"
            :per-page="10"
            class="mt-5"
            paginated
          >
            <b-table-column
              v-slot="props"
              :label="$tf('tiers.usersWithoutTier.table.name|Kolléga neve')"
              field="name"
              sortable
            >
              <span class="user-name">{{ props.row.name }}</span>
            </b-table-column>
            <b-table-column
              v-slot="props"
              :label="$tf('tiers.usersWithoutTier.table.title|Titulus')"
              field="title"
              sortable
            >
              <span class="user-name">{{ props.row.title }}</span>
            </b-table-column>
          </b-table>
        </template>
      </transition>
    </div>

    <div class="container">
      <div class="is-flex">
        <h1 class="title is-size-5">
          {{
            $tf(
              "tiers.config.title|Részletes konfiguráció: szintek, ráták, összerendelések"
            )
          }}
          <b-tooltip :auto-close="['outside', 'escape']" :triggers="['click']">
            <template v-slot:content>
              <div class="content tooltip">
                <p>
                  {{
                    $tf(
                      "tiers.config.tooltip|Elemek módosítása esetén minden számolás (visszamenőleg is) módosulni fog! Múltba felvett új elemek esetén, az érvényességi dátumtól kezdődően minden számolás módosul! Elemek törlése csak hibás, vagy extra elem létrehozása esetén javasolt."
                    )
                  }}
                </p>
              </div>
            </template>
            <b-icon icon="info-circle" />
          </b-tooltip>
        </h1>

        <b-button
          class="tier-create-button"
          type="is-info"
          @click="$refs[activeTab].openEdit(null)"
        >
          <span v-if="activeTab === TABS.TIER_COSTS">
            {{ $tf("tiers.config.tierRate.new|Új árszint felvétele") }}</span
          >
          <span v-if="activeTab === TABS.USER_TIERS">
            {{
              $tf("tiers.config.assign.new|Új összerendelés felvétele")
            }}</span
          >
          <span v-if="activeTab === TABS.STANDARD_COSTS">
            {{
              $tf(
                "tiers.config.standardTier.new|Új általános árszint felvétele"
              )
            }}</span
          >
          <span v-if="activeTab === TABS.FIXED_PRICE">
            {{
              $tf("tiers.config.customTier.new|Új egyedi árszint felvétele")
            }}</span
          >
          <span v-if="activeTab === TABS.TIERS">
            {{ $tf("tiers.config.tier.new|Új szint felvétele") }}</span
          >
        </b-button>
      </div>

      <b-tabs v-model="activeTab" class="toggle-only" expanded type="is-toggle">
        <b-tab-item
          :label="$tf('tiers.config.tabs.tierRates|Szintek árai')"
          :value="TABS.TIER_COSTS"
        />
        <b-tab-item
          :label="$tf('tiers.config.tabs.assign|Dolgozó - szint összerendelés')"
          :value="TABS.USER_TIERS"
        />
        <b-tab-item
          :label="$tf('tiers.config.tabs.standard|Általános árszintek')"
          :value="TABS.STANDARD_COSTS"
        />
        <b-tab-item
          :label="$tf('tiers.config.tabs.custom|Egyedi árszintű munkatársak')"
          :value="TABS.FIXED_PRICE"
        />
        <b-tab-item
          :label="$tf('tiers.config.tabs.tiers|Szintek')"
          :value="TABS.TIERS"
        />
      </b-tabs>

      <TierCostsTab
        v-if="activeTab === TABS.TIER_COSTS"
        :ref="TABS.TIER_COSTS"
      />
      <UserTiersTab
        v-if="activeTab === TABS.USER_TIERS"
        :ref="TABS.USER_TIERS"
      />
      <StandardCostsTab
        v-if="activeTab === TABS.STANDARD_COSTS"
        :ref="TABS.STANDARD_COSTS"
      />
      <FixedPriceTierTab
        v-if="activeTab === TABS.FIXED_PRICE"
        :ref="TABS.FIXED_PRICE"
      />
      <TiersTab v-if="activeTab === TABS.TIERS" :ref="TABS.TIERS" />
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import TierCostsTab from "./tab/TiersCostsTab";
import UserTiersTab from "./tab/UserTiersTab";
import StandardCostsTab from "./tab/StandardCostsTab";
import TiersTab from "./tab/TiersTab";
import FixedPriceTierTab from "./tab/FixedPriceTierTab";
import { FIXED_PRICE_TIER_NAME } from "@/store/modules/enterprise/tiers";
import ClickableIcon from "../../../components/module/icon/ClickableIcon";
import { formatDate, moneyify } from "@/utils/util";
import LoadingMixin from "@/mixins/LoadingMixin";
import FileSaver from "file-saver";
import TierImportModal from "@/views/enterprise/tiers/edit/TierImportModal.vue";

const TABS = {
  TIER_COSTS: "TIER_COSTS",
  USER_TIERS: "USER_TIERS",
  STANDARD_COSTS: "STANDARD_COSTS",
  FIXED_PRICE: "FIXED_PRICE",
  TIERS: "TIERS",
};

export default {
  name: "Enterprise-Tiers",
  data() {
    return {
      TABS,
      activeTab: TABS.TIER_COSTS,
      FIXED_PRICE_TIER_NAME,
      carouselPage: 0,
      carouselDetailsOpen: false,
      usersWithoutTierOpen: false,
      showEx: false,
      showExternal: false,
      isExportInProgress: false,
      moneyify,
    };
  },
  components: {
    ClickableIcon,
    FixedPriceTierTab,
    TiersTab,
    StandardCostsTab,
    UserTiersTab,
    TierCostsTab,
  },
  computed: {
    aggregateTiers() {
      let aggregate = [];
      for (let tc in this.currentTierCosts) {
        let currentTierCosts = this.currentTierCosts[tc];
        let column = {
          tier: currentTierCosts.tier,
          cost: currentTierCosts.cost,
          since: currentTierCosts.since,
        };

        if (column.tier.tier === FIXED_PRICE_TIER_NAME) {
          column.users = this.fixedPriceTiers;
        } else {
          column.users = this.currentUserTiers.filter(
            (user) => user.tier.id === currentTierCosts.tier.id
          );
        }

        aggregate.push(column);
      }
      return aggregate;
    },
    usersWithoutTierFiltered() {
      let array = this.usersWithoutTier;
      if (!this.showEx) {
        array = array.filter((user) => !user.ex);
      }
      if (!this.showExternal) {
        array = array.filter((user) => !user.external);
      }
      return array;
    },
    ...mapGetters({
      usersWithoutTier: "enterprise_tiers/usersWithoutTier",
      currentTierCosts: "enterprise_tiers/currentTierCosts",
      currentUserTiers: "enterprise_tiers/currentUserTiers",
      currentStandardTier: "enterprise_tiers/currentStandardCost",
      fixedPriceTiers: "enterprise_tiers/fixedPriceTiers",
    }),
  },
  methods: {
    formatDate,
    async fetch() {
      await this.doStartLoading();
      await this.$store.dispatch("enterprise_tiers/fetchTiers", true);
      await this.$store.dispatch(
        "enterprise_tiers/fetchUsersWithoutTier",
        true
      );
      await this.$store.dispatch("enterprise_tiers/fetchUserTiers", true);
      await this.$store.dispatch("enterprise_tiers/fetchStandardCosts", true);
      await this.$store.dispatch("enterprise_tiers/fetchTierCosts", true);
      await this.$store.dispatch("enterprise_tiers/fetchFixedPriceTiers", true);
      await this.doFinishLoading();
    },
    async exportTiers() {
      this.isExportInProgress = true;
      try {
        let data = await this.$store.dispatch("enterprise_tiers/exportTiers");
        FileSaver.saveAs(data, "tiers.xlsx");
      } finally {
        this.isExportInProgress = false;
      }
    },
    async importTiers() {
      this.$buefy.modal.open({
        parent: this,
        component: TierImportModal,
        hasModalCard: true,
        trapFocus: true,
        events: {
          save: async () => await this.fetch(),
        },
      });
    },
  },
  mixins: [LoadingMixin],
  async mounted() {
    await this.fetch();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/colors.scss";

.tier-create-button {
  margin-left: auto;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

.link.is-danger {
  color: $red;
}

.red {
  color: $red;
}

.cost-label {
  color: $custom-grayer-blue;
}

.cost-value {
  font-weight: 500;
  color: $custom-dark-blue;
}

.cost-since {
  color: $custom-grayer-blue;
  font-size: 14px;
}

.tier-toggle {
  :deep(.has-text-link) {
    color: $custom-dark-blue !important;
  }
}

.users-without-tiers-container {
  h1.title {
    color: $red;
    font-size: 1.1rem;
  }
}

.fixed-price-margin {
  margin-bottom: 2rem;
}
</style>
