import { newLogger } from "@/utils/util";
import StoreCore from "../../StoreCore";

let logger = newLogger("EmployeeStore");

const urls = {
  users: "/census/list-users",
  employees: "/census/users",
  superadminEmployees: (id) => `/tenant/cross/census/superadmin/users/${id}`,
  workers: "/census/logging-users",
  colleagues: "/census/colleagues",
  leads: "/census/leads",
  teamlessColleagues: "/census/teamless-colleagues",
  forTimesheet: "/census/timesheet/users",
  avatar: "/byte-barn/document",
};

/**
 * Employee store
 */

const initState = () => {
  return {
    users: [],
    employees: [],
    teamlessEmployees: [],
    workers: [],
    leads: [],
    timesheetEmployees: [],
    timesheetWorkers: [],
  };
};

const storeCore = new StoreCore();
const actions = {
  fetch: async function ({ commit, dispatch }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.employees,
        null,
        true,
        dispatch,
        "Dolgozók betöltése..."
      );
      commit("save", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchEmployees: async function ({ commit, dispatch }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.users,
        null,
        true,
        dispatch,
        "Dolgozók betöltése..."
      );
      commit("save", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchEmployeesForTimesheet: async function ({ commit, dispatch }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.forTimesheet.concat("?external=false"),
        null,
        true,
        dispatch,
        "Dolgozók betöltése..."
      );
      commit("saveEmployeesForTimesheet", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchAdmin: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.remoteRequest(
        "get",
        "/census/admin/users",
        null,
        true,
        dispatch,
        "Dolgozók betöltése...",
        null,
        force
      );
      commit("save", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchMyEmployees: async function ({ commit, dispatch }) {
    try {
      let data = await this.remoteRequest(
        "get",
        "/census/mine/users",
        null,
        true,
        dispatch,
        "Saját dolgozók betöltése..."
      );
      commit("save", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchWorkers: async function ({ commit, dispatch }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.workers,
        null,
        true,
        dispatch,
        "Dolgozók betöltése..."
      );
      commit("saveWorkers", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchLeads: async function ({ commit, dispatch }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.leads,
        null,
        true,
        dispatch,
        "Leadek betöltése..."
      );
      commit("saveLeads", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchWorkersForTimeSheet: async function ({ commit, dispatch }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.forTimesheet.concat("?external=true"),
        null,
        true,
        dispatch,
        "Dolgozók betöltése..."
      );
      commit("saveWorkersForTimesheet", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchColleagues: async function ({ commit, dispatch }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.colleagues,
        null,
        true,
        dispatch,
        "Dolgozók betöltése..."
      );
      commit("save", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchTeamlessColleagues: async function ({ commit, dispatch }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.teamlessColleagues,
        null,
        true,
        dispatch,
        "Dolgozók betöltése..."
      );
      commit("saveTeamless", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  create: async function (
    { dispatch },
    { createUserRequest, avatarForm, externalIdentifiers }
  ) {
    const hasAvatar = createUserRequest.avatar;
    delete createUserRequest.avatar;
    try {
      const searchParams = new URLSearchParams();
      if (Array.isArray(externalIdentifiers)) {
        externalIdentifiers.forEach((item) =>
          searchParams.append("externalIdentifiers", item)
        );
      }
      let response = await this.remoteRequest(
        "post",
        `${urls.employees}?${searchParams.toString()}`,
        createUserRequest,
        false,
        dispatch,
        "Felhasználó létrehozása..."
      );
      if (hasAvatar) {
        avatarForm.append("identifier", response.id);
        await this.remoteRequest(
          "post",
          `${urls.avatar}`,
          avatarForm,
          false,
          dispatch,
          "Avatar feltöltése..."
        );
      }
    } catch (err) {
      if ("Cached!" !== err) {
        await Promise.reject(err);
      }
    }
  }.bind(storeCore),
  superadminCreate: async function ({ dispatch }, { request, avatarForm }) {
    const hasAvatar = request.avatar;
    delete request.avatar;
    try {
      let response = await this.remoteRequest(
        "post",
        urls.superadminEmployees(createUserRequest.tenantId),
        request,
        false,
        dispatch,
        "Felhasználó létrehozása..."
      );
      if (hasAvatar) {
        avatarForm.append("identifier", response.id);
        await this.remoteRequest(
          "post",
          `${urls.avatar}`,
          avatarForm,
          false,
          dispatch,
          "Avatar feltöltése..."
        );
      }
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  update: async function ({ dispatch }, { request, avatarForm }) {
    const hasAvatar = request.avatar;
    delete request.avatar;
    try {
      let response = await this.remoteRequest(
        "put",
        urls.employees + "/" + request.id,
        request,
        false,
        dispatch,
        "Adatok módosítása..."
      );
      if (hasAvatar) {
        avatarForm.append("identifier", response.id);
        await this.remoteRequest(
          "post",
          `${urls.avatar}`,
          avatarForm,
          false,
          dispatch,
          "Avatar feltöltése..."
        );
      }
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  createExternalUser: async function (
    { commit, dispatch },
    { request, issueTrackingConfigIdentifier }
  ) {
    try {
      let data = await this.remoteRequest(
        "put",
        `${urls.employees}/${request.id}/external?issueTrackingConfigIdentifier=${issueTrackingConfigIdentifier}`,
        request,
        false,
        dispatch,
        "Creating external user..."
      );
      commit("saveEmployeeExternalId", {
        userId: data.id,
        externalId: data.externalId,
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  save: (state, employees) => {
    employees.sort((a, b) => a.name.localeCompare(b.name));
    state.employees.length = 0;
    for (let e in employees) {
      if (!employees.hasOwnProperty(e) || !employees[e]) continue;
      state.employees.push(employees[e]);
    }
  },
  saveEmployeeExternalId: (state, { userId, externalId }) => {
    state.employees.map((e) => {
      e.externalId = e.id === userId ? externalId : e.externalId;
      return e;
    });
  },
  saveTeamless: (state, employees) => {
    employees.sort((a, b) => a.name.localeCompare(b.name));
    state.teamlessEmployees.length = 0;
    for (let e in employees) {
      if (!employees.hasOwnProperty(e) || !employees[e]) continue;
      state.teamlessEmployees.push(employees[e]);
    }
  },
  saveEmployeesForTimesheet: (state, employees) => {
    state.timesheetEmployees.length = 0;
    for (let e in employees) {
      if (!employees.hasOwnProperty(e) || !employees[e]) continue;
      state.timesheetEmployees.push(employees[e]);
    }
  },
  saveWorkers: (state, workers) => {
    state.workers.length = 0;
    for (let e in workers) {
      if (!workers.hasOwnProperty(e) || !workers[e]) continue;
      state.workers.push(workers[e]);
    }
  },
  saveLeads: (state, leads) => {
    state.leads.length = 0;
    for (let e in leads) {
      if (!leads.hasOwnProperty(e) || !leads[e]) continue;
      state.leads.push(leads[e]);
    }
  },
  saveWorkersForTimesheet: (state, workers) => {
    state.timesheetWorkers.length = 0;
    for (let e in workers) {
      if (!workers.hasOwnProperty(e) || !workers[e]) continue;
      state.timesheetWorkers.push(workers[e]);
    }
  },
};

const getters = {
  employees: (state) => {
    return state.employees;
  },
  currentEmployees: (state) => {
    return state.employees.filter(
      (user) => !user.endDate || new Date(user.endDate) < new Date()
    );
  },
  loggingEmployees: (state) => {
    return state.employees.filter(
      (user) =>
        (!user.endDate || new Date(user.endDate) >= new Date()) &&
        user.loggingRequired
    );
  },
  teamlessEmployees: (state) => {
    return state.teamlessEmployees;
  },
  timesheetEmployees: (state) => {
    return state.timesheetEmployees;
  },
  workers: (state) => {
    return state.workers;
  },
  leads: (state) => {
    return state.leads;
  },
  timesheetWorkers: (state) => {
    return state.timesheetWorkers;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
