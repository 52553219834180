<template>
  <div class="onboarding-step-page-main">
    <div class="onboarding-step-page-content">
      <h2>
        {{
          $tf(
            "onboarding.setupUserParameters.title|Felhasználói attribútumok konfigurációja"
          )
        }}
      </h2>
      <p>
        {{
          $tf(
            "onboarding.setupUserParameters.subtitle|Ezeket szintén személyre szabhatod, vagy használhatod az alapértelmezett értékeket"
          )
        }}
      </p>
      <div class="parameters-container">
        <div
          v-for="(parameter, parameter_index) in Object.keys(parameters)"
          :key="parameter_index"
          class="parameter-group-card"
        >
          <h3>
            {{ TRANSLATION[parameter] }}
          </h3>
          <p>
            {{ DESCRIPTION[parameter] }}
          </p>
          <div class="is-flex has-gap-3 is-flex-wrap-wrap">
            <div
              v-for="(parameterValue, index) in parameters[parameter]"
              :key="index"
              class="parameter-card"
            >
              <b-input
                v-if="
                  editParameter === parameter && editParameterIndex === index
                "
                v-model="editParameterValue"
                size="is-small"
              />

              <span v-else>{{ parameterValue }}</span>
              <b-icon
                v-if="
                  editParameter === parameter && editParameterIndex === index
                "
                class="cursor-pointer has-text-blue"
                icon="save"
                size="is-small"
                @click="save"
              />
              <b-icon
                v-else
                class="cursor-pointer has-text-blue"
                icon="pencil"
                size="is-small"
                @click="edit(parameter, index)"
              />
              <b-icon
                class="cursor-pointer has-text-blue"
                icon="trash"
                size="is-small"
                @click="remove(parameter, index)"
              />
            </div>
            <div
              class="is-flex is-align-items-center has-text-blue has-font-weight-500 has-gap-2 cursor-pointer"
              @click="add(parameter)"
            >
              <b-icon icon="plus" size="is-small" />
              <span>{{
                $tf("onboarding.setupUserParameters.addNew|Újabb hozzáadása")
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="onboarding-step-page-footer">
      <div class="is-flex ml-auto has-gap-2">
        <b-button
          class="onboarding-button onboarding-button-transparent onboarding-button-small"
          @click="stepBack"
        >
          {{ $tf("onboarding.setupUserParameters.back|Vissza") }}
        </b-button>
        <b-button
          class="onboarding-button onboarding-button-small"
          type="is-info"
          @click="stepNext"
        >
          {{ $tf("onboarding.setupUserParameters.continue|Tovább") }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ONBOARDING_PAGES } from "@/utils/const";

export default {
  name: "OnboardingSetupUserParameters",
  data: function () {
    return {
      showEdit: false,
      ONBOARDING_PAGES,
      TRANSLATION: {
        titles: this.$tf(
          "onboarding.setupUserParameters.title_parameter|Munkakör"
        ),
        department: this.$tf(
          "onboarding.setupUserParameters.department|Terület"
        ),
        competencies: this.$tf(
          "onboarding.setupUserParameters.competencies|Kompetenciák"
        ),
        seniorities: this.$tf(
          "onboarding.setupUserParameters.seniorities|Szenioritás"
        ),
      },
      DESCRIPTION: {
        titles: this.$tf(
          "onboarding.setupUserParameters.title_parameter.description|Dolgozók pozíciója feladatainak, munkakörének megfelelően"
        ),
        department: this.$tf(
          "onboarding.setupUserParameters.department.description|Különböző típusú feladatokat összefoglaló területek a cégben"
        ),
        competencies: this.$tf(
          "onboarding.setupUserParameters.competencies.description|Dolgozók által ismert technológiák, módszertanok, egyéb skillek"
        ),
        seniorities: this.$tf(
          "onboarding.setupUserParameters.seniorities.description|Dolgozók lehetséges tapasztalati szintjei"
        ),
      },
      parameters: {
        titles: [],
        department: [],
        competencies: [],
        seniorities: [],
      },
      editParameter: null,
      editParameterIndex: null,
      editParameterValue: null,
    };
  },
  mounted() {
    this.parameters = this.$store.getters["onboarding/getCompetencies"];
  },
  methods: {
    add(parameter) {
      this.parameters[parameter].push("");
      this.parameters[parameter] = this.parameters[parameter];
      this.editParameter = parameter;
      this.editParameterIndex = this.parameters[parameter].length - 1;
    },
    remove(parameter, index) {
      let truncatedArray = this.parameters[parameter];
      truncatedArray.splice(index, 1);
      this.parameters[parameter] = truncatedArray;
    },
    edit(parameter, parameterIndex) {
      this.editParameter = parameter;
      this.editParameterIndex = parameterIndex;
      this.editParameterValue = this.parameters[parameter][parameterIndex];
    },
    save() {
      this.parameters[this.editParameter][this.editParameterIndex] =
        this.editParameterValue;
      this.editParameter = null;
      this.editParameterIndex = null;
      this.editParameterValue = null;
    },
    async saveRemote() {
      for (let param of Object.keys(this.parameters)) {
        this.parameters[param] = this.parameters[param].filter((p) => p !== "");
      }
      await this.$store.dispatch(
        "onboarding/updateCompetencies",
        this.parameters
      );
    },
    async stepBack() {
      await this.saveRemote();
      this.$emit("nextPage", ONBOARDING_PAGES.SETUP_ROLES);
    },
    async stepNext() {
      await this.saveRemote();
      this.$emit("nextPage", ONBOARDING_PAGES.SETUP_PRICE_LEVEL);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.parameters-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.parameters-table > tr > td:nth-child(1) {
  font-weight: 700;
}

.parameters-table > tr > td {
  border: none;
  vertical-align: middle;
}

.actionButtons {
  color: #4e75d9;
  display: flex;
  gap: 6px;
  cursor: pointer;
}

.parameters-edit-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 16px;
}

.parameters-edit-box {
  background: $white;
  border-radius: 5px;
  height: 412px;
  flex-grow: 1;
  padding: 12px;
  display: flex;
  flex-direction: column;
}

.parameters-edit-list {
  padding: 8px;
  flex-direction: column;
  display: flex;
  gap: 8px;
  max-height: 312px;
}

.parameter-group-card {
  border-radius: 8px;
  border: 2px solid #cad3de;
  padding: 16px;
  max-width: 812px;
}

.parameter-card {
  background-color: #f3f5f9;
  border-radius: 8px;
  padding: 8px 16px 8px 16px;
  font-weight: 700;
  color: $custom-dark-blue;
  font-size: 14px;
  display: flex;
  gap: 16px;
  align-items: center;
}
</style>
