<template>
  <div>
    <h3 class="title">
      <template v-if="editedActivity">
        {{
          $tf("activityForm.base.editTitle|{name} szerkesztése", {
            name: editedActivity.name,
          })
        }}
      </template>
      <template v-else>
        {{ $tf("activityForm.base.newTitle|Új aktivitás felvétele") }}
      </template>
    </h3>
    <div class="columns mt-5">
      <div class="column">
        <b-field
          :label="$tf('activityForm.base.name|Név')"
          label-position="on-border"
        >
          <b-input v-model="activityEdit.name" :expanded="true"></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field
          :label="$tf('activityForm.base.identifier|Azonosító')"
          label-position="on-border"
        >
          <b-input v-model="activityEdit.identifier" :expanded="true"></b-input>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field
          :label="$tf('activityForm.base.description|Leírás')"
          label-position="on-border"
        >
          <b-input v-model="activityEdit.description" type="textarea"></b-input>
        </b-field>
      </div>
    </div>
    <div class="mb-4">
      <external-issue-targeting-form
        v-model="activityEdit.externalIssueTargeting"
        :prefix="prefix"
        :project-identifier="projectIdentifier"
        :project-type="PROJECT_TYPES.SUPPORT"
        :value="activityEdit.externalIssueTargeting"
        @loading="isTargetingLoading = $event"
      />
    </div>
    <div class="columns">
      <div class="column">
        <b-button :expanded="true" type="is-info" @click="createActivity"
          >{{ $tf("activityForm.createActivity|Mentés") }}
        </b-button>
      </div>
    </div>
    <b-loading
      v-model="isTargetingLoading"
      :can-cancel="false"
      :is-full-page="false"
    />
  </div>
</template>

<script>
import ExternalIssueTargetingForm from "@/components/enterprise/targeting/ExternalIssueTargetingForm.vue";
import { PROJECT_TYPES } from "@/utils/const";
import { correctWithTimezoneOffset, deepCopy } from "@/utils/util";

export default {
  name: "SupportActivityForm",
  components: { ExternalIssueTargetingForm },
  emits: ["activity-submitted"],
  props: {
    projectIdentifier: {
      type: String,
      required: true,
    },
    prefix: {
      type: String,
      required: true,
    },
    activityType: {
      type: String,
      required: true,
    },
    editedActivity: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      activityEdit: {
        status: "ONGOING",
        externalIssueTargeting: {},
      },
      PROJECT_TYPES,
      isTargetingLoading: false,
    };
  },
  mounted() {
    if (this.editedActivity) {
      this.activityEdit = deepCopy(this.editedActivity);
      if (this.activityEdit.invoiceDate) {
        this.activityEdit.invoiceDate = new Date(this.activityEdit.invoiceDate);
      }
    }
  },
  methods: {
    async createActivity() {
      if (this.activityType === "BILLABLE" && !this.activityEdit.invoiceDate) {
        this.$buefy.toast.open({
          duration: 5000,
          message: this.$tf(
            `supportActivityForm.create.error.missingInvoiceDate|Elszámolás dátum megadása <strong>KÖTELEZŐ</strong>!`
          ),
          position: "is-bottom",
          type: "is-danger",
        });
        return;
      }
      this.activityEdit.type = this.activityType;
      const request = deepCopy(this.activityEdit);
      if (request.invoiceDate) {
        request.invoiceDate = correctWithTimezoneOffset(
          new Date(request.invoiceDate)
        );
      }
      delete request.externalIssueTargeting;
      if (this.editedActivity) {
        await this.$store.dispatch("support_projects/updateActivity", request);
      } else {
        await this.$store.dispatch("support_projects/createActivity", {
          identifier: this.projectIdentifier,
          activity: request,
        });
      }
      this.$emit("activity-submitted");
    },
  },
};
</script>

<style lang="scss" scoped></style>
