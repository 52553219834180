<template>
  <div class="modal-card" style="width: auto">
    <div class="container" style="width: 42rem">
      <section class="modal-card-body">
        <h1 v-if="createMode" class="title">
          {{
            $tf("tiers.fixedPrice.modal.newTitle|Új egyedi árszint felvétele")
          }}
        </h1>
        <h1 v-else class="title">
          {{
            $tf("tiers.fixedPrice.modal.editTitle|Egyedi árszint szerkesztése")
          }}
        </h1>

        <form v-if="loaded">
          <vuelidated-input
            v-model="editFixedPriceTier.cost"
            :label="$tf('tiers.fixedPrice.modal.cost|Óradíj')"
            :placeholder="$tf('tiers.fixedPrice.modal.cost|Óradíj')"
            :validation-rule="v$.editFixedPriceTier.cost"
            class="mt-5"
            label-position="on-border"
          ></vuelidated-input>

          <vuelidated-form
            :validations="v$.editFixedPriceTier.userId"
            class="mt-5"
          >
            <template v-slot="{ errors, invalid }">
              <b-field
                :message="
                  v$.editFixedPriceTier.userId.$error ? errors[0] : null
                "
                :type="{ 'is-danger': invalid }"
                label-position="on-border"
              >
                <template #label>
                  {{ $tf("tiers.fixedPrice.modal.user|Dolgozó") }}
                  <span class="has-text-danger">*</span>
                </template>

                <b-autocomplete
                  v-model="editFixedPriceTier.displayName"
                  :data="selectableNewUsers"
                  :placeholder="
                    $tf(
                      'tiers.fixedPrice.modal.user.placeholder|pl. Elektrom Ágnes'
                    )
                  "
                  clearable
                  field="name"
                  icon="search"
                  rounded
                  @blur="v$.editFixedPriceTier.userId.$touch()"
                  @select="
                    (option) =>
                      (editFixedPriceTier.userId = option ? option.id : null)
                  "
                  @update:modelValue="
                    selectableNewUsers = filterEmployees(
                      editFixedPriceTier.displayName
                    )
                  "
                >
                  <template #empty
                    >{{
                      $tf("tiers.fixedPrice.modal.user.empty|Nincs találat")
                    }}
                  </template>
                </b-autocomplete>
              </b-field>
            </template>
          </vuelidated-form>

          <b-field
            :label="$tf('tiers.fixedPrice.modal.validFrom|Érvényesség kezdete')"
            class="mt-5"
            label-position="on-border"
          >
            <f-datepicker
              v-model="editFixedPriceTier.date"
              :placeholder="
                $tf(
                  'tiers.fixedPrice.modal.validFrom.placeholder|Kezdeti dátum'
                )
              "
              append-to-body
              icon="calendar-alt"
            >
            </f-datepicker>
          </b-field>

          <b-field class="mt-5 is-pulled-right">
            <b-button @click="close">
              {{ $tf("tiers.fixedPrice.modal.cancel|Mégse") }}
            </b-button>
            <b-button class="ml-3" type="is-info" @click="save">
              {{ $tf("tiers.fixedPrice.modal.save|Mentés") }}
            </b-button>
          </b-field>
        </form>

        <LoadingSkeleton v-else />
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "@vuelidate/validators";
import VuelidatedForm from "@/components/module/input/VuelidatedForm";
import VuelidatedInput from "@/components/module/input/VuelidatedInput";
import { deepCopy, employeeFilter, formatDate } from "@/utils/util";
import LoadingSkeleton from "../../../../components/module/loading/LoadingSkeleton";
import { getCurrentInstance } from "vue";
import FDatepicker from "@/components/module/input/FDatepicker.vue";
import useCustomVuelidate from "@/plugins/vuelidate";

export default {
  name: "FixedPriceTierEditModal",
  components: { FDatepicker, LoadingSkeleton, VuelidatedForm, VuelidatedInput },
  setup: () => {
    const instance = getCurrentInstance();
    const { v$, touch$ } = useCustomVuelidate();
    return {
      v$,
      touch$,
      instance,
    };
  },
  props: {
    fixedPriceTier: {
      type: Object,
      required: false,
    },
  },
  async mounted() {
    this.createMode = !this.fixedPriceTier;
    //await this.$store.dispatch("employee/fetch");

    if (this.fixedPriceTier) {
      this.editFixedPriceTier = deepCopy(this.fixedPriceTier);
      this.editFixedPriceTier.date = new Date(this.fixedPriceTier.since);
    }

    this.loaded = true;
  },
  data() {
    return {
      loaded: false,
      createMode: false,
      editFixedPriceTier: {
        userId: undefined,
        date: new Date(),
        displayName: undefined,
      },
      selectableNewUsers: [],
      selectableEditUsers: [],
    };
  },
  validations: {
    editFixedPriceTier: {
      userId: {
        required,
      },
      cost: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      employees: "employee/employees",
    }),
  },
  methods: {
    close() {
      this.instance.emit("close");
    },
    filterEmployees(name) {
      return employeeFilter(name, this.employees || []);
    },
    async save() {
      this.touch$();
      if (!this.v$.$invalid) {
        let request = deepCopy(this.editFixedPriceTier);
        request.since = formatDate(this.editFixedPriceTier.date);

        if (this.createMode) {
          await this.$store.dispatch(
            "enterprise_tiers/createFixedPriceTier",
            request
          );
        } else {
          await this.$store.dispatch(
            "enterprise_tiers/modifyFixedPriceTier",
            request
          );
        }
        this.$emit("save");
        this.close();
      }
    },
  },
};
</script>
