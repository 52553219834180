<template>
  <div>
    <ForecastSinceStartTable
      :project-details="projectDetails"
      class="is-flex-1"
    ></ForecastSinceStartTable>
  </div>
</template>

<script>
import ForecastSinceStartTable from "@/components/enterprise/project/module/forecast/ForecastSinceStartTable.vue";

export default {
  name: "ForecastSinceStartStatusOverview",
  components: {
    ForecastSinceStartTable,
  },
  mixins: [],
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
    projectData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
