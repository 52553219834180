import StoreCore from "../../StoreCore";
import axios from "axios";
import { isNullOrUndefined } from "@/utils/util";
import {
  ISSUE_TRACKING_CONST,
  ISSUE_TRACKING_SYSTEM,
  UI_THEMES,
} from "@/utils/const";

const urls = {
  config: "/api/tenant/ui-config",
};

const initState = () => {
  return {
    flags: {},
    enterprise: {},
    issueTracking: {},
    currentTheme: UI_THEMES.SYSTEM,
  };
};

const storeCore = new StoreCore();
const actions = {
  fetch: async function ({ commit }, tenantId) {
    try {
      const { data } = await axios.get(urls.config, {
        headers: {
          "X-Tenant-Id": tenantId,
        },
      });
      commit("save", data);
    } catch (err) {
      console.error(err);
      await Promise.reject(err);
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  save: (state, data) => {
    state.flags = data["flags"];
    state.enterprise = data["enterprise"];
    state.issueTracking = {
      primaryIssueTracking: data.primaryIssueTracking,
      additionalIssueTrackings: data.additionalIssueTrackings,
    };
  },
  setCurrentTheme: (state, theme) => {
    state.currentTheme = theme;
  },
};

const getters = {
  isLoaded: (state) => {
    return (
      Object.keys(state.flags ?? []).length &&
      Object.keys(state.enterprise ?? []).length
    );
  },
  getCurrencySymbol: (state) => {
    return state.enterprise?.currency?.symbol ?? "";
  },
  getCurrencyTier: (state) => (tier) => {
    return state.enterprise?.currency?.tiers?.find((t) => t.tier === tier);
  },
  getCurrencyPrecision: (state) => {
    return isNullOrUndefined(state.enterprise?.currency?.decimals)
      ? 2
      : state.enterprise.currency.decimals;
  },
  getCurrencyIsPrefix: (state) => {
    return !!state.enterprise.currency.prefixed;
  },
  getPrimaryIssueTrackingData: (state) => {
    return state.issueTracking.primaryIssueTracking;
  },
  getAdditionalIssueTrackingData: (state) => {
    return Object.keys(
      state.issueTracking.additionalIssueTrackings || {}
    ).reduce((acc, key) => {
      const item = state.issueTracking.additionalIssueTrackings[key];
      if (item[ISSUE_TRACKING_CONST.ISSUE_TRACKING_IDENTIFIER_FIELD] != null) {
        acc[item[ISSUE_TRACKING_CONST.ISSUE_TRACKING_IDENTIFIER_FIELD]] = item;
      }
      return acc;
    }, {});
  },
  getIssueTrackingDataForIdentifier: (state) => (identifier) => {
    return identifier === ISSUE_TRACKING_CONST.PRIMARY_ISSUE_TRACKING_CONFIG_KEY
      ? state.issueTracking.primaryIssueTracking
      : state.issueTracking.additionalIssueTrackings?.find(
          (tracking) =>
            tracking[ISSUE_TRACKING_CONST.ISSUE_TRACKING_IDENTIFIER_FIELD] ===
            identifier
        ) || {};
  },
  isUsingSelftracking: (state, getters) => {
    return Object.values(getters.getAllIssueTrackingData).some(
      (tracking) => tracking.system === ISSUE_TRACKING_SYSTEM.SELF.enum
    );
  },
  isUsingExternalTracking: (state, getters) => {
    return Object.values(getters.getAllIssueTrackingData).some(
      (tracking) => tracking.system !== ISSUE_TRACKING_SYSTEM.SELF.enum
    );
  },
  getAllIssueTrackingData: (state, getters) => {
    return {
      [ISSUE_TRACKING_CONST.PRIMARY_ISSUE_TRACKING_CONFIG_KEY]:
        state.issueTracking.primaryIssueTracking,
      ...getters.getAdditionalIssueTrackingData,
    };
  },
  getFlags: (state) => {
    return state.flags;
  },
  getEnterprise: (state) => {
    return state.enterprise;
  },
  getRiskKPILimits: (state) => {
    return [
      state.enterprise?.lowerRiskKPILimit ?? 1,
      state.enterprise?.upperRiskKPILimit ?? 1,
    ];
  },
  getCurrentTheme: (state) => {
    return state.currentTheme;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
