<template>
  <div class="modal-card" style="width: auto">
    <div class="container overflow-y-auto" style="width: 30rem">
      <section class="modal-card-body">
        <template v-if="loading">
          <LoadingSkeleton />
        </template>
        <template v-else>
          <h1 v-if="isCreate" class="title">Új dimenzió érték létrehozása</h1>
          <h1 v-else class="title">{{ dimensionValueName }} módosítása</h1>

          <form class="mt-5" @submit.prevent="submit">
            <b-field class="mb-1">
              <vuelidated-input
                v-model="editData.name"
                :validation-rule="v$.editData.name"
                label="Dimenzió érték"
                label-position="on-border"
                placeholder="Dimenzió érték megnevezése"
              />
            </b-field>

            <b-field label="Projektek">
              <b-taginput
                v-model="editData.projects"
                :allow-new="false"
                :data="filteredProjects"
                :open-on-focus="true"
                append-to-body
                autocomplete
                field="name"
                icon="list"
                label-position="on-border"
                placeholder="Projektek választása"
                @typing="filterProjects"
              >
              </b-taginput>
            </b-field>

            <b-field class="mt-5 is-pulled-right">
              <b-button @click="close"> Mégse</b-button>
              <b-button class="ml-2" native-type="submit" type="is-info">
                Mentés
              </b-button>
            </b-field>
          </form>
        </template>
      </section>
    </div>
  </div>
</template>

<script>
import { maxLength, required } from "@vuelidate/validators";
import VuelidatedInput from "@/components/module/input/VuelidatedInput";
import { getCurrentInstance } from "vue";
import LoadingSkeleton from "@/components/module/loading/LoadingSkeleton.vue";
import useCustomVuelidate from "@/plugins/vuelidate";

export default {
  name: "ProjectDimensionValueForm",
  components: { LoadingSkeleton, VuelidatedInput },
  setup: () => {
    const instance = getCurrentInstance();
    const { v$, touch$ } = useCustomVuelidate();
    return {
      v$,
      touch$,
      instance,
    };
  },
  props: {
    dimensionValue: {
      type: Object,
      required: false,
      default: null,
    },
    dimensionId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dimensionValueName: "",
      editData: {},
      isCreate: true,
      projects: [],
      filteredProjects: [],
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch("enterprise_core/fetchLimitedProjects");
    this.$store.getters["enterprise_core/limitedProjects"].forEach(
      (project) => {
        this.projects.push({
          identifier: project.identifier,
          name: project.name,
          type: project.type,
        });
      }
    );

    if (this.dimensionValue) {
      this.isCreate = false;
      this.dimensionValueName = this.dimensionValue.name;
      this.editData = JSON.parse(JSON.stringify(this.dimensionValue));

      let selectedProjects = [];
      if (this.editData.projects) {
        this.editData.projects.forEach((dimValProject) => {
          selectedProjects.push(
            this.projects.find((project) => {
              return project.identifier === dimValProject.identifier;
            })
          );
        });
      }
      this.filteredProjects = this.projects;
      this.editData.projects = selectedProjects;
    } else {
      this.isCreate = true;
    }
    this.loading = false;
  },
  validations: {
    editData: {
      name: {
        required,
        maxLength: maxLength(255),
      },
    },
  },
  methods: {
    close() {
      this.instance.emit("close");
    },
    afterSave() {
      this.$emit("after-save");
      this.close();
    },
    filterProjects(text) {
      this.filteredProjects = this.projects.filter((projects) => {
        return (
          projects.name.toString().toLowerCase().indexOf(text.toLowerCase()) >=
          0
        );
      });
    },
    submit() {
      this.touch$();
      if (this.v$.$invalid) {
        return;
      }

      if (this.isCreate) {
        this.editData.dimensionId = this.dimensionId;
        this.$store
          .dispatch("project_dimensions/createDimensionValue", this.editData)
          .then(() => {
            this.afterSave();
          });
      } else {
        this.$store
          .dispatch("project_dimensions/updateDimensionValue", this.editData)
          .then(() => {
            this.afterSave();
          });
      }
    },
  },
};
</script>
