<template>
  <div class="is-flex is-flex-direction-column has-gap-4">
    <div>
      <h3 class="title">
        {{ $tf("contractEdit.base.title|Szerződés szerkesztése") }}
      </h3>
      <div class="columns mt-5">
        <div class="column">
          <b-field>
            <b-switch v-model="allMonths">Összes hónap</b-switch>
          </b-field>
        </div>
        <div class="column">
          <b-field
            :label="$tf('contractEdit.base.changeFrom|Változás kezdete')"
            label-position="on-border"
          >
            <vuelidated-input
              v-model="contractEdit.changeFrom"
              :disabled="allMonths"
              :placeholder="$tf('moneyModal.choose|Válassz')"
              :validation-rule="v$.contractEdit.changeFrom"
              icon="calendar-day"
              type="date"
            >
            </vuelidated-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field
            :label="$tf('contractForm.base.contractStart|Szerződés kezdete')"
            label-position="on-border"
          >
            <vuelidated-input
              v-model="contractEdit.contractStart"
              :placeholder="$tf('moneyModal.choose|Válassz')"
              :validation-rule="v$.contractEdit.contractStart"
              icon="calendar-day"
              type="date"
            >
            </vuelidated-input>
          </b-field>
        </div>
        <div class="column">
          <b-field
            :label="$tf('contractForm.base.contractExpiry|Szerződés vége')"
            label-position="on-border"
          >
            <vuelidated-input
              v-model="contractEdit.contractExpiry"
              :placeholder="$tf('moneyModal.choose|Válassz')"
              :validation-rule="v$.contractEdit.contractExpiry"
              icon="calendar-day"
              type="date"
            >
            </vuelidated-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <money-input
            v-model="contractEdit.hourlyFee"
            :label="$tf('contractForm.base.hourlyFee|Óradíj')"
            :validation-rule="v$.contractEdit.hourlyFee"
            label-position="on-border"
          />
        </div>
        <div class="column">
          <b-field
            :label="$tf('contractForm.base.monthlyHours|Havi óraszám')"
            label-position="on-border"
          >
            <vuelidated-input
              v-model="contractEdit.monthlyHours"
              :expanded="true"
              :validation-rule="v$.contractEdit.monthlyHours"
              type="number"
            ></vuelidated-input>
          </b-field>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-button
          :expanded="true"
          :loading="buttonLoading"
          type="is-info"
          @click="editContract"
          >Mentés
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import MoneyInput from "@/components/module/input/MoneyInput.vue";
import { PROJECT_TYPES } from "@/utils/const";
import { minValue, required, requiredIf } from "@vuelidate/validators";
import VuelidatedInput from "@/components/module/input/VuelidatedInput.vue";
import { correctWithTimezoneOffset, deepCopy } from "@/utils/util";
import useCustomVuelidate from "@/plugins/vuelidate";

export default {
  name: "OutsourceContractEdit",
  components: { VuelidatedInput, MoneyInput },
  emits: ["contract-edited"],
  setup: () => useCustomVuelidate(),
  props: {
    contractData: {
      type: Object,
      required: true,
    },
    projectIdentifier: {
      type: String,
      required: true,
    },
    prefix: {
      type: String,
      required: true,
    },
  },
  computed: {},
  data() {
    return {
      contractEdit: {},
      contractName: "",
      PROJECT_TYPES,
      allMonths: false,
      buttonLoading: false,
    };
  },
  validations: {
    contractEdit: {
      contractStart: {
        required,
      },
      contractExpiry: {
        required,
      },
      monthlyHours: {
        minValue: minValue(1),
        required,
      },
      hourlyFee: {
        minValue: minValue(1),
        required,
      },
      changeFrom: {
        required: requiredIf(function () {
          return !this.allMonths;
        }),
      },
    },
  },
  methods: {
    async editContract() {
      this.touch$();
      if (this.v$.$invalid) {
        return;
      }
      this.buttonLoading = true;
      const request = deepCopy(this.contractEdit);
      request.contractStart = correctWithTimezoneOffset(
        new Date(request.contractStart)
      );
      request.contractExpiry = correctWithTimezoneOffset(
        new Date(request.contractExpiry)
      );
      request.changeFrom = this.allMonths
        ? null
        : correctWithTimezoneOffset(new Date(request.changeFrom));
      try {
        await this.$store.dispatch(
          "outsource_projects/updateContract",
          request
        );
        this.$emit("contract-edited");
      } catch (ex) {
        console.error("Something went wrong during updateContract", ex);
      } finally {
        this.buttonLoading = false;
      }
    },
  },
  mounted() {
    this.contractEdit = deepCopy(this.contractData);
    this.contractEdit.contractStart = new Date(this.contractData.contractStart);
    this.contractEdit.contractExpiry = new Date(
      this.contractData.contractExpiry
    );
  },
};
</script>

<style lang="scss" scoped></style>
