<template>
  <div
    class="is-flex is-flex-direction-column is-align-items-center has-gap-5 is-align-self-center"
    style="width: 100%"
  >
    <template v-if="finished">
      <h2>{{ $tf("onboarding.finish.title|Kész is vagyunk!") }}</h2>
      <p class="has-text-centered">
        {{
          $tf(
            "onboarding.finish.subtitle.doneWithSettings|A legfontosabb beállításokkal végeztél."
          )
        }}
        <br />
        {{
          $tf(
            "onboarding.finish.subtitle.checkEmail|Ellenőrizd a postafiókod, ahol a belépéshez szükséges információkat találod."
          )
        }}
        <br />
      </p>
      <div style="width: 192px; height: 192px">
        <OnboardingSyncSuccessIllustration />
      </div>
    </template>
    <template v-else-if="error">
      <h2>
        {{
          $tf("onboarding.finish.anErrorOccurred|Ajjaj... hibába ütköztünk!")
        }}
      </h2>
      <div class="has-text-centered">
        <p>
          {{
            $tf(
              "onboarding.finish.anErrorOccurred.message|Sajnos az adatok feldolgozása során hiba lépett fel. Kérjük, próbáld újra, vagy vedd fel velünk a kapcsolatot!"
            )
          }}
        </p>
      </div>
      <b-button
        id="retryButton"
        class="onboarding-button has-text-white"
        @click="retry"
      >
        {{ $tf("onboarding.finish.retry|Újra megpróbálom") }}
      </b-button>
    </template>
    <template v-else>
      <h2 class="has-text-centered">
        {{
          $tf(
            "onboarding.finish.processingData|Az adatok feldolgozása folyamatban van..."
          )
        }}
      </h2>
      <div style="width: 128px; height: 128px">
        <OnboardingSpinner />
      </div>
      <div style="width: 80%; max-width: 500px">
        <b-progress
          :value="percent"
          format="percent"
          show-value
          size="is-medium"
          type="is-info"
        />
      </div>
      <div class="has-text-centered">
        <p>
          {{ summary }}
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OnboardingSpinner from "@/components/onboarding/OnboardingSpinner.vue";
import OnboardingSyncSuccessIllustration from "@/components/onboarding/OnboardingSyncSuccessIllustration.vue";

export default {
  name: "OnboardingFinish",
  components: { OnboardingSyncSuccessIllustration, OnboardingSpinner },
  mounted() {
    if (!this.finished) {
      this.finishOnboarding();
    }
  },
  data() {
    return {
      error: false,
      percent: 0,
      summary: "",
    };
  },
  computed: {
    ...mapGetters({
      finished: "onboarding/getFinished",
    }),
  },
  methods: {
    retry() {
      const button = document.getElementById("retryButton");
      button.disabled = true;
      setTimeout(() => {
        button.disabled = false;
      }, 1000);
      this.error = false;
      this.summary = "";
      this.percent = 0;
      this.finishOnboarding();
    },
    finishOnboarding: function () {
      const evtSource = new EventSource(
        "/api/tenant/onboarding/step/" +
          this.$route.params.onboardingToken +
          "/finish"
      );

      evtSource.onerror = (event) => {
        this.error = true;
      };

      evtSource.onmessage = (event) => {
        let data = JSON.parse(event.data);
        if (data.isError) {
          this.error = true;
          evtSource.close();
        } else {
          this.percent = data.percent;
          this.summary = data.summary;
          if (data.percent === 100) {
            evtSource.close();
            this.$store.dispatch("onboarding/fetchInfo");
          }
        }
      };
    },
  },
};
</script>

<style scoped></style>
