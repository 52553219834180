<template>
  <div class="tig-bars">
    <tig-bar
      v-for="(obj, index) of orderedValues"
      :key="index"
      :count="obj.count"
      :header="obj.header"
      :new-line="newLine"
      :size="obj.size"
      :type="obj.type"
    />
  </div>
</template>

<script>
import { deepCopy } from "@/utils/util";
import TigBar from "@/components/enterprise/ProjectDetails/TigBar";

export default {
  name: "TigBarList",
  components: { TigBar },

  props: {
    values: {
      type: Array,
      required: true,
    },
    newLine: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    orderedValues() {
      let ordered = deepCopy(this.values);
      return ordered.sort((a, b) => b.count - a.count);
    },
  },
};
</script>

<style lang="scss" scoped>
.tig-bars {
  .tig-bar-main {
    margin-top: 32px;
  }

  width: 100%;
}
</style>
