<template>
  <section class="section">
    <div class="container">
      <h1 class="title">{{ $tf("reports.title|Jelentések") }}</h1>
      <h2 class="subtitle">
        {{ $tf("reports.subtitle|Kimutatások exportálása Excel formátumban") }}
      </h2>
    </div>
    <div class="is-flex is-justify-content-center">
      <div style="width: 500px">
        <div class="container">
          <ExcelReports></ExcelReports>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ExcelReports from "@/components/absence/admin/ExcelReports";

export default {
  name: "AbsenceReports",
  components: {
    ExcelReports,
  },
  data() {
    return {
      isAddUserModalActive: false,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.plusIcon:hover {
  background-color: $primary;
  color: $white;
}
</style>
<style lang="scss">
@import "~@/assets/scss/colors.scss";

.adminTab .tabs li.is-active a {
  color: $primary;
}
</style>
