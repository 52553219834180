<template>
  <div class="scrollable-modal-card">
    <div class="container">
      <header class="modal-card-head">
        <h1 v-if="newUser" class="title">
          {{ $tf("userModal.newTitle|Új munkatárs felvétele") }}
        </h1>
        <h1 v-else class="title">
          {{
            $tf("userModal.editTitle|{name} módosítása", {
              name: userData.name,
            })
          }}
        </h1>
      </header>
      <section class="modal-card-body">
        <form>
          <b-tabs v-model="activeTab" class="mb-2 toggle-only">
            <b-tab-item
              v-for="key in Object.keys(USER_MODAL_TABS)"
              :key="`userModal.tab.${key}`"
              :label="USER_MODAL_TABS[key]"
              :value="key"
            ></b-tab-item>
          </b-tabs>
          <div v-show="activeTab === 'BASIC_DATA'">
            <h3 class="heading">
              {{ $tf("userForm.basics.title|Alapadatok") }}
            </h3>

            <div class="columns">
              <div class="column">
                <b-field
                  :label="$tf('userForm.identifier|Azonosító')"
                  expanded
                  label-position="on-border"
                >
                  <b-input :value="userId" disabled></b-input>
                </b-field>
              </div>

              <div class="column">
                <vuelidated-input
                  v-model="userEdit.name"
                  :label="$tf('userForm.name|Név')"
                  :placeholder="$tf('userForm.name.placeholder|Munkatárs neve')"
                  :ref-value="'name'"
                  :validation-rule="v$.userEdit.name"
                  expanded
                  label-position="on-border"
                />
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <vuelidated-input
                  :disabled="!newUser"
                  :label="$tf('userForm.username|Felhasználónév')"
                  :model-value="userEdit.username"
                  :placeholder="
                    $tf(
                      'userForm.username.placeholder|Munkatárs felhasználóneve'
                    )
                  "
                  :validation-rule="v$.userEdit.username"
                  expanded
                  label-position="on-border"
                  @update:modelValue="
                    (val) => {
                      if (newUser) userEdit.username = val;
                    }
                  "
                />
              </div>
              <div class="column">
                <vuelidated-input
                  v-model="userEdit.email"
                  :label="$tf('userForm.email|E-mail cím')"
                  :placeholder="
                    $tf('userForm.email.placeholder|Munkatárs e-mail címe')
                  "
                  :validation-rule="v$.userEdit.email"
                  expanded
                  label-position="on-border"
                />
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <phone-input
                  ref="phoneInput"
                  v-model="userEdit.phone"
                  :force-error="forcePhoneInputError"
                  :label="$tf('userForm.phone|Telefonszám')"
                  :placeholder="
                    $tf('userForm.phone.placeholder|Munkatárs telefonszáma')
                  "
                  class="phone-input"
                  data-field="phone"
                  required
                  @validate="validPhoneInput = $event"
                />
              </div>

              <div class="column">
                <div
                  class="switch-column is-flex has-gap-5 is-align-items-start"
                >
                  <b-switch
                    v-model="userEdit.locked"
                    :rounded="false"
                    class="field"
                    type="is-danger"
                  >
                    <label>{{ $tf("userForm.locked|Zárolt") }}</label>
                  </b-switch>

                  <b-switch
                    v-model="userEdit.hidden"
                    :rounded="false"
                    class="field"
                    type="is-danger"
                  >
                    <label>{{ $tf("userForm.hidden|Rejtett") }}</label>
                  </b-switch>
                </div>
              </div>
            </div>

            <div v-if="!newUser" class="columns">
              <div class="column">
                <b-field>
                  <b-button type="is-info" @click="resetPassword">
                    {{ $tf("userForm.resetPassword|Jelszó helyreállítása") }}
                  </b-button>
                </b-field>
              </div>

              <div class="column"></div>
            </div>
          </div>

          <div v-show="activeTab === 'COMPANY_SETTINGS'">
            <h3 class="heading">
              {{ $tf("userForm.companySettings.title|Céges beállítások") }}
            </h3>

            <div class="columns">
              <div class="column">
                <vuelidated-input
                  v-model="userEdit.startDate"
                  :label="$tf('userForm.startDate|Belépés dátuma')"
                  :placeholder="
                    $tf('userForm.startDate.placeholder|Belépés dátuma')
                  "
                  :validation-rule="v$.userEdit.startDate"
                  editable
                  expanded
                  icon="calendar-week"
                  label-position="on-border"
                  position="is-bottom-right"
                  type="date"
                />
              </div>
              <div class="column">
                <b-field
                  :label="$tf('userForm.endDate|Kilépés dátuma')"
                  expanded
                  label-position="on-border"
                >
                  <f-datepicker
                    v-model="userEdit.endDate"
                    :placeholder="
                      $tf('userForm.endDate.placeholder|Kilépés dátuma')
                    "
                    editable
                    icon="calendar-week"
                    position="is-bottom-right"
                  />
                </b-field>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <vuelidated-input
                  v-model="userEdit.parentCompany"
                  :disabled="!userEdit.external"
                  :label="$tf('userForm.parentCompany|Anyacég')"
                  :placeholder="
                    $tf('userForm.parentCompany.placeholder|Anyacég')
                  "
                  :validation-rule="v$.userEdit.parentCompany"
                  expanded
                  label-position="on-border"
                />
              </div>
              <div class="column">
                <div class="checkbox-column">
                  <b-field>
                    <b-checkbox v-model="userEdit.external"
                      >{{ $tf("userForm.external|Külsős") }}
                    </b-checkbox>
                  </b-field>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="checkbox-column">
                  <b-field>
                    <b-checkbox
                      v-model="userEdit.loggingRequired"
                      :disabled="
                        Object.values(createsExternalUser).some((v) => v)
                      "
                    >
                      {{
                        $tf(
                          "userForm.loggingRequired|Timesheetelésben részt vesz"
                        )
                      }}
                    </b-checkbox>
                  </b-field>
                </div>
              </div>
            </div>
            <div
              v-if="
                primaryIssueTrackingSystem !== ISSUE_TRACKING_SYSTEM.SELF.enum
              "
              class="columns"
            >
              <div class="column">
                <vuelidated-input
                  v-model="userEdit.primaryExternalId"
                  :disabled="
                    !userEdit.loggingRequired ||
                    createsExternalUser[
                      ISSUE_TRACKING_CONST.PRIMARY_ISSUE_TRACKING_CONFIG_KEY
                    ]
                  "
                  :label="
                    $tf('userForm.primaryExternalId|Elsődleges external ID')
                  "
                  :placeholder="
                    createsExternalUser[
                      ISSUE_TRACKING_CONST.PRIMARY_ISSUE_TRACKING_CONFIG_KEY
                    ]
                      ? $tf(
                          'userForm.primaryExternalId.generated|Később kerül generálásra'
                        )
                      : $tf(
                          'userForm.primaryExternalId.placeholder|Külső azonosító'
                        )
                  "
                  :validation-rule="v$.userEdit.primaryExternalId"
                  icon="fingerprint"
                  label-position="on-border"
                />
              </div>
              <div v-if="newUser" class="column">
                <div class="checkbox-column">
                  <b-checkbox
                    v-model="
                      createsExternalUser[
                        ISSUE_TRACKING_CONST.PRIMARY_ISSUE_TRACKING_CONFIG_KEY
                      ]
                    "
                    :disabled="!userEdit.loggingRequired"
                    >{{
                      $tf(
                        "userForm.createPrimaryExternalUser|Külső felhasználó létrehozása"
                      )
                    }}
                  </b-checkbox>
                </div>
              </div>
              <div v-else class="column">
                <b-button
                  v-if="!userEdit.primaryExternalId"
                  :disabled="
                    !userEdit.loggingRequired ||
                    createsExternalUser[
                      ISSUE_TRACKING_CONST.PRIMARY_ISSUE_TRACKING_CONFIG_KEY
                    ]
                  "
                  :loading="
                    createsExternalUser[
                      ISSUE_TRACKING_CONST.PRIMARY_ISSUE_TRACKING_CONFIG_KEY
                    ]
                  "
                  type="is-info"
                  @click="
                    userData.email !== userEdit.email
                      ? openExternalUserCreationConfirm(
                          ISSUE_TRACKING_CONST.PRIMARY_ISSUE_TRACKING_CONFIG_KEY
                        )
                      : createExternalUser(
                          ISSUE_TRACKING_CONST.PRIMARY_ISSUE_TRACKING_CONFIG_KEY
                        )
                  "
                  >{{
                    createsExternalUser[
                      ISSUE_TRACKING_CONST.PRIMARY_ISSUE_TRACKING_CONFIG_KEY
                    ]
                      ? ""
                      : $tf(
                          "userForm.createPrimaryExternalUser|Külső felhasználó létrehozása"
                        )
                  }}
                </b-button>
              </div>
            </div>
            <div
              v-for="issueTrackingIdentifier in Object.keys(
                nonSelfTrackingAdditionalIssueTrackings
              )"
              :key="`userModal.externalIdRow.${issueTrackingIdentifier}`"
              class="columns"
            >
              <div class="column">
                <vuelidated-input
                  v-model="
                    userEdit.additionalExternalIds[issueTrackingIdentifier]
                  "
                  :disabled="
                    !userEdit.loggingRequired ||
                    createsExternalUser[issueTrackingIdentifier]
                  "
                  :label="
                    $tf(
                      'userForm.additionalExternalIdForIdentifier|External ID ({issueTrackingIdentifier})',
                      { issueTrackingIdentifier }
                    )
                  "
                  :placeholder="
                    createsExternalUser[issueTrackingIdentifier]
                      ? $tf(
                          'userForm.additionalExternalId.generated|Később kerül generálásra'
                        )
                      : $tf(
                          'userForm.additionalExternalId.placeholder|Külső azonosító'
                        )
                  "
                  :validation-rule="
                    v$.userEdit.additionalExternalIds[issueTrackingIdentifier]
                  "
                  icon="fingerprint"
                  label-position="on-border"
                />
              </div>
              <div v-if="newUser" class="column">
                <div class="checkbox-column">
                  <b-checkbox
                    v-model="createsExternalUser[issueTrackingIdentifier]"
                    :disabled="!userEdit.loggingRequired"
                    >{{
                      $tf(
                        "userForm.createExternalUserForIdentifier|{issueTrackingIdentifier} felhasználó létrehozása",
                        {
                          issueTrackingIdentifier,
                        }
                      )
                    }}
                  </b-checkbox>
                </div>
              </div>
              <div v-else class="column">
                <b-button
                  v-if="
                    !userEdit.additionalExternalIds[issueTrackingIdentifier]
                  "
                  :disabled="
                    !userEdit.loggingRequired ||
                    createsExternalUser[issueTrackingIdentifier]
                  "
                  :loading="createsExternalUser[issueTrackingIdentifier]"
                  type="is-info"
                  @click="
                    userData.email !== userEdit.email
                      ? openExternalUserCreationConfirm(issueTrackingIdentifier)
                      : createExternalUser(issueTrackingIdentifier)
                  "
                  >{{
                    createsExternalUser[issueTrackingIdentifier]
                      ? ""
                      : $tf(
                          "userForm.createExternalUserForIdentifier|{issueTrackingIdentifier} felhasználó létrehozása",
                          {
                            issueTrackingIdentifier,
                          }
                        )
                  }}
                </b-button>
              </div>
            </div>
          </div>

          <div v-show="activeTab === 'COMPETENCIES'">
            <h3 class="heading">
              {{ $tf("userForm.competencies.title|Kompetenciák") }}
            </h3>

            <vuelidated-input
              v-model="userEdit.title"
              :label="$tf('userForm.title|Titulus')"
              :placeholder="
                $tf('userForm.title.placeholder|Megjelenített titulus')
              "
              :validation-rule="v$.userEdit.title"
              label-position="on-border"
            />
            <div class="columns">
              <div class="column">
                <vuelidated-input
                  v-model="userEdit.roleId"
                  :class="{ 'select-placeholder': userEdit.roleId == null }"
                  :label="$tf('userForm.role|Szerepkör')"
                  :placeholder="
                    $tf('userForm.title.placeholder|Kérlek, válassz...')
                  "
                  :validation-rule="v$.userEdit.roleId"
                  expanded
                  label-position="on-border"
                  type="select"
                >
                  <template #select>
                    <option
                      v-for="indicator in indicators.filter(
                        (i) => i.type === USER_INDICATOR_TYPE_ENUM.ROLE
                      )"
                      :key="indicator.id"
                      :value="indicator.id"
                    >
                      {{ indicator.name }}
                    </option>
                  </template>
                </vuelidated-input>
              </div>

              <div class="column">
                <vuelidated-input
                  v-model="userEdit.seniorityId"
                  :class="{
                    'select-placeholder': userEdit.seniorityId == null,
                  }"
                  :label="$tf('userForm.seniority|Szenioritás')"
                  :placeholder="
                    $tf('userForm.seniority.placeholder|Kérlek, válassz...')
                  "
                  :validation-rule="v$.userEdit.seniorityId"
                  expanded
                  label-position="on-border"
                  type="select"
                >
                  <template #select>
                    <option
                      v-for="indicator in indicators.filter(
                        (i) => i.type === USER_INDICATOR_TYPE_ENUM.SENIORITY
                      )"
                      :key="indicator.id"
                      :value="indicator.id"
                    >
                      {{ indicator.name }}
                    </option>
                  </template>
                </vuelidated-input>
              </div>

              <div class="column">
                <vuelidated-input
                  v-model="userEdit.departmentId"
                  :class="{
                    'select-placeholder': userEdit.departmentId == null,
                  }"
                  :label="$tf('userForm.department|Terület')"
                  :placeholder="
                    $tf('userForm.department.placeholder|Kérlek, válassz...')
                  "
                  :validation-rule="v$.userEdit.departmentId"
                  expanded
                  label-position="on-border"
                  type="select"
                >
                  <template #select>
                    <option
                      v-for="indicator in indicators.filter(
                        (i) => i.type === USER_INDICATOR_TYPE_ENUM.DEPARTMENT
                      )"
                      :key="indicator.id"
                      :value="indicator.id"
                    >
                      {{ indicator.name }}
                    </option>
                  </template>
                </vuelidated-input>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <b-field
                  :label="$tf('userForm.competencies|Kompetenciák')"
                  expanded
                  label-position="on-border"
                >
                  <b-taginput
                    v-model="userEdit.competenciesEdit"
                    :allow-new="false"
                    :data="filteredCompetencies"
                    :open-on-focus="true"
                    :placeholder="
                      $tf(
                        'userForm.competencies.placeholder|Kompetenciák választása'
                      )
                    "
                    autocomplete
                    field="text"
                    icon="book"
                    @typing="
                      filterTags($event, competencies, 'filteredCompetencies')
                    "
                  >
                  </b-taginput>
                </b-field>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <vuelidated-input
                  v-model="userEdit.rolesEdit"
                  :allow-new="false"
                  :data="filteredRoles"
                  :label="$tf('userForm.roles|Jogosultságok')"
                  :open-on-focus="true"
                  :placeholder="
                    $tf('userForm.roles.placeholder|Jogkörök választása')
                  "
                  :validation-rule="v$.userEdit.rolesEdit"
                  autocomplete
                  expanded
                  icon="microchip"
                  label-position="on-border"
                  type="taginput"
                  @typing="filterTags($event, roles, 'filteredRoles')"
                />
              </div>
            </div>
          </div>

          <div v-show="activeTab === 'OTHER'">
            <h3 class="heading">{{ $tf("userForm.misc.title|Egyéb") }}</h3>

            <div class="columns">
              <div class="column">
                <phone-input
                  ref="contactInput"
                  v-model="userEdit.contact"
                  :label="$tf('userForm.contact|Telefonszám')"
                  :placeholder="$tf('userForm.contact.placeholder|Telefonszám')"
                  @validate="validContactInput = $event"
                />
              </div>

              <div class="column">
                <b-field
                  :label="$tf('userForm.birthMonth|Születési hónap')"
                  label-position="on-border"
                >
                  <f-datepicker
                    v-model="userEdit.birthDate"
                    :placeholder="
                      $tf('userForm.birthMonth.placegholder|Születési hónap')
                    "
                    append-to-body
                    icon="calendar-week"
                    month-view
                    position="is-bottom-right"
                    type="month"
                  >
                    <template #header>
                      <span> </span>
                    </template>
                  </f-datepicker>
                </b-field>
              </div>
              <div class="column">
                <b-field
                  :label="$tf('userForm.birthDay|Születési nap')"
                  label-position="on-border"
                >
                  <f-datepicker
                    v-model="userEdit.birthDate"
                    :day-names="[]"
                    :nearby-month-days="false"
                    :placeholder="
                      $tf('userForm.birthDay.placeholder|Születési nap')
                    "
                    append-to-body
                    day-view
                    icon="calendar-week"
                    position="is-bottom-right"
                  >
                    <template #header>
                      <span> </span>
                    </template>
                  </f-datepicker>
                </b-field>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <b-field
                  :label="$tf('userForm.rank|Rangsor')"
                  expanded
                  label-position="on-border"
                >
                  <b-input
                    v-model="userEdit.rank"
                    :placeholder="$tf('userForm.rank.placeholder|1234')"
                    type="number"
                  />
                </b-field>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <b-field
                  :label="$tf('userForm.dataLink|Adatbekérő')"
                  label-position="on-border"
                >
                  <b-input
                    v-model="userEdit.dataLink"
                    :placeholder="
                      $tf('userForm.dataLink.placeholder|Adatbekérő URL')
                    "
                    icon="link"
                    type="url"
                  >
                  </b-input>
                </b-field>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <b-field
                  :label="$tf('userForm.flair|Bio')"
                  expanded
                  label-position="on-border"
                >
                  <b-input
                    v-model="userEdit.flair"
                    :placeholder="
                      $tf(
                        'userForm.flair.placeholder|Egyedi megjegyzés / idézet / ars poetica'
                      )
                    "
                    icon="quote-left"
                  >
                  </b-input>
                </b-field>
              </div>
            </div>
            <b-field :class="{ 'has-name': !!userEdit.avatar }" class="file">
              <b-upload
                v-model="userEdit.avatar"
                accept="image/*"
                class="file-label"
                rounded
              >
                <span class="file-cta">
                  <b-icon class="file-icon" icon="upload"></b-icon>
                  <span class="file-label">{{
                    $tf("userForm.avatarUpload|Profilkép feltöltése")
                  }}</span>
                </span>
                <span v-if="userEdit.avatar" class="file-name">
                  {{ userEdit.avatar.name }}
                </span>
              </b-upload>
            </b-field>
          </div>
        </form>
      </section>
      <footer class="modal-card-footer">
        <b-field class="ml-auto">
          <b-button class="mr-3" @click="cancel">
            {{ $tf("userModal.cancel|Mégse") }}
          </b-button>
          <b-field>
            <b-button type="is-info" @click="submit">
              {{ $tf("userModal.save|Mentés") }}
            </b-button>
          </b-field>
        </b-field>
      </footer>
    </div>
  </div>
</template>

<script>
import {
  correctWithTimezoneOffset,
  dateToMonthDay,
  deepCopy,
  normalize,
} from "@/utils/util";
import { getCurrentInstance } from "vue";
import { mapGetters } from "vuex";
import PhoneInput from "@/components/module/input/PhoneInput.vue";
import VuelidatedInput from "@/components/module/input/VuelidatedInput.vue";
import FDatepicker from "@/components/module/input/FDatepicker.vue";
import { email, maxLength, required, requiredIf } from "@vuelidate/validators";
import {
  ISSUE_TRACKING_CONST,
  ISSUE_TRACKING_SYSTEM,
  USER_INDICATOR_TYPE_ENUM,
  USER_PERMISSION_TYPE_ENUM,
} from "@/utils/const";
import useCustomVuelidate from "@/plugins/vuelidate";

export default {
  name: "UserModal",
  setup: () => {
    const instance = getCurrentInstance();
    const { v$, touch$ } = useCustomVuelidate();
    return {
      v$,
      touch$,
      instance,
    };
  },
  components: { FDatepicker, VuelidatedInput, PhoneInput },
  props: {
    newUser: {
      type: Boolean,
      required: true,
    },
    userData: {
      type: Object,
      required: false,
      default: null,
    },
    superadminCreate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  async mounted() {
    this.activeTab = this.USER_MODAL_TABS.BASIC_DATA;
    await this.$store.dispatch("role/list");
    this.competencies = this.indicators
      .filter((i) => i.type === USER_INDICATOR_TYPE_ENUM.COMPETENCY)
      .map((i) => {
        return {
          value: i.id,
          text: i.name,
        };
      });
    this.filteredRoles = this.roles;
    this.filteredCompetencies = this.competencies;
    if (!this.newUser) {
      this.userEdit = deepCopy(this.userData);
      if (this.userEdit.roles) {
        this.userEdit.rolesEdit = this.userEdit.roles
          .split(",")
          .map((role) =>
            this.roles.filter((rr) => rr.identifier === role).shift()
          );
        this.userEdit.avatar = null;
      }

      if (this.userEdit.competencies) {
        this.userEdit.competenciesEdit = this.userEdit.competencies.map(
          (competency) =>
            this.competencies.filter((cc) => cc.value === competency.id).shift()
        );
      }

      if (this.userEdit.startDate) {
        this.userEdit.startDate = new Date(this.userEdit.startDate);
      }

      if (this.userEdit.endDate) {
        this.userEdit.endDate = new Date(this.userEdit.endDate);
      }

      if (this.userEdit.birthDate) {
        this.userEdit.birthDate = new Date("2024-" + this.userEdit.birthDate); // 2024 because leap year
      }
    }
  },
  data() {
    return {
      normalize,
      USER_INDICATOR_TYPE_ENUM,
      USER_PERMISSION_TYPE_ENUM,
      competencies: [],
      createsExternalUser: {},
      filteredRoles: [],
      filteredCompetencies: [],
      userEdit: {
        avatar: null,
        name: null,
        dataLink: null,
        department: null,
        occupation: null,
        username: "",
        email: null,
        flair: null,
        id: null,
        phone: null,
        contact: null,
        external: false,
        portraitLink: null,
        locked: false,
        hidden: false,
        rank: null,
        roles: null,
        rolesEdit: [],
        competencies: null,
        competenciesEdit: [],
        parentCompany: null,
        loggingRequired: null,
        startDate: null,
        endDate: null,
        birthDate: null,
        aggregateHours: null,
        tier: null,
        primaryExternalId: null,
        additionalExternalIds: {},
      },
      changes: [],
      validPhoneInput: true,
      validContactInput: true,
      ISSUE_TRACKING_SYSTEM,
      activeTab: null,
      ISSUE_TRACKING_CONST,
    };
  },
  validations() {
    return {
      userEdit: this.userEditValidations,
    };
  },
  watch: {
    createsExternalUser(val) {
      if (val) {
        this.userEdit.loggingRequired = true;
      }
    },
  },
  computed: {
    ...mapGetters({
      env: "env/getField",
      indicators: "user_indicator/indicators",
      employees: "employee/employees",
      primaryIssueTrackingData: "uiConfigStore/getPrimaryIssueTrackingData",
      additionalIssueTrackingData:
        "uiConfigStore/getAdditionalIssueTrackingData",
      rolesGetter: "role/list",
    }),
    USER_MODAL_TABS() {
      return {
        BASIC_DATA: this.$tf("userForm.tabs.basicData|Alapadatok"),
        COMPANY_SETTINGS: this.$tf(
          "userForm.tabs.companySettings|Céges beállítások"
        ),
        COMPETENCIES: this.$tf("userForm.tabs.competencies|Kompetenciák"),
        OTHER: this.$tf("userForm.tabs.other|Egyéb"),
      };
    },
    primaryIssueTrackingSystem() {
      return this.primaryIssueTrackingData.system;
    },
    nonSelfTrackingAdditionalIssueTrackings() {
      return Object.keys(this.additionalIssueTrackingData).reduce(
        (acc, key) => {
          if (
            this.additionalIssueTrackingData[key]?.system !==
            ISSUE_TRACKING_SYSTEM.SELF.enum
          )
            acc[key] = this.additionalIssueTrackingData[key];
          return acc;
        },
        {}
      );
    },
    userEditValidations() {
      const validations = {
        id: {
          maxLength: maxLength(255),
        },
        name: {
          required,
          maxLength: maxLength(255),
        },
        username: {
          required,
          maxLength: maxLength(255),
        },
        email: {
          required,
          maxLength: maxLength(255),
          email,
        },
        primaryExternalId: {
          required: requiredIf(function () {
            return (
              this.userEdit.loggingRequired &&
              this.primaryIssueTrackingSystem !==
                ISSUE_TRACKING_SYSTEM.SELF.enum
            );
          }),
          maxLength: maxLength(255),
        },
        additionalExternalIds: {},
        startDate: {
          required,
        },
        parentCompany: {
          required: requiredIf(function () {
            return this.userEdit.external;
          }),
          maxLength: maxLength(255),
        },
        title: {
          required,
          maxLength: maxLength(255),
        },
        roleId: {
          required,
        },
        seniorityId: {
          required,
        },
        departmentId: {
          required,
        },
        rolesEdit: {
          required,
        },
      };
      for (const identifier of Object.keys(
        this.nonSelfTrackingAdditionalIssueTrackings
      )) {
        validations.additionalExternalIds[identifier] = {
          maxLength: maxLength(255),
        };
      }
      return validations;
    },
    userId() {
      if (this.newUser) {
        return "{Generated}";
      } else {
        return this.userEdit.id;
      }
    },
    roles() {
      return this.rolesGetter || [];
    },
  },
  methods: {
    filterTags(value, selectable, fieldKey) {
      let filtered = [];
      if (!value) {
        filtered = selectable;
      } else {
        filtered = selectable.filter((e) =>
          normalize(e.text).includes(normalize(value))
        );
      }
      this[fieldKey] = filtered;
    },
    async close() {
      this.$emit("fetch");
      this.cancel();
    },
    cancel() {
      this.$emit("close");
    },
    resetPassword() {
      this.$buefy.dialog.confirm({
        message: this.$tf(
          "userForm.passwordResetModal.message|Biztos helyre szeretnéd állítani {name} jelszavát?",
          { name: this.userEdit.name }
        ),
        onConfirm: () =>
          this.$store.dispatch(
            "census_user/resetAdminPassword",
            this.userEdit.id
          ),
      });
    },
    openExternalUserCreationConfirm(issueTrackingIdentifier) {
      this.$buefy.dialog.confirm({
        title: this.$tf(
          "userForm.externalUserConfirmModal.title|{issueTrackingIdentifier} fiók létrehozása",
          {
            issueTrackingIdentifier,
          }
        ),
        message: this.$tf(
          "userForm.externalUserConfirmModal.message|A felhasználónak megváltozattad az email címét, de ezt még nem mentetted el. A {issueTrackingIdentifier} fiók az új {email} címmel fog létrejönni!",
          { issueTrackingIdentifier, email: this.userEdit.email }
        ),
        confirmText: this.$tf(
          "userForm.externalUserConfirmModal.confirm|Rendben"
        ),
        cancelText: this.$tf("userForm.externalUserConfirmModal.cancel|Mégsem"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => this.createExternalUser(issueTrackingIdentifier),
      });
    },
    async createExternalUser(issueTrackingConfigIdentifier) {
      this.createsExternalUser[issueTrackingConfigIdentifier] = true;
      let request = deepCopy(this.userData);
      request.email = this.userEdit.email;
      await this.$store.dispatch("employee/createExternalUser", {
        request,
        issueTrackingConfigIdentifier,
      });
      if (
        issueTrackingConfigIdentifier ===
        ISSUE_TRACKING_CONST.PRIMARY_ISSUE_TRACKING_CONFIG_KEY
      ) {
        this.userEdit.primaryExternalId = this.employees.find(
          (e) => e.id === this.userData.id
        ).primaryExternalId;
      } else {
        this.userEdit.additionalExternalIds[issueTrackingConfigIdentifier] =
          this.employees.find(
            (e) => e.id === this.userData.id
          ).additionalExternalIds[issueTrackingConfigIdentifier];
      }

      this.createsExternalUser[issueTrackingConfigIdentifier] = false;
    },

    async submit() {
      this.touch$();

      let phoneInput = this.$refs.phoneInput;
      if (phoneInput) {
        phoneInput.touch();
      }

      let contactInput = this.$refs.contactInput;
      if (contactInput) {
        contactInput.touch();
      }

      let changeEntryComponents = this.$refs.entries;
      let entryValues = [];

      const invalidBasicInput = this.v$.$invalid;
      const invalidPhoneInput = !phoneInput || phoneInput.v$.$invalid;
      const invalidContactInput = !contactInput || contactInput.v$.$invalid;

      if (!invalidBasicInput && !invalidPhoneInput && !invalidContactInput) {
        if (changeEntryComponents) {
          for (let index in changeEntryComponents) {
            let changeEntryComponent = changeEntryComponents[index];
            if (await changeEntryComponent.isInvalid()) {
              return;
            }
            entryValues.push(changeEntryComponent.getValues());
          }
        }

        const request = deepCopy(this.userEdit);

        if (request.rolesEdit) {
          request.roles = request.rolesEdit.map((r) => r.identifier).join(",");
        }
        delete request.rolesEdit;

        if (request.competenciesEdit) {
          request.competencies = request.competenciesEdit.map((c) => {
            return { id: c.value };
          });
        }
        delete request.competenciesEdit;

        if (request.startDate) {
          request.startDate = correctWithTimezoneOffset(
            new Date(request.startDate)
          );
        }

        if (request.birthDate) {
          request.birthDate = dateToMonthDay(request.birthDate);
        }

        if (request.endDate) {
          request.endDate = correctWithTimezoneOffset(
            new Date(request.endDate)
          );
        }

        request.phone = request.phone?.replace(/\s/g, "");
        request.contact = request.contact?.replace(/\s/g, "");

        const avatar = this.userEdit.avatar;
        let avatarForm;
        if (avatar && typeof avatar === "object") {
          avatarForm = new FormData();
          avatarForm.append("file", avatar);
          avatarForm.append("fileName", avatar.name);
          avatarForm.append("documentType", "USER_AVATAR");
        }

        if (this.newUser) {
          request.tenantId = this.tenantId;
          request.lang = this.env("DEFAULT_LANGUAGE");

          if (this.superadminCreate) {
            await this.$store
              .dispatch("employee/superadminCreate", {
                request,
                avatarForm,
              })
              .then(async () => {
                await this.close();
              });
          } else {
            await this.$store
              .dispatch("employee/create", {
                createUserRequest: request,
                externalIdentifiers: Object.keys(
                  this.createsExternalUser
                ).filter((key) => this.createsExternalUser[key]),
                avatarForm,
              })
              .then(async () => {
                await this.close();
              })
              .catch((error) => {
                this.scrollToField(
                  `[data-field="${error?.response?.data?.error}"]`
                );
              });
          }
        } else {
          request.id = this.userId;
          await this.$store
            .dispatch("employee/update", {
              request,
              avatarForm,
            })
            .then(async () => {
              await this.close();
            });
        }
      } else {
        const basicErrors = [
          "id",
          "name",
          "username",
          "email",
          "locked",
          "hidden",
        ];
        const companyErrors = [
          "startDate",
          "endDate",
          "parentCompany",
          "primaryExternalId",
          "additionalExternalIds",
        ];
        const competenciesErrors = [
          "title",
          "roleId",
          "seniorityId",
          "departmentId",
          "rolesEdit",
        ];
        if (invalidBasicInput) {
          const errors = this.v$.userEdit;
          for (const identifier in errors) {
            if (Object.hasOwn(errors, identifier)) {
              const validationObj = errors[identifier];
              if (validationObj.$invalid) {
                if (basicErrors.includes(identifier) || invalidPhoneInput) {
                  this.activeTab = "BASIC_DATA";
                  return;
                }
                if (companyErrors.includes(identifier)) {
                  this.activeTab = "COMPANY_SETTINGS";
                  return;
                }
                if (competenciesErrors.includes(identifier)) {
                  this.activeTab = "COMPETENCIES";
                  return;
                }
              }
            }
          }
        }
        if (invalidContactInput) {
          this.activeTab = "OTHER";
        }
      }
    },
  },
};
</script>
