<template>
  <b-collapse :aria-id="id" :model-value="false" animation="slide">
    <template #trigger="props">
      <div
        :aria-controls="id"
        :aria-expanded="props.open"
        class="card-header"
        role="button"
      >
        <div class="is-flex is-align-items-center pl-2">
          <h1 class="title card-title">
            <span v-if="header">{{ header }}</span>
            <slot v-else name="header"></slot>
          </h1>
        </div>
        <div class="ml-auto">
          <a class="card-header-icon">
            <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'">
            </b-icon>
          </a>
        </div>
      </div>
    </template>
    <slot />
  </b-collapse>
</template>

<script>
export default {
  name: "ProjectCollapse",
  props: {
    header: {
      type: String,
      required: false,
    },
    id: {
      type: [String, Number],
      required: true,
    },
  },
};
</script>

<style scoped>
.card-title {
  font-size: 16px;
  margin: 0 !important;
}

.card-header {
  box-shadow: 0 0;
}
</style>
