<template>
  <div>
    <div class="is-flex is-justify-content-center header-container">
      <div class="usp-element">
        <b-icon
          class="mr-2"
          icon="check-double"
          icon-pack="fa"
          type="is-white"
        ></b-icon>

        <span class="has-text-white"><label>New And Improved</label></span>
      </div>
      <div class="usp-element">
        <b-icon
          class="mr-2"
          icon="check-double"
          icon-pack="fa"
          type="is-white"
        ></b-icon>
        <span class="has-text-white"><label>Closed BETA test</label></span>
      </div>
      <div class="usp-element">
        <b-icon
          class="mr-2"
          icon="check-double"
          icon-pack="fa"
          type="is-white"
        ></b-icon>
        <span class="has-text-white"><label>Feedback is welcome</label></span>
      </div>
    </div>

    <div class="is-flex is-justify-content-center sign-in-container">
      <section class="section">
        <!--    <div id="google-sign-in">-->
        <!--      <div id="portal-google-signin"></div>-->
        <!--    </div>-->

        <div class="classic-sign-in">
          <img
            :src="require('@/assets/images/login_logo.svg')"
            alt="Főképernyő logo"
            class="erp-logo"
          />

          <form class="login-form" @submit.prevent="doLogin">
            <b-notification
              v-if="isError"
              aria-close-label="Bezárás"
              role="alert"
              type="is-danger is-light"
            >
              Hiba történt bejelentkezéskor!
            </b-notification>

            <b-field
              class="mb-5"
              expanded
              label="Felhasználónév"
              label-position="on-border"
            >
              <b-input
                v-model="user"
                icon="user"
                placeholder="Felhasználónév"
                type="text"
              />
            </b-field>

            <b-field
              class="mb-6"
              expanded
              label="Jelszó"
              label-position="on-border"
            >
              <b-input
                v-model="pwd"
                icon="lock"
                placeholder="Jelszó"
                type="password"
              />
            </b-field>

            <b-field>
              <b-button
                :disabled="isLoading"
                expanded
                native-type="submit"
                tag="input"
                type="is-primary"
                value="Bejelentkezés"
              >
                Bejelentkezés
              </b-button>
            </b-field>
          </form>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
import { getSessionData } from "@/utils/util";
// import uiConfigMixin from "../mixins/uiConfigMixin";

export default {
  name: "RootSignIn",
  // mixins: [uiConfigMixin],
  data() {
    return {
      user: "",
      pwd: "",
      tnt: "MASTER",
      isError: false,
      isLoading: false,
    };
  },
  // computed: {
  //   ...mapGetters({
  //     show: "session/isReady",
  //     avatar: "session/avatar",
  //     displayName: "session/displayName",
  //   }),
  // },
  methods: {
    async doLogin() {
      try {
        this.isLoading = true;
        this.isError = false;
        let sessionData = await getSessionData();
        await this.$store.dispatch("session/login", {
          user: this.user,
          password: this.pwd,
          tenant: this.tnt,
          sessionData: sessionData,
        });
        // await this.fetchIsSeatingFeatureEnabled();
        if (this.$route.query?.returnUrl) {
          this.$router.push(this.$route.query.returnUrl);
        } else {
          this.$router.push("/");
        }
      } catch (ex) {
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    // called by the gapi if the login is successful
    // onSuccess: function(googleUser) {
    //   let profile = googleUser.getBasicProfile();
    //   let googleData = {
    //     token: googleUser.getAuthResponse().id_token,
    //     name: profile.getName(),
    //     email: profile.getEmail(),
    //     avatar: profile.getImageUrl()
    //   };
    //   this.$store.dispatch("session/login", googleData);
    // },
    // onFailure: function(error) {
    //   console.error(error);
    // },
    // // logout functionality, combines gapi with internal jwt destruction
    // signOut: function() {
    //   let auth2 = window.gapi.auth2.getAuthInstance();
    //   auth2.signOut().then(() => {
    //     this.$store.dispatch("session/logout");
    //   });
    // }
  },
  beforeCreate: function () {
    // let reference = {
    //   done: false,
    //   id: null
    // };
    // const trySigninHook = () => {
    //   if (!reference.done && window.gapi) {
    //     reference.done = true;
    //     logger.debug("GSignin Hook running..");
    //     window.gapi.signin2.render("portal-google-signin", {
    //       scope: "profile email openid",
    //       width: 240,
    //       height: 56,
    //       longtitle: true,
    //       theme: "dark",
    //       onsuccess: this.onSuccess,
    //       onfailure: this.onFailure
    //     });
    //     window.clearInterval(reference.id);
    //   } else {
    //     logger.debug("GSignin Hook prevented");
    //   }
    // };
    // reference.id = window.setInterval(trySigninHook, 250);
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.sign-in-container {
  width: 100%;
}

.header-container {
  margin-top: 1rem;
  text-shadow: 1px 1px 2px black;

  :deep(svg) {
    filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.4));
  }
}

.classic-sign-in {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: $white;
  border-radius: 16px;
  padding: 32px;
  min-width: 460px;
  margin-top: 3rem;
  box-shadow: rgb(38, 57, 77) 0 20px 30px -10px;

  .erp-logo {
    height: 2.5rem;
    margin-bottom: 3rem;
    margin-top: 0.75rem;
  }
}

.usp-element {
  padding: 10px;
}
</style>

<style>
.login-layout-wrapper {
  background-image: url("~@/assets/images/login_background.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>
