import SaveableObject from "@/objects/SaveableObject";
import SaveableObjectContainer from "@/objects/SaveableObjectContainer";

export default {
  methods: {
    fetchAllSaveObjects(saveObjects) {
      if (saveObjects && saveObjects instanceof SaveableObjectContainer) {
        Object.values(saveObjects._properties).forEach((saveObject) => {
          if (saveObject instanceof SaveableObject) {
            saveObject.fetchOrDefault();
          }
        });
      }
    },
    saveAllSaveObjects(saveObjects) {
      if (saveObjects && saveObjects instanceof SaveableObjectContainer) {
        Object.values(saveObjects._properties).forEach((saveObject) => {
          if (saveObject instanceof SaveableObject) {
            saveObject.save();
          }
        });
      }
    },
    resetAllSaveObjects(saveObjects) {
      if (saveObjects && saveObjects instanceof SaveableObjectContainer) {
        Object.values(saveObjects._properties).forEach((saveObject) => {
          if (saveObject instanceof SaveableObject) {
            saveObject.resetToDefault();
          }
        });
      }
    },
    clearAllSaveObjects(saveObjects) {
      if (saveObjects && saveObjects instanceof SaveableObjectContainer) {
        Object.values(saveObjects._properties).forEach((saveObject) => {
          if (saveObject instanceof SaveableObject) {
            saveObject.clear();
          }
        });
      }
    },
  },
};
