<template>
  <section class="section">
    <div class="container">
      <div class="is-flex is-justify-content-space-between">
        <h1 class="title">
          {{
            $tf("projectDimensionsAssign.title|Projekt-dimenzió hozzárendelés")
          }}
        </h1>
        <b-button
          icon-left="chevron-left"
          type="is-primary"
          @click="$router.push('/settings/project-dimensions')"
        >
          {{ $tf("projectDimensionsAssign.dimensions|Projekt dimenziók") }}
        </b-button>
      </div>
      <b-table :data="projects">
        <b-table-column
          v-slot="props"
          :label="$tf('projectDimensionsAssign.table.identifier|Azonosító')"
          field="identifier"
          sortable
        >
          {{ props.row.identifier }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          :label="$tf('projectDimensionsAssign.table.name|Név')"
          field="name"
          sortable
        >
          {{ props.row.name }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          :label="$tf('projectDimensionsAssign.table.color|Szín')"
          field="color"
          sortable
        >
          <b-select
            :modelValue="props.row.color"
            @update:modelValue="
              (value) => modifyColor(value, props.row.identifier)
            "
          >
            <option
              v-for="color in projectColors"
              :key="'pc' + color.id"
              :value="color.id"
            >
              {{ color.colorName }}
            </option>
          </b-select>
        </b-table-column>

        <b-table-column
          v-for="dimension in projectDimensions"
          :key="'pdt' + dimension.id"
          v-slot="props"
          :label="dimension.dimensionName"
          field="color"
          sortable
        >
          <b-taginput
            v-if="dimension.multipleAssignment"
            :data="dimension.values"
            :modelValue="
              getDimensionsValuesForProject(
                dimension.multipleAssignment,
                props.row.dimensionValues,
                dimension.id
              )
            "
            :placeholder="
              $tf(
                'projectDimensionsAssign.table.multipleDimensionValuesPlaceholder|Érték hozzáadása'
              )
            "
            autocomplete
            field="name"
            icon="plus-circle"
            open-on-focus
            @update:modelValue="
              (value) =>
                modifyMultipleValue(dimension.id, value, props.row.identifier)
            "
          >
          </b-taginput>

          <b-select
            v-else
            :modelValue="
              getDimensionsValuesForProject(
                dimension.multipleAssignment,
                props.row.dimensionValues,
                dimension.id
              )
            "
            @update:modelValue="
              (value) =>
                modifySimpleValue(dimension.id, value, props.row.identifier)
            "
          >
            <option
              v-for="dimensionValue in dimension.values"
              :key="'pc' + dimensionValue.id"
              :value="dimensionValue.id"
            >
              {{ dimensionValue.name }}
            </option>
          </b-select>
        </b-table-column>
      </b-table>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProjectDimensionAssigner",
  computed: {
    ...mapGetters({
      projectDimensions: "project_dimensions/getProjectDimensionsWithValues",
      projectColors: "project_dimensions/getProjectColors",
      projects: "enterprise_core/limitedProjects",
    }),
  },
  methods: {
    getDimensionsValuesForProject(multiple, dimensions, dimensionId) {
      if (multiple) {
        return dimensions.find((d) => d.id === dimensionId)?.values ?? [];
      } else {
        return dimensions.find((d) => d.id === dimensionId)?.values[0].id;
      }
    },
    async modifyColor(newVal, projectTag) {
      await this.$store.dispatch("project_dimensions/updateColorForProject", {
        projectTag: projectTag,
        values: newVal,
      });
    },
    async modifySimpleValue(dimensionId, newVal, projectTag) {
      await this.$store.dispatch(
        "project_dimensions/updateDimensionValuesForProject",
        {
          projectTag: projectTag,
          dimensionId: dimensionId,
          values: [newVal],
        }
      );
    },
    async modifyMultipleValue(dimensionId, newValues, projectTag) {
      await this.$store.dispatch(
        "project_dimensions/updateDimensionValuesForProject",
        {
          projectTag: projectTag,
          dimensionId: dimensionId,
          values: newValues.map((nw) => nw.id),
        }
      );
    },
  },
  async mounted() {
    await this.$store.dispatch("project_dimensions/fetchProjectColors");
    await this.$store.dispatch(
      "project_dimensions/fetchActiveProjectDimensionsWithValues"
    );
    await this.$store.dispatch("enterprise_core/fetchLimitedProjects");
  },
};
</script>
<style lang="scss" scoped></style>
