<template>
  <div>
    <table
      aria-label="since-start"
      class="has-text-dark-blue has-font-weight-500 future-table"
    >
      <thead>
        <tr>
          <th></th>
          <th class="icon-cell"></th>
          <th>
            {{ $tf("projectPage.forecastFutureStatus.table.mwd|Munkaidő") }}
          </th>
          <th class="icon-cell"></th>
          <th>
            {{
              $tf(
                "projectPage.forecastFutureStatus.table.budgetSpent|Büdzsé/Költés"
              )
            }}
          </th>
          <th class="icon-cell"></th>
          <th>
            {{
              $tf("projectPage.forecastFutureStatus.table.time|Átfutási idő")
            }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="remaining-row">
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastFutureStatus.table.remaining.tooltip|A projekten hátralévő erőforrások. A tervből kivont tényleges ráfordítás alapján kerül kiszámításra.'
                )
              "
              position="is-right"
              >{{
                $tf(
                  "projectPage.forecastFutureStatus.table.remaining|Hátralévő keret"
                )
              }}
            </b-tooltip>
          </td>
          <td class="icon-cell"></td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastFutureStatus.table.remaining.mwd|Az eddigi munkaidő költés és a vállalás különbsége'
                )
              "
            >
              {{ workdayify(remainingMd) }}
            </b-tooltip>
          </td>
          <td class="icon-cell"></td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastFutureStatus.table.remaining.hudgetSpent|Az eddigi költség és a vállalás különbsége'
                )
              "
            >
              {{ moneyify(remainingBudget) }}
            </b-tooltip>
          </td>
          <td class="icon-cell"></td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastFutureStatus.table.remaining.time|Az eddig eltelt munkanapok és az átfutás különbsége'
                )
              "
              position="is-left"
            >
              {{ calendarDayify(remainingTime) }}
            </b-tooltip>
          </td>
        </tr>
        <tr class="blank-row-small">
          <td class="blank-row-small-td" colspan="13"></td>
        </tr>
        <tr class="remaining-trend-row">
          <td>
            <b-tooltip position="is-right">
              <template v-slot:content>
                <div class="content tooltip">
                  <p>
                    {{
                      $tf(
                        "projectPage.forecastFutureStatus.table.trendingForecast.tooltip|A 'trendtartó' azt jelenti, hogy a dolgok úgy haladnak a továbbiakban, mint eddig, tehát a jelenlegi KPI érvényesül az egész projektre"
                      )
                    }}
                  </p>
                </div>
              </template>
              {{
                $tf(
                  "projectPage.forecastFutureStatus.table.trendingForecast|Trendtartó FC"
                )
              }}
            </b-tooltip>
          </td>
          <td class="icon-cell"></td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastFutureStatus.table.trendingForecast.mwd|Trendtartó Forecast szerint ennyi lesz a projekt várható összes munkaideje'
                )
              "
            >
              {{ workdayify(trendingMd) }}
            </b-tooltip>
          </td>
          <td class="icon-cell"></td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastFutureStatus.table.trendingForecast.budgetSpent|Trendtartó Forecast szerint ennyi lesz a projekt várható összes költsége'
                )
              "
            >
              {{ moneyify(trendingBudget) }}
            </b-tooltip>
          </td>
          <td class="icon-cell"></td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastFutureStatus.table.trendingForecast.time|Trendtartó Forecast szerint ennyi lesz a projekt várható teljes átfutása'
                )
              "
              position="is-left"
            >
              {{ calendarDayify(trendingTime) }}
            </b-tooltip>
          </td>
        </tr>
        <tr>
          <td class="is-vertical-align-middle">
            {{
              $tf("projectPage.forecastFutureStatus.table.planVsFc|Terv vs FC")
            }}
          </td>
          <td class="icon-cell">
            <div :class="getRiskColor(diffTrendingPercentMd)" class="diff-icon">
              <b-icon
                :icon="getRiskIcon(diffTrendingPercentMd)"
                size="is-small"
              ></b-icon>
            </div>
          </td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastFutureStatus.table.planVsFc.mwd|A Forecast érték és a fennmaradó keret különbsége. Amennyiben a fennmaradó keret negatív, ehhez a számoláshoz azt 1 MWD-nek tekintjük, hiszen negatív munkaidővel számolni értelmetlen lenne, nullával pedig nem lehet osztani.'
                )
              "
            >
              {{ workdayify(diffTrendingMd) }}
              <span class="table-percent"
                >({{ percentify(diffTrendingPercentMd) }})</span
              >
            </b-tooltip>
          </td>
          <td class="icon-cell">
            <div
              :class="getRiskColor(diffTrendingPercentBudget)"
              class="diff-icon"
            >
              <b-icon
                :icon="getRiskIcon(diffTrendingPercentBudget)"
                size="is-small"
              ></b-icon>
            </div>
          </td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastFutureStatus.table.planVsFc.budgetSpent|A Forecast érték és a fennmaradó keret különbsége. Amennyiben a fennmaradó keret negatív, ehhez a számoláshoz azt 1 napnyi átlag költségnek tekintjük, hiszen negatív büdzsével számolni értelmetlen lenne, nullával pedig nem lehet osztani.'
                )
              "
            >
              {{ moneyify(diffTrendingBudget)
              }}<span class="table-percent">
                ({{ percentify(diffTrendingPercentBudget) }})
              </span>
            </b-tooltip>
          </td>
          <td class="icon-cell">
            <div
              :class="getRiskColor(diffTrendingPercentTime)"
              class="diff-icon"
            >
              <b-icon
                :icon="getRiskIcon(diffTrendingPercentTime)"
                size="is-small"
              ></b-icon>
            </div>
          </td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastFutureStatus.table.planVsFc.time|A Forecast érték és a fennmaradó keret különbsége. Amennyiben a fennmaradó keret negatív, ehhez a számoláshoz azt 1 napnak tekintjük, hiszen negatív átfutással számolni értelmetlen lenne, nullával pedig nem lehet osztani.'
                )
              "
              position="is-left"
            >
              {{ numberify(diffTrendingTime) }} nap
              <span class="table-percent">
                ({{ percentify(diffTrendingPercentTime) }})
              </span>
            </b-tooltip>
          </td>
        </tr>
        <tr class="blank-row-small">
          <td class="blank-row-small-td" colspan="13"></td>
        </tr>
        <tr class="remaining-plan-row">
          <td>
            <b-tooltip position="is-right">
              <template v-slot:content>
                <div class="content tooltip">
                  <p>
                    {{
                      $tf(
                        "projectPage.forecastFutureStatus.table.planningForecast.tooltip|A 'tervtartó' azt jelenti, hogy történt eddig bármi, mostantól az eredeti terv szerint fejeződik be a projekt hátralevő része."
                      )
                    }}
                  </p>
                </div>
              </template>
              {{
                $tf(
                  "projectPage.forecastFutureStatus.table.planningForecast|Tervtartó FC"
                )
              }}
            </b-tooltip>
          </td>
          <td class="icon-cell"></td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastFutureStatus.table.planningForecast.mwd|Tervtartó Forecast szerint ennyi lesz a projekt várható összes munkaideje'
                )
              "
            >
              {{ workdayify(planningMd) }}
            </b-tooltip>
          </td>
          <td class="icon-cell"></td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastFutureStatus.table.planningForecast.budgetSpent|Tervtartó Forecast szerint ennyi lesz a projekt várható összes költsége'
                )
              "
            >
              {{ moneyify(planningBudget) }}
            </b-tooltip>
          </td>
          <td class="icon-cell"></td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastFutureStatus.table.planningForecast.time|Tervtartó Forecast szerint ennyi lesz a projekt várható teljes átfutása'
                )
              "
              position="is-left"
            >
              {{ calendarDayify(planningTime) }}
            </b-tooltip>
          </td>
        </tr>
        <tr>
          <td class="is-vertical-align-middle">
            {{
              $tf("projectPage.forecastFutureStatus.table.planVsFc|Terv vs FC")
            }}
          </td>
          <td class="icon-cell">
            <div :class="getRiskColor(diffPlanningPercentMd)" class="diff-icon">
              <b-icon
                :icon="getRiskIcon(diffPlanningPercentMd)"
                size="is-small"
              ></b-icon>
            </div>
          </td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastFutureStatus.table.planVsFc.mwd|A Forecast érték és a fennmaradó keret különbsége. Amennyiben a fennmaradó keret negatív, ehhez a számoláshoz azt 1 MWD-nek tekintjük, hiszen negatív munkaidővel számolni értelmetlen lenne, nullával pedig nem lehet osztani.'
                )
              "
            >
              {{ workdayify(diffPlanningMd) }}
              <span class="table-percent">
                ({{ percentify(diffPlanningPercentMd) }})
              </span>
            </b-tooltip>
          </td>
          <td class="icon-cell">
            <div
              :class="getRiskColor(diffPlanningPercentBudget)"
              class="diff-icon"
            >
              <b-icon
                :icon="getRiskIcon(diffPlanningPercentBudget)"
                size="is-small"
              ></b-icon>
            </div>
          </td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastFutureStatus.table.planVsFc.budgetSpent|A Forecast érték és a fennmaradó keret különbsége. Amennyiben a fennmaradó keret negatív, ehhez a számoláshoz azt 1 napnyi átlag költségnek tekintjük, hiszen negatív büdzsével számolni értelmetlen lenne, nullával pedig nem lehet osztani.'
                )
              "
            >
              {{ moneyify(diffPlanningBudget) }}
              <span class="table-percent">
                ({{ percentify(diffPlanningPercentBudget) }})
              </span>
            </b-tooltip>
          </td>
          <td class="icon-cell">
            <div
              :class="getRiskColor(diffPlanningPercentTime)"
              class="diff-icon"
            >
              <b-icon
                :icon="getRiskIcon(diffPlanningPercentTime)"
                size="is-small"
              ></b-icon>
            </div>
          </td>
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastFutureStatus.table.planVsFc.time|A Forecast érték és a fennmaradó keret különbsége. Amennyiben a fennmaradó keret negatív, ehhez a számoláshoz azt 1 napnak tekintjük, hiszen negatív átfutással számolni értelmetlen lenne, nullával pedig nem lehet osztani.'
                )
              "
              position="is-left"
            >
              {{ numberify(diffPlanningTime) }} nap
              <span class="table-percent">
                ({{ percentify(diffPlanningPercentTime) }})
              </span>
            </b-tooltip>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  calendarDayify,
  formatDate,
  moneyify,
  numberify,
  percentify,
  workdayify,
} from "@/utils/util";
import { PROJECT_PROGRESS_TYPE } from "@/utils/const";
import KpiMixin from "@/mixins/KpiMixin";

export default {
  name: "ForecastFutureTable",
  mixins: [KpiMixin],
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    notTracked() {
      return this.projectDetails.progressType === PROJECT_PROGRESS_TYPE.NONE;
    },
    remainingMd() {
      if (this.notTracked) return "-";
      return this.projectDetails.commitment - this.projectDetails.sumSpentMd;
    },
    planningMd() {
      if (this.notTracked) return "-";
      return (
        this.projectDetails.forecastPlanningMd - this.projectDetails.sumSpentMd
      );
    },
    diffPlanningMd() {
      if (this.notTracked) return "-";
      return this.planningMd - this.fixNegativeMd(this.remainingMd);
    },
    diffPlanningPercentMd() {
      if (this.notTracked) return "-";
      return this.diffPlanningMd / this.fixNegativeMd(this.remainingMd);
    },
    trendingMd() {
      if (this.notTracked) return "-";
      return (
        this.projectDetails.forecastTrendingMd - this.projectDetails.sumSpentMd
      );
    },
    diffTrendingMd() {
      if (this.notTracked) return "-";
      return this.trendingMd - this.fixNegativeMd(this.remainingMd);
    },
    diffTrendingPercentMd() {
      if (this.notTracked) return "-";
      return this.diffTrendingMd / this.fixNegativeMd(this.remainingMd);
    },

    remainingBudget() {
      if (this.notTracked) return "-";
      return this.projectDetails.budget - this.projectDetails.sumSpentBudget;
    },
    planningBudget() {
      if (this.notTracked) return "-";
      return (
        this.projectDetails.forecastPlanningBudget -
        this.projectDetails.sumSpentBudget
      );
    },
    diffPlanningBudget() {
      if (this.notTracked) return "-";
      return this.planningBudget - this.fixNegativeMoney(this.remainingBudget);
    },
    diffPlanningPercentBudget() {
      if (this.notTracked) return "-";
      return (
        this.diffPlanningBudget / this.fixNegativeMoney(this.remainingBudget)
      );
    },
    trendingBudget() {
      if (this.notTracked) return "-";
      return (
        this.projectDetails.forecastTrendingBudget -
        this.projectDetails.sumSpentBudget
      );
    },
    diffTrendingBudget() {
      if (this.notTracked) return "-";
      return this.trendingBudget - this.fixNegativeMoney(this.remainingBudget);
    },
    diffTrendingPercentBudget() {
      if (this.notTracked) return "-";
      return (
        this.diffTrendingBudget / this.fixNegativeMoney(this.remainingBudget)
      );
    },

    remainingTime() {
      if (this.notTracked) return "-";
      return (
        this.projectDetails.daysBetweenDue - this.projectDetails.daysSinceStart
      );
    },
    planningTime() {
      if (this.notTracked) return "-";
      return (
        this.projectDetails.forecastPlanningTime -
        this.projectDetails.daysSinceStart
      );
    },
    diffPlanningTime() {
      if (this.notTracked) return "-";
      return this.planningTime - this.fixNegativeMd(this.remainingTime);
    },
    diffPlanningPercentTime() {
      if (this.notTracked) return "-";
      return this.diffPlanningTime / this.fixNegativeMd(this.remainingTime);
    },
    trendingTime() {
      if (this.notTracked) return "-";
      return (
        this.projectDetails.forecastTrendingTime -
        this.projectDetails.daysSinceStart
      );
    },
    diffTrendingTime() {
      if (this.notTracked) return "-";
      return this.trendingTime - this.fixNegativeMd(this.remainingTime);
    },
    diffTrendingPercentTime() {
      if (this.notTracked) return "-";
      return this.diffTrendingTime / this.fixNegativeMd(this.remainingTime);
    },
  },
  data() {
    return {
      formatDate,
      percentify,
      moneyify,
      workdayify,
      numberify,
    };
  },
  methods: {
    calendarDayify,
    // these are used to default to at least 1 day for negative values
    fixNegativeMd(value) {
      return value < 1 ? 1 : value;
    },
    fixNegativeMoney(value) {
      if (
        value <
        this.projectDetails.sumSpentBudget / this.projectDetails.sumSpentMd
      ) {
        // return the avg 1 day cost
        return (
          this.projectDetails.sumSpentBudget / this.projectDetails.sumSpentMd
        );
      }
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.is-vertical-align-middle {
  vertical-align: middle !important;
}

.remaining-row > td {
  background: $grey-lightest;
  color: $custom-dark-blue !important;
}

.remaining-plan-row > td {
  // background: $forecast-tertiary;
  color: $custom-dark-blue !important;
}

.remaining-plan-row > td:first-child {
  border-left: 5px solid $forecast-primary !important;
}

.remaining-trend-row > td {
  // background: $forecast-secondary;
  color: $custom-dark-blue !important;
}

.remaining-trend-row > td:first-child {
  border-left: 5px solid $forecast-tertiary !important;
}

.future-table th,
.future-table td {
  color: $custom-dark-blue;
}

.icon-cell {
  width: 28px;
  padding: 0 !important;
  vertical-align: middle;
}

.diff-icon {
  // display: flex;
  display: none !important;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: $white;
  margin: 2px;
  height: 2rem;
}

.diff-icon.is-fatal {
  background: $black;
}

.diff-icon.is-danger {
  background: $red;
}

.diff-icon.is-warning {
  background: $yellow;
}

.diff-icon.is-success {
  background: $green;
}

table {
  border-collapse: unset;
  border-spacing: 0 0.5rem;
}

th {
  border: none !important;
  font-size: 10px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
}

tr {
  td:first-child {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    border-left: 1px solid #eceff5 !important;
    font-weight: 700 !important;
  }

  td:last-child {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    border-right: 1px solid #eceff5 !important;
  }

  td {
    border-bottom: 1px solid #eceff5 !important;
    border-top: 1px solid #eceff5 !important;
    padding: 16px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }
}

.table-percent {
  display: block;
  font-size: 12px;
  font-weight: 400;
}

.blank-row-small {
  height: 4px !important;
  line-height: 0;

  &-td {
    padding: 0 !important;
    border-bottom: 1px dashed #cad3de !important;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;

    &:first-of-type {
      border-radius: 0 !important;
      border-left: 0 !important;
    }

    &:last-of-type {
      border-radius: 0 !important;
      border-right: 0 !important;
    }
  }
}
</style>
