<template>
  <component
    :is="isPageLoading ? LoadingTable : BTable"
    :animation="isPageLoading ? 'wave-new' : $attrs.animation"
    :height="isPageLoading ? '40px' : $attrs.height"
    :rounded="isPageLoading ? true : $attrs.rounded"
    :width="isPageLoading ? '50px' : $attrs.width"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot v-if="!hideComponent"></slot>
  </component>
</template>

<script>
import LoadingTable from "@/components/loading/LoadingTable.vue";
import { BTable } from "buefy/dist/cjs/table";
import LoadingMixin from "@/mixins/LoadingMixin";

export default {
  name: "BTableWithLoading",
  props: {
    loadingWidth: {
      type: [String, Number],
    },
    hideComponent: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [LoadingMixin],
  data() {
    return {
      BTable,
      LoadingTable,
    };
  },
  inheritAttrs: false,
};
</script>
