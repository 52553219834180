import SaveableObject from "@/objects/SaveableObject";

const SaveableObjectContainer = class SaveableObjectContainer {
  constructor(properties = {}) {
    this._properties = properties;
    for (const [key, value] of Object.entries(properties)) {
      this[key] = value;
    }
  }

  toRequestParams() {
    const returnObject = {};
    for (const [key, value] of Object.entries(this._properties)) {
      if (value instanceof SaveableObject) {
        returnObject[key] = value.value;
      } else {
        returnObject[key] = value;
      }
    }
    return returnObject;
  }
};

export default SaveableObjectContainer;
