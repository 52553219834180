<template>
  <div class="modal-card" style="min-width: 1000px; border-radius: 10px">
    <section class="modal-card-body">
      <h1 class="title">
        {{ $tf("resource.employeeSelect.title|Alkalmazott kiválasztása") }}
      </h1>
      <div class="columns gap-2">
        <div class="column">
          <span class="uppercase-subtitle-small has-text-grey">
            {{ $tf("resource.employeeSelect.filters.role|Szűrés munkakörre") }}
          </span>

          <b-select
            v-model="roleFilter"
            :placeholder="$tf('resource.employeeSelect.filters.all|Összes')"
            expanded
          >
            <option :value="null">
              {{ $tf("resource.employeeSelect.filters.all|Összes") }}
            </option>
            <option v-for="option in roles" :key="option" :value="option">
              {{ option }}
            </option>
          </b-select>
        </div>

        <div class="column">
          <span class="uppercase-subtitle-small has-text-grey">
            {{
              $tf(
                "resource.employeeSelect.filters.seniority|Szűrés szenioritásra"
              )
            }}
          </span>

          <b-select v-model="seniorityFilter" expanded>
            <option :value="null">
              {{ $tf("resource.employeeSelect.filters.all|Összes") }}
            </option>
            <option
              v-for="option in seniorities"
              :key="option"
              :value="option"
              class="w-100"
            >
              {{ option }}
            </option>
          </b-select>
        </div>

        <div class="column">
          <span class="uppercase-subtitle-small has-text-grey">
            {{
              $tf(
                "resource.employeeSelect.filters.external|Belsős / Külsős személyek"
              )
            }}
          </span>

          <b-select
            v-model="affiliationFilter"
            :placeholder="$tf('resource.employeeSelect.filters.all|Összes')"
            expanded
          >
            <option :value="null">
              {{ $tf("resource.employeeSelect.filters.all|Összes") }}
            </option>
            <option
              v-for="option in AFFILIATION_TYPE"
              :key="option.enum"
              :value="option.enum"
            >
              {{ $tf(option.label) }}
            </option>
          </b-select>
        </div>
      </div>

      <div class="columns has-gap-2 mb-4">
        <div class="column py-0">
          <b-field
            :label="$tf('resource.employeeSelect.filters.name|Szűrés névre')"
            label-position="on-border"
          >
            <b-input v-model="employeeNameFilter" icon-right="search" />
          </b-field>
        </div>

        <div class="column py-0 is-narrow mt-2">
          <b-checkbox v-model="myTeamOnlyFilter">
            {{
              $tf(
                "resource.employeeSelect.filters.ownTeamOnly|Csak a saját csapat megjelenítése"
              )
            }}
          </b-checkbox>
        </div>
      </div>

      <b-table
        :data="filtered"
        :row-class="(row) => row === selectedRow && 'selected-row'"
        class="employee-table"
        sticky-header
      >
        <b-table-column
          v-slot="props"
          :label="$tf('resource.employeeSelect.table.label.name|Név')"
          field="name"
        >
          <div
            class="is-flex is-align-items-center cursor-pointer"
            @click="selectedRow = props.row"
          >
            <f-avatar :data="props?.row" size="32x32" />
            <span class="has-font-weight-700">
              {{ props.row.name }}
            </span>
          </div>
        </b-table-column>
        <b-table-column
          v-slot="props"
          :label="$tf('resource.employeeSelect.table.label.role|Munkakör')"
          field="role"
        >
          <div class="is-flex is-flex-wrap-wrap has-gap-2">
            <indicator-tag :indicator-id="props.row.roleId" />
          </div>
        </b-table-column>
        <b-table-column
          v-slot="props"
          :label="
            $tf('resource.employeeSelect.table.label.seniority|Szenioritás')
          "
        >
          <indicator-tag :indicator-id="props.row.seniorityId" />
        </b-table-column>

        <template #empty>
          <div class="has-text-centered">
            {{ $tf("resource.employeeSelect.table.empty|Nincs találat") }}
          </div>
        </template>
      </b-table>

      <div class="mt-4">
        <b-field grouped>
          <b-button
            :disabled="!selectedRow"
            type="is-primary"
            @click="addEmployee"
          >
            {{ $tf("resource.employeeSelect.button.addEmployee|Hozzáadás") }}
          </b-button>
          <b-button class="ml-2" type="is-primary" @click="addEmployee">
            {{
              $tf(
                "resource.employeeSelect.button.addAllEmployee|Mind Hozzáadása"
              )
            }}
          </b-button>
        </b-field>
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import IndicatorTag from "@/components/module/info/IndicatorTag.vue";
import FAvatar from "@/components/module/icon/FAvatar.vue";
import { AFFILIATION_TYPE } from "@/utils/const";

export default defineComponent({
  name: "EmployeeSelect",
  components: { FAvatar, IndicatorTag },
  props: {
    employees: {
      type: Array,
      required: true,
    },
    leadTeams: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      AFFILIATION_TYPE,
      employeeNameFilter: "",
      roleFilter: null,
      seniorityFilter: null,
      affiliationFilter: null,
      myTeamOnlyFilter: null,
      filtered: [],
      selectedRow: null,
    };
  },
  async mounted() {
    this.filtered = this.employees;
  },
  watch: {
    employeeNameFilter() {
      this.doFilter();
    },
    roleFilter() {
      this.doFilter();
    },
    seniorityFilter() {
      this.doFilter();
    },
    affiliationFilter() {
      this.doFilter();
    },
    myTeamOnlyFilter() {
      this.doFilter();
    },
  },
  computed: {
    roles() {
      return this.employees
        .map((emp) => emp.role)
        .filter((role, index, roles) => roles.indexOf(role) === index);
    },
    seniorities() {
      return [...new Set(this.employees.map((emp) => emp.seniority))];
    },
  },
  methods: {
    doFilter() {
      const text = this.employeeNameFilter.toLowerCase().trim();
      if (text) {
        this.filtered = this.employees.filter((employee) =>
          employee.name?.toLowerCase().includes(text)
        );
      } else {
        this.filtered = this.employees;
      }

      if (this.roleFilter) {
        this.filtered = this.filtered.filter(
          (employee) => employee.role === this.roleFilter
        );
      }
      if (this.seniorityFilter) {
        this.filtered = this.filtered.filter(
          (employee) => employee.seniority === this.seniorityFilter
        );
      }
      if (this.affiliationFilter) {
        this.filtered = this.filtered.filter(
          (employee) =>
            employee.external ===
            (this.affiliationFilter === AFFILIATION_TYPE.EXTERNAL.enum)
        );
      }

      if (this.myTeamOnlyFilter) {
        const myEmployees = this.leadTeams.flatMap(
          (team) => team.employees?.map((employee) => employee.employeeId) ?? []
        );
        this.filtered = this.filtered.filter((employee) =>
          myEmployees.includes(employee.id)
        );
      }

      if (!this.filtered.includes(this.selectedRow)) {
        this.selectedRow = null;
      }
    },
    addEmployee() {
      this.$emit("add-employee", this.selectedRow.id);
    },
  },
});
</script>

<style>
tr.selected-row {
  background-color: #bfd9bf;
}

.employee-table {
  overflow: auto;
}

.employee-table .has-sticky-header {
  max-height: 54vh;
  height: auto !important;
}
</style>

<style lang="scss" scoped>
.is-rounded-5 {
  border-radius: 5px;
}
</style>
