<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">
        {{
          $tf(
            "adminAbsenceRequests.editModal.title|Szabadságkérelem szerkesztése"
          )
        }}
      </p>
      <button class="delete" type="button" @click="$emit('close')" />
    </header>
    <section class="modal-card-body is-flex is-flex-direction-column has-gap-3">
      <b-field grouped>
        <b-field
          :label="$tf('adminAbsenceRequests.editModal.requestor|Kérelmező')"
          expanded
          label-position="on-border"
        >
          <b-input :modelValue="request.userData.name" disabled expanded />
        </b-field>
        <b-field
          :label="$tf('adminAbsenceRequests.editModal.created|Időpont')"
          expanded
          label-position="on-border"
        >
          <b-input :modelValue="request.created" disabled expanded />
        </b-field>
      </b-field>
      <vuelidated-input
        v-model="modelValue.status"
        :label="$tf('adminAbsenceRequests.editModal.status|Státusz')"
        :validation-rule="v$.modelValue.status"
        append-to-body
        expanded
        label-position="on-border"
        open-on-focus
        type="select"
      >
        <template #select>
          <option :value="'REQUEST'">
            {{ $tf("adminAbsenceRequests.table.status.request|Kérelem") }}
          </option>
          <option :value="'LINE_APPROVED'">
            {{
              $tf(
                "adminAbsenceRequests.table.status.lineApproved|Vezető által elfogadva"
              )
            }}
          </option>
          <option :value="'APPROVED'">
            {{ $tf("adminAbsenceRequests.table.status.approved|Elfogadva") }}
          </option>
          <option :value="'ACTIVE'">
            {{ $tf("adminAbsenceRequests.table.status.active|Aktív") }}
          </option>
          <option :value="'TAKEN'">
            {{ $tf("adminAbsenceRequests.table.status.taken|Felhasznált") }}
          </option>
          <option :value="'DENIED'">
            {{ $tf("adminAbsenceRequests.table.status.denied|Elutasítva") }}
          </option>
          <option :value="'REVOKED'">
            {{ $tf("adminAbsenceRequests.table.status.revoked|Visszavont") }}
          </option>
        </template>
      </vuelidated-input>
      <vuelidated-input
        v-model="modelValue.type"
        :label="$tf('adminAbsenceRequests.editModal.type|Típus')"
        :validation-rule="v$.modelValue.type"
        append-to-body
        expanded
        label-position="on-border"
        open-on-focus
        type="select"
      >
        <template #select>
          <option
            v-for="type in absenceTypes"
            :key="`absenceRequestEditModal-${type.id}`"
            :value="type.id"
          >
            {{ type.name }}
          </option>
        </template>
      </vuelidated-input>
      <vuelidated-input
        v-model="modelValue.description"
        :label="$tf('adminAbsenceRequests.editModal.description|Leírás')"
        :validation-rule="v$.modelValue.description"
        append-to-body
        expanded
        label-position="on-border"
      >
      </vuelidated-input>

      <b-checkbox v-model="modelValue.carryOver">
        {{
          $tf("adminAbsenceRequests.editModal.carryOver|Előző évről áthozott")
        }}
      </b-checkbox>

      <vuelidated-input
        v-model="modelValue.absenceRequestDates"
        :label="$tf('adminAbsenceRequests.editModal.dates|Távollét napjai')"
        :validation-rule="v$.modelValue.absenceRequestDates"
        append-to-body
        expanded
        label-position="on-border"
        multiple
        type="date"
      >
      </vuelidated-input>
    </section>
    <footer class="modal-card-foot">
      <b-button
        :label="$tf('adminAbsenceRequests.editModal.close|Mégsem')"
        @click="$emit('close')"
      />
      <b-button
        :label="$tf('adminAbsenceRequests.editModal.save|Mentés')"
        type="is-primary"
        @click="save"
      />
    </footer>
  </div>
</template>

<script>
import { minLength, required, requiredIf } from "@vuelidate/validators";
import { mapGetters } from "vuex";
import { deepCopy, formatDate } from "@/utils/util";
import VuelidatedInput from "@/components/module/input/VuelidatedInput.vue";
import useCustomVuelidate from "@/plugins/vuelidate";

export default {
  name: "AbsenceRequestEditModal",
  components: { VuelidatedInput },
  props: {
    request: {
      type: Object,
      required: true,
    },
  },
  setup: () => useCustomVuelidate(),
  async mounted() {
    this.modelValue = deepCopy(this.request);
    this.modelValue.type = this.modelValue.type?.id;
    this.modelValue.status = this.modelValue.status?.enum;
    this.modelValue.absenceRequestDates =
      this.modelValue.absenceRequestDates?.map((date) => new Date(date));
    delete this.modelValue.created;
    delete this.modelValue.hasComment;
    delete this.modelValue.userId;
    delete this.modelValue.userData;
    await this.$store.dispatch("absence_request/getAdminTypes");
  },
  data() {
    return {
      modelValue: {
        id: undefined,
        absenceRequestDates: [],
        carryOver: false,
        description: undefined,
        status: undefined,
        type: undefined,
        userData: undefined,
      },
    };
  },
  validations: {
    modelValue: {
      absenceRequestDates: {
        required,
        minLength: minLength(1),
      },
      carryOver: {
        required,
      },
      status: {
        required,
      },
      type: {
        required,
      },
      description: {
        required: requiredIf(function () {
          return !!this.selectedType?.mustHaveComment;
        }),
      },
    },
  },
  computed: {
    ...mapGetters({
      absenceTypes: "absence_request/types",
    }),
    selectedType() {
      return this.absenceTypes?.find(
        (type) => type.id === this.modelValue.type
      );
    },
  },
  methods: {
    async save() {
      this.touch$();
      if (!this.v$.$invalid) {
        const request = deepCopy(this.modelValue);
        request.absenceRequestDates = request.absenceRequestDates?.map((date) =>
          formatDate(date)
        );
        await this.$store.dispatch("absence_request/updateRequestByAdmin", {
          id: this.request.id,
          request,
        });
        this.$emit("save");
        this.$emit("close");
      }
    },
  },
};
</script>
