<template>
  <loading-component>
    <template #loader>
      <div-with-loading
        loading-height="50px"
        loading-width="200px"
      ></div-with-loading>
    </template>
    <b-field class="select-with-buttons" grouped>
      <b-button
        :disabled="leftDisabled"
        class="left-button"
        icon-left="chevron-right"
        title="Hátra"
        @click="prev()"
      />
      <b-select
        v-if="dropdown"
        :expanded="true"
        :modelValue="valueKey ? selected[valueKey] : selected"
        @update:modelValue="setValueFromDropdown"
      >
        <option
          v-for="(item, index) in options"
          :key="index"
          :value="valueKey ? item[valueKey] : item"
        >
          {{ nameKey ? item[nameKey] : item }}
        </option>
      </b-select>
      <b-input
        v-else
        :value="nameKey ? selected[nameKey] : selected"
        disabled
      />
      <b-button
        :disabled="rightDisabled"
        class="right-button"
        icon-left="chevron-right"
        title="Előre"
        @click="next()"
      />
    </b-field>
  </loading-component>
</template>

<script>
import LoadingComponent from "@/components/loading/LoadingComponent.vue";
import DivWithLoading from "@/components/loading/DivWithLoading.vue";

export default {
  name: "SelectWithButtons",
  components: { LoadingComponent, DivWithLoading },
  props: {
    modelValue: {
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    nameKey: {
      type: String,
      required: false,
    },
    valueKey: {
      type: String,
      required: false,
    },
    dropdown: {
      type: Boolean,
      default: false,
    },
    reversed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: undefined,
      leftDisabled: false,
      rightDisabled: false,
    };
  },
  created() {
    this.onValueChanged();
  },
  watch: {
    modelValue() {
      this.onValueChanged();
    },
    options() {
      this.onOptionsChange();
    },
  },
  methods: {
    prev(skipCheck = false) {
      if (!skipCheck && this.reversed) {
        this.next(true);
        return;
      }
      let min = 0;
      let newIndex = min;
      let indexOfCurrent = this.options.findIndex(
        (option) => option.value === this.selected.value
      );
      if (indexOfCurrent > min) {
        newIndex = indexOfCurrent - 1;
      }
      this.selected = this.options[newIndex];
      this.emitValueChange();
    },
    next(skipCheck = false) {
      if (!skipCheck && this.reversed) {
        this.prev(true);
        return;
      }
      let max = this.options.length - 1;
      let newIndex = max;
      let indexOfCurrent = this.options.findIndex(
        (option) => option.value === this.selected.value
      );
      if (indexOfCurrent < max) {
        newIndex = indexOfCurrent + 1;
      }
      this.selected = this.options[newIndex];
      this.emitValueChange();
    },
    onValueChanged() {
      if (this.modelValue) {
        this.selected = this.options
          .filter((option) =>
            this.valueKey
              ? option[this.valueKey] ===
                (this.valueKey
                  ? this.modelValue[this.valueKey]
                  : this.modelValue)
              : option ===
                (this.valueKey
                  ? this.modelValue[this.valueKey]
                  : this.modelValue)
          )
          .shift();
      } else {
        this.selected = this.options[0];
      }
      let currentIndex = this.options.indexOf(this.selected);
      this.leftDisabled = currentIndex === 0;
      this.rightDisabled = currentIndex === this.options.length - 1;
      if (this.reversed) {
        const temp = this.leftDisabled;
        this.leftDisabled = this.rightDisabled;
        this.rightDisabled = temp;
      }
    },
    onOptionsChange() {
      this.selected = this.options[0];
    },
    emitValueChange() {
      this.$emit(
        "update:modelValue",
        this.valueKey ? this.selected[this.valueKey] : this.selected
      );
    },
    setValueFromDropdown(val) {
      this.selected = this.options.find(
        (option) => (this.valueKey ? option[this.valueKey] : option) === val
      );
      this.emitValueChange();
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/colors";

.select-with-buttons {
  .button,
  .input:disabled {
    border-color: $grey-lighter;
    color: $custom-dark-blue !important;
    background-color: $white !important;
  }

  .left-button {
    transform: rotate(180deg);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    padding-left: 0.5rem;
    padding-right: 1rem;
  }

  .right-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    padding-left: 0.5rem;
    padding-right: 1rem;
  }

  .left-button,
  .right-button {
    &:hover,
    &:active,
    &:focus {
      border: 1px solid $grey-lighter;
      border-left: none;
    }
  }

  .input:disabled {
    cursor: default !important;
    border-radius: 0 !important;
    border-left: none !important;
    border-right: none !important;
    text-align: center;
    width: 8rem;
  }

  .button {
    width: 0.5rem;
  }

  .control {
    margin-left: 0 !important;
    margin-right: 0 !important;

    .input {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .icon {
    font-size: 0.9rem;
  }

  select {
    border-left: none !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border-right: none !important;
    border-radius: 0 !important;
    text-align: center !important;
  }
}
</style>
