<template>
  <div>
    <table class="collapse">
      <caption></caption>
      <tbody>
        <tr>
          <th
            v-for="quarter in FORECAST_YEAR_QUARTER"
            :key="`header-${quarter}`"
            :class="[
              $style.column,
              $style.header,
              $style.leftBorder,
              $style.rightBorder,
            ]"
            class="has-text-centered"
          >
            {{ quarter }}
          </th>
        </tr>
        <tr v-for="(row, index) in data" :key="`row-${index}`">
          <td
            v-for="quarter in FORECAST_YEAR_QUARTER"
            :key="`row-q-${quarter}`"
            :class="[
              $style.column,
              {
                [$style.leftBorder]: hasLeftBorder(row, quarter),
                [$style.rightBorder]: hasRightBorder(row, quarter),
              },
            ]"
            :style="{
              'padding-left':
                isAdmissibleFirstQuarter(row, quarter) && row.rank
                  ? `${Math.min(row.rank * 15, 100)}px !important`
                  : 0,
            }"
            class="px-0 pb-0 is-whitespace-nowrap"
          >
            <template v-if="showDataForQuarter(row, quarter)">
              <div
                :class="[
                  $style.base,
                  style(row),
                  {
                    [$style.closedLeft]: isTrueFirstQuarter(row, quarter),
                    [$style.closedRight]: isLastQuarter(row, quarter),
                    [$style.cutoff]: isLastQuarter(row, quarter),
                  },
                ]"
                @click="$emit('select', row, $event)"
              >
                <div
                  class="is-flex is-justify-content-space-between has-gap-1 p-1"
                >
                  <div class="mb-1">
                    <b-tooltip
                      :label="rowName(row, quarter)"
                      append-to-body
                      type="is-white"
                    >
                      {{ rowName(row, quarter) }}
                    </b-tooltip>
                  </div>
                  <div
                    v-if="isProject(row) && isLastQuarter(row, quarter)"
                    :class="$style.iconContainer"
                  >
                    <b-tooltip
                      :auto-close="['outside', 'escape']"
                      :class="$style.iconContainer"
                      append-to-body
                      type="is-light"
                    >
                      <template v-slot:content>
                        <div class="content tooltip">
                          <h2 class="title is-5">
                            {{
                              $tf(
                                "forecastYearlyData.projectTooltip.title|Tavalyról átnyúló projekt"
                              )
                            }}
                          </h2>
                          <p>
                            {{
                              $tf(
                                "forecastYearlyData.projectTooltip.content|Az alábbi tervsor egy tavaly rögzített Jira projekt, így nem módosítható a tervben"
                              )
                            }}
                          </p>
                          <router-link
                            :to="`/projects/project/${row.project.identifier}`"
                          >
                            <p>
                              {{
                                $tf(
                                  "forecastYearlyData.projectTooltip.link|Kattints ide a projekt adatlapjának megtekintéséhez"
                                )
                              }}
                            </p>
                          </router-link>
                        </div>
                      </template>
                      <b-icon :class="[$style.infoIcon]" icon="info" />
                    </b-tooltip>
                  </div>
                </div>
                <div class="is-flex is-justify-content-space-between px-2">
                  <div>{{ chanceText(row, quarter) }}</div>
                  <div>{{ incomeText(row, quarter) }}</div>
                </div>
              </div>
            </template>
          </td>
        </tr>
        <tr>
          <td
            v-for="quarter in FORECAST_YEAR_QUARTER"
            :key="`row-q-empty-${quarter}`"
            :class="[$style.column, $style.leftBorder, $style.rightBorder]"
            class="pt-0 pb-6 is-whitespace-nowrap"
          />
        </tr>
        <tr>
          <td
            v-for="(val, quarter) in quarterlyMoneyData"
            :key="`quarterlyMoney-${quarter}`"
            class="has-text-centered"
          >
            {{
              $tf(`forecastYearlyData.incomeQuarter|{quarter}: {income}`, {
                quarter,
                income: moneyify(
                  val,
                  CURRENCY_TIERS.LEVEL_TWO,
                  true,
                  true,
                  true,
                  2
                ),
              })
            }}
          </td>
        </tr>
      </tbody>
    </table>
    <slot name="footer"></slot>
    <div class="is-flex has-gap-3 mb-2">
      <div class="is-flex has-gap-2">
        <div :class="[$style.legendBox, $style.project]"></div>
        <div>
          {{
            $tf(
              "forecastYearlyData.legend.project|JIRA Projekttel összekötött sor"
            )
          }}
        </div>
      </div>
      <div class="is-flex has-gap-2">
        <div :class="[$style.legendBox, $style.plan]"></div>
        <div>
          {{ $tf("forecastYearlyData.legend.plan|Éves tervsor") }}
        </div>
      </div>
      <div class="is-flex has-gap-2">
        <div :class="[$style.legendBox, $style.childPlan]"></div>
        <div>
          {{
            $tf(
              "forecastYearlyData.legend.childPlan|Éves tervhez kötött szűkített tervsor"
            )
          }}
        </div>
      </div>
    </div>
    <div>
      <h4>
        {{
          $tf("forecastYearlyData.yearlyIncome|Éves összeg: {income}", {
            income: moneyify(
              yearlyIncome,
              CURRENCY_TIERS.LEVEL_TWO,
              true,
              true,
              true,
              2
            ),
          })
        }}
      </h4>
    </div>
  </div>
</template>

<script>
import { CURRENCY_TIERS, FORECAST_YEAR_QUARTER } from "@/utils/const";
import { moneyify } from "@/utils/util";
import ForecastDataMixin from "@/mixins/ForecastDataMixin";
import { isEmpty } from "lodash";

export default {
  name: "ForecastYearlyData",
  props: {
    year: {
      type: Number,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    quarterlyMoneyData: {
      type: Object,
      required: true,
    },
    yearlyIncome: {
      type: Number,
      required: false,
    },
  },
  mixins: [ForecastDataMixin],
  data() {
    return {
      FORECAST_YEAR_QUARTER,
      moneyify,
      CURRENCY_TIERS,
    };
  },
  methods: {
    showDataForQuarter(row, quarter) {
      switch (quarter) {
        case FORECAST_YEAR_QUARTER.Q1:
          return (
            (this.isCurrentYear(row) &&
              this.hasIncome(row, FORECAST_YEAR_QUARTER.Q1)) ||
            (this.isPreviousYear(row) &&
              this.hasIncomeNextYear(row, FORECAST_YEAR_QUARTER.Q1))
          );
        case FORECAST_YEAR_QUARTER.Q2:
          return (
            (this.isCurrentYear(row) &&
              this.hasIncome(row, FORECAST_YEAR_QUARTER.Q2)) ||
            (this.isPreviousYear(row) &&
              this.hasIncomeNextYear(row, FORECAST_YEAR_QUARTER.Q2))
          );
        case FORECAST_YEAR_QUARTER.Q3:
          return (
            (this.isCurrentYear(row) &&
              this.hasIncome(row, FORECAST_YEAR_QUARTER.Q3)) ||
            (this.isPreviousYear(row) &&
              this.hasIncomeNextYear(row, FORECAST_YEAR_QUARTER.Q3))
          );
        case FORECAST_YEAR_QUARTER.Q4:
          return (
            (this.isCurrentYear(row) &&
              this.hasIncome(row, FORECAST_YEAR_QUARTER.Q4)) ||
            (this.isPreviousYear(row) &&
              this.hasIncomeNextYear(row, FORECAST_YEAR_QUARTER.Q4))
          );
      }
    },
    isAdmissibleFirstQuarter(row, quarter) {
      switch (quarter) {
        case FORECAST_YEAR_QUARTER.Q1:
          return true;

        case FORECAST_YEAR_QUARTER.Q2:
          return (
            (this.isCurrentYear(row) &&
              !this.hasIncome(row, FORECAST_YEAR_QUARTER.Q1)) ||
            (this.isPreviousYear(row) &&
              !this.hasIncomeNextYear(row, FORECAST_YEAR_QUARTER.Q1))
          );

        case FORECAST_YEAR_QUARTER.Q3:
          return (
            (this.isCurrentYear(row) &&
              !this.hasIncome(row, FORECAST_YEAR_QUARTER.Q2)) ||
            (this.isPreviousYear(row) &&
              !this.hasIncomeNextYear(row, FORECAST_YEAR_QUARTER.Q2))
          );

        case FORECAST_YEAR_QUARTER.Q4:
          return (
            (this.isCurrentYear(row) &&
              !this.hasIncome(row, FORECAST_YEAR_QUARTER.Q3)) ||
            (this.isPreviousYear(row) &&
              !this.hasIncomeNextYear(row, FORECAST_YEAR_QUARTER.Q3))
          );
      }
    },
    isTrueFirstQuarter(row, quarter) {
      switch (quarter) {
        case FORECAST_YEAR_QUARTER.Q1:
          return this.isCurrentYear(row);

        case FORECAST_YEAR_QUARTER.Q2:
          return (
            (this.isCurrentYear(row) &&
              !this.hasIncome(row, FORECAST_YEAR_QUARTER.Q1)) ||
            (this.isPreviousYear(row) &&
              !this.hasIncomeNextYear(row, FORECAST_YEAR_QUARTER.Q1))
          );

        case FORECAST_YEAR_QUARTER.Q3:
          return (
            (this.isCurrentYear(row) &&
              !this.hasIncome(row, FORECAST_YEAR_QUARTER.Q2)) ||
            (this.isPreviousYear(row) &&
              !this.hasIncomeNextYear(row, FORECAST_YEAR_QUARTER.Q2))
          );

        case FORECAST_YEAR_QUARTER.Q4:
          return (
            (this.isCurrentYear(row) &&
              !this.hasIncome(row, FORECAST_YEAR_QUARTER.Q3)) ||
            (this.isPreviousYear(row) &&
              !this.hasIncomeNextYear(row, FORECAST_YEAR_QUARTER.Q3))
          );
      }
    },
    isLastQuarter(row, quarter) {
      switch (quarter) {
        case FORECAST_YEAR_QUARTER.Q1:
          return !(
            (this.isCurrentYear(row) &&
              this.hasIncome(row, FORECAST_YEAR_QUARTER.Q2)) ||
            (this.isPreviousYear(row) &&
              this.hasIncomeNextYear(row, FORECAST_YEAR_QUARTER.Q2))
          );
        case FORECAST_YEAR_QUARTER.Q2:
          return !(
            (this.isCurrentYear(row) &&
              this.hasIncome(row, FORECAST_YEAR_QUARTER.Q3)) ||
            (this.isPreviousYear(row) &&
              this.hasIncomeNextYear(row, FORECAST_YEAR_QUARTER.Q3))
          );
        case FORECAST_YEAR_QUARTER.Q3:
          return !(
            (this.isCurrentYear(row) &&
              this.hasIncome(row, FORECAST_YEAR_QUARTER.Q4)) ||
            (this.isPreviousYear(row) &&
              this.hasIncomeNextYear(row, FORECAST_YEAR_QUARTER.Q4))
          );
        case FORECAST_YEAR_QUARTER.Q4:
          return !(
            this.isCurrentYear(row) &&
            this.hasIncomeNextYear(row, FORECAST_YEAR_QUARTER.Q2)
          );
      }
    },
    hasLeftBorder(row, quarter) {
      switch (quarter) {
        case FORECAST_YEAR_QUARTER.Q1:
          return !(
            this.isPreviousYear(row) &&
            this.hasIncomeNextYear(row, FORECAST_YEAR_QUARTER.Q1)
          );
        case FORECAST_YEAR_QUARTER.Q2:
          return (
            (this.isCurrentYear(row) &&
              !(
                this.hasIncome(row, FORECAST_YEAR_QUARTER.Q1) &&
                this.hasIncome(row, FORECAST_YEAR_QUARTER.Q2)
              )) ||
            (this.isPreviousYear(row) &&
              !(
                this.hasIncomeNextYear(row, FORECAST_YEAR_QUARTER.Q1) &&
                this.hasIncomeNextYear(row, FORECAST_YEAR_QUARTER.Q2)
              ))
          );
        case FORECAST_YEAR_QUARTER.Q3:
          return (
            (this.isCurrentYear(row) &&
              !(
                this.hasIncome(row, FORECAST_YEAR_QUARTER.Q2) &&
                this.hasIncome(row, FORECAST_YEAR_QUARTER.Q3)
              )) ||
            (this.isPreviousYear(row) &&
              !(
                this.hasIncomeNextYear(row, FORECAST_YEAR_QUARTER.Q2) &&
                this.hasIncomeNextYear(row, FORECAST_YEAR_QUARTER.Q3)
              ))
          );
        case FORECAST_YEAR_QUARTER.Q4:
          return (
            (this.isCurrentYear(row) &&
              !(
                this.hasIncome(row, FORECAST_YEAR_QUARTER.Q3) &&
                this.hasIncome(row, FORECAST_YEAR_QUARTER.Q4)
              )) ||
            (this.isPreviousYear(row) &&
              !(
                this.hasIncomeNextYear(row, FORECAST_YEAR_QUARTER.Q3) &&
                this.hasIncomeNextYear(row, FORECAST_YEAR_QUARTER.Q4)
              ))
          );
      }
    },
    hasRightBorder(row, quarter) {
      switch (quarter) {
        case FORECAST_YEAR_QUARTER.Q1:
          return (
            (this.isCurrentYear(row) &&
              !(
                this.hasIncome(row, FORECAST_YEAR_QUARTER.Q1) &&
                this.hasIncome(row, FORECAST_YEAR_QUARTER.Q2)
              )) ||
            (this.isPreviousYear(row) &&
              !(
                this.hasIncomeNextYear(row, FORECAST_YEAR_QUARTER.Q1) &&
                this.hasIncomeNextYear(row, FORECAST_YEAR_QUARTER.Q2)
              ))
          );
        case FORECAST_YEAR_QUARTER.Q2:
          return (
            (this.isCurrentYear(row) &&
              !(
                this.hasIncome(row, FORECAST_YEAR_QUARTER.Q2) &&
                this.hasIncome(row, FORECAST_YEAR_QUARTER.Q3)
              )) ||
            (this.isPreviousYear(row) &&
              !(
                this.hasIncomeNextYear(row, FORECAST_YEAR_QUARTER.Q2) &&
                this.hasIncomeNextYear(row, FORECAST_YEAR_QUARTER.Q3)
              ))
          );
        case FORECAST_YEAR_QUARTER.Q3:
          return (
            (this.isCurrentYear(row) &&
              !(
                this.hasIncome(row, FORECAST_YEAR_QUARTER.Q3) &&
                this.hasIncome(row, FORECAST_YEAR_QUARTER.Q4)
              )) ||
            (this.isPreviousYear(row) &&
              !(
                this.hasIncomeNextYear(row, FORECAST_YEAR_QUARTER.Q3) &&
                this.hasIncomeNextYear(row, FORECAST_YEAR_QUARTER.Q4)
              ))
          );
        case FORECAST_YEAR_QUARTER.Q4:
          return !(
            this.isCurrentYear(row) &&
            this.hasIncome(row, FORECAST_YEAR_QUARTER.Q4) &&
            this.hasIncomeNextYear(row, FORECAST_YEAR_QUARTER.Q1)
          );
      }
    },
    rowName(row, quarter) {
      if (!this.isAdmissibleFirstQuarter(row, quarter)) return " ";
      if (this.isProject(row))
        return this.$tf(`forecastYearlyData.projectName|JIRA: {identifier}`, {
          identifier: row.project.identifier,
        });
      let name = row.name;
      if (row.salesType) name += ` - ${row.salesType.name}`;
      if (row.incomeType) name += ` - ${row.incomeType.name}`;
      if (row.serviceType) name += ` - ${row.serviceType.name}`;
      if (row.account) name += ` - ${row.account.name}`;
      if (row.segment) name += ` - ${row.segment.name}`;
      if (row.userId && row.userData) name += ` - ${row.userData.name}`;
      return name;
    },
    chanceText(row, quarter) {
      const chance = this.getChance(row, quarter);
      if (typeof chance === "undefined") return " ";
      return this.$tf("forecastYearlyData.chance|Valószínűség: {chance}%", {
        chance,
      });
    },
    incomeText(row, quarter) {
      const income = this.getIncome(row, quarter);
      if (typeof income === "undefined") return " ";

      const incomeDecorated = moneyify.call(
        this,
        income,
        CURRENCY_TIERS.LEVEL_TWO,
        true,
        true,
        true,
        2
      );

      const totalIncome = this.calculateTotalIncome(row, quarter);

      const totalIncomeDecorated = moneyify.call(
        this,
        totalIncome,
        CURRENCY_TIERS.LEVEL_TWO,
        true,
        false,
        true,
        2
      );

      return totalIncome > 0
        ? `${totalIncomeDecorated} / ${incomeDecorated}`
        : incomeDecorated;
    },

    calculateTotalIncome(row, quarter) {
      let totalIncome = 0;
      const children = this.findChildren(row);

      if (!isEmpty(children)) {
        children.forEach((child) => {
          const childIncome = this.getIncome(child, quarter) || 0;
          totalIncome += childIncome;
        });
      }

      return totalIncome;
    },
    findChildren(row) {
      return this.data.filter((it) => it.parent?.id === row.id);
    },
  },
};
</script>
<style lang="scss" module scoped>
@import "~@/assets/scss/colors.scss";

.column {
  max-width: 25px !important;
}

.header {
  font-weight: 500 !important;
  border-bottom: 2px solid $black !important;
}

.base {
  cursor: pointer;
}

.cutoff {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.plan {
  background-color: $primary;
}

.childPlan {
  background-color: $purple;
}

.project {
  background-color: $turquoise;
}

.closedLeft {
  border-radius: 5px 0 0 5px !important;
}

.closedRight {
  border-radius: 0 5px 5px 0 !important;
}

.leftBorder {
  padding-left: 5px !important;
  border-left: 2px solid $grey !important;
}

.rightBorder {
  padding-right: 5px !important;
  border-right: 2px solid $grey !important;
}

.infoIcon {
  border-radius: 9999px;
  background-color: white;
  color: $turquoise;
}

.iconContainer {
  height: 24px !important;
}

.legendBox {
  width: 24px !important;
  height: 24px !important;
  border-radius: 8px !important;
}
</style>
