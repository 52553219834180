<template>
  <div>
    <div v-if="displayMode === 'minimal'">
      <b-tooltip
        :active="active"
        :append-to-body="appendTooltipToBody"
        :position="`is-${tooltipPosition}`"
        always
        multilined
      >
        <f-avatar
          :border="border"
          :data="client"
          :font-size="fontSize"
          :size="size"
          :username="clientName || client?.identifier"
          @mouseEnterAvatar="showTooltip"
          @mouseLeaveAvatar="delayedHideTooltip"
        />
        <template v-slot:content>
          <div
            @mouseenter="showTooltip"
            @mouseleave="delayedHideTooltip"
            @click.stop
          >
            {{ client?.identifier }}<br />
            {{ client?.name }}<br />
          </div>
        </template>
      </b-tooltip>
    </div>
    <div v-else-if="displayMode === 'long'" class="media is-align-items-center">
      <f-avatar
        :border="border"
        :data="client"
        :font-size="fontSize"
        :size="size"
        :username="clientName || client?.identifier"
        @mouseEnterAvatar="showTooltip"
        @mouseLeaveAvatar="delayedHideTooltip"
      />
      <div class="media-content">
        <p class="title is-5">{{ client?.identifier }}</p>
      </div>
    </div>
    <div
      v-else-if="displayMode === 'brief'"
      class="media is-align-items-center"
    >
      <div class="media-content">
        <p class="brief-title">
          <template v-if="!!client?.internal">
            {{ $tf("clientInfo.internal|Belsős projekt - ") }}
          </template>
          <template v-else>
            {{ $tf("clientInfo.external|Kliens projekt - ") }}
          </template>
          {{ client?.name }}
        </p>
      </div>
      <f-avatar
        :border="border"
        :data="client"
        :font-size="fontSize"
        :size="size"
        :username="clientName || client?.identifier"
        @mouseEnterAvatar="showTooltip"
        @mouseLeaveAvatar="delayedHideTooltip"
      />
    </div>
  </div>
</template>

<script>
import FAvatar from "@/components/module/icon/FAvatar.vue";

export default {
  components: { FAvatar },
  data() {
    return {
      active: false,
    };
  },
  props: {
    client: {
      type: Object,
      required: true,
      default: () => ({
        identifier: "",
        name: "",
        avatar: "",
      }),
    },
    clientName: {
      type: String,
      default: "",
    },
    displayMode: {
      type: String,
      required: true,
      validator: (value) => ["minimal", "long", "brief"].includes(value),
    },
    tooltipPosition: {
      type: String,
      validator: function (value) {
        return ["top", "right", "bottom", "left"].includes(value);
      },
    },
    size: {
      type: String,
      default: "48x48",
    },
    fontSize: {
      type: Number,
    },
    appendTooltipToBody: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    showTooltip() {
      if (this.hideTimeout) {
        clearTimeout(this.hideTimeout);
        this.hideTimeout = null;
      }
      this.active = true;
    },
    delayedHideTooltip() {
      this.hideTimeout = setTimeout(() => {
        this.active = false;
      }, 10);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.brief-title {
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: $blue;
}
</style>
