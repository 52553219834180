<template>
  <div class="label-box">
    <b-icon :icon="icon" class="icon-xl" icon-pack="fal" size="is-large" />
    <div
      class="is-flex is-flex-direction-column is-justify-content-space-between"
    >
      <div>
        <p class="header">{{ header }}</p>
      </div>
      <div>
        <p class="text">{{ text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LabelBox",
  props: {
    icon: {
      type: String,
      required: true,
    },
    header: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.label-box {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 10px;

  height: 45px;

  .header {
    font-family: Roboto, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18.2px;
    text-align: left;
    color: $custom-grayer-blue;
  }

  .text {
    font-family: Roboto, serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    text-align: left;
    color: $custom-dark-blue;
  }

  .icon {
    width: 24px;
    height: 24px;
  }
}
</style>
