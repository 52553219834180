<template>
  <div class="modal-card" style="width: 28rem">
    <div class="container">
      <section class="modal-card-body">
        <div v-if="tigData.status != 'PLANNED'">
          <h1 class="title small-title mt-0">
            <b-icon icon="eye" size="is-small" />
            Változtatott védett adatok
          </h1>

          <div v-if="tigData.budget != tigEdit.budget">
            Budget: {{ moneyify(tigData.budget) }}
            <b-icon
              class="arrow-icon"
              icon="arrow-right"
              size="is-small"
            ></b-icon>
            {{ moneyify(tigEdit.budget) }}
          </div>

          <div
            v-if="
              toDate(tigData.dueDate).getFullYear() !=
                tigEdit.dueDate.getFullYear() ||
              toDate(tigData.dueDate).getMonth() !=
                tigEdit.dueDate.getMonth() ||
              toDate(tigData.dueDate).getDate() != tigEdit.dueDate.getDate()
            "
          >
            Határidő: {{ tigData.dueDate }}
            <b-icon
              class="arrow-icon"
              icon="arrow-right"
              size="is-small"
            ></b-icon>
            {{ formatDate(tigEdit.dueDate) }}
          </div>

          <div v-if="tigData.commitment != tigEdit.commitment">
            Vállalás: {{ numberify(tigData.commitment) }} nap
            <b-icon
              class="arrow-icon"
              icon="arrow-right"
              size="is-small"
            ></b-icon>
            {{ numberify(tigEdit.commitment) }} nap
          </div>

          <div v-if="tigData.billableAmount != tigEdit.billableAmount">
            Számlázható összeg: {{ moneyify(tigData.billableAmount) }}
            <b-icon
              class="arrow-icon"
              icon="arrow-right"
              size="is-small"
            ></b-icon>
            {{ moneyify(tigEdit.billableAmount) }}
          </div>

          <div v-if="!securedDataChange">Nem változtattál védett adatot</div>
        </div>

        <b-field class="mt-4" label="Összegzés" label-position="on-border">
          <b-input v-model="summary"></b-input>
        </b-field>

        <b-field class="mt-4" label="Leírás" label-position="on-border">
          <b-input v-model="description" type="textarea"></b-input>
        </b-field>

        <b-button
          :disabled="summary == null || description == null"
          type="is-info"
          @click="saveTig()"
        >
          Mentés
        </b-button>
      </section>
    </div>
  </div>
</template>

<script>
import { formatDate, moneyify, numberify } from "../../../../utils/util";
import { getCurrentInstance } from "vue";

export default {
  name: "JournalProjectModal",
  setup: () => {
    const instance = getCurrentInstance();
    return {
      instance: instance,
    };
  },
  props: {
    tigData: {
      type: Object,
      required: true,
    },
    tigEdit: {
      type: Object,
      required: true,
    },
  },
  computed: {
    securedDataChange() {
      return (
        this.tigData.budget !== this.tigEdit.budget ||
        this.tigData.expectedIncome !== this.tigEdit.expectedIncome ||
        this.tigData.commitment !== this.tigEdit.commitment ||
        this.toDate(this.tigData.dueDate).getFullYear() !=
          this.tigEdit.dueDate.getFullYear() ||
        this.toDate(this.tigData.dueDate).getMonth() !=
          this.tigEdit.dueDate.getMonth() ||
        this.toDate(this.tigData.dueDate).getDate() !=
          this.tigEdit.dueDate.getDate() ||
        this.tigData.billableAmount !== this.tigEdit.billableAmount
      );
    },
  },
  data() {
    return {
      formatDate,
      moneyify,
      numberify,
      summary: null,
      description: null,
    };
  },
  methods: {
    toDate(date) {
      const numbers = date.split("-");
      return new Date(numbers[0], numbers[1] - 1, numbers[2]);
    },
    async saveTig() {
      this.$emit("tig-saved", {
        summary: this.summary,
        description: this.description,
      });
      this.instance.emit("close");
    },
  },
};
</script>
