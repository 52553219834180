import Minilog from "minilog";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import {
  CURRENCY_TIERS,
  FORECAST_RULES,
  FORECAST_YEAR_QUARTER,
  ISSUE_TRACKING_SYSTEM,
  romanMatrix,
  UI_THEMES,
} from "@/utils/const";
import tinycolor from "tinycolor2";
import { app_colors } from "@/utils/colors";
import { ColorUpdater } from "bulma-css-vars";
import { bulmaCssVariablesDefs } from "@/bulma-generated/bulma-colors";
import { useStore } from "vuex";
import { md5 } from "js-md5";
import ColorHash from "color-hash";
import { $tf } from "@/plugins/i18n";

dayjs.extend(updateLocale);
const weekOfYear = require("dayjs/plugin/weekOfYear");
dayjs.extend(weekOfYear);

dayjs.updateLocale("en", {
  months: [
    "Január",
    "Február",
    "Március",
    "Április",
    "Május",
    "Június",
    "Július",
    "Augusztus",
    "Szeptember",
    "Október",
    "November",
    "December",
  ],
});

const LOCALSTORAGE_CONFIG_KEY = "loginet_internal_config";

/**
 * Sets up a configuration JSON in localStorage and stores the value on the key
 * @param key
 * @param value
 */
export function localStore(key, value) {
  let stored = localStorage.getItem(LOCALSTORAGE_CONFIG_KEY);
  if (stored !== null) {
    let parsed = JSON.parse(stored);
    parsed[key] = value;
    localStorage.setItem(LOCALSTORAGE_CONFIG_KEY, JSON.stringify(parsed));
  } else {
    let data = {};
    data[key] = value;
    localStorage.setItem(LOCALSTORAGE_CONFIG_KEY, JSON.stringify(data));
  }
}

/**
 * Returns the value stored in the config JSON
 * @param key
 * @returns {undefined|*}
 */
export function localFetch(key) {
  let stored = localStorage.getItem(LOCALSTORAGE_CONFIG_KEY);
  if (stored !== null) {
    // localStorage returns null if not exists
    let parsed = JSON.parse(stored);
    return parsed[key];
  } else {
    return undefined; //parsed[key] returns undefined if not exists, so we do it too
  }
}

export function isDevMode() {
  /* eslint-disable */
  return process.env.NODE_ENV === "development";
}

export function newLogger(name) {
  if (isDevMode() === true) {
    Minilog.enable();
  }
  return Minilog(name || "app");
}


export function formatDate(date, fallback) {
  if (isNullOrUndefined(date) && fallback) return fallback;
  return dayjs(date).format("YYYY-MM-DD");
}

export function formatDateWithMonthName(date) {
  return dayjs(date).format("YYYY. MMMM DD.").toLowerCase();
}

export function formatDayWithMonthName(date) {
  return dayjs(date).format("MMMM D.");
}

export function formatDayWithMonthNameShort(date) {
  return dayjs(date).format("MMM. DD");
}

export function formatDateTime(date) {
  if (!date) return "-";
  return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
}

export function formatTime(date) {
  return dayjs(date).format("HH:mm");
}

export function getMonthName(date) {
  return dayjs(date).format("MMMM");
}

export function addDay(date, numberOfDays) {
  return dayjs(date).add(numberOfDays, "day").format("YYYY-MM-DD");
}

export function subtractDay(date, numberOfDays) {
  return dayjs(date).subtract(numberOfDays, "day").format("YYYY-MM-DD");
}

export function normalize(object) {
  return object.toString()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
}

export function employeeFilter(str, employees) {
  return employees.filter(element => {
    if (!str || !element.name) return false;
    return (
      normalize(element.name)
        .indexOf(
          normalize(
            str)
        ) >= 0 ||
      (element.handle && normalize(element.handle)
          .indexOf(normalize(str))
      ) >= 0);
  });
}

export function openDeleteConfirm(buefy, onConfirmCallback, message = $tf("util.deleteConfirm.message|Biztos vagy benne, hogy <b>törölni</b> szeretnéd?")) {
  buefy.dialog.confirm({
    title: $tf("util.deleteConfirmModel.title|Törlés megerősítés"),
    message: message,
    confirmText: $tf("util.deleteConfirmModel.confirmText|Igen"),
    cancelText: $tf("util.deleteConfirmModel.cancelText|Mégsem"),
    type: "is-danger",
    hasIcon: true,
    onConfirm: async () => onConfirmCallback()
  });
}

export function createUrlSearchParams(pagination, sort) {
  const { page, size } = pagination;
  const sortParam =
    sort.field !== null
      ? `${sort.field},${sort.order}`
      : null;

  let urlSearchParams = new URLSearchParams();
  urlSearchParams.append("page", page);
  urlSearchParams.append("size", size);
  urlSearchParams.append("sort", sortParam);

  return urlSearchParams;
}

export function getDisplayName(employees, user) {
  let employee = employees.filter(emp => {
    return emp.id === user;
  });
  if (employee[0]) return employee[0].name;
  return user;
}

export function deepCopy(object) {
  try {
    return JSON.parse(JSON.stringify(object));
  } catch (e) {
    console.warn("Couldn't copy object in the next line!");
    console.warn(object);
    return {}; // todo:: legyen-e undefined? null?
  }
}

export const numberify = (str, showPolarityPrefix = false) => {
  if (!str) {
    return 0;
  }
  let num;
  if (typeof str !== "number") {
    num = parseFloat(str.replaceAll(" ", ""));
  } else {
    num = str;
  }
  let decoratedValue = num;
  if (showPolarityPrefix && Number(num) > 0) decoratedValue = "+".concat(num);
  return decoratedValue;
};

export function useMoneyify() {
  let currencySymbol;
  let getCurrencyTier;
  let prefixed = false;
  let decimals = 2;
  const store = useStore();

  if (store?.getters["uiConfigStore/isLoaded"]) {
    if (store.getters["uiConfigStore/getCurrencySymbol"])
      currencySymbol = store.getters["uiConfigStore/getCurrencySymbol"];
    if (store.getters["uiConfigStore/getCurrencyTier"])
      getCurrencyTier = store.getters["uiConfigStore/getCurrencyTier"];
    decimals = store.getters["uiConfigStore/getCurrencyPrecision"];
    prefixed = store.getters["uiConfigStore/getCurrencyIsPrefix"];
  }

  return function(
    number,
    {
      tier = CURRENCY_TIERS.LEVEL_ZERO,
      showSuffix = true,
      showCurrency = true,
      useSpaces = true,
      precision = undefined,
      showPolarityPrefix = false
    } = {}
  ) {
    if (isNaN(number)) {
      return "-";
    }

    const currencyTier = getCurrencyTier?.(tier);
    const divided = number / (currencyTier?.divisor ?? 1);
    const prefix = divided < 0 ? "-" : "";
    const absValue = Math.abs(divided);
    let parts = roundToNDecimals(absValue, precision ?? decimals)
      .toString()
      .split(".");
    if (parts[0] && useSpaces) {
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
    let decoratedNumber = parts.join(".").trim();
    if (typeof currencySymbol !== "undefined" && showSuffix)
      decoratedNumber = decoratedNumber.concat(" ", currencyTier?.symbol ?? "");
    if (showPolarityPrefix && Number(divided) > 0)
      decoratedNumber = "+".concat(decoratedNumber);
    if (typeof currencySymbol !== "undefined" && showCurrency)
      decoratedNumber = prefix.concat(prefixed ? currencySymbol.concat(decoratedNumber) : decoratedNumber.concat(" ", currencySymbol));
    return decoratedNumber.trim();
  };
}

export function moneyify(number,
                         tier = CURRENCY_TIERS.LEVEL_ZERO,
                         showSuffix = true,
                         showCurrency = true,
                         useSpaces = true,
                         precision = undefined,
                         showPolarityPrefix = false) {
  const format = useMoneyify();
  return format(number, {
    tier,
    showSuffix,
    showCurrency,
    useSpaces,
    precision,
    showPolarityPrefix
  });
}

export function moneyifyAs({
                             number,
                             tier = CURRENCY_TIERS.LEVEL_ZERO,
                             showSuffix = true,
                             showCurrency = true,
                             useSpaces = true,
                             precision = undefined,
                             showPolarityPrefix = false
                           }) {
  return moneyify.call(this, number, tier, showSuffix, showCurrency, useSpaces, precision, showPolarityPrefix);
}

export function percentify(value, showPolarityPrefix = false) {
  const num = roundToNDecimals(value * 100, 1);
  let decoratedValue = num;
  if (num > 9999) decoratedValue = "999..%";
  if (num < -9999) decoratedValue = "-999..%";
  if (showPolarityPrefix && Number(num) > 0) decoratedValue = "+".concat(decoratedValue.toString());
  if (decoratedValue.toString().endsWith("%")) return decoratedValue;
  return Number.isNaN(num) ? "-" : decoratedValue + "%";
}

export function workdayify(val, showPolarityPrefix = false) {

  const num = roundToNDecimals(parseFloat(val), 1);
  let decoratedValue = num;
  if (showPolarityPrefix && Number(num) > 0) decoratedValue += "+".concat(num.toString());
  return $tf("calendar.workdayify|{number} MWD", {
    number: decoratedValue
  });
}

export function calendarDayify(val) {

  return $tf("calendar.dayify|{number} nap", {
    number: roundToNDecimals(parseFloat(val), 1)
  });
}

export function roundToTwoDecimals(value) {
  return roundToNDecimals(value, 2);
}

//function to round to given number of decimals
export function roundToNDecimals(value, decimals) {
  const divider = Math.pow(10, decimals);
  return Number.parseFloat((Math.round(value * divider) / divider).toString());
}


export function toShortFormMoney(value, decimals = 0) {
  let divided = value;
  let suffix = "";
  if (value > 1_000_000) {
    divided = roundToNDecimals(value / 1_000_000, decimals);
    suffix = "m";
  } else if (value > 1_000) {
    divided = roundToNDecimals(value / 1_000, decimals);
    suffix = "e";
  }

  return roundToTwoDecimals(divided) + `${suffix} Ft`;
}

export function secondsToHoursAndMinutes(time) {
  let hours = Math.floor(time / 60 / 60);
  let minutes = time / 60 % 60;
  let formatted = `${hours} óra`;
  if (minutes !== 0) {
    formatted = formatted.concat(` ${minutes} perc`);
  }
  return formatted;
}

export function secondToTimeFormat(time) {
  let hours = time > 0 ? Math.floor(time / 60 / 60) : Math.ceil(time / 60 / 60);
  let minutes = Math.abs(time / 60 % 60);
  let formattedHours = hours.toString().padStart(2, "0");
  let formattedMinutes = minutes.toString().padStart(2, "0");
  return `${formattedHours}:${formattedMinutes}`;
}

export function secondToDayFormat(time) {

  let days = Math.floor(time / 60 / 60 / 24);
  let hours = Math.floor(time / 60 / 60 % 24);
  let minutes = Math.round(time / 60 % 60);
  let formattedString = "";
  formattedString = formattedString.concat(days > 0 ? $tf("calendar.dayify.days.short|{number}d ", { number: days }) : "");
  formattedString = formattedString.concat($tf("calendar.dayify.hours.short|{number}h ", { number: hours }));
  formattedString = formattedString.concat($tf("calendar.dayify.minutes.short|{number}m ", { number: minutes }));
  return formattedString;
}

export function hourToShortWorkDayFormat(time) {
  return secondToShortWorkDayFormat(time * 3600);
}

export function secondToShortWorkDayFormat(time, showPolarityPrefix = false) {

  const sign = time < 0 ? "-" : showPolarityPrefix ? "+" : "";
  time = Math.abs(time);
  let days = Math.floor(time / 60 / 60 / 8);
  let hours = Math.floor(time / 60 / 60 % 8);
  let minutes = Math.round(time / 60 % 60);
  let formattedString = sign;
  formattedString = formattedString.concat(days > 0 ? $tf("calendar.mandayify.days.short|{number}d ", { number: days }) : "");
  formattedString = formattedString.concat(hours > 0 ? $tf("calendar.mandayify.hours.short|{number}h ", { number: hours }) : "");
  formattedString = formattedString.concat(minutes > 0 ? $tf("calendar.mandayify.minutes.short|{number}m ", { number: minutes }) : "");
  if (formattedString === "") return "-";
  return formattedString;
}

export function secondToWorkDayFormat(time) {

  let days = Math.floor(time / 60 / 60 / 8);
  let hours = Math.floor(time / 60 / 60 % 8);
  let minutes = Math.round(time / 60 % 60);
  let formattedString = "";
  formattedString = formattedString.concat(Math.abs(days) > 0 ? $tf("calendar.mandayify.days|{number} embernap ", { number: days }) : "");
  formattedString = formattedString.concat(Math.abs(hours) > 0 ? $tf("calendar.mandayify.hours|{number} óra ", { number: hours }) : "");
  formattedString = formattedString.concat(Math.abs(minutes) > 0 ? $tf("calendar.mandayify.minutes|{number} perc ", { number: minutes }) : "");
  if (formattedString === "") return "-";
  return formattedString;
}

let removeSuffix = (string) => {
  if (string.endsWith("/")) {
    return removeSuffix(string.substring(0, string.length - 1));
  }
  return string;
};

let addProtocol = (string) => {
  if (string.startsWith("https://")) {
    return string;
  }
  if (string.startsWith("http://")) {
    return string.replace("http://", "https://");
  }
  return "https://".concat(string);
};

let toBrowseUrl = ({ url, system }, key) => {
  if (!url) return "";
  const issueTrackingSystem = ISSUE_TRACKING_SYSTEM[system];
  if (issueTrackingSystem?.issuePageUrl) {
    return removeSuffix(addProtocol(url)).concat(issueTrackingSystem.issuePageUrl(key));
  }
  return "";
};

export function createTicketUrl(key, issueTrackingSystemIdentifier) {
  const store = useStore();
  const issueTrackingData = store.getters["uiConfigStore/getIssueTrackingDataForIdentifier"](issueTrackingSystemIdentifier);
  if(!issueTrackingData) return null;
  return toBrowseUrl(issueTrackingData, key);
}

export function getPathWithoutParam(route) {
  let path = route.fullPath;
  Object.values(route.params).forEach(param => {
    path = path.replace(`/${param}`, "");
  });
  if (path.endsWith("/")) {
    path = path.slice(0, -1);
  }
  return path;
}

export function genericAutocompleteFilter(filterText, selectableElements, filterKey, selectedElements, selectedKey, filteredKey) {
  let filteredElements = selectableElements;
  if (filterText) {
    filteredElements = selectableElements.filter((selectable) => {
      return (
        selectable[filterKey]
          .toString()
          .toLowerCase()
          .indexOf(filterText.toLowerCase()) >= 0
      );
    });
  }
  if (selectedElements) {
    filteredElements = filteredElements.filter(
      (filtered) =>
        !selectedElements.some(
          (selected) => selected[selectedKey] === filtered[filteredKey]
        )
    );
  }
  return filteredElements;
}

export function getTotalSecondsCss(dailyTotalSeconds, expectedHoursOfDay = 8) {
  if (dailyTotalSeconds < expectedHoursOfDay * 60 * 60) {
    return "is-danger";
  }
  if (dailyTotalSeconds === expectedHoursOfDay * 60 * 60) {
    return "is-success";
  }
  if (dailyTotalSeconds > expectedHoursOfDay * 60 * 60) {
    return "is-primary";
  }
}

export function correctWithTimezoneOffset(date) {
  if (date) { // fixme: ez hülyeséget csinált nyári időszámításkor
    if (date.getHours() === 0) {
      // return new Date(date.setHours(date.getHours() + (new Date().getTimezoneOffset() / -60)));
      date.setHours(12);
    }
    return date;
  }
  return null;
}

export function getWeekOfMonth(date) {
  const d = new Date(+date);
  const f = new Date(d.getFullYear(), d.getMonth(), 1);
  let dD = d.getDay() - 1;
  if (dD === -1) dD = 6;
  let fD = f.getDay() - 1;
  if (fD === -1) fD = 6;
  d.setDate(d.getDate() - dD + 1);
  f.setDate(f.getDate() - fD + 1);
  const fM = +f.getMonth() + 1;
  let v = {
    month: +d.getMonth() + 1,
    week: Math.ceil(d.getDate() / 7)
  };
  if (fM === date.getMonth()) v.week += 1;
  return v.week === 6 ? 1 : v.week;
}

//https://stackoverflow.com/questions/36721830/convert-hsl-to-rgb-and-hex
export function hslToHex(h, s, l) {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0"); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

export function getContrastedColor(color) {
  if (color.startsWith("var(")) {
    const element = document.createElement("div");
    document.body.appendChild(element);
    element.style.color = color;
    color = getComputedStyle(element).color;
    document.body.removeChild(element);
  }

  if (color.startsWith("rgb")) {
    const rgbValues = color.match(/\d+/g).map(Number);
    return getBrightness(rgbValues[0], rgbValues[1], rgbValues[2]) > 125
      ? "#000000"
      : "#FFFFFF";
  }

  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  if (result) {
    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);
    return getBrightness(r, g, b) > 125 ? "#000000" : "#FFFFFF";
  }

  return "#000000";
}

function getBrightness(r, g, b) {
  return Math.round((r * 299 + g * 587 + b * 114) / 1000);
}

export function getContrastedColorCSS(hex) {
  return "color: " + getContrastedColor(hex);
}

export async function getSessionData() {
  try {
    //let ip = (await axios.get('https://api.ipify.org?format=json')).data.ip;

    //let response = (await axios.get(`https://ipapi.co/${ip}/json`)).data;
    //let ip = "8.8.8.8";

    // let response = (await axios.get(`https://ipapi.co/${ip}/json`)).data;


    //let country = response.country;

    //let city = response.city;
    const unknown = "-";

    // screen

    let screenSize = "";
    let width = "";
    let height = "";
    if (screen.width) {
      width = (screen.width) ? screen.width : "";
      height = (screen.height) ? screen.height : "";
      screenSize += "" + width + " x " + height;
    }
    // browser

    const nVer = "";
    const nAgt = navigator.userAgent;
    let browser = "Ismeretlen böngésző";
    let version = "";
    let majorVersion;
    let nameOffset, verOffset, ix;

    // Opera
    if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
      browser = "Opera";
      version = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf("Version")) !== -1) {
        version = nAgt.substring(verOffset + 8);
      }
    }
    // Opera Next
    if ((verOffset = nAgt.indexOf("OPR")) !== -1) {
      browser = "Opera";
      version = nAgt.substring(verOffset + 4);
    }
    // Legacy Edge
    else if ((verOffset = nAgt.indexOf("Edge")) !== -1) {
      browser = "Microsoft Legacy Edge";
      version = nAgt.substring(verOffset + 5);
    }
    // Edge (Chromium)
    else if ((verOffset = nAgt.indexOf("Edg")) !== -1) {
      browser = "Microsoft Edge";
      version = nAgt.substring(verOffset + 4);
    }
    // MSIE
    else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
      browser = "Microsoft Internet Explorer";
      version = nAgt.substring(verOffset + 5);
    }
    // Chrome
    else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
      browser = "Chrome";
      version = nAgt.substring(verOffset + 7);
    }
    // Safari
    else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
      browser = "Safari";
      version = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf("Version")) !== -1) {
        version = nAgt.substring(verOffset + 8);
      }
    }
    // Firefox
    else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
      browser = "Firefox";
      version = nAgt.substring(verOffset + 8);
    }
    // MSIE 11+
    else if (nAgt.indexOf("Trident/") !== -1) {
      browser = "Microsoft Internet Explorer";
      version = nAgt.substring(nAgt.indexOf("rv:") + 3);
    }
    // Other browsers
    else if ((nameOffset = nAgt.lastIndexOf(" ") + 1) < (verOffset = nAgt.lastIndexOf("/"))) {
      browser = nAgt.substring(nameOffset, verOffset);
      version = nAgt.substring(verOffset + 1);
      if (browser.toLowerCase() === browser.toUpperCase()) {
        browser = navigator.appName;
      }
    }
    // trim the version string
    if ((ix = version.indexOf(";")) !== -1) version = version.substring(0, ix);
    if ((ix = version.indexOf(" ")) !== -1) version = version.substring(0, ix);
    if ((ix = version.indexOf(")")) !== -1) version = version.substring(0, ix);

    majorVersion = parseInt("" + version, 10);
    if (isNaN(majorVersion)) {
      version = "" + parseFloat(nVer);
      majorVersion = parseInt(nVer, 10);
    }

    // mobile version
    const mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);

    // cookie
    let cookieEnabled = (navigator.cookieEnabled);

    if (typeof navigator.cookieEnabled == "undefined" && !cookieEnabled) {
      document.cookie = "testcookie";
      cookieEnabled = (document.cookie.indexOf("testcookie") !== -1);
    }

    // system
    let os = unknown;
    const clientStrings = [
      { s: "Windows 10", r: /(Windows 10.0|Windows NT 10.0)/ },
      { s: "Windows 8.1", r: /(Windows 8.1|Windows NT 6.3)/ },
      { s: "Windows 8", r: /(Windows 8|Windows NT 6.2)/ },
      { s: "Windows 7", r: /(Windows 7|Windows NT 6.1)/ },
      { s: "Windows Vista", r: /Windows NT 6.0/ },
      { s: "Windows Server 2003", r: /Windows NT 5.2/ },
      { s: "Windows XP", r: /(Windows NT 5.1|Windows XP)/ },
      { s: "Windows 2000", r: /(Windows NT 5.0|Windows 2000)/ },
      { s: "Windows ME", r: /(Win 9x 4.90|Windows ME)/ },
      { s: "Windows 98", r: /(Windows 98|Win98)/ },
      { s: "Windows 95", r: /(Windows 95|Win95|Windows_95)/ },
      { s: "Windows NT 4.0", r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
      { s: "Windows CE", r: /Windows CE/ },
      { s: "Windows 3.11", r: /Win16/ },
      { s: "Android", r: /Android/ },
      { s: "Open BSD", r: /OpenBSD/ },
      { s: "Sun OS", r: /SunOS/ },
      { s: "Chrome OS", r: /CrOS/ },
      { s: "Linux", r: /(Linux|X11(?!.*CrOS))/ },
      { s: "iOS", r: /(iPhone|iPad|iPod)/ },
      { s: "Mac OS X", r: /Mac OS X/ },
      { s: "Mac OS", r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
      { s: "QNX", r: /QNX/ },
      { s: "UNIX", r: /UNIX/ },
      { s: "BeOS", r: /BeOS/ },
      { s: "OS/2", r: /OS\/2/ },
      { s: "Search Bot", r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ }
    ];
    for (const id in clientStrings) {
      const cs = clientStrings[id];
      if (cs.r.test(nAgt)) {
        os = cs.s;
        break;
      }
    }

    let osVersion = unknown;

    if (/Windows/.test(os)) {
      osVersion = /Windows (.*)/.exec(os)[1];
      os = "Windows";
    }

    switch (os) {
      case "Mac OS":
      case "Mac OS X":
      case "Android":
        osVersion = /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([._\d]+)/.exec(nAgt)[1];
        break;

      case "iOS":
        osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
        osVersion = osVersion[1] + "." + osVersion[2] + "." + (osVersion[3] | 0);
        break;
    }
    return {
      "device": os + " " + osVersion,
      "browser": browser + " " + majorVersion + " (" + version + ")",
      "Mobile": mobile,
      "Cookies": cookieEnabled,
      "Screen Size": screenSize,
      "Full User Agent": navigator.userAgent
    };
  } catch (error) {
    console.error(error);
    return {
      "device": "Ismeretlen",
      "browser": "",
      "Mobile": "",
      "Cookies": "",
      "Screen Size": "",
      "Full User Agent": ""
    };
  }
}

export function stringToColour(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xFF;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
}

export function isNullOrUndefined(val) {
  return val === null || val === undefined;
}

String.prototype.snakeToCamel = function() {
  let copy = this;
  copy = copy.toLowerCase().replace(/([-_][a-z])/g, group =>
    group
      .toUpperCase()
      .replace("-", "")
      .replace("_", "")
  );
  return copy;
};

String.prototype.camelToUpperSnake = function() {
  let copy = this;
  copy = copy.toLowerCase().replace(/([A-Z])/g, letter => `_${letter}`).toUpperCase();
  return copy;
};

Date.prototype.addDaysSkippingWeekends = function(days) {
  const result = new Date(this.valueOf());
  let addedDays = 0;
  if (days < 0) {
    while (addedDays > days) {
      result.setDate(result.getDate() - 1);
      if (!(result.getDay() === 0
        || result.getDay() == 6)) {
        --addedDays;
      }
    }
    return result;
  } else {
    while (addedDays < days) {
      result.setDate(result.getDate() + 1);
      if (!(result.getDay() === 0
        || result.getDay() == 6)) {
        ++addedDays;
      }
    }
  }
  return result;
};

Date.prototype.daysBetween = function(date) {
  let result = 0;
  let current = new Date(this.valueOf());
  while (current < date) {
    ++result;
    current.setDate(current.getDate() + 1);
  }
  return result;
};

Date.prototype.workDaysBetween = function(date) {
  let result = 0;
  let current = new Date(this.valueOf());
  while (current < date) {
    if (!(current.getDay() === 0
      || current.getDay() === 6)) {
      ++result;
    }
    current.setDate(current.getDate() + 1);
  }
  return result;
};

Number.prototype.countDecimals = function() {
  if (Math.floor(this.valueOf()) === this.valueOf()) return 0;
  let str = this.toString();
  if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
    return str.split("-")[1] || 0;
  } else if (str.indexOf(".") !== -1) {
    return str.split(".")[1].length || 0;
  }
  return str.split("-")[1] || 0;
};

String.prototype.truncate = function(n, useWordBoundary) {
  if (this.length <= n) {
    return this;
  }
  const subString = this.slice(0, n - 1);
  return (useWordBoundary
    ? subString.slice(0, subString.lastIndexOf(" "))
    : subString) + "…";
};

export function getShortDayNamesLocale(localeString = "hu") {
  if (localeString === "hu") {
    return [
      "V",
      "H",
      "K",
      "Sz",
      "Cs",
      "P",
      "Sz"
    ];
  } else {
    return [
      "S",
      "M",
      "T",
      "W",
      "T",
      "F",
      "S"
    ];
  }
}

export function calculateDarken(color) {
  return (
    "#" +
    tinycolor
      .mix(
        tinycolor(
          "rgb " +
          Math.floor(
            (tinycolor(color).toRgb().r * tinycolor(color).toRgb().r) / 255
          ) +
          " " +
          Math.floor(
            (tinycolor(color).toRgb().g * tinycolor(color).toRgb().g) / 255
          ) +
          " " +
          Math.floor(
            (tinycolor(color).toRgb().b * tinycolor(color).toRgb().b) / 255
          )
        ),
        color,
        1
      )
      .darken(5)
      .toHex()
  );
}

export function calculateLighten(color, lightenAmount, saturateAmount) {
  return "#" + tinycolor.mix(tinycolor("#ffffff"), color, lightenAmount).saturate(saturateAmount).toHex();
}

export function getProjectStatusObject(enu, archived = false, withText = false) {


  let text;
  let imageName;
  let type;
  let color;
  switch (enu) {
    case "REQUEST":
      text = $tf("projectStatus.REQUEST|KÉRELEM");
      imageName = "common/Calendar.svg";
      type = "is-info";
      color = "yellow";
      break;
    case "CANCELLED":
      text = $tf("projectStatus.CANCELLED|VISSZAVONVA");
      imageName = "project/red-x-icon.svg";
      type = "is-danger";
      color = "green";
      break;
    case "DENIED":
      text = $tf("projectStatus.DENIED|ELUTASÍTVA");
      imageName = "common/red-thumbs-down.svg";
      type = "is-danger";
      color = "green";
      break;
    case "APPROVED":
      text = $tf("projectStatus.APPROVED|ELFOGADVA");
      imageName = "common/green-thumbs-up.svg";
      type = "is-success";
      color = "blue";
      break;
    case "ONGOING":
      text = $tf("projectStatus.ONGOING|FOLYAMATBAN");
      imageName = "project/clock-lines.svg";
      type = "is-primary";
      color = "blue";
      break;
    case "DONE":
      text = $tf("projectStatus.DONE|KÉSZ");
      imageName = "project/project-check.svg";
      type = "is-success";
      color = "green";
      break;
    case "CLOSED":
      text = $tf("projectStatus.CLOSED|LEZÁRVA");
      imageName = "project/lock-solid.svg";
      type = "is-dark";
      color = "green";
      break;
    default:
      text = $tf("projectStatus.UNKNOWN|ISMERETLEN");
      imageName = "project/project-question.svg";
      type = "is-warning";
      color = "blue";
  }
  if (archived) {
    text = $tf("projectStatus.ARCHIVED|ARCHÍV");
    type = "is-dark";
  }
  const image = require(`@/assets/images/${imageName}`);
  return {
    color,
    text,
    image: `<div class="is-flex ml-1 is-align-items-center" style="gap: 5px;">
                <div class="status-image-div is-flex is-align-items-center" style="min-width: 14px; max-width: 20px;">
                    <img src="${image}" alt="${imageName}" />
                </div>
                <div>
                    <p>${withText ? text : ""}</p>
                </div>
              </div>`,
    type
  };
}

export function getTigStatusObject(status) {

  const imageNames = {
    PLANNED: "common/Calendar.svg",
    PENDING: "project/clock-lines.svg",
    BILLABLE: "project/money-bag-colorable.svg",
    BILLED: "project/money-bag-checked.svg",
    COMPLETED: "project/project-check.svg",
    PAID: "project/project-check.svg",
    CANCELLED: "project/red-x-icon.svg",
    DEFAULT: "project/project-question.svg"
  };
  const statusNames = {
    PLANNED: $tf("projectpage.tigList.type.planned|TERVEZETT"),
    PENDING: $tf("projectpage.tigList.type.pending|FOLYAMATBAN"),
    BILLABLE: $tf("projectpage.tigList.type.billable|SZÁMLÁZHATÓ"),
    BILLED: $tf("projectpage.tigList.type.billed|SZÁMLÁZVA"),
    COMPLETED: $tf("projectpage.tigList.type.completed|KÉSZ"),
    PAID: $tf("projectpage.tigList.type.paid|KIFIZETVE"),
    CANCELLED: $tf("projectpage.tigList.type.cancelled|VISSZAVONVA"),
    DEFAULT: $tf("projectpage.tigList.type.unknown|ISMERETLEN")
  };
  const typeNames = {
    PLANNED: "is-light",
    PENDING: "is-primary",
    BILLABLE: "is-warning",
    BILLED: "is-info",
    PAID: "is-success",
    COMPLETED: "is-success",
    CANCELLED: "is-dark",
    DEFAULT: "is-warning"
  };
  const imageName = imageNames[status] || imageNames.DEFAULT;
  const image = require(`@/assets/images/${imageName}`);
  return {
    text: statusNames[status] || statusNames.DEFAULT,
    image: `
        <div><img src="${image}" alt="${imageName}" style="min-width: 14px; max-width: 20px;" /></div>
`,
    type: typeNames[status] || typeNames.DEFAULT
  };
}

export function divideList(list, n) {
  return Array.from({ length: Math.ceil(list.length / n) }, (_, i) => list.slice(i * n, i * n + n));
}


export function convertToRoman(num) {
  if (num === 0) {
    return "";
  }
  for (let i = 0; i < romanMatrix.length; i++) {
    if (num >= romanMatrix[i][0]) {
      return romanMatrix[i][1] + convertToRoman(num - romanMatrix[i][0]);
    }
  }
}

export function projectIdentifierToPageUrl(identifier) {
  return `/project/${identifier}`;
}

export function changeTheme(theme, save = true) {
  const colorUpdater = new ColorUpdater(bulmaCssVariablesDefs);

  if (theme === undefined) {
    theme = UI_THEMES.SYSTEM;
  }

  if (save) {
    localStore("saved_ui_theme", theme);
  }

  localStore("current_ui_theme", theme);

  let newColorSet = getColorSetForTheme(getCurrentTheme(theme));

  for (const [colorName, colorValue] of Object.entries(newColorSet)) {
    colorUpdater.updateVarsInDocument(colorName, colorValue);
  }
}

export function getColorSetForTheme(theme) {
  return app_colors[theme];
}

export function getCurrentColorSet() {
  return getColorSetForTheme(getCurrentTheme());
}

export function getCurrentTheme() {

  const theme = localFetch("current_ui_theme");

  if (theme === UI_THEMES.LIGHT) {
    return UI_THEMES.LIGHT;
  } else if (theme === UI_THEMES.DARK) {
    return UI_THEMES.DARK;
  } else if (theme === UI_THEMES.SYSTEM || theme === undefined) {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      return UI_THEMES.DARK;
    } else {
      return UI_THEMES.LIGHT;
    }
  }

}

export function getRiskKPIColor(value) {
  if (isNullOrUndefined(value) || value > 10) return "is-fatal";
  const roundedValue = roundToTwoDecimals(value);

  let riskKPILimits = [1, 1];
  if (this.$store.getters["uiConfigStore/isLoaded"]) {
    if (this.$store.getters["uiConfigStore/getRiskKPILimits"]) riskKPILimits = this.$store.getters["uiConfigStore/getRiskKPILimits"];
  }

  if (roundedValue > riskKPILimits[1])
    return "is-danger";
  if (roundedValue < riskKPILimits[0])
    return "is-success";
  return "is-warning";
}

export function getBudgetRiskValue(value) {
  return Number.isFinite(value) ? roundToTwoDecimals(value) : "?";
}

export function isTraversable(obj) {
  return Array.isArray(obj)
    || obj !== null && ["function", "object"].includes(typeof obj);
}

export function getLowercaseMonthName(month) {

  switch (month) {
    case 0:
      return $tf("month.january|Január").toLowerCase();
    case 1:
      return $tf("month.february|Február").toLowerCase();
    case 2:
      return $tf("month.march|Március").toLowerCase();
    case 3:
      return $tf("month.april|Április").toLowerCase();
    case 4:
      return $tf("month.may|Május").toLowerCase();
    case 5:
      return $tf("month.june|Június").toLowerCase();
    case 6:
      return $tf("month.july|Július").toLowerCase();
    case 7:
      return $tf("month.august|Augusztus").toLowerCase();
    case 8:
      return $tf("month.september|Szeptember").toLowerCase();
    case 9:
      return $tf("month.october|Október").toLowerCase();
    case 10:
      return $tf("month.november|November").toLowerCase();
    case 11:
      return $tf("month.december|December").toLowerCase();
  }
}

export function tenantIdFromPath() {
  const hostnameParts = window.location.hostname.split(".");
  if (hostnameParts.length === 3) {
    return hostnameParts[0];
  } else {
    return null;
  }
}

export function generateArraySummaryTranslated(array, length = 3) {

  if (array.length <= length) {
    return array.join(", ");
  } else {
    return $tf(
      "utils.ArraySummary|{array} és {length} további",
      { array: array.slice(0, length).join(", "), length: array.length - length }
    );
  }
}

export function findForecastRule(enumValue) {
  return FORECAST_RULES.find(item => item.enum === enumValue);
}

export function getQuarters(startYear, start, end) {
  const quarters = {
    thisYearQuarters: [],
    nextYearQuarters: []
  };

  if (!start && !end) return quarters;
  const quarterKeys = Object.keys(FORECAST_YEAR_QUARTER);
  const quarterDuration = Math.ceil(12 / quarterKeys.length);
  const startDate = new Date(start ?? `${new Date(end)}-01-01`);
  let endDate = new Date(end ?? `${new Date(start)}-12-31`);

  if (startDate.getFullYear() > startYear) {
    startDate.setFullYear(startYear);
    startDate.setMonth(1);
    startDate.setDate(1);
  }
  let endYear = endDate.getFullYear();
  if (endYear > startYear + 1) {
    endYear = startYear + 1;
    endDate.setFullYear(endYear);
    endDate.setMonth(12);
    endDate.setDate(31);
  }
  for (let year = startYear; year <= endYear; year++) {
    for (let i = 0; i < quarterKeys.length; i++) {
      const quarterStart = new Date(year, i * quarterDuration, 1);

      const quarterEnd = new Date(quarterStart);
      quarterEnd.setMonth(quarterStart.getMonth() + quarterDuration);
      quarterEnd.setDate(quarterEnd.getDate() - 1); // Last day of quarter

      if ((year === startYear && quarterEnd >= startDate) || (year === startYear + 1 && quarterStart <= endDate)) {
        const quartersArray = year === startYear ? quarters.thisYearQuarters : quarters.nextYearQuarters;
        quartersArray.push(quarterKeys[i]);
      }
    }
  }
  return quarters;
}

export function getCurrentQuarter(dateString) {
  const currentDate = new Date(dateString);
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const quarterKeys = Object.keys(FORECAST_YEAR_QUARTER);
  const quarterDuration = Math.ceil(12 / quarterKeys.length);
  const quarterIndex = Math.floor(currentMonth / quarterDuration);
  return Object.keys(FORECAST_YEAR_QUARTER)[quarterIndex];
}

export function secondsToJiraTime(seconds) {
  let days = Math.floor(seconds / 28800);
  let hours = Math.floor((seconds - days * 28800) / 3600);
  let minutes = Math.floor((seconds - days * 28800 - hours * 3600) / 60);
  return (
    (days > 0 ? days + "d " : "") +
    (hours > 0 ? hours + "h " : "") +
    (minutes > 0 ? minutes + "m" : "")
  );
}

export function getProjectPage(projectType, projectIdentifier) {
  switch (projectType) {
    case "BASIC":
      return `/projects/project/${projectIdentifier}`;
    case "SUPPORT":
      return `/projects/support/${projectIdentifier}`;
    case "OUTSOURCE":
      return `/projects/outsource/${projectIdentifier}`;
    default:
      return `/projects/project/${projectIdentifier}`;
  }
}

export function generateNextNWeeks(date, n) {
  const weeks = [];
  let startDate = new Date(date);

  // Adjust start date to the Monday of the current week
  while (startDate.getDay() !== 1) {
    startDate.setDate(startDate.getDate() - 1);
  }

  for (let i = 0; i < n; i++) {
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6);

    const year = endDate.getFullYear();
    const weekNumber = getWeekNumber(startDate);

    weeks.push({
      year: year,
      week: weekNumber,
      start: new Date(startDate),
      startDate: formatDateToHungarian(new Date(startDate)),
      end: new Date(endDate),
      endDate: formatDateToHungarian(new Date(endDate))
    });

    // Move to the next week
    startDate.setDate(startDate.getDate() + 7);
  }

  return weeks;
}

export function generateNext4Weeks(date) {
  return generateNextNWeeks(date, 4);
}

export function generateNext6Weeks(date) {
  // thanks Peti
  return generateNextNWeeks(date, 5);
}

export function weeksInYear(year) {
  let weeks = [];
  let weekCounter = 0;
  let date = new Date(year, 0, 1);

  // adjust first week
  if (date.getDay() <= 4) {
    date = date.addDays(date.getDay() * -1 + 1);
  } else {
    date = date.addDays(8 - date.getDay());
  }

  while (date.getFullYear() <= year) {
    let weekData = {
      start: date,
      end: date.addDays(),
      number: ++weekCounter
    };
    weekData.end = date.addDays(6);
    weeks.push(weekData);
    date = date.addDays(7);
  }

  // adjust last week
  if (date.getDate() > 4) {
    weeks.pop();
  }
  return weeks;
}

export function getWeekNumber(date) {
  // Create a copy of the date to not modify the original
  const copyDate = new Date(date.getTime());
  // Set the copyDate to Thursday of the same week to ensure the week number is calculated correctly
  copyDate.setDate(copyDate.getDate() + (4 - copyDate.getDay()));
  const yearStart = new Date(copyDate.getFullYear(), 0, 1);
  const weekNo = Math.ceil((((copyDate - yearStart) / 86400000) + 1) / 7);
  return weekNo;
}

export function getWeekData(year, week) {
  return weeksInYear(year).find(w => w.number === week) ?? {};
}

export function isWeekend(date) {
  return [0, 6].includes(dayjs(date).day());
}

export function isOlderThanPreviousWeek(year, week) {
  const currentDate = dayjs().year(year).week(week).startOf("isoWeek");
  const previousWeekMonday = dayjs().startOf("isoWeek").subtract(1, "week");

  return currentDate.isBefore(previousWeekMonday);
}


function formatDateToHungarian(date) {
  const months = ["jan", "feb", "márc", "ápr", "máj", "jún", "júl", "aug", "szep", "okt", "nov", "dec"];
  const day = date.getDate();
  const month = months[date.getMonth()];

  return `${month} ${day}`;
}

export function convertHoursToMWD(hours) {
  const number = Math.round(hours / 8 * 10) / 10;
  if (Number.isNaN(number)) return 0;
  return number;
}

export function randomColors(count) {
  let colors = [];
  for (let i = 2; i < count + 2; i++) {
    const hue = i * 137.508; // use golden angle approximation
    colors.push(`hsl(${hue}, 70%, ${Math.random() * 20 + 50}%)`);
  }
  return colors;
}

export function getWeeksBetween(start, end) {
  const result = [];
  let { year: startYear, week: startWeek } = start;
  const { year: endYear, week: endWeek } = end;

  // Skip the first input week
  startWeek++;

  // Adjust if skipping moves us to a new year
  if (startWeek > 52) {
    startWeek = 1;
    startYear++;
  }

  while (startYear < endYear || (startYear === endYear && startWeek <= endWeek)) {
    result.push({ year: startYear, week: startWeek });

    // Move to the next week
    startWeek++;

    // Check if we've moved into the next year
    if (startWeek > 52) { // assuming 52 weeks in a year
      startWeek = 1;
      startYear++;
    }
  }

  return result;
}

export function dateToMonthDay(date) {
  if (!date) return date;
  const parsedDate = new Date(date);
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
  const day = String(parsedDate.getDate()).padStart(2, "0");
  return `${month}-${day}`;
}

export function stringToColor(string) {
  let colorHash = new ColorHash();
  const hash = md5(string ?? "");
  return colorHash.hex(hash);
}

export function createMonogram(string) {
  return string ? this.username.split(" ").map((n) => n[0]).join("").toUpperCase() : "";
}

export function getISOWeekYearAndNumber(date) {
  const tempDate = new Date(date);
  tempDate.setUTCHours(0, 0, 0, 0);

  const dayOfWeek = (tempDate.getUTCDay() + 6) % 7;
  tempDate.setUTCDate(tempDate.getUTCDate() - dayOfWeek + 3);

  const year = tempDate.getUTCFullYear();

  const firstThursday = new Date(Date.UTC(year, 0, 4));
  const firstWeekStart = new Date(firstThursday);
  firstWeekStart.setUTCDate(firstThursday.getUTCDate() - ((firstThursday.getUTCDay() + 6) % 7));

  const weekNumber = Math.ceil(((tempDate - firstWeekStart) / 86400000 + 1) / 7);

  return { year, week: weekNumber };
}

export function getWeekDataBetween(fromDate, toDate) {
  if (!fromDate || !toDate) {
    return [];
  }

  fromDate = new Date(fromDate);
  toDate = new Date(toDate);

  const weeks = [];
  let currentDate = new Date(fromDate);

  while (currentDate <= toDate) {
    const { year, week } = getISOWeekYearAndNumber(currentDate);

    if (
      !weeks.some((entry) => entry.year === year && entry.week === week)
    ) {
      weeks.push({ year, week });
    }

    currentDate.setDate(currentDate.getDate() + 7);
  }

  return weeks.sort((a, b) => {
    if (a.year !== b.year) {
      return a.year - b.year;
    } else {
      return a.week - b.week;
    }
  });
}