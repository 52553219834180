<template>
  <div class="is-flex is-flex-direction-column">
    <div>
      <h3 class="title">
        {{ $tf("commitmentModal.base.title|Vállalás lebontása") }}
      </h3>
    </div>
    <form @submit.prevent>
      <div class="columns">
        <div class="column">
          <div class="money-subtext">
            Megadott vállalás:
            <span>{{ workdayify(projectCommitment) }}</span>
          </div>
        </div>
        <div class="column">
          <div class="money-subtext">
            Lebontott vállalás:
            <span>{{ workdayify(commitmentDetailsSum) }}</span>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <vuelidated-form :validations="v$.commitmentEdit.role">
            <template v-slot="{ errors, invalid }">
              <b-field
                :message="v$.commitmentEdit.role.$error ? errors[0] : null"
                :type="{ 'is-danger': invalid }"
                label-for="role"
                label-position="on-border"
              >
                <template #label>
                  {{ $tf("commitmentModal.base.role|Szerepkör") }}
                  <span class="has-text-danger">*</span>
                </template>
                <b-autocomplete
                  ref="roleAutocomplete"
                  v-model="roleSearch"
                  :data="genericAutocompleteFilter(roleSearch, roles, 'name')"
                  :placeholder="
                    $tf('commitmentModal.role.placeholder|Válassz a listából')
                  "
                  append-to-body
                  class="has-blue-icon"
                  clearable
                  expanded
                  field="name"
                  icon="search"
                  open-on-focus
                  rounded
                  @select="(option) => (commitmentEdit.role = option)"
                >
                  <template #empty
                    >{{ $tf("commitmentModal.role.empty|Nincs találat") }}
                  </template>
                </b-autocomplete>
              </b-field>
            </template>
          </vuelidated-form>
        </div>
        <div class="column">
          <b-field
            :label="$tf('commitmentModal.base.seniority|Senioritás')"
            label-position="on-border"
          >
            <b-autocomplete
              ref="seniorityAutocomplete"
              v-model="senioritySearch"
              :data="
                genericAutocompleteFilter(senioritySearch, seniorities, 'name')
              "
              :placeholder="
                $tf('commitmentModal.seniority.placeholder|Válassz a listából')
              "
              append-to-body
              class="has-blue-icon"
              clearable
              expanded
              field="name"
              icon="search"
              open-on-focus
              rounded
              @select="(option) => (commitmentEdit.seniority = option)"
            >
              <template #empty
                >{{ $tf("commitmentModal.seniority.empty|Nincs találat") }}
              </template>
            </b-autocomplete>
          </b-field>
        </div>
        <div class="column">
          <b-field
            :label="$tf('commitmentModal.base.workDays|Allokált napok')"
            label-position="on-border"
          >
            <vuelidated-input
              v-model="commitmentEdit.workDays"
              :expanded="true"
              :validation-rule="v$.commitmentEdit.workDays"
              type="number"
            ></vuelidated-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-button
            :expanded="true"
            native-type="submit"
            type="is-info"
            @click="$emit('add-commitment', commitmentEdit)"
            >Mentés
          </b-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { minValue, required } from "@vuelidate/validators";
import { genericAutocompleteFilter, workdayify } from "@/utils/util";
import VuelidatedInput from "@/components/module/input/VuelidatedInput.vue";
import VuelidatedForm from "@/components/module/input/VuelidatedForm.vue";
import useCustomVuelidate from "@/plugins/vuelidate";

export default {
  name: "CommitmentModal",
  components: { VuelidatedForm, VuelidatedInput },
  methods: { genericAutocompleteFilter, workdayify },
  emits: ["add-commitment"],
  setup: () => useCustomVuelidate(),
  props: {
    editedCommitment: {
      type: Object,
      default: () => null,
    },
    roles: {
      type: Array,
      default: () => [],
    },
    seniorities: {
      type: Array,
      default: () => [],
    },
    projectCommitment: {
      type: Number,
      default: 0,
    },
    commitmentDetailsSum: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      roleSearch: null,
      senioritySearch: null,
      commitmentEdit: {},
    };
  },
  validations: {
    commitmentEdit: {
      role: {
        required,
      },
      workDays: {
        required,
        minValue: minValue(1),
      },
    },
  },
  mounted() {
    if (this.editedCommitment) {
      const role = this.roles.find(
        (r) => r.id === this.editedCommitment.roleId
      );
      const seniority = this.seniorities.find(
        (s) => s.id === this.editedCommitment.seniorityId
      );
      this.commitmentEdit = {
        workDays: this.editedCommitment.workDays,
        role: role,
        seniority: seniority,
      };
      this.$refs.roleAutocomplete.setSelected(role);
      this.$refs.seniorityAutocomplete.setSelected(seniority);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.money-subtext {
  color: $turquoise;
  font-weight: 400;

  span {
    color: $custom-dark-blue;
    font-weight: 500;
  }
}
</style>
