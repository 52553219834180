<template>
  <section class="section">
    <div class="container-without-background">
      <div class="is-flex has-gap-2">
        <h1 v-if="activeTab === PROJECT_TABS.ALL" class="title">
          {{ $tf("projectList.title.all|Minden Projekt") }}
        </h1>
        <h1 v-if="activeTab === PROJECT_TABS.SIMPLE" class="title">
          {{ $tf("projectList.title.simple|Projektek") }}
        </h1>
        <h1 v-if="activeTab === PROJECT_TABS.SUPPORT" class="title">
          {{ $tf("projectList.title.support|Folyamatos Projektek") }}
        </h1>
        <h1 v-if="activeTab === PROJECT_TABS.OUTSOURCE" class="title">
          {{ $tf("projectList.title.outsource|Outsource Projektek") }}
        </h1>
        <b-field class="full-search-bar">
          <b-input
            v-model="saveObjects.search.value"
            :placeholder="
              $tf(
                'projectList.filter.mixed.placeholder|Keresés... (Név, Felelős, Azonosító, stb. alapján)'
              )
            "
            expanded
            icon="magnifying-glass"
          ></b-input>
        </b-field>
        <b-checkbox-button
          :modelValue="!saveObjects.showOnlyLiked.value"
          class="p-0 checkbox-button"
          @update:modelValue="
            saveObjects.showOnlyLiked.value = !saveObjects.showOnlyLiked.value
          "
        >
          <b-icon
            v-if="saveObjects.showOnlyLiked.value"
            class="m-0 p-0"
            icon="heart"
            size="is-large"
          />
          <b-icon
            v-else
            class="m-0 p-0"
            icon="heart-half-stroke"
            size="is-large"
          />
        </b-checkbox-button>

        <b-checkbox-button
          :modelValue="!saveObjects.showOnlyStarred.value"
          class="p-0 checkbox-button"
          @update:modelValue="
            saveObjects.showOnlyStarred.value =
              !saveObjects.showOnlyStarred.value
          "
        >
          <b-icon
            v-if="saveObjects.showOnlyStarred.value"
            class="m-0 p-0"
            icon="star"
            size="is-large"
          />
          <b-icon
            v-else
            class="m-0 p-0"
            icon="star-half-stroke"
            size="is-large"
          />
        </b-checkbox-button>
        <b-field class="ml-auto">
          <b-button
            icon-left="bars-filter"
            @click="showDetailedFilters = !showDetailedFilters"
            >{{ $tf("projectList.detailtedFilters.toggle|Szűrők") }}
          </b-button>
          <b-button
            class="ml-2"
            icon-left="plus"
            type="is-info"
            @click="goToCreate(activeTab)"
            >{{ $tf("projectList.createProject|Projekt létrehozása") }}
          </b-button>
        </b-field>
      </div>
    </div>
    <b-collapse
      v-model="showDetailedFilters"
      aria-id="showDetailedFilters"
      position="is-bottom"
    >
      <div class="container is-flex is-flex-direction-column has-gap-3">
        <div
          class="is-flex has-gap-2 is-align-items-center is-flex-wrap-wrap"
          style="row-gap: 12px"
        >
          <div>
            <b-field
              :label="$tf('projectList.filter.status|Projekt státusz')"
              label-position="on-border"
              style="width: 200px"
            >
              <multiselect-dropdown
                v-model="saveObjects.selectedProjectStatuses.value"
                :items="projectStatuses"
                :none-selected-text="$tf('projectList.filter.all|Mind')"
                has-select-all-option
              ></multiselect-dropdown>
            </b-field>
          </div>

          <div>
            <b-field
              :label="$tf('projectList.filter.color|Projekt szín')"
              label-position="on-border"
              style="width: 200px"
            >
              <multiselect-dropdown
                v-model="saveObjects.selectedProjectColors.value"
                :items="projectColors"
                :none-selected-text="$tf('projectList.filter.all|Mind')"
                has-select-all-option
                identifierField="id"
                nameField="colorName"
              ></multiselect-dropdown>
            </b-field>
          </div>

          <div v-for="(dimension, index) in projectDimensions" :key="index">
            <b-field
              :label="dimension.dimensionName"
              label-position="on-border"
              style="width: 200px"
            >
              <multiselect-dropdown
                :items="dimension.values"
                :modelValue="getDimensionValues(dimension.dimensionName)"
                :none-selected-option-id="dimension.id"
                :none-selected-text="$tf('projectList.filter.all|Mind')"
                has-select-all-option
                identifier-field="id"
                @update:modelValue="
                  (val) => saveDimensionValues(dimension.dimensionName, val)
                "
              ></multiselect-dropdown>
            </b-field>
          </div>
          <div>
            <b-field
              v-if="projectRisks"
              :label="$tf('projectList.filter.projectKPI|Projekt KPI')"
              class="field-has-label-mb-0"
              label-position="on-border"
              style="width: 200px"
            >
              <f-autocomplete
                v-model="saveObjects.selectedRiskKPI.value"
                :custom-formatter="
                  (option) => `${option.name} (${option.intervalText})`
                "
                :data="projectRisks"
                clearable
                id-field="id"
              >
                <template v-slot="props">
                  <div class="is-flex is-align-items-center has-gap-1">
                    <div>
                      <svg
                        fill="none"
                        height="10"
                        viewBox="0 0 10 10"
                        width="10"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          :fill="props.option.color"
                          cx="5"
                          cy="5"
                          r="5"
                        />
                      </svg>
                    </div>
                    <div>
                      <div>
                        {{ props.option.name }}
                      </div>
                      <div>({{ props.option.intervalText }})</div>
                    </div>
                  </div>
                </template>
              </f-autocomplete>
            </b-field>
          </div>
        </div>
        <div
          class="is-flex has-gap-2 is-align-items-center is-flex-wrap-wrap"
          style="row-gap: 12px"
        >
          <div>
            <b-field
              :label="$tf('projectList.filter.fromDate|Kezdeti dátum')"
              class="field-has-label-mb-0"
              label-position="on-border"
              style="width: 200px"
            >
              <f-datepicker
                :icon-right="saveObjects.fromDate.value ? 'close-circle' : ''"
                :modelValue="fromDateObject"
                :placeholder="datePattern"
                icon="calendar-day"
                icon-right-clickable
                @update:modelValue="saveObjects.fromDate.value = $event"
                @icon-right-click="saveObjects.fromDate.value = null"
              >
              </f-datepicker>
            </b-field>
          </div>
          <div>
            <b-field
              :label="$tf('projectList.filter.toDate|Befejezési dátum')"
              class="field-has-label-mb-0"
              label-position="on-border"
              style="width: 200px"
            >
              <f-datepicker
                :icon-right="saveObjects.toDate.value ? 'close-circle' : ''"
                :modelValue="toDateObject"
                :placeholder="datePattern"
                icon="calendar-day"
                icon-right-clickable
                @update:modelValue="saveObjects.toDate.value = $event"
                @icon-right-click="saveObjects.toDate.value = null"
              >
              </f-datepicker>
            </b-field>
          </div>
        </div>
        <div
          class="is-flex has-gap-2 is-align-items-center is-flex-wrap-wrap"
          style="row-gap: 12px"
        >
          <div>
            <b-field
              :label="$tf('projectList.filter.projectName|Projekt név')"
              class="field-has-label-mb-0"
              label-position="on-border"
              style="width: 200px"
            >
              <b-input
                v-model="saveObjects.projectName.value"
                :placeholder="
                  $tf('projectList.filter.search.placeholder|Keresés...')
                "
                icon="magnifying-glass"
              ></b-input>
            </b-field>
          </div>
          <div>
            <b-field
              :label="
                $tf('projectList.filter.projectIdentifier|Projekt azonosító')
              "
              class="field-has-label-mb-0"
              label-position="on-border"
              style="width: 200px"
            >
              <b-input
                v-model="saveObjects.projectIdentifier.value"
                :placeholder="
                  $tf('projectList.filter.search.placeholder|Keresés...')
                "
                icon="magnifying-glass"
              ></b-input>
            </b-field>
          </div>
          <div>
            <b-field
              :label="$tf('projectList.filter.client|Ügyfél')"
              class="field-has-label-mb-0"
              label-position="on-border"
              style="width: 200px"
            >
              <b-input
                v-model="saveObjects.clientName.value"
                :placeholder="
                  $tf('projectList.filter.search.placeholder|Keresés...')
                "
                icon="magnifying-glass"
              ></b-input>
            </b-field>
          </div>
          <div>
            <b-field
              :label="$tf('projectList.filter.leadName|Felelős')"
              class="field-has-label-mb-0"
              label-position="on-border"
              style="width: 200px"
            >
              <b-input
                v-model="saveObjects.leadName.value"
                :placeholder="
                  $tf('projectList.filter.search.placeholder|Keresés...')
                "
                icon="magnifying-glass"
              ></b-input>
            </b-field>
          </div>
        </div>

        <div
          class="is-flex has-gap-2 is-align-items-center is-flex-wrap-wrap"
          style="row-gap: 12px"
        >
          <div>
            <b-field>
              <b-switch
                v-if="
                  activeTab !== PROJECT_TABS.AGGREGATED && canSeeAllProjects
                "
                v-model="saveObjects.showOnlyMine.value"
                size="is-small"
              >
                {{
                  $tf(
                    "projectList.filter.onlyMine|Csak saját projektek mutatása"
                  )
                }}
              </b-switch>
            </b-field>
          </div>
          <div>
            <b-field>
              <b-switch
                v-model="saveObjects.showArchived.value"
                class="field-has-label-mb-0"
                label-position="on-border"
                size="is-small"
              >
                {{
                  $tf(
                    "projectList.filter.showArchived|Archivált projektek mutatása"
                  )
                }}
              </b-switch>
            </b-field>
          </div>
          <div>
            <b-field>
              <b-switch
                v-model="saveObjects.showInternal.value"
                class="field-has-label-mb-0"
                label-position="on-border"
                size="is-small"
              >
                {{
                  $tf(
                    "projectList.filter.showInternal|Belső projektek mutatása"
                  )
                }}
              </b-switch>
            </b-field>
          </div>
        </div>
      </div>
    </b-collapse>

    <div style="display: none">
      <!-- this is hidden here temporarily, since the refactor removed the tabs -->
      <b-tabs v-model="activeTab" class="toggle-only" type="is-toggle">
        <b-tab-item
          :label="$tf('projectList.tab.all|Összesített')"
          :value="PROJECT_TABS.ALL"
          icon="stream"
        />
        <b-tab-item
          :label="$tf('projectList.tab.simple|Hagyományos')"
          :value="PROJECT_TABS.SIMPLE"
          icon="long-arrow-alt-right"
        />
        <b-tab-item
          :label="$tf('projectList.tab.support|Folyamatos teljesítésű')"
          :value="PROJECT_TABS.SUPPORT"
          icon="headset"
        />
        <b-tab-item
          :label="$tf('projectList.tab.outsource|Outsource')"
          :value="PROJECT_TABS.OUTSOURCE"
          icon="handshake"
        />
      </b-tabs>
    </div>

    <div class="container-without-background">
      <template v-if="activeTab === PROJECT_TABS.ALL">
        <all-projects-list
          :filters="saveObjects"
          @reset-filters="resetAllSaveObjects(saveObjects)"
        >
        </all-projects-list>
      </template>
      <template v-if="activeTab === PROJECT_TABS.SIMPLE">
        <normal-project-list
          :filters="saveObjects"
          :filters-loaded="filtersLoaded"
          @reset-filters="resetAllSaveObjects(saveObjects)"
        >
        </normal-project-list>
      </template>

      <template v-else-if="activeTab === PROJECT_TABS.SUPPORT">
        <support-project-list
          :filters="saveObjects"
          :filters-loaded="filtersLoaded"
          @reset-filters="resetAllSaveObjects(saveObjects)"
        />
      </template>

      <template v-else-if="activeTab === PROJECT_TABS.OUTSOURCE">
        <outsource-project-list
          :filters="saveObjects"
          :filters-loaded="filtersLoaded"
          @reset-filters="resetAllSaveObjects(saveObjects)"
        />
      </template>
    </div>
  </section>
</template>

<script>
import NormalProjectList from "@/components/enterprise/project/list/NormalProjectList";
import ProjectListMixin from "../../../mixins/ProjectListMixin";
import { mapGetters } from "vuex";
import { PRIVILEGES, PROJECT_STATUS_ENUM, PROJECT_TABS } from "@/utils/const";
import MultiselectDropdown from "@/components/MultiselectDropdown.vue";
import LocalStoreMixin from "@/mixins/LocalStoreMixin";
import SaveableObject from "@/objects/SaveableObject";
import SaveableObjectContainer from "@/objects/SaveableObjectContainer";
import SupportProjectList from "@/components/enterprise/project/list/SupportProjectList.vue";
import OutsourceProjectList from "@/components/enterprise/project/list/OutsourceProjectList.vue";
import AllProjectsList from "@/components/enterprise/project/list/AllProjectsList.vue";
import LoadingMixin from "@/mixins/LoadingMixin";
import debounce from "lodash.debounce";
import FDatepicker from "@/components/module/input/FDatepicker.vue";
import FAutocomplete from "@/components/module/input/FAutocomplete.vue";

const LOCAL_KEY_PREFIX = "project_list_filter_";

export default {
  name: "Enterprise-List",
  mixins: [ProjectListMixin, LocalStoreMixin, LoadingMixin],
  components: {
    FAutocomplete,
    FDatepicker,
    AllProjectsList,
    OutsourceProjectList,
    SupportProjectList,
    MultiselectDropdown,
    NormalProjectList,
  },
  async mounted() {
    await this.$store.dispatch("project_risk/list");
    await this.$store.dispatch(
      "project_dimensions/fetchActiveProjectDimensionsWithValues"
    );
    await this.$store.dispatch("project_dimensions/fetchProjectColorsCompact");
    await this.fetchAllSaveObjects(this.saveObjects);

    //remove items from localstorage if the dimension is deleted in the settings
    this.saveObjects.dimensionFilters.value =
      this.saveObjects.dimensionFilters.value.filter((item) =>
        this.projectDimensions.some((dim) => dim.dimensionName === item.name)
      );
    this.saveObjects.selectedProjectColors.value =
      this.saveObjects.selectedProjectColors.value.filter((selectedColor) =>
        this.projectColors?.some((color) => {
          return color.id === selectedColor;
        })
      );
    this.setFiltersLoaded();
  },
  data() {
    return {
      PROJECT_TABS,
      projectTypes: [
        { identifier: "BASIC", name: "Fejlesztési projekt" },
        { identifier: "OUTSOURCE", name: "Outsource projekt" },
        { identifier: "SUPPORT", name: "Support projekt" },
      ],
      saveObjects: new SaveableObjectContainer({
        showOnlyMine: new SaveableObject(
          LOCAL_KEY_PREFIX + "showOnlyMine",
          true,
          false
        ),
        showInternal: new SaveableObject(
          LOCAL_KEY_PREFIX + "showInternal",
          false,
          true
        ),
        showClosed: new SaveableObject(
          LOCAL_KEY_PREFIX + "showClosed",
          true,
          false
        ),
        showArchived: new SaveableObject(
          LOCAL_KEY_PREFIX + "showArchived",
          false,
          true
        ),
        fromDate: new SaveableObject("fromDate", null, false),
        toDate: new SaveableObject("toDate", null, false),
        search: new SaveableObject(LOCAL_KEY_PREFIX + "search", ""),
        showOnlyStarred: new SaveableObject(
          LOCAL_KEY_PREFIX + "showOnlyStarred",
          false
        ),
        showOnlyLiked: new SaveableObject(
          LOCAL_KEY_PREFIX + "showOnlyLiked",
          false
        ),
        dimensionFilters: new SaveableObject(
          LOCAL_KEY_PREFIX + "dimensionFilters",
          []
        ),
        selectedProjectColors: new SaveableObject(
          LOCAL_KEY_PREFIX + "selectedProjectColors",
          []
        ),
        selectedProjectTypes: new SaveableObject(
          LOCAL_KEY_PREFIX + "selectedProjectTypes",
          []
        ),
        selectedProjectStatuses: new SaveableObject(
          "selectedProjectStatuses",
          []
        ),
        selectedRiskKPI: new SaveableObject(
          LOCAL_KEY_PREFIX + "selectedRiskKPI",
          ""
        ),
        projectName: new SaveableObject(LOCAL_KEY_PREFIX + "projectName", ""),
        projectIdentifier: new SaveableObject(
          LOCAL_KEY_PREFIX + "projectIdentifier",
          ""
        ),
        clientName: new SaveableObject(LOCAL_KEY_PREFIX + "clientName", ""),
        leadName: new SaveableObject(LOCAL_KEY_PREFIX + "leadName", ""),
      }),
      filtersLoaded: false,
      showDetailedFilters: false,
    };
  },
  methods: {
    setFiltersLoaded: debounce(function () {
      this.filtersLoaded = true;
    }, 500),
    getDimensionValues(dimensionName) {
      const foundObject = this.saveObjects.dimensionFilters.value.find(
        function (obj) {
          return obj.name === dimensionName;
        }
      );

      return foundObject?.values ? foundObject.values : [];
    },
    saveDimensionValues(dimensionName, values) {
      const foundObject = this.saveObjects.dimensionFilters.value.find(
        function (obj) {
          return obj.name === dimensionName;
        }
      );
      if (foundObject) {
        foundObject.values = values;
      } else {
        this.saveObjects.dimensionFilters.value.push({
          name: dimensionName,
          values: values,
        });
      }

      this.saveObjects.dimensionFilters.save();
    },
  },
  computed: {
    ...mapGetters({
      currencySymbol: "uiConfigStore/getCurrencySymbol",
    }),
    canSeeAllProjects: function () {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ENTERPRISE.PROJECT.LIST_ANY,
        PRIVILEGES.ENTERPRISE.PROJECT.ADMIN
      );
    },
    projectStatuses() {
      return Object.keys(PROJECT_STATUS_ENUM).map((key) => ({
        identifier: key,
        name: this.resolveStatus(key),
      }));
    },
    fromDateObject() {
      return this.saveObjects.fromDate.value
        ? new Date(this.saveObjects.fromDate.value)
        : null;
    },
    toDateObject() {
      return this.saveObjects.toDate.value
        ? new Date(this.saveObjects.toDate.value)
        : null;
    },
    datePattern() {
      const formatter = new Intl.DateTimeFormat().formatToParts();
      return formatter
        .map((part) => {
          switch (part.type) {
            case "day":
              return "DD";
            case "month":
              return "MM";
            case "year":
              return "YYYY";
            default:
              return part.value;
          }
        })
        .join("");
    },
    activeTab: {
      get() {
        switch (this.$router.currentRoute.value.hash) {
          case PROJECT_TABS.HASH.SIMPLE:
            return PROJECT_TABS.SIMPLE;
          case PROJECT_TABS.HASH.SUPPORT:
            return PROJECT_TABS.SUPPORT;
          case PROJECT_TABS.HASH.OUTSOURCE:
            return PROJECT_TABS.OUTSOURCE;
          default:
            return PROJECT_TABS.ALL;
        }
      },
      set(val) {
        switch (val) {
          case PROJECT_TABS.SIMPLE:
            this.$router.push({
              name: "ControllingProjects",
              hash: PROJECT_TABS.HASH.SIMPLE,
            });
            break;
          case PROJECT_TABS.SUPPORT:
            this.$router.push({
              name: "ControllingProjects",
              hash: PROJECT_TABS.HASH.SUPPORT,
            });
            break;
          case PROJECT_TABS.OUTSOURCE:
            this.$router.push({
              name: "ControllingProjects",
              hash: PROJECT_TABS.HASH.OUTSOURCE,
            });
            break;
          default:
            this.$router.push({
              name: "ControllingProjects",
              hash: PROJECT_TABS.HASH.ALL,
            });
            break;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.right-border {
  border-right: 1px solid $grey-lighter;
}

.checkbox-column {
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 0.5rem;
  }
}

.category-list {
  max-width: 40rem;
  overflow: auto;
}
</style>

<style lang="scss">
.full-search-bar {
  padding-left: 50px;
  width: 600px;
}

.column-selector-modal {
  .modal-background {
    background-color: rgba(0, 0, 0, 0);
  }

  .animation-content {
    position: absolute;
    left: 300px;
  }
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-header {
  font-size: 0.6rem;

  .icon {
    font-size: 0.8rem !important;
    cursor: pointer;
  }
}

.list-top-row {
  font-size: 1.1rem;
}

.list-bottom-row {
  font-size: 0.9rem;
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
