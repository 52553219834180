<template>
  <div class="normal-project-list">
    <slot></slot>
    <b-table
      ref="projectsTable"
      :aria-current-label="$tf('aria.current.label|Aktuális lap')"
      :aria-next-label="$tf('aria.next.label|Következő lap')"
      :aria-page-label="$tf('aria.page.label|Lap')"
      :aria-previous-label="$tf('aria.previous.label|Előző lap')"
      :data="listItems"
      :per-page="pagination.value.size"
      :row-class="() => 'cursor-pointer'"
      :total="pagination.value.totalCount"
      backend-filtering
      backend-pagination
      backend-sorting
      hoverable
      paginated
      @click="goToProject"
      @sort="onSort"
      @page-change="onPageChange"
    >
      <b-table-column v-slot="props" :cell-class="$style['thin-column']">
        <div
          :class="`is-flex is-flex-direction-column border-${
            getProjectStatusObject(props.row.status).color
          }`"
        >
          <star-icon v-if="props.row.starred" filled></star-icon>
          <heart-icon
            v-if="likedProjects.includes(props.row.id)"
            filled
          ></heart-icon>
        </div>
      </b-table-column>
      <!--      <b-table-column-->
      <!--        centered-->
      <!--        field="status"-->
      <!--        :label="$tf('simpleProjectList.table.status|Státusz')"-->
      <!--        v-slot="props"-->
      <!--      >-->
      <!--        <b-tag-->
      <!--          :type="-->
      <!--            getProjectStatusObject(props.row.status, props.row.archived).type-->
      <!--          "-->
      <!--        >-->
      <!--          {{-->
      <!--            getProjectStatusObject(props.row.status, props.row.archived).text-->
      <!--          }}-->
      <!--        </b-tag>-->
      <!--      </b-table-column>-->
      <b-table-column
        v-slot="props"
        :cell-class="$style['wide-column']"
        :label="$tf('simpleProjectList.table.nameAndID|Név / Azonosító')"
        field="name"
        sortable
      >
        <div class="list-top-row">
          {{ props.row.identifier }}
        </div>
        <div class="list-bottom-row cut-text">
          {{ props.row.name }}
        </div>
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="$tf('simpleProjectList.table.dueDate|Határidő')"
        cell-class="do-not-wrap"
        field="dueDate"
        sortable
      >
        <div class="list-top-row">
          {{ formatDate(new Date(props.row.dueDate)) }}
        </div>
        <div
          v-if="new Date(props.row.dueDate) < new Date()"
          class="list-bottom-row has-font-weight-700"
        >
          {{
            $tf("normalProjectList.table.daysOverdue|{daysLate} nap késés", {
              daysLate: new Date(props.row.dueDate).daysBetween(new Date()),
            })
          }}
        </div>
        <div v-else class="list-bottom-row has-font-weight-700">
          {{
            calendarDayify(new Date().daysBetween(new Date(props.row.dueDate)))
          }},
          {{
            workdayify(new Date().workDaysBetween(new Date(props.row.dueDate)))
          }}
        </div>
      </b-table-column>

      <b-table-column
        v-slot="props"
        :cell-class="$style['wide-column']"
        :label="$tf('simpleProjectList.table.income|Bevétel')"
        field="expectedIncome"
        sortable
      >
        <template v-if="props.row.internal"> -</template>
        <template v-else>
          <div class="list-top-row">
            {{ moneyify(props.row.expectedIncome) }}
          </div>
          <div class="list-bottom-row">
            {{
              $tf(
                "normalProjectList.table.billedPercent|{billedPercent} számlázva",
                {
                  billedPercent: percentify(
                    props.row.invoicedIncome / props.row.expectedIncome
                  ),
                }
              )
            }}
          </div>
        </template>
      </b-table-column>

      <b-table-column
        v-slot="props"
        :cell-class="$style['wide-column']"
        :label="$tf('simpleProjectList.table.budgetAndSpent|Büdzsé / Költés')"
        field="budget"
        sortable
      >
        <div>{{ moneyify(props.row.budget) }}</div>
        <div class="has-text-grey">{{ moneyify(props.row.spent) }}</div>
      </b-table-column>

      <b-table-column
        v-slot="props"
        :cell-class="$style['mid-column']"
        :label="$tf('simpleProjectList.table.state|Állapot')"
        field="readiness"
        sortable
      >
        <div class="list-top-row">
          <b-icon icon="check" />
          {{ percentify(props.row.readiness) }}
        </div>
        <div class="list-bottom-row">
          <b-icon icon="hand-holding-dollar" />
          {{ percentify(props.row.spentPercentage) }}
        </div>
      </b-table-column>

      <b-table-column
        v-slot="props"
        :cell-class="$style['thin-column']"
        :label="$tf('simpleProjectList.table.lead|Felelős')"
        field="leadUserId"
        sortable
      >
        <div class="has-font-weight-500 has-text-grey">
          <UserInfo
            :user="getEmployeeDetailsByName(props.row.leadName)"
            :username="props.row.leadName"
            displayMode="minimal"
            tooltipPosition="right"
          />
        </div>
      </b-table-column>

      <b-table-column
        v-slot="props"
        :cell-class="$style['thin-column']"
        :label="$tf('simpleProjectList.table.riskKPI|Risk KPI')"
        field="riskKPI"
        sortable
      >
        <div>
          <RiskKPITag :value="props.row.riskKPI" />
        </div>
      </b-table-column>

      <b-table-column
        v-slot="props"
        :cell-class="$style['wide-column']"
        :label="
          $tf(
            'simpleProjectList.table.forecastTrendingBudget|Várható költés (trendt. FC)'
          )
        "
        field="forecastTrendingBudget"
        sortable
      >
        <div v-if="props.row.forecastTrendingBudget">
          <div class="list-top-row">
            {{ moneyify(props.row.forecastTrendingBudget) }}
          </div>
          <div class="list-bottom-row has-font-weight-700">
            {{
              props.row.forecastTrendingBudget - props.row.budget > 0
                ? $tf(
                    "normalProjectList.table.overspend|+{overspend} túlköltés",
                    {
                      overspend: percentify(
                        (props.row.forecastTrendingBudget - props.row.budget) /
                          props.row.budget
                      ),
                    }
                  )
                : $tf(
                    "normalProjectList.table.underspend|{underspend} megtakarítás",
                    {
                      underspend: percentify(
                        (props.row.forecastTrendingBudget - props.row.budget) /
                          props.row.budget
                      ),
                    }
                  )
            }}
          </div>
        </div>
        <div v-else>-</div>
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="$tf('simpleProjectList.table.expectedProfit|Várható profit')"
        cell-class="do-not-wrap"
        field="expectedProfit"
        sortable
      >
        <div
          :class="{
            'has-font-weight-700 has-text-danger':
              !props.row.internal && props.row.expectedProfit < 0,
          }"
        >
          <template v-if="props.row.internal"> -</template>
          <template v-else>
            {{ moneyify(props.row.expectedProfit) }}
          </template>
        </div>
      </b-table-column>

      <template #empty>
        <template v-if="isPageLoading || !filtersLoaded">
          <loading-normal-project-listing
            v-for="n in pagination.value.size"
            :key="`loading-${n}`"
          />
        </template>
        <section v-else class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="frown" size="is-large"></b-icon>
            </p>
            <p>
              {{
                $tf(
                  "simpleProjectList.table.empty|Nincs találat a megadott szűrési feltételekre."
                )
              }}
            </p>
            <b-button @click="resetFilters"
              >{{
                $tf(
                  "simpleProjectList.table.resetFilters|Szűrők alapértelmezettre állítása"
                )
              }}
            </b-button>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProjectListMixin from "@/mixins/ProjectListMixin";
import debounce from "lodash.debounce";
import {
  calendarDayify,
  getProjectStatusObject,
  percentify,
  roundToTwoDecimals,
  workdayify,
} from "@/utils/util";
import { PROJECT_PROGRESS_TYPE } from "@/utils/const";
import StarIcon from "@/components/module/icon/StarIcon.vue";
import HeartIcon from "@/components/module/icon/HeartIcon.vue";
import LocalStoreMixin from "@/mixins/LocalStoreMixin";
import SaveableObject from "@/objects/SaveableObject";
import UserInfo from "@/components/module/info/UserInfo.vue";
import LoadingNormalProjectListing from "@/components/loading/LoadingNormalProjectListing.vue";
import LoadingMixin from "@/mixins/LoadingMixin";
import RiskKpiTag from "@/components/enterprise/project/module/RiskKPITag.vue";

const LOCAL_KEY_PREFIX = "project_list_simple_";

export default {
  name: "NormalProjectList",
  components: {
    RiskKPITag: RiskKpiTag,
    LoadingNormalProjectListing,
    HeartIcon,
    StarIcon,
    UserInfo,
  },
  mixins: [ProjectListMixin, LocalStoreMixin, LoadingMixin],
  watch: {
    filters: {
      handler: debounce(function () {
        this.pagination.resetToDefault();
        this.getSimpleProjects();
      }, 500),
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      projects: "enterprise_core/simpleProjects",
      riskKPILimits: "uiConfigStore/getRiskKPILimits",
      likedProjects: "project_like/likedProjects",
      employees: "employee/currentEmployees",
      clients: "enterprise_clients/clients",
    }),
    listItems() {
      if (this.isPageLoading || !this.filtersLoaded) return [];
      return this.projects?.items || [];
    },
    totalCount() {
      if (this.isPageLoading || !this.filtersLoaded) return 0;
      return this.pagination?.totalCount ?? 0;
    },
  },
  data() {
    return {
      pagination: new SaveableObject(LOCAL_KEY_PREFIX + "pagination", {
        totalCount: 0,
        page: 0,
        size: 20,
      }),
      sort: new SaveableObject(LOCAL_KEY_PREFIX + "sort", {
        field: "name",
        order: "asc",
      }),
      PROJECT_PROGRESS_TYPE,
      percentify,
      calendarDayify,
      workdayify,
      getProjectStatusObject,
    };
  },
  methods: {
    goToProject(row) {
      this.$router.push(`/projects/project/${row.identifier}`);
    },
    resetFilters() {
      this.pagination.resetToDefault();

      this.$emit("reset-filters");
    },
    async getSimpleProjects() {
      this.doStartLoading();
      let requestParams = this.composeRequestParams();
      let projects = await this.$store.dispatch(
        "enterprise_core/fetchSimpleProjects",
        { params: requestParams }
      );
      this.pagination.value = projects?.pagination
        ? projects.pagination
        : {
            totalCount: 0,
            page: 0,
            size: 20,
          };
      this.doFinishLoading();
    },
    onPageChange: function (page) {
      this.pagination.value.page = page - 1;
      this.pagination.save();
      this.getSimpleProjects();
    },
    onSort: function (field, order) {
      this.sort.value.field = field;
      this.sort.value.order = order;
      this.sort.save();
      this.getSimpleProjects();
    },
    workDaysBetween(start, end) {
      return new Date(start).workDaysBetween(new Date(end));
    },
    getSpentPercentageColor(value) {
      return value > 1 ? "is-danger" : "is-warning";
    },
    getBudgetRiskValue(value) {
      return Number.isFinite(value) ? roundToTwoDecimals(value) : "?";
    },
    getMaxPercentage(project) {
      return Math.max(
        this.calcProgressValue(
          1,
          project.status === "CLOSED" ? 1 : project.readiness
        ),
        this.calcProgressValue(1, project.spentPercentage),
        100
      );
    },
    getEmployeeDetailsByName(name) {
      const employee = this.employees.find((emp) => emp.name === name);
      if (employee) {
        return {
          userId: employee.id,
          name: employee.name,
          avatar: employee.avatar,
          email: employee.email,
          phone: employee.phone,
        };
      } else {
        return null;
      }
    },
  },
  async mounted() {
    if (this.filtersLoaded) {
      await this.$store.dispatch("project_like/fetchLikedProjects");
      await this.getSimpleProjects();
      await this.$store.dispatch(
        "project_dimensions/fetchActiveProjectDimensionsWithValues"
      );
      await this.$store.dispatch("enterprise_clients/fetchClients");
      // await this.$store.dispatch("employee/fetchColleagues");
    }
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors.scss";

.normal-project-list {
  table {
    background: transparent;
    border-spacing: 1px 8px;

    tr {
      td {
        margin-bottom: 12px;
        margin-top: 12px;
        background: $white;
        border: none;
        position: relative;
      }

      td:first-of-type {
        border-top-left-radius: 0.625rem;
        border-bottom-left-radius: 0.625rem;
        padding: 0 !important;
        position: relative;
      }

      td:last-of-type {
        border-top-right-radius: 0.625rem;
        border-bottom-right-radius: 0.625rem;
      }
    }
  }

  .border {
    &-yellow {
      border-left: 5px solid $yellow !important;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      border-radius: 0.625rem;
      padding-left: 15px;
      padding-top: 2px;
    }

    &-blue {
      border-left: 5px solid $blue !important;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      border-radius: 0.625rem;
      padding-left: 15px;
      padding-top: 2px;
    }

    &-green {
      border-left: 5px solid $green !important;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      border-radius: 0.625rem;
      padding-left: 15px;
      padding-top: 2px;
    }
  }
}
</style>

<style lang="scss" module>
@import "~@/assets/scss/colors.scss";

.wide-column {
  min-width: 8rem;
  max-width: 12rem;
}

.thin-column {
  max-width: 2.5rem;
}
</style>

<style lang="scss" scoped>
.scrollable {
  overflow: auto;
  white-space: nowrap;
}

.scrollable-element {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.p-bar-div {
  width: 100%;
  align-items: center;
}

.p-bar {
  height: 24px;
  margin-bottom: 0 !important;
  padding-top: 6px;
  padding-bottom: 6px;
  align-items: center;
  text-align: left;
}
</style>
