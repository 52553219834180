<template>
  <div>
    <b-table v-if="loaded" :data="fixedPriceTiers" :per-page="10" paginated>
      <b-table-column
        v-slot="props"
        :label="$tf('tiers.fixedPrice.table.cost|Összeg')"
        field="cost"
        searchable
        sortable
      >
        {{ props.row.cost }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="$tf('tiers.fixedPrice.table.user|Dolgozó')"
        field="displayName"
        searchable
        sortable
      >
        {{ props.row.displayName }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="$tf('tiers.fixedPrice.table.userId|Azonosító')"
        centered
        field="userId"
        sortable
      >
        {{ props.row.userId }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="$tf('tiers.fixedPrice.table.validFrom|Érvényesség kezdete')"
        field="date"
        sortable
      >
        {{ $filterDateFormat(props.row.since) }}
      </b-table-column>

      <b-table-column v-slot="props" cell-class="do-not-wrap" width="32">
        <clickable-icon
          class="mr-2"
          icon="pencil"
          type="is-info"
          @click="openEdit(props.row)"
        ></clickable-icon>
        <clickable-icon
          icon="trash"
          type="is-danger"
          @click="deleteRow(props.row.id)"
        ></clickable-icon>
      </b-table-column>

      <template #empty>
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="frown" size="is-large"></b-icon>
            </p>
            <p>
              {{
                $tf(
                  "tiers.fixedPrice.table.empty|Még nincsenek egyedi árszinttel rendelkező munkatársak."
                )
              }}
            </p>
          </div>
        </section>
      </template>
    </b-table>
    <LoadingSkeleton v-else />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { openDeleteConfirm } from "@/utils/util";
import ClickableIcon from "../../../../components/module/icon/ClickableIcon";
import FixedPriceTierEditModal from "../edit/FixedPriceTierEditModal";
import LoadingSkeleton from "../../../../components/module/loading/LoadingSkeleton";

export default {
  name: "FixedPriceTierTab",
  components: { ClickableIcon, LoadingSkeleton },
  computed: {
    ...mapGetters({
      fixedPriceTiers: "enterprise_tiers/fixedPriceTiers",
    }),
  },
  data() {
    return {
      loaded: false,
    };
  },
  methods: {
    openEdit(row) {
      this.$buefy.modal.open({
        parent: this,
        component: FixedPriceTierEditModal,
        props: { fixedPriceTier: row },
        hasModalCard: true,
        trapFocus: true,
        events: {
          save: () => this.reloadPage(true),
        },
      });
    },
    async deleteRow(id) {
      openDeleteConfirm(this.$buefy, async () => {
        await this.$store.dispatch("enterprise_tiers/deleteFixedPriceTier", id);
        await this.reloadPage(true);
      });
    },
    async load(force) {
      await this.$store.dispatch(
        "enterprise_tiers/fetchFixedPriceTiers",
        force
      );
    },
    async reloadPage(force) {
      await this.load(force);
      await this.$store.dispatch("enterprise_tiers/fetchUserTiers");
      await this.$store.dispatch("enterprise_tiers/fetchUsersWithoutTier");
    },
  },
  async mounted() {
    await this.load();
    this.loaded = true;
  },
};
</script>
