<template>
  <div>
    <h2 class="has-text-dark-blue has-font-weight-500 mb-2">
      {{ $tf("forecast.segment.edit.filtersHeader|Szűrési feltételek") }}
    </h2>
    <div v-for="(object, index) in objects" :key="index" class="mb-4">
      <ForecastSegmentFiltersEntry
        ref="filters"
        :initial="object"
      ></ForecastSegmentFiltersEntry>
    </div>
  </div>
</template>

<script>
import { FORECAST_RULES } from "@/utils/const";
import { deepCopy } from "@/utils/util";
import ForecastSegmentFiltersEntry from "@/components/forecast/ForecastSegmentFiltersEntry.vue";
import { isEmpty } from "lodash";
import { nextTick } from "vue";

export default {
  name: "ForecastSegmentFiltersPicker",
  components: { ForecastSegmentFiltersEntry },
  props: {
    initial: {
      type: Array,
      required: false,
    },
  },
  async mounted() {
    for (const rule of FORECAST_RULES) {
      await this.$store.dispatch(rule.fetch.function, rule.fetch.params);
    }
    if (this.initial) {
      this.objects = deepCopy(
        this.initial.map((it) => {
          return {
            ...FORECAST_RULES.find((rule) => rule.enum === it.key),
            key: it.key,
            value: it.value.map((val) => val.id),
          };
        })
      );
    }
    for (const rule of FORECAST_RULES) {
      if (!this.objects.some((it) => it.key === rule.enum)) {
        this.objects.push(rule);
      }
    }
    nextTick(() => {
      this.$emit("loaded");
    });
  },
  data() {
    return {
      objects: [],
    };
  },
  methods: {
    validate() {
      let objects = this.$refs.filters;
      if (!objects) return false;
      for (const object of objects) {
        const data = object.getData();
        if (!isEmpty(data)) return true;
      }
      return false;
    },
    getData() {
      return (
        this.$refs.filters?.reduce((data, object) => {
          data[object.currentRule.enum] = object.getData();
          return data;
        }, {}) || []
      );
    },
  },
};
</script>

<style scoped></style>
