<template>
  <section class="section executive-dashboard">
    <div class="container">
      <h1 class="title">
        <loading-component>
          {{ $tf("execDashboard.title|Executive dashboard") }}
        </loading-component>
      </h1>
      <div class="is-flex has-gap-3 mb-2">
        <loading-component>
          <b-checkbox-button
            v-model="filter.preference.value"
            class="p-0 checkbox-button"
            native-value="FAVORITE"
            @update:modelValue="modifyFilter"
          >
            <b-icon class="m-0" icon="heart" />
          </b-checkbox-button>
        </loading-component>
        <loading-component>
          <b-checkbox-button
            v-model="filter.preference.value"
            class="p-0 checkbox-button"
            native-value="HIGHLIGHTED"
            @update:modelValue="modifyFilter"
          >
            <b-icon class="m-0" icon="star" />
          </b-checkbox-button>
        </loading-component>
        <loading-component>
          <b-checkbox-button
            v-model="filter.preference.value"
            class="p-0 checkbox-button"
            native-value="IMPORTANT"
            @update:modelValue="modifyFilter"
          >
            <b-icon class="m-0" icon="circle-dollar-to-slot" />
          </b-checkbox-button>
        </loading-component>
        <b-tooltip
          :active="filter.preference.value.length > 0"
          :label="
            $tf(
              'execDashboard.filterWarning|Ezek a szűrők csak akkor használhatók, ha a bal oldali szűrőkapcsolók inaktívak!'
            )
          "
          type="is-info"
        >
          <div class="is-flex is-flex-wrap-wrap has-gap-2">
            <div
              class="is-flex has-gap-2 is-align-items-center is-flex-wrap-wrap"
              style="row-gap: 12px"
            >
              <loading-component>
                <b-field
                  :label="
                    $tf('execDashboard.filter.projectType|Projekt típusa')
                  "
                  label-position="on-border"
                >
                  <multiselect-dropdown
                    v-model="filter.types.value"
                    :disabled="filter.preference.value.length > 0"
                    :items="projectTypes"
                    append-to-body
                    has-select-all-option
                    max-height="200px"
                    none-selected-text="Mind"
                    searchable
                    @update:modelValue="modifyFilter"
                  ></multiselect-dropdown>
                </b-field>
              </loading-component>
              <loading-component>
                <b-field
                  :label="$tf('execDashboard.filter.projectColor|Projekt szín')"
                  label-position="on-border"
                >
                  <multiselect-dropdown
                    v-model="filter.colors.value"
                    :disabled="filter.preference.value.length > 0"
                    :items="getColorsWithUnassigned(projectColors)"
                    append-to-body
                    has-select-all-option
                    identifierField="id"
                    max-height="200px"
                    nameField="colorName"
                    none-selected-text="Mind"
                    searchable
                    @update:modelValue="modifyFilter"
                  ></multiselect-dropdown>
                </b-field>
              </loading-component>
              <loading-component
                v-for="(dimension, index) in projectDimensions"
                :key="index"
              >
                <b-field
                  :label="dimension.dimensionName"
                  label-position="on-border"
                >
                  <multiselect-dropdown
                    :disabled="filter.preference.value.length > 0"
                    :items="
                      getProjectDimensionValuesWithUnassigned(dimension.values)
                    "
                    :modelValue="getDimensionValues(dimension.dimensionName)"
                    append-to-body
                    has-select-all-option
                    identifier-field="id"
                    max-height="200px"
                    none-selected-text="Mind"
                    searchable
                    @update:modelValue="
                      (val) => saveDimensionValues(dimension.dimensionName, val)
                    "
                  ></multiselect-dropdown>
                </b-field>
              </loading-component>
              <loading-component>
                <b-field
                  :label="$tf('execDashboard.filter.client|Ügyfél')"
                  label-position="on-border"
                >
                  <multiselect-dropdown
                    v-model="filter.clients.value"
                    :disabled="filter.preference.value.length > 0"
                    :items="clientsWithUnassigned"
                    append-to-body
                    has-select-all-option
                    max-height="200px"
                    none-selected-text="Mind"
                    searchable
                    @update:modelValue="modifyFilter"
                  ></multiselect-dropdown>
                </b-field>
              </loading-component>
              <loading-component>
                <b-field
                  :label="$tf('execDashboard.filter.team|Csapat')"
                  label-position="on-border"
                >
                  <multiselect-dropdown
                    v-model="filter.teams.value"
                    :disabled="filter.preference.value.length > 0"
                    :items="teamsWithUnassigned"
                    append-to-body
                    has-select-all-option
                    identifier-field="id"
                    max-height="200px"
                    none-selected-text="Mind"
                    searchable
                    @update:modelValue="modifyFilter"
                  ></multiselect-dropdown>
                </b-field>
              </loading-component>
            </div>
          </div>
        </b-tooltip>
      </div>
      <template v-if="isEmpty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="frown" size="is-large"></b-icon>
            </p>
            <p>
              {{
                $tf(
                  "execDashboard.empty|Nincs találat a megadott szűrési feltételekre"
                )
              }}
            </p>
          </div>
        </section>
      </template>
      <template v-else>
        <ExecutiveDashboardOverview :overview="data.overview" />
      </template>
    </div>
    <div>
      <ExecutiveDashboardOverspend
        v-if="data"
        :loading="loading"
        :overspend="data.overspend"
      />

      <ExecutiveDashboardDelay :delay="data.delay" :loading="loading" />

      <ExecutiveDashboardFinancial :financial="data.financial" />

      <ExecutiveDashboardDoneProjects :done-projects="data.doneProjects" />

      <ExecutiveDashboardStartingProjects
        :starting-projects="data.startingProjects"
      />

      <div class="columns" style="max-width: 100%">
        <div class="column is-full">
          <ExecutiveDashboardMissingLogs :missing-logs="data.missingLogs" />
        </div>
        <!--        <div class="column is-half">-->
        <!--          <ExecutiveDashboardUnknownIssues-->
        <!--            :unknown-issues="data.unknownIssues"-->
        <!--          />-->
        <!--        </div>-->
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { moneyify, percentify } from "@/utils/util";
import ExecutiveDashboardOverview from "@/components/enterprise/executive-dashboard/ExecutiveDashboardOverview.vue";
import ExecutiveDashboardOverspend from "@/components/enterprise/executive-dashboard/ExecutiveDashboardOverspend.vue";
import ExecutiveDashboardDelay from "@/components/enterprise/executive-dashboard/ExecutiveDashboardDelay.vue";
import ExecutiveDashboardFinancial from "@/components/enterprise/executive-dashboard/ExecutiveDashboardFinancial.vue";
import ExecutiveDashboardDoneProjects from "@/components/enterprise/executive-dashboard/ExecutiveDashboardDoneProjects.vue";
import ExecutiveDashboardStartingProjects from "@/components/enterprise/executive-dashboard/ExecutiveDashboardStartingProjects.vue";
import ExecutiveDashboardMissingLogs from "@/components/enterprise/executive-dashboard/ExecutiveDashboardMissingLogs.vue";
import network from "@/utils/network";
import MultiselectDropdown from "@/components/MultiselectDropdown.vue";
import LocalStoreMixin from "@/mixins/LocalStoreMixin";
import SaveableObject from "@/objects/SaveableObject";
import SaveableObjectContainer from "@/objects/SaveableObjectContainer";
import LoadingMixin from "@/mixins/LoadingMixin";
import LoadingComponent from "@/components/loading/LoadingComponent.vue";

const LOCAL_KEY_PREFIX = "executive_dashboard_";

export default {
  name: "ExecutiveDashboard",
  components: {
    LoadingComponent,
    MultiselectDropdown,
    // BFieldWithLoading,
    // BCheckboxButtonWithLoading,
    // ExecutiveDashboardUnknownIssues,
    ExecutiveDashboardMissingLogs,
    ExecutiveDashboardStartingProjects,
    ExecutiveDashboardDoneProjects,
    ExecutiveDashboardFinancial,
    ExecutiveDashboardDelay,
    ExecutiveDashboardOverspend,
    ExecutiveDashboardOverview,
    // Skeleton,
  },
  mixins: [LocalStoreMixin, LoadingMixin],
  data() {
    return {
      filter: new SaveableObjectContainer({
        preference: new SaveableObject(LOCAL_KEY_PREFIX + "preference", []),
        categories: new SaveableObject(LOCAL_KEY_PREFIX + "categories", []),
        types: new SaveableObject(LOCAL_KEY_PREFIX + "types", []),
        colors: new SaveableObject(LOCAL_KEY_PREFIX + "colors", []),
        clients: new SaveableObject(LOCAL_KEY_PREFIX + "clients", []),
        teams: new SaveableObject(LOCAL_KEY_PREFIX + "teams", []),
        dimensionFilters: new SaveableObject(
          LOCAL_KEY_PREFIX + "dimensionFilters",
          []
        ),
      }),
      isEmpty: false,
      loading: true,
      projectTypes: [
        {
          identifier: "BASIC",
          name: this.$tf(
            "execDashboard.filter.projectType.basic|Fejlesztési projekt"
          ),
        },
        {
          identifier: "OUTSOURCE",
          name: this.$tf(
            "execDashboard.filter.projectType.outsource|Outsource projekt"
          ),
        },
        {
          identifier: "SUPPORT",
          name: this.$tf(
            "execDashboard.filter.projectType.support|Support projekt"
          ),
        },
      ],
      data: {
        overview: {
          numberOfRunningProjects: 0,
          expectedOverSpending: 0,
          expectedProfit: 0,
          expectedIncome: 0,
        },
        delay: {
          number: {},
          delay: {},
          projects: [],
        },
        overspend: {
          number: {},
          overspend: {},
          profit: {},
          projects: [],
        },
        financial: {
          income: {},
          expenses: {},
          profit: {},
          numberOfEmployees: {},
        },

        doneProjects: [],
        startingProjects: [],
        missingLogs: [],
        unknownIssues: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      teams: "census_team/teams",
      clients: "enterprise_clients/clients",
      projectDimensions: "project_dimensions/getProjectDimensionsWithValues",
      projectColors: "project_dimensions/getProjectColors",
    }),
    clientsWithUnassigned() {
      return [
        {
          id: null,
          name: this.$tf(
            "execDashboard.filter.unassigned|Hozzárendelés nélkül"
          ),
        },
        ...this.clients,
      ];
    },
    teamsWithUnassigned() {
      return [
        {
          id: null,
          name: this.$tf(
            "execDashboard.filter.unassigned|Hozzárendelés nélkül"
          ),
        },
        ...this.teams,
      ];
    },
    labelForTeamMultiselect() {
      if (this.filter.teams.value.length === 0) {
        return this.$tf("execDashboard.filter.all|Mind");
      } else {
        let joinedTeams = this.filter.teams.value
          .map((id) => this.teams.find((t) => t.identifier === id).name)
          .join(", ");

        if (joinedTeams.length > 28) {
          joinedTeams = joinedTeams.substring(0, 28).concat("...");
        }

        return joinedTeams;
      }
    },
    labelForClientMultiselect() {
      if (this.filter.clients.value.length === 0) {
        return this.$tf("execDashboard.filter.all|Mind");
      } else {
        let joinedClients = this.filter.clients.value
          .map((id) => this.clients.find((t) => t.id === id).name)
          .join(", ");

        if (joinedClients.length > 28) {
          joinedClients = joinedClients.substring(0, 28).concat("...");
        }

        return joinedClients;
      }
    },
    labelForProjectTypeMultiselect() {
      if (this.filter.types.value.length === 0) {
        return this.$tf("execDashboard.filter.all|Mind");
      } else {
        let joinedProjectTypes = this.filter.types.value
          .map((id) => this.projectTypes.find((t) => t.identifier === id).name)
          .join(", ");

        if (joinedProjectTypes.length > 28) {
          joinedProjectTypes = joinedProjectTypes
            .substring(0, 28)
            .concat("...");
        }

        return joinedProjectTypes;
      }
    },
  },
  async mounted() {
    await this.doStartLoading();
    this.fetchAllSaveObjects(this.filter);
    await this.$store.dispatch("census_team/fetchTeams");
    await this.$store.dispatch("enterprise_clients/fetchClients");
    await this.$store.dispatch(
      "project_dimensions/fetchActiveProjectDimensionsWithValues"
    );
    await this.$store.dispatch("project_dimensions/fetchProjectColorsCompact");
    await this.getData();

    //remove items from localstorage if the dimension is deleted in the settings
    this.filter.dimensionFilters.value =
      this.filter.dimensionFilters.value.filter((item) =>
        this.projectDimensions.some((dim) => dim.dimensionName === item.name)
      );
  },
  methods: {
    percentify,
    moneyify,
    async modifyFilter() {
      if (this.filter.preference.value.length > 0) {
        this.filter.types.resetToDefault();
        this.filter.colors.resetToDefault();
        this.filter.clients.resetToDefault();
        this.filter.teams.resetToDefault();
        if (this.filter.preference.value.length > 1) {
          this.filter.preference.value = this.filter.preference.value.slice(-1);
        }
      }
      // this.saveAllSaveObjects(this.filter);

      await this.getData();
    },
    getDimensionValues(dimensionName) {
      const foundObject = this.filter.dimensionFilters.value.find(function (
        obj
      ) {
        return obj.name === dimensionName;
      });

      return foundObject?.values ? foundObject.values : [];
    },
    getProjectDimensionValuesWithUnassigned(dimensionValues) {
      return [
        {
          id: null,
          name: "Hozzárendelés nélkül",
        },
        ...dimensionValues,
      ];
    },
    getColorsWithUnassigned(colors) {
      return [
        {
          id: null,
          colorName: "Hozzárendelés nélkül",
        },
        ...colors,
      ];
    },
    saveDimensionValues(dimensionName, values) {
      const foundObject = this.filter.dimensionFilters.value.find(function (
        obj
      ) {
        return obj.name === dimensionName;
      });
      if (foundObject) {
        foundObject.values = values;
      } else {
        this.filter.dimensionFilters.value.push({
          name: dimensionName,
          values: values,
        });
      }

      this.filter.dimensionFilters.save();
      this.modifyFilter();
    },
    async extractDimensionValueIds(requestParams) {
      if (requestParams.dimensionFilters) {
        let dimensionValuesId = [];
        for (const dimension of requestParams.dimensionFilters) {
          for (const dimensionValue of dimension.values) {
            dimensionValuesId.push(dimensionValue);
          }
        }
        delete requestParams.dimensionFilters;
        requestParams.dimensionValueList = dimensionValuesId;
      }
      return requestParams;
    },
    async getData() {
      try {
        await this.doStartLoading();
        const axios = await network.connection();
        let requestParams = this.filter.toRequestParams();
        requestParams = await this.extractDimensionValueIds(requestParams);
        const { data } = await axios.post(
          "/enterprise/executive",
          requestParams
        );
        this.data = data;
      } finally {
        this.doFinishLoading();
      }
    },
  },
};
</script>

<style lang="scss">
.executive-dashboard {
  .container {
    padding-left: 24px;
    padding-right: 24px;
  }
}
</style>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.filter-dropdown-content {
  padding: 0 12px;
  width: 300px;

  div {
    display: flex;
    align-items: center;
    padding: 6px 0;

    &:not(:first-child) {
      border-top: 1px solid $grey-lighter;
    }
  }
}

.filter-dropdown-field {
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $grey-lighter;
  border-radius: 8px;
  padding: calc(0.5em - 1px) calc(0.75em - 1px);
  cursor: pointer;
}

.filter-dropdown-badge {
  padding-left: 6px;
  padding-right: 6px;
  color: $white;
  background-color: $primary;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 700;
}

h3.heading {
  font-size: 24px;
}
</style>
