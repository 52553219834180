<template>
  <div class="modal-card">
    <div class="container">
      <section class="modal-card-body">
        <timesheet-details-row
          :data="data"
          :day="day"
          :expected="expected"
          :my-time-sheet="myTimeSheet"
          modal-view
        />
      </section>
    </div>
  </div>
</template>

<script>
import eventBus, { EVENTS } from "../../../utils/eventBus";
import { defineAsyncComponent, getCurrentInstance } from "vue";

export default {
  name: "TimesheetDetailsModal",
  setup: () => {
    const instance = getCurrentInstance();
    return {
      instance: instance,
    };
  },
  props: {
    day: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: false,
    },
    expected: {
      type: Array,
      required: true,
      default: () => [],
    },
    myTimeSheet: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TimesheetDetailsRow: defineAsyncComponent(() =>
      import("./TimesheetDetailsRow")
    ),
  },
  data() {
    return {};
  },
  mounted() {
    eventBus.$on(EVENTS.OVERTIME_REQUESTED, () => this.instance.emit("close"));
  },
  methods: {
    close() {
      this.instance.emit("close");
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/colors";

.timesheet-details-modal {
  .animation-content {
    width: 80% !important;
  }

  .modal-card {
    width: 100% !important;

    .container {
      padding: 0 !important;

      .modal-card-body {
        padding: 0;
        border-radius: 8px;

        .timesheet-details-row {
          border: none !important;
          border-radius: 0 !important;
          padding: 1rem;

          &-header {
            &-icon {
              margin-top: 0.4rem;
            }

            &-worklogDto-count {
              margin-top: 0.25rem;
            }

            &-project-name {
              margin-top: 0.25rem;
            }
          }

          .overtime-badge {
            margin-top: 0.3rem;
          }
        }
      }
    }
  }
}
</style>
