import { deepCopy, newLogger } from "@/utils/util";
import StoreCore from "@/store/StoreCore";

let logger = newLogger("Enterprise-SupportProjects");

const urls = {
  project: (identifier) => `/enterprise/projects/support/${identifier}`,
  projects: "/enterprise/projects/support",
  fee: "/enterprise/projects/support/fee",
  expense: "/enterprise/projects/support/expense",
  activity: "/enterprise/projects/support/activity",
  billableTask: "/enterprise/projects/support/task",
  embeddable: (request) =>
    `/enterprise/projects/support/${request.activityType}/${request.embeddableType}/${request.activityId}/${request.id}`,
};

const initState = () => {
  return {
    project: {},
    projects: [],
  };
};

const storeCore = new StoreCore();
const actions = {
  fetchProjectDetails: async function (
    { commit, dispatch },
    { projectIdentifier, requestParams, force } = {}
  ) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.project(projectIdentifier),
        requestParams,
        true,
        dispatch,
        "Projekt adatainak betöltése...",
        0,
        force
      );
      commit("saveProjectDetails", {
        identifier: projectIdentifier,
        data: data,
      });
      return data;
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
        throw err;
      }
    }
  }.bind(storeCore),
  fetchProjectsForYear: async function (
    { commit, dispatch },
    { requestParams, force } = {}
  ) {
    try {
      let data = deepCopy(
        await this.remoteRequest(
          "get",
          urls.projects,
          requestParams,
          true,
          dispatch,
          "Support projektek betöltése...",
          force
        )
      );
      commit("saveProjects", data);
      return data;
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  createActivity: async function (
    { commit, dispatch },
    { identifier, activity } = {}
  ) {
    try {
      await this.remoteRequest(
        "post",
        `${urls.project(identifier)}/activity`,
        activity,
        false,
        dispatch,
        "Support tevékenység létrehozása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  updateActivity: async function ({ commit, dispatch }, activity) {
    try {
      await this.remoteRequest(
        "put",
        urls.activity,
        activity,
        false,
        dispatch,
        "Support tevékenység módosítása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deleteActivity: async function (
    { commit, dispatch },
    { projectId, activityType, id }
  ) {
    try {
      await this.remoteRequest(
        "delete",
        `${urls.activity}/${projectId}/${activityType}/${id}`,
        null,
        false,
        dispatch,
        "Support tevékenység törlése..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  createFee: async function ({ commit, dispatch }, fee) {
    try {
      await this.remoteRequest(
        "post",
        urls.fee,
        fee,
        false,
        dispatch,
        "Havidíj létrehozása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  createExpense: async function ({ commit, dispatch }, expense) {
    try {
      await this.remoteRequest(
        "post",
        urls.expense,
        expense,
        false,
        dispatch,
        "Kiadás létrehozása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  updateFee: async function ({ commit, dispatch }, fee) {
    try {
      await this.remoteRequest(
        "put",
        urls.fee,
        fee,
        false,
        dispatch,
        "Havidíj módosítása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  updateExpense: async function ({ commit, dispatch }, expense) {
    try {
      await this.remoteRequest(
        "put",
        urls.expense,
        expense,
        false,
        dispatch,
        "Kiadás módosítása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  createBillableTask: async function ({ commit, dispatch }, billableTask) {
    try {
      await this.remoteRequest(
        "post",
        urls.billableTask,
        billableTask,
        false,
        dispatch,
        "Elszámolható feladat létrehozása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  updateBillableTask: async function ({ commit, dispatch }, billableTask) {
    try {
      await this.remoteRequest(
        "put",
        urls.billableTask,
        billableTask,
        false,
        dispatch,
        "Elszámolható feladat módosítása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deleteBillableTask: async function ({ commit, dispatch }, id) {
    try {
      await this.remoteRequest(
        "delete",
        `${urls.billableTask}/${id}`,
        null,
        false,
        dispatch,
        "Elszámolható feladat törlése..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deleteEmbeddable: async function ({ commit, dispatch }, request) {
    try {
      await this.remoteRequest(
        "delete",
        urls.embeddable(request),
        null,
        false,
        dispatch,
        "Tevékenység törlése..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveProjectDetails: (state, { identifier, data }) => {
    if (data.costs && data.costs.length) {
      for (let c in data.costs) {
        data.costs[c].day = new Date(data.costs[c].date);
      }
    }
    if (data.invoices && data.invoices.length) {
      for (let c in data.invoices) {
        data.invoices[c].day = new Date(data.invoices[c].date);
      }
    }
    data.overrides = data.overrides || [];
    state.project[identifier] = data;
  },
  saveProjects: (state, data) => {
    state.projects = data;
  },
};

const getters = {
  projectDetails: (state) => (identifier) => {
    return state.project[identifier];
  },
  projectData: (state) => {
    return state.projects;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
