<template>
  <div class="onboarding-container">
    <div
      v-if="showIntroLogo"
      class="onboarding-logo-container is-align-items-center"
    >
      <div class="px-2">
        <!--        <img-->
        <!--          src="@/assets/images/forecastify_logo_text_white.svg"-->
        <!--          alt="Forecastify logo"-->
        <!--          style="height: 96px"-->
        <!--        />-->
        <p>
          {{
            $tf(
              "onboarding.landing.flair1|Precision in Progress: Time Logging for Success"
            )
          }}<br />
          {{ $tf("onboarding.landing.flair2|Record, Analyze, Succeed") }}
        </p>
      </div>
    </div>

    <div v-else class="onboarding-progress-container">
      <!--      <img-->
      <!--        :src="require('@/assets/images/forecastify_logo_text_white.svg')"-->
      <!--        alt="Forecastify logo"-->
      <!--        style="height: 48px"-->
      <!--      />-->
      <div class="onboarding-progress-steps">
        <template
          v-for="onboardingStep in stepDetails.slice(0, -1)"
          :key="'obst' + onboardingStep.order"
        >
          <div class="onboarding-progress-step">
            <div
              :class="{
                'onboarding-progress-step-number-active':
                  currentPage === onboardingStep.step,
              }"
              class="onboarding-progress-step-number"
            >
              <b-icon
                v-if="
                  onboardingStep.order <
                  stepDetails.findIndex((sd) => sd.step === currentPage) + 1
                "
                icon="check"
                style="color: #30d0c0"
              />
              <span v-else>{{ onboardingStep.order }}</span>
            </div>
            <div>
              <div class="onboarding-progress-step-title">
                {{ onboardingStep.title }}
              </div>
              <div class="onboarding-progress-step-subtitle">
                {{ onboardingStep.subtitle }}
              </div>
            </div>
          </div>
          <div
            v-if="onboardingStep.order < stepDetails.length - 1"
            :key="'obstd' + onboardingStep.order"
            class="onboarding-progress-step-divider"
          />
        </template>
      </div>
    </div>

    <div
      :style="{ flexGrow: showIntroLogo ? 0 : 1 }"
      class="onboarding-step-container"
    >
      <div
        :class="{
          'onboarding-step-card': showIntroLogo,
          'onboarding-step-page': !showIntroLogo,
        }"
      >
        <transition mode="out-in" name="fade">
          <OnboardingWelcome
            v-if="loaded && currentPage === ONBOARDING_PAGES.START"
            @nextPage="switchPage"
          />

          <OnboardingExternalIssueTrackerSelect
            v-else-if="
              currentPage === ONBOARDING_PAGES.EXTERNAL_ISSUE_TRACKER_SELECT
            "
            @nextPage="switchPage"
          />

          <OnboardingExternalIssueTrackerSyncStart
            v-else-if="
              currentPage === ONBOARDING_PAGES.EXTERNAL_ISSUE_TRACKER_SYNC_START
            "
            @nextPage="switchPage"
          />

          <OnboardingExternalIssueTrackerSyncInProgress
            v-else-if="
              currentPage ===
              ONBOARDING_PAGES.EXTERNAL_ISSUE_TRACKER_SYNC_IN_PROGRESS
            "
            @nextPage="switchPage"
          />

          <OnboardingExternalIssueTrackerSyncSuccess
            v-else-if="
              currentPage ===
              ONBOARDING_PAGES.EXTERNAL_ISSUE_TRACKER_SYNC_SUCCESS
            "
            @nextPage="switchPage"
          />

          <OnboardingCompanyProfile
            v-else-if="currentPage === ONBOARDING_PAGES.COMPANY_PROFILE"
            @nextPage="switchPage"
          />

          <OnboardingSetupUserRoles
            v-else-if="currentPage === ONBOARDING_PAGES.SETUP_ROLES"
            @nextPage="switchPage"
          />

          <OnboardingSetupUserParameters
            v-else-if="currentPage === ONBOARDING_PAGES.SETUP_USER_PARAMETERS"
            @nextPage="switchPage"
          />

          <OnboardingSetupPriceLevel
            v-else-if="currentPage === ONBOARDING_PAGES.SETUP_PRICE_LEVEL"
            @nextPage="switchPage"
          />

          <OnboardingSetupUsers
            v-else-if="currentPage === ONBOARDING_PAGES.SETUP_USERS"
            @nextPage="switchPage"
          />

          <OnboardingSetupClients
            v-else-if="currentPage === ONBOARDING_PAGES.SETUP_CLIENTS"
            @nextPage="switchPage"
          />

          <OnboardingSetupProjects
            v-else-if="currentPage === ONBOARDING_PAGES.SETUP_PROJECTS"
            @nextPage="switchPage"
          />

          <OnboardingFinish
            v-else-if="currentPage === ONBOARDING_PAGES.FINISH"
          />

          <div v-else-if="!loaded">
            <template v-if="error"> Hiba - hibás onboarding token!</template>
            <template v-else> Betöltés...</template>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import OnboardingWelcome from "@/components/onboarding/OnboardingWelcome.vue";
import OnboardingExternalIssueTrackerSelect from "@/components/onboarding/OnboardingExternalIssueTrackerSelect.vue";
import { ONBOARDING_PAGES, UI_THEMES } from "@/utils/const";
import OnboardingCompanyProfile from "@/components/onboarding/OnboardingCompanyProfile.vue";
import OnboardingSetupUserParameters from "@/components/onboarding/OnboardingSetupUserParameters.vue";
import OnboardingSetupUserRoles from "@/components/onboarding/OnboardingSetupUserRoles.vue";
import OnboardingSetupPriceLevel from "@/components/onboarding/OnboardingSetupPriceLevel.vue";
import OnboardingSetupUsers from "@/components/onboarding/OnboardingSetupUsers.vue";
import OnboardingSetupProjects from "@/components/onboarding/OnboardingSetupProjects.vue";
import OnboardingFinish from "@/components/onboarding/OnboardingFinish.vue";
import OnboardingSetupClients from "@/components/onboarding/OnboardingSetupClients.vue";
import { changeTheme } from "@/utils/util";

export default {
  name: "OnboardingPage",
  components: {
    OnboardingSetupClients,
    OnboardingFinish,
    OnboardingSetupProjects,
    OnboardingSetupUsers,
    OnboardingSetupPriceLevel,
    OnboardingSetupUserRoles,
    OnboardingSetupUserParameters,
    OnboardingCompanyProfile,
    OnboardingExternalIssueTrackerSelect,
    // OnboardingExternalIssueTrackerSyncStart,
    // OnboardingExternalIssueTrackerSyncInProgress,
    // OnboardingExternalIssueTrackerSyncSuccess,
    OnboardingWelcome,
  },
  data: function () {
    return {
      loaded: false,
      error: null,
      ONBOARDING_PAGES,
    };
  },
  methods: {
    switchPage(toPage) {
      this.$store.dispatch("onboarding/updatePosition", toPage);
    },
  },
  computed: {
    currentPage() {
      return this.$store.getters["onboarding/getCurrentPosition"];
    },
    token() {
      return this.$route.params.onboardingToken;
    },
    showIntroLogo() {
      return [
        ONBOARDING_PAGES.START,
        ONBOARDING_PAGES.EXTERNAL_ISSUE_TRACKER_SELECT,
        ONBOARDING_PAGES.EXTERNAL_ISSUE_TRACKER_SYNC_START,
        ONBOARDING_PAGES.EXTERNAL_ISSUE_TRACKER_SYNC_IN_PROGRESS,
        ONBOARDING_PAGES.EXTERNAL_ISSUE_TRACKER_SYNC_SUCCESS,
      ].includes(this.currentPage);
    },
    stepDetails() {
      return [
        {
          step: ONBOARDING_PAGES.COMPANY_PROFILE,
          order: 1,
          title: this.$tf("onboarding.steps.companyProfile.main|Cégprofil"),
          subtitle: this.$tf(
            "onboarding.steps.companyProfile.sub|kiválasztása"
          ),
        },
        {
          step: ONBOARDING_PAGES.SETUP_ROLES,
          order: 2,
          title: this.$tf("onboarding.steps.roles.main|Jogkörök"),
          subtitle: this.$tf("onboarding.steps.roles.sub|létrehozása"),
        },
        {
          step: ONBOARDING_PAGES.SETUP_USER_PARAMETERS,
          order: 3,
          title: this.$tf("onboarding.steps.userParameters.main|Tudásszintek"),
          subtitle: this.$tf(
            "onboarding.steps.userParameters.sub|meghatározsa"
          ),
        },
        {
          step: ONBOARDING_PAGES.SETUP_PRICE_LEVEL,
          order: 4,
          title: this.$tf("onboarding.steps.priceLevels.main|Belső árszintek"),
          subtitle: this.$tf("onboarding.steps.priceLevels.sub|meghatározása"),
        },
        {
          step: ONBOARDING_PAGES.SETUP_USERS,
          order: 5,
          title: this.$tf("onboarding.steps.users.main|Adathozzárendelés"),
          subtitle: this.$tf("onboarding.steps.users.sub|a felhasználókhoz"),
        },
        {
          step: ONBOARDING_PAGES.SETUP_CLIENTS,
          order: 6,
          title: this.$tf("onboarding.steps.clients.main|Ügyfelek"),
          subtitle: this.$tf("onboarding.steps.clients.sub|bevitele"),
        },
        {
          step: ONBOARDING_PAGES.SETUP_PROJECTS,
          order: 7,
          title: this.$tf("onboarding.steps.projects.main|Projektek"),
          subtitle: this.$tf("onboarding.steps.projects.sub|beállítása"),
        },
        {
          step: ONBOARDING_PAGES.FINISH,
          order: 8,
        },
      ];
    },
  },
  async mounted() {
    changeTheme(UI_THEMES.LIGHT, false);

    if (this.token) {
      try {
        await this.$store.dispatch("onboarding/fetchInfo");
        this.loaded = true;
        await Promise.all([
          this.$store.dispatch("onboarding/fetchTemplates"),
          this.$store.dispatch("onboarding/fetchCurrencies"),
          this.$store.dispatch("onboarding/fetchPrivileges"),
        ]);
      } catch (e) {
        this.error = e;
      }
    } else {
      console.error("Onboarding token was not found!");
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.onboarding-container {
  background: url("@/assets/images/onboarding/background-main.png") no-repeat;
  background-size: 100% auto;
  height: 100vh;
  width: 100%;
  display: flex;
  gap: 32px;
}

.onboarding-logo-container {
  color: $white;
  font-size: 20px;
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.onboarding-progress-container {
  width: 420px;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: 32px 64px;
  align-items: center;
  //margin: auto 0;
}
</style>

<style lang="scss">
@import "~@/assets/scss/colors.scss";

.onboarding-progress-step {
  display: flex;
  gap: 24px;
  align-items: center;
}

.onboarding-progress-step-number {
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 2px dashed $grey-light;
  color: $white;
  font-weight: 700;
  width: 38px;
  height: 38px;
  font-size: 14px;
  transition: background-color 0.5s;
}

.onboarding-progress-step-number-active {
  border: none;
  background-color: $turquoise;
  animation-name: step-number-pop;
  animation-duration: 0.5s;
}

.onboarding-progress-step-title {
  color: $white;
  font-weight: 500;
  font-size: 16px;
}

.onboarding-progress-step-subtitle {
  color: $white;
  font-size: 13px;
  opacity: 0.5;
}

.onboarding-progress-step-divider {
  margin: 4px 0 4px 17px;
  height: 38px;
  border-left: 2px dashed $grey-light;
}

.onboarding-progress-steps {
  margin-top: 32px;
}

@keyframes step-number-pop {
  0% {
    transform: scale(1);
    background-color: $turquoise;
    color: $white;
  }
  50% {
    transform: scale(1.1);
    background-color: color-mix(in srgb, var(--turquoise), #fff 30%);
    color: $yellow;
  }
  100% {
    transform: scale(1);
    background-color: $turquoise;
    color: $white;
  }
}
</style>
