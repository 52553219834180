<template>
  <div class="modal-card" style="width: auto">
    <div class="container" style="width: 42rem">
      <section class="modal-card-body">
        <h1 class="title">
          Jegy részletek exportálása ({{ this.projectKey }})
        </h1>

        <div class="mt-5 mb-5">
          <b-checkbox v-model="onlyTime"> Költség kihagyása</b-checkbox>
        </div>

        <b-button
          :loading="isExportInProgress"
          rounded
          type="is-info"
          @click="doExport"
        >
          Exportálás megkezdése
        </b-button>
      </section>
    </div>
  </div>
</template>

<script>
import FileSaver from "file-saver";
import { getCurrentInstance } from "vue";
import useCustomVuelidate from "@/plugins/vuelidate";

export default {
  name: "IssueExportModal",
  setup: () => {
    const instance = getCurrentInstance();
    const { v$, touch$ } = useCustomVuelidate();
    return {
      v$,
      touch$,
      instance,
    };
  },
  props: {
    projectKey: {
      type: String,
      default: null,
      validator: (prop) => typeof prop === "string" || prop === null,
    },
    tigKey: {
      type: String,
      default: null,
      validator: (prop) => typeof prop === "string" || prop === null,
    },
  },
  data() {
    return {
      onlyTime: false,
      isExportInProgress: false,
    };
  },
  methods: {
    close() {
      this.instance.emit("close");
    },
    async doExport() {
      this.isExportInProgress = true;
      try {
        if (!!this.tigKey || !!this.projectKey) {
          let data = await this.$store.dispatch("enterprise_issue/export", {
            key: !!this.tigKey ? this.tigKey : this.projectKey,
            onlyTime: this.onlyTime,
          });
          FileSaver.saveAs(data, "issue_export.xlsx");
        }
      } finally {
        this.isExportInProgress = false;
        this.close();
      }
    },
  },
};
</script>
