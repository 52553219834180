<template>
  <section class="section">
    <div class="container is-flex">
      <div>
        <h1 class="title">
          {{ $tf("forecast.chanceCategory.title|Esélykategóriák") }}
        </h1>
        <h2 class="subtitle">
          {{
            $tf("forecast.chanceCategory.subtitle|Esélykategóriák beállítása")
          }}
        </h2>
      </div>
      <b-button
        v-if="canCreate"
        class="ml-auto"
        icon-left="plus-circle"
        type="is-info"
        @click="openEdit(null)"
      >
        {{
          $tf("forecast.chanceCategory.newButton|Új esélykategória felvétele")
        }}
      </b-button>
    </div>

    <div class="container">
      <b-table
        ref="table"
        :data="chanceCategories"
        :loading="!loaded"
        height="auto"
        hoverable
        mobile-cards
        sticky-header
        striped
      >
        <b-table-column
          v-slot="props"
          :label="$tf('forecast.chanceCategory.nameTableHeader|Név')"
          field="name"
          searchable
          sortable
        >
          <label class="has-font-weight-500">{{ props.row.name }}</label>
        </b-table-column>

        <b-table-column
          v-slot="props"
          :label="$tf('forecast.chanceCategory.chanceTableHeader|Esély %')"
          field="filters"
          searchable
          sortable
        >
          {{ props.row.chance }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          :visible="canEdit"
          cell-class="do-not-wrap"
          width="32"
        >
          <div class="is-flex has-gap-3 pr-5">
            <clickable-icon
              v-if="canEdit"
              :disabled="!props.row.modifiable"
              :tooltip="
                props.row.modifiable
                  ? $tf('forecast.chanceCategory.editTooltip|Szerkesztés')
                  : $tf(
                      'forecast.chanceCategory.unEditable|Használatban van, nem szerkeszthető'
                    )
              "
              class="mr-2"
              icon="pencil"
              type="is-info"
              @click="openEdit(props.row)"
            ></clickable-icon>
            <clickable-icon
              v-if="canDelete"
              :disabled="!props.row.modifiable"
              :tooltip="
                props.row.modifiable
                  ? $tf('forecast.chanceCategory.deleteTooltip|Törlés')
                  : $tf(
                      'forecast.chanceCategory.unDeletableTooltip|Használatban van, nem törölhető'
                    )
              "
              icon="trash"
              type="is-danger"
              @click="deleteRow(props.row.id)"
            ></clickable-icon>
          </div>
        </b-table-column>

        <template #empty>
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon icon="frown" size="is-large"></b-icon>
              </p>
              <p>
                {{
                  $tf(
                    "forecast.chanceCategory.emptyTable|Még nincsenek esélykategóriák felvéve."
                  )
                }}
              </p>
            </div>
          </section>
        </template>
      </b-table>
    </div>
  </section>
</template>

<script>
import { PRIVILEGES } from "@/utils/const";
import { mapGetters } from "vuex";

import ClickableIcon from "@/components/module/icon/ClickableIcon.vue";
import ForecastChanceCategoryEditModal from "@/components/forecast/edit/ForecastChanceCategoryEditModal.vue";

import { openDeleteConfirm } from "@/utils/util";

export default {
  name: "ForecastChanceCategory",
  data() {
    return {
      loaded: false,
    };
  },
  components: {
    ClickableIcon,
  },
  computed: {
    ...mapGetters({
      chanceCategories: "forecast_chance_category/chanceCategories",
    }),
    canCreate() {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ENTERPRISE.FORECAST.CHANCE_CATEGORY.CREATE,
        PRIVILEGES.ENTERPRISE.FORECAST.MISC.ADMIN
      );
    },
    canEdit() {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ENTERPRISE.FORECAST.CHANCE_CATEGORY.EDIT,
        PRIVILEGES.ENTERPRISE.FORECAST.MISC.ADMIN
      );
    },
    canDelete() {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ENTERPRISE.FORECAST.CHANCE_CATEGORY.DELETE,
        PRIVILEGES.ENTERPRISE.FORECAST.MISC.ADMIN
      );
    },
  },
  methods: {
    openEdit(row) {
      this.$buefy.modal.open({
        parent: this,
        component: ForecastChanceCategoryEditModal,
        props: { chanceCategory: row },
        hasModalCard: true,
        trapFocus: true,
        events: {
          save: () => this.load(true),
        },
      });
    },
    async deleteRow(id) {
      openDeleteConfirm(this.$buefy, async () => {
        await this.$store.dispatch(
          "forecast_chance_category/deleteChanceCategory",
          id
        );
        await this.load(true);
      });
    },
    async load(force) {
      this.loaded = false;

      await this.$store.dispatch(
        "forecast_chance_category/fetchChanceCategories",
        force
      );
      this.loaded = true;
    },

    async save(row) {
      await this.$store.dispatch(
        "forecast_chance_category/updateChanceCategory",
        row
      );
      await this.load(true);
    },
  },
  async mounted() {
    await this.load();
    this.loaded = true;
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/colors";

.link.is-danger {
  color: $red;
}

.red {
  color: $red;
}

.cost-label {
  color: $custom-grayer-blue;
}

.cost-value {
  font-weight: 500;
  color: $custom-dark-blue;
}

.cost-since {
  color: $custom-grayer-blue;
  font-size: 14px;
}

.tier-toggle {
  :deep(.has-text-link) {
    color: $custom-dark-blue !important;
  }
}

.users-without-tiers-container {
  h1.title {
    color: $red;
    font-size: 1.1rem;
  }
}

.fixed-price-margin {
  margin-bottom: 2rem;
}
</style>
