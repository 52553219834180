<template>
  <div :class="type">
    <money-modal
      :data-type="getDataType"
      :edit-data="editData"
      :employees="employees"
      :label="getHeader()"
      :modify="onModify"
      :show="editModal"
      :tigs="tigs"
      :type="type"
      @close="closeEditModal()"
    />
    <money-modal
      :create="onCreate"
      :data-type="getDataType"
      :employees="employees"
      :label="getHeader()"
      :show="createModal"
      :tigs="tigs"
      :type="type"
      @close="closeCreateModal()"
    />
    <div class="is-flex is-justify-content-space-between">
      <div>
        <h4 class="title">
          {{ getHeader() }}
          <b-tooltip
            v-if="type === 'overrides'"
            :auto-close="['outside', 'escape']"
            :triggers="['click']"
            position="is-right"
            type="is-info"
          >
            <template v-slot:content>
              <div class="content tooltip">
                <p>
                  {{
                    $tf(
                      "projectpage.override.tooltip|A megadott érték kizárólag ebben a projektben érvényesül, itt viszont általánosan, a dolgozó minden munka bejegyzésére."
                    )
                  }}
                </p>
              </div>
            </template>
            <b-icon icon="info-circle" />
          </b-tooltip>
        </h4>
      </div>
      <div>
        <b-button
          :disabled="type === 'invoices' && tigs?.length < 1"
          rounded
          size="is-small"
          type="is-info"
          @click="showCreateModal"
        >
          {{
            $tf("projectpage.overrideAndExpense.add|{type} hozzáadása", {
              type: getDataType,
            })
          }}
        </b-button>
      </div>
    </div>
    <b-table
      ref="expenseTable"
      :aria-current-label="$tf('aria.current.label|Aktuális lap')"
      :aria-next-label="$tf('aria.next.label|Következő lap')"
      :aria-page-label="$tf('aria.page.label|Lap')"
      :aria-previous-label="$tf('aria.previous.label|Előző lap')"
      :data="data"
      :per-page="10"
      :show-detail-icon="type !== 'overrides'"
      detail-key="id"
      detailed
    >
      <template #empty>
        <div class="has-text-centered">
          {{
            $tf("projectpage.overrideAndExpense.table.empty|Nincsen {type}", {
              type: getDataType.toLowerCase(),
            })
          }}
        </div>
      </template>
      <b-table-column
        v-for="column in MONEY_COLUMNS[type].filter((col) => !col.notInTable)"
        :key="column.field"
        v-slot="props"
        :field="column.field"
        :label="$tf(column.label)"
        centered
        sortable
      >
        <template v-if="column.field === 'status'">
          <span :class="INVOICE_STATUS[props.row.status].type" class="tag">
            {{ $tf(INVOICE_STATUS[props.row.status].token) }}
          </span>
        </template>
        <template v-else-if="column.field === 'tigIdentifiers'">
          {{ props.row.tigIdentifiers.join(", ") }}
        </template>
        <template v-else-if="column.field === 'cost'">
          {{ moneyify(props.row[column.field]) }}
        </template>
        <template v-else>
          {{ props.row[column.field] }}
        </template>
      </b-table-column>
      <b-table-column
        v-slot="props"
        :label="$tf('projectpage.override.table.actions|Műveletek')"
        centered
        field="actions"
        width="80"
      >
        <clickable-icon
          v-if="onApprove && props.row.status === 'DUE'"
          :tooltip="
            $tf('projectpage.invoices.actions.markPaid|Fizetettnek jelölés')
          "
          icon="circle-check"
          type="is-success"
          @click="onApprove(props.row.id)"
        />
        <clickable-icon
          v-if="onCancel && props.row.status === 'DUE'"
          :tooltip="
            $tf('projectpage.invoices.table.actions.cancel|Visszavonás')
          "
          icon="circle-xmark"
          type="is-danger"
          @click="onCancel(props.row.id)"
        />
        <clickable-icon
          :tooltip="$tf('projectpage.override.table.actions.edit|Szerkesztés')"
          icon="edit"
          type="is-info"
          @click="showEditModal(props.row)"
        />
        <clickable-icon
          v-if="onDelete"
          :tooltip="$tf('projectpage.override.table.actions.delete|Törlés')"
          icon="trash"
          type="is-danger"
          @click="onDelete(props.row.identifier)"
        />
      </b-table-column>
      <template #detail="props">
        <div class="columns">
          <div class="main column">
            <div class="top is-flex is-justify-content-center">
              <div>
                <p>{{ $tf("projectpage.invoice.summary|Megjegyzés") }}</p>
              </div>
            </div>
            <div
              class="bottom has-font-weight-500 is-flex is-justify-content-center"
            >
              <div>
                <p>{{ props.row.summary }}</p>
              </div>
            </div>
          </div>
          <div v-if="type === 'invoices'" class="main column">
            <div class="top is-flex is-justify-content-center">
              <div>
                <p>{{ $tf("projectpage.invoices.table.tig|Mérföldkő") }}</p>
              </div>
            </div>
            <div
              class="bottom has-font-weight-500 is-flex is-justify-content-center"
            >
              <div>
                <p>{{ props.row.tigIdentifiers.join(", ") }}</p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { deepCopy, moneyify } from "@/utils/util";
import ClickableIcon from "@/components/module/icon/ClickableIcon";
import MoneyModal from "@/components/enterprise/ProjectDetails/TabItems/MoneyModal";
import { mapGetters } from "vuex";
import { INVOICE_STATUS, MONEY_COLUMNS } from "@/utils/const";

export default {
  name: "MoneyTable",
  components: {
    MoneyModal,
    ClickableIcon,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    onCreate: {
      type: Function,
      required: true,
    },
    onModify: {
      type: Function,
      required: true,
    },
    onDelete: {
      type: Function,
      required: false,
    },
    onApprove: {
      type: Function,
      required: false,
    },
    onCancel: {
      type: Function,
      required: false,
    },
    data: {
      type: Array,
      required: true,
    },
    tigs: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      MONEY_COLUMNS,
      INVOICE_STATUS,
      newData: {},
      modifiedData: {},
      name: "",
      editModal: false,
      createModal: false,
      sSummary: false,
      vSummary: "",
      editData: {},
    };
  },
  computed: {
    getDataType() {
      switch (this.type) {
        case "expenses":
          return this.$tf("projectpage.expense|Kiadás");
        case "overrides":
          return this.$tf("projectpage.override|Felülírás");
        case "invoices":
          return this.$tf("projectpage.invoice|Számla");
        default:
          return this.$tf("projectpage.expenseAndOverrideMisc|Egyéb");
      }
    },
    filteredEmployees() {
      let employees = this.employees || [];
      return employees.filter((element) => {
        if (!this.name || !element.name || !element.username) return false;
        return (
          element.name
            .toString()
            .toLowerCase()
            .indexOf(
              this.name
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0 ||
          element.username
            .toString()
            .toLowerCase()
            .indexOf(
              this.name
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0
        );
      });
    },
    ...mapGetters({
      employees: "employee/loggingEmployees",
    }),
  },
  methods: {
    moneyify,
    getHeader() {
      switch (this.type) {
        case "expenses":
          return this.$tf("projectpage.expenses|Kiadások");
        case "overrides":
          return this.$tf("projectpage.overrides|Felülírások");
        case "invoices":
          return this.$tf("projectpage.invoices|Számlák");
        default:
          return this.$tf("projectpage.expensesAndOverridesMisc|Egyéb");
      }
    },
    toggleDetails(props) {
      this.modifiedData = deepCopy(props.row);
      if (props.row.date) {
        this.modifiedData.date = new Date(props.row.date);
      }
      if (props.row.day) {
        this.modifiedData.day = new Date(props.row.day);
      }
      props.toggleDetails(props.row);
    },
    closeEditModal() {
      this.editData = {};
      this.editModal = false;
    },
    showEditModal(data) {
      this.editData = deepCopy(data);
      this.editModal = true;
    },
    closeCreateModal() {
      this.createModal = false;
    },
    showCreateModal() {
      this.createModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.tag {
  margin-bottom: 0.25rem;
}

.main {
  margin: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: $white;
}

.top {
  color: $custom-grayer-blue;
}

.bottom {
  color: $custom-dark-blue;
}
</style>
