<template>
  <b-field :label="label" :labelPosition="labelPosition">
    <b-autocomplete
      v-model="search"
      :clear-on-select="true"
      :data="filteredData"
      :field="field"
      :open-on-focus="true"
      :placeholder="placeholder"
      @select="(option) => addSelect(option)"
    >
      <template #header>
        <div
          v-for="option in selectedOptions"
          :key="selectedOptions.indexOf(option)"
          class="selectedOption my-1"
        >
          {{ option[field]
          }}<a @click="removeSelect(option)">
            <b-icon class="xmark" icon="xmark" size="is-small"></b-icon>
          </a>
        </div>
      </template>
    </b-autocomplete>
  </b-field>
</template>

<script>
export default {
  name: "MultiselectAutocomplete",
  props: {
    modelValue: {
      type: Array,
      default() {
        return [];
      },
      required: false,
    },
    data: {
      type: Array,
      required: false,
    },
    field: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    labelPosition: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
  },
  watch: {
    selectedOptions: {
      handler(val) {
        this.$emit("update:modelValue", val);
      },
      deep: true,
    },
    modelValue(val) {
      this.selectedOptions = val;
    },
  },
  computed: {
    filteredData() {
      return this.data
        ? this.selectedOptions
          ? this.data.filter((element) => {
              return (
                !this.selectedOptions.includes(element) &&
                element.name.toLowerCase().indexOf(this.search.toLowerCase()) >=
                  0
              );
            })
          : this.data
        : [];
    },
  },
  data() {
    return {
      search: "",
      selectedOptions: this.$props["modelValue"],
    };
  },
  methods: {
    addSelect(option) {
      if (!this.selectedOptions) {
        this.selectedOptions = [];
      }
      if (option) {
        this.selectedOptions.push(option);
        this.search = "";
      }
    },
    removeSelect(option) {
      this.selectedOptions = this.selectedOptions.filter((element) => {
        return element !== option;
      });
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors.scss";

.selectedOption {
  background-color: $primary;
  color: $white;
  border-radius: 1rem;
  padding: 0.5rem;
  width: min-content;
}

.selectedOption a {
  color: $white;
}

.xmark {
  vertical-align: middle;
  padding-bottom: 0.2rem;
}
</style>
